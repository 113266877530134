import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Message } from "semantic-ui-react";
import { RootState } from "../store";
import { hideInfoMessage } from "../store/ui/infoMessage";
import "../stylesheets/InfoMessage.css";

const mapStateToProps = (state: RootState) => ({ ...state.ui.infoMessage });

const connector = connect(mapStateToProps, { hideInfoMessage });

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {} & PropsFromRedux;

const InfoMessage: React.FunctionComponent<Props> = props => (
  <Message
    id="info-message"
    onDismiss={() => props.hideInfoMessage()}
    hidden={!props.visible}
    floating
    compact
  >
    <Message.Header>{props.header}</Message.Header>
    <label>{props.content}</label>
  </Message>
);

export default connector(InfoMessage);
