import { isEmpty, isNull } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Button, Grid } from "semantic-ui-react";
import { zoningSteps } from "../../config/config";
import * as projectCtrl from "../../controllers/projects";
import * as zoningCtrl from "../../controllers/zoning";
import * as projectActions from "../../store/projects";
import * as zoningActions from "../../store/zoning";
import SetbacksFt from "../SetbacksFt";
import SetbacksPercent from "../SetbacksPercent";
import Steps from "../Steps";
import WizardStep from "../WizardStep";

const actionsObj = Object.assign({}, zoningActions, zoningCtrl, projectActions, projectCtrl);

const footerButtons = props => {
  return (
    <div>
      <Button
        onClick={() => {
          props.setActiveModalForm(zoningSteps["zoning_step_2"]);
        }}
      >
        Back
      </Button>
      <Button
        onClick={() => {
          props.submitSetbacks(props.usePercent);
        }}
      >
        Next
      </Button>
    </div>
  );
};

export const SetbacksStepFooter = connect(state => {
  const cityId = state.projects.selectedProject.parcel_data.city_id;
  const city = state.cities.citiesList.find(c => parseInt(c.id) === cityId);
  return {
    usePercent: city.enablePercentageSetbacks
  };
}, actionsObj)(footerButtons);

class SetbacksStep extends WizardStep {
  handleBackClick(e) {
    e.preventDefault();
    this.props.setActiveModalForm("zoningPt2");
  }

  render() {
    const stepData = [
      { title: "Zoning", active: false, disabled: false },
      { title: "Height & Density", active: false, disabled: false },
      { title: "Setbacks", active: true, disabled: false },
      { title: "Open Space", active: false, disabled: true },
      { title: "Parking", active: false, disabled: true }
    ];
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Steps stepData={stepData} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              {this.props.usePercent ? (
                <SetbacksPercent onSubmit={this.props.handleSetbacksSubmit} />
              ) : (
                <SetbacksFt onSubmit={this.props.handleSetbacksSubmit} />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { zoningValues, zoningInitialValues } = state.zoning;
  const cityId = state.projects.selectedProject.parcel_data.city_id;
  const zoningExists = !isEmpty(zoningInitialValues);
  const city = state.cities.citiesList.find(c => parseInt(c.id) === cityId);
  return {
    setbackNotes: isNull(zoningValues.setback_notes) ? zoningValues.setback_notes : "",
    editMode: zoningExists,
    usePercent: city.enablePercentageSetbacks
  };
};

const SetbacksStepForm = reduxForm({
  form: "SetbacksStepForm",
  destroyOnUnmount: false
})(SetbacksStep);

export default connect(mapStateToProps, actionsObj)(SetbacksStepForm);
