import React from "react";
import { Segment } from "semantic-ui-react";

import LayerToggler from "./LayerToggler";

class BuildingsLayerToggler extends React.Component<{
  isVisible: boolean;
  toggleVisibility: any;
}> {
  render() {
    return (
      <Segment>
        <LayerToggler
          isVisible={this.props.isVisible}
          label="OpenStreetMap Buildings"
          toggleVisibility={this.props.toggleVisibility}
        />
      </Segment>
    );
  }
}

export default BuildingsLayerToggler;
