import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { Button, Divider, Form, Grid, Popup, Segment } from "semantic-ui-react";
import {
  isZeroOrPositiveNumeric,
  required,
  validateBasisBoost,
  validateCapRate,
  validateDebtServiceRatio,
  validateInt,
  validatePercentage
} from "../../config/config";
import { sourceOfFundsDefaults } from "../../config/defaultValues";
import { formatDollars } from "../../config/utils";
import * as proformaCtrl from "../../controllers/proforma";
import { is } from "../../lib";
import * as proformaActions from "../../store/proforma";
import * as projectActions from "../../store/projects";
import { getSelectedBuilding, getSelectedBuildingId } from "../../store/selectors/projects";
import Steps from "../Steps";
import WizardStep from "../WizardStep";
import { CheckboxInput, isToggleChecked } from "./../forms/CheckboxInput";
import TextInput from "./../forms/TextInput";
import { DemoMessage } from "./DemoMessage";

const actionObj = Object.assign({}, projectActions, proformaActions, proformaCtrl);
const footerButtons = props => {
  return (
    <div>
      <DemoMessage isDemoBuilding={props.isDemoBuilding} />
      <Button
        onClick={() => {
          props.setActiveModalForm("OperatingStep");
        }}
      >
        Back
      </Button>
      <Button
        disabled={props.isDemoBuilding}
        onClick={() => {
          props.submitSourceOfFundsStep();
        }}
      >
        Next
      </Button>
    </div>
  );
};

export const SourceOfFundsStepFooter = connect(state => {
  return {
    isDemoBuilding: is.null(getSelectedBuildingId(state))
  };
}, actionObj)(footerButtons);

class SourceOfFundsStep extends WizardStep {
  constructor(props) {
    super(props);
    this.state = {
      managerUnits: 0
    };
  }

  //QCT - Qualified census tract
  handleQctCheck(e) {
    const { ddaChecked } = this.props;
    if (e.target.checked) {
      this.props.updateBasisBoost(
        ddaChecked
          ? sourceOfFundsDefaults.basis_boost_checked
          : sourceOfFundsDefaults.dda_or_qct_checked
      );
    } else {
      this.props.updateBasisBoost(ddaChecked ? sourceOfFundsDefaults.dda_or_qct_checked : null);
    }
  }
  //DDA - Difficult to develop area
  handleDdaCheck(e) {
    const { qctChecked } = this.props;
    if (e.target.checked) {
      this.props.updateBasisBoost(
        qctChecked
          ? sourceOfFundsDefaults.basis_boost_checked
          : sourceOfFundsDefaults.dda_or_qct_checked
      );
    } else {
      this.props.updateBasisBoost(qctChecked ? sourceOfFundsDefaults.dda_or_qct_checked : null);
    }
  }

  render() {
    const {
      handleSubmit,
      taxCreditValue,
      qctChecked,
      ddaChecked,
      otherChecked,
      eligibleBasis,
      isSelectedProjectInsideUSA
    } = this.props;
    this.props.setEligibleBasis(eligibleBasis);

    const stepData = [
      { title: "Development Costs", active: false, disabled: false },
      { title: "Operating Period Assumptions", active: false, disabled: false },
      { title: "Sources of Funds", active: true, disabled: false }
    ];

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Steps stepData={stepData} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form onSubmit={handleSubmit}>
                <Segment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <h5>Debt</h5>
                        <Divider />
                        <Field
                          name="has_mortgage"
                          label="First Mortgage"
                          component={CheckboxInput}
                          toggle
                          readOnly={this.readOnlyMode}
                        />

                        {isToggleChecked(this.props.mortgageToggle) ? (
                          <div style={{ marginTop: "10px" }}>
                            <h5>First Mortgage</h5>
                            <Field
                              name="interest_rate"
                              component={TextInput}
                              label="Interest Rate"
                              unitLabel="%"
                              labelPosition="right"
                              readOnly={this.readOnlyMode}
                              validate={[isZeroOrPositiveNumeric, validatePercentage, required]}
                            />
                            <Field
                              name="armortization_period"
                              component={TextInput}
                              label="Amortization Period"
                              validate={[validateInt, required]}
                              readOnly={this.readOnlyMode}
                            />
                            <Field
                              name="debt_service_coverage_ratio"
                              component={TextInput}
                              label="DSCR"
                              validate={[
                                isZeroOrPositiveNumeric,
                                required,
                                validateDebtServiceRatio
                              ]}
                              readOnly={this.readOnlyMode}
                            />
                            <Field
                              name="origination_fee"
                              component={TextInput}
                              label="Origination Fee"
                              unitLabel="%"
                              labelPosition="right"
                              readOnly={this.readOnlyMode}
                              validate={[isZeroOrPositiveNumeric, validatePercentage, required]}
                            />
                            <Field
                              name="other_debt"
                              component={TextInput}
                              label="Other Debt"
                              unitLabel="$"
                              labelPosition="right"
                              readOnly={this.readOnlyMode}
                              validate={[validateInt, required]}
                            />
                          </div>
                        ) : null}
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <h5>Equity</h5>
                        <Divider />
                        {isSelectedProjectInsideUSA ? (
                          <Field
                            name="has_LIHTC"
                            label="Add LIHTC Equity"
                            component={CheckboxInput}
                            toggle
                            tooltip="Add Low Income Housing Tax Credit Equity"
                            readOnly={this.readOnlyMode}
                          />
                        ) : null}
                        {isToggleChecked(this.props.LIHTCToggle) ? (
                          <div>
                            <label>Type: </label>
                            <div style={{ display: "flex" }}>
                              <div style={{ marginRight: "5px" }}>
                                <Field
                                  name="tax_credit"
                                  component="input"
                                  type="radio"
                                  value="four"
                                  checked={taxCreditValue === "four"}
                                  onChange={() => {
                                    this.props.changeTaxCreditRadio(3.3);
                                  }}
                                  readOnly={this.readOnlyMode}
                                />
                              </div>
                              <div style={{ marginRight: "10px" }}>
                                <span>4%</span>
                              </div>
                              <div style={{ marginRight: "5px" }}>
                                <Field
                                  name="tax_credit"
                                  component="input"
                                  type="radio"
                                  value="nine"
                                  checked={taxCreditValue === "nine"}
                                  onChange={() => {
                                    this.props.changeTaxCreditRadio(7.7);
                                  }}
                                  readOnly={this.readOnlyMode}
                                />
                              </div>
                              <span>9%</span>
                            </div>
                            <br />
                            <p>Eligible Basis: {formatDollars(eligibleBasis)}</p>
                            <Field
                              name="tax_credit_percentage"
                              component={TextInput}
                              label="Tax Credit Percentage"
                              unitLabel="%"
                              labelPosition="right"
                              readOnly={this.readOnlyMode}
                              validate={[isZeroOrPositiveNumeric, validatePercentage]}
                            />
                            <Field
                              name="tax_credit_pricing"
                              component={TextInput}
                              label="Tax Credit Pricing"
                              unitLabel="$"
                              labelPosition="left"
                              readOnly={this.readOnlyMode}
                              validate={[isZeroOrPositiveNumeric]}
                            />
                            <Field
                              name="percent_affordable"
                              component={TextInput}
                              label="Percent Affordable"
                              unitLabel="%"
                              labelPosition="right"
                              readOnly={this.readOnlyMode}
                              validate={[isZeroOrPositiveNumeric, validatePercentage]}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around"
                              }}
                            >
                              <Field
                                name="basis_boost"
                                component={TextInput}
                                label="Basis Boost"
                                unitLabel="%"
                                labelPosition="right"
                                validate={[validateBasisBoost]}
                                onChange={(e, data) => {
                                  if (
                                    data === sourceOfFundsDefaults.basis_boost_checked.toString()
                                  ) {
                                    this.props.updateDdaQct([
                                      { field: "qct", value: true },
                                      { field: "dda", value: true }
                                    ]);
                                  } else if (
                                    data === sourceOfFundsDefaults.dda_or_qct_checked.toString()
                                  ) {
                                    this.props.updateDdaQct([
                                      { field: "qct", value: true },
                                      { field: "dda", value: false }
                                    ]);
                                  } else {
                                    this.props.updateDdaQct([
                                      { field: "qct", value: false },
                                      { field: "dda", value: false }
                                    ]);
                                  }
                                }}
                                readOnly={this.readOnlyMode}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between"
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                  <Field
                                    name="qct"
                                    component="input"
                                    type="checkbox"
                                    checked={qctChecked}
                                    onClick={e => {
                                      this.handleQctCheck(e);
                                    }}
                                    readOnly={this.readOnlyMode}
                                  />
                                  <Popup
                                    content="Qualified Census Tract"
                                    trigger={<label style={{ marginLeft: "5px" }}>QCT</label>}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                  <Field
                                    name="dda"
                                    component="input"
                                    type="checkbox"
                                    checked={ddaChecked}
                                    onClick={e => {
                                      this.handleDdaCheck(e);
                                    }}
                                  />
                                  <Popup
                                    content="Difficult to Develop Area"
                                    trigger={<label style={{ marginLeft: "5px" }}>DDA</label>}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                  <Field
                                    name="basis_other"
                                    component="input"
                                    type="checkbox"
                                    checked={otherChecked}
                                    readOnly={this.readOnlyMode}
                                  />
                                  <label style={{ marginLeft: "5px" }}>Other</label>
                                </div>
                              </div>
                            </div>
                            <Field
                              name="compliance_period"
                              component={TextInput}
                              label="Compliance Period"
                              unitLabel="Years"
                              labelPosition="left"
                              validate={[validateInt]}
                              readOnly={this.readOnlyMode}
                            />
                          </div>
                        ) : (
                          <div />
                        )}
                        {isSelectedProjectInsideUSA ? <br /> : null}
                        <Field
                          name="other_equity"
                          component={TextInput}
                          label="Other Equity"
                          unitLabel="$"
                          labelPosition="left"
                          validate={[validateInt]}
                          readOnly={this.readOnlyMode}
                        />
                        <Field
                          name="residential_cap_rate"
                          component={TextInput}
                          label="Residential Capitalization Rate"
                          unitLabel="%"
                          labelPosition="left"
                          validate={[isZeroOrPositiveNumeric, validateCapRate]}
                          readOnly={this.readOnlyMode}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const selector = formValueSelector("SourceOfFundsStepForm");

export const mapStateToProps = state => {
  const selectedBuilding = getSelectedBuilding(state);
  const devcostsOutput = state.proforma.devcostsData.outputs;
  const devcostsInput = state.proforma.devcostsData.inputs;
  const { isSelectedProjectInsideUSA } = state.projects;

  let eligibleBasis = devcostsOutput.preliminary_total_development_costs;
  if (devcostsInput.development_cost_type === "use_complete_development_costs") {
    eligibleBasis -= devcostsInput.acquistion_cost;
    eligibleBasis -=
      devcostsInput.commercial_construction_cost_per_sf *
      selectedBuilding.actual_commercial_retail_gsf;
  }

  return {
    LIHTCToggle: selector(state, "has_LIHTC"),
    mortgageToggle: selector(state, "has_mortgage"),
    taxCreditValue: selector(state, "tax_credit"),
    qctChecked: selector(state, "qct"),
    ddaChecked: selector(state, "dda"),
    otherChecked: selector(state, "basis_other"),
    eligibleBasis: eligibleBasis,
    initialValues: {
      has_LIHTC: selectedBuilding.finance
        ? selectedBuilding.finance.inputs.has_LIHTC
        : sourceOfFundsDefaults.has_LIHTC,
      has_mortgage: selectedBuilding.finance
        ? selectedBuilding.finance.inputs.has_mortgage
        : sourceOfFundsDefaults.has_mortgage,
      interest_rate: selectedBuilding.finance
        ? (selectedBuilding.finance.inputs.interest_rate * 100).toFixed(2)
        : sourceOfFundsDefaults.int_rate,
      armortization_period: selectedBuilding.finance
        ? selectedBuilding.finance.inputs.armortization_period
        : sourceOfFundsDefaults.years,
      debt_service_coverage_ratio: selectedBuilding.finance
        ? selectedBuilding.finance.inputs.debt_service_coverage_ratio
        : sourceOfFundsDefaults.dcr,
      origination_fee: selectedBuilding.finance
        ? (selectedBuilding.finance.inputs.origination_fee * 100).toFixed(2)
        : sourceOfFundsDefaults.origination_fee.toFixed(2),
      other_debt: selectedBuilding.finance
        ? selectedBuilding.finance.inputs.other_debt
        : sourceOfFundsDefaults.other_debt,
      tax_credit: selectedBuilding.finance
        ? selectedBuilding.finance.inputs.tax_credit_percentage === 0.033
          ? "four"
          : "nine"
        : sourceOfFundsDefaults.tax_credit,
      tax_credit_percentage: selectedBuilding.finance
        ? (selectedBuilding.finance.inputs.tax_credit_percentage * 100).toFixed(2)
        : sourceOfFundsDefaults.tax_credit_pct,
      tax_credit_pricing: selectedBuilding.finance
        ? selectedBuilding.finance.inputs.tax_credit_pricing
        : sourceOfFundsDefaults.tax_credit_pricing,
      percent_affordable: selectedBuilding.finance
        ? (selectedBuilding.finance.inputs.percent_affordable * 100).toFixed(2)
        : sourceOfFundsDefaults.percent_affordable,
      qct: selectedBuilding.finance
        ? selectedBuilding.finance.inputs.basis_boost * 100 ===
            sourceOfFundsDefaults.basis_boost_checked ||
          selectedBuilding.finance.inputs.basis_boost * 100 ===
            sourceOfFundsDefaults.dda_or_qct_checked
        : false,
      dda: selectedBuilding.finance
        ? selectedBuilding.finance.inputs.basis_boost * 100 ===
          sourceOfFundsDefaults.basis_boost_checked
        : false,
      basis_boost: selectedBuilding.finance
        ? (selectedBuilding.finance.inputs.basis_boost * 100).toFixed()
        : sourceOfFundsDefaults.basis_boost,
      compliance_period: selectedBuilding.finance
        ? selectedBuilding.finance.inputs.compliance_period
        : sourceOfFundsDefaults.compliance_period,
      other_equity: selectedBuilding.finance
        ? selectedBuilding.finance.inputs.other_equity
        : sourceOfFundsDefaults.other_equity,
      residential_cap_rate:
        selectedBuilding.finance && selectedBuilding.finance.inputs.residential_cap_rate > 0
          ? (selectedBuilding.finance.inputs.residential_cap_rate * 100).toFixed(2)
          : sourceOfFundsDefaults.residential_cap_rate
    },
    isSelectedProjectInsideUSA: isSelectedProjectInsideUSA
  };
};

const SourceOfFundsStepForm = reduxForm({
  form: "SourceOfFundsStepForm",
  destroyOnUnmount: false
})(SourceOfFundsStep);

export default connect(mapStateToProps, actionObj)(SourceOfFundsStepForm);
