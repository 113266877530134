import {
  actions,
  ownerships,
  resources,
  PermissionAction,
  PermissionOwnership,
  PermissionResource
} from "../constants/permissions";
import store from "../store";

/**
 * Check if logged in user is allowed to perform the desired action in the target resource.
 *
 * @param {PermissionAction} desiredAction
 * @param {PermissionOwnership} targetOwnership
 * @param {PermissionResource} targetResource
 *
 * @return {Boolean}
 */
export const userIsAllowedTo = (
  desiredAction: PermissionAction,
  targetOwnership: PermissionOwnership,
  targetResource: PermissionResource
): boolean => {
  for (const [source, target, errorString] of [
    [actions, desiredAction, "desired action"],
    [ownerships, targetOwnership, "target ownership"],
    [resources, targetResource, "target resource"]
  ]) {
    if (!Object.values(source).includes(target as string)) {
      console.warn(
        `permissions::userIsAllowedTo -> Unkown ${errorString} '${target}'. Returning false.`
      );
      return false;
    }
  }

  const userPermissions = store.getState().users.permissions;
  const hasTargetResource = targetResource in userPermissions;
  const isAllowed =
    hasTargetResource &&
    (userPermissions[targetResource] || []).some(
      permission => permission.code === desiredAction && permission.ownership === targetOwnership
    );

  return isAllowed;
};

/**
 * Check if logged in user is allowed to perform any of the desired actions in the desired
 * resource.
 *
 * @param {Array<PermissionAction> | PermissionAction} desiredActions
 * @param {Array<PermissionOwnership> | PermissionOwnership} targetOwnerships
 * @param {PermissionResource} targetResource
 *
 * @return {Boolean}
 */
export const userIsAllowedToAny = (
  desiredActions: Array<PermissionAction> | PermissionAction,
  targetOwnerships: Array<PermissionOwnership> | PermissionOwnership,
  targetResource: PermissionResource
): boolean => {
  if (typeof desiredActions === "string") desiredActions = [desiredActions];

  if (typeof targetOwnerships === "string") targetOwnerships = [targetOwnerships];

  let isAllowed = false;
  for (const action of desiredActions)
    for (const ownership of targetOwnerships)
      isAllowed = isAllowed || userIsAllowedTo(action, ownership, targetResource);

  return isAllowed;
};
