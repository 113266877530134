import React, { Component, MouseEventHandler, FormEventHandler } from "react";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { Modal, Button, Form } from "semantic-ui-react";
import { Operation } from "../store/operations";
import { InformativeButton } from "./common";
import TextInput from "./forms/TextInput";

type CustomProps = {
  what: "Building" | "Project";
  open: boolean;
  initialValues: { name: string };
  cancelAction: MouseEventHandler;
  saveAction: MouseEventHandler;
  operation: Operation;
};

type RenameModalProps = CustomProps & InjectedFormProps<{}, CustomProps>;
type RenameModalState = {
  nameBeforeChange: string;
};

class RenameModal extends Component<RenameModalProps, RenameModalState> {
  constructor(props: RenameModalProps) {
    super(props);
    this.state = { nameBeforeChange: props.initialValues.name };
  }

  componentDidUpdate(prevProps: RenameModalProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ nameBeforeChange: this.props.initialValues.name });
    }
  }
  render() {
    const { open, cancelAction, saveAction, operation, what } = this.props;
    return (
      <Modal
        as={Form}
        open={open || operation.justSucceeded}
        onSubmit={saveAction}
        onClose={cancelAction}
        autoFocus={false}
      >
        <Modal.Header>
          Rename {what}: {this.state.nameBeforeChange}
          <Button
            style={{ float: "right" }}
            circular
            icon="cancel"
            onClick={cancelAction}
            type="button"
          />
        </Modal.Header>
        <Modal.Content>
          <Field
            name="name"
            label={`New ${what} Name: `}
            component={TextInput}
            form="RenameModalForm"
            onSubmit={saveAction as FormEventHandler}
            required
            autoFocus={true}
            readOnly={operation.isLoading || operation.justSucceeded}
          />
        </Modal.Content>
        <Modal.Actions>
          <InformativeButton operation={operation} onClick={saveAction} content="Save" />
        </Modal.Actions>
      </Modal>
    );
  }
}

const RenameModalForm = reduxForm<{}, CustomProps>({
  form: "RenameModalForm",
  enableReinitialize: true
})(RenameModal);

export default RenameModalForm;
