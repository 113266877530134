// Note (@nicoluce): The implementation of this draw mode has been based on
// https://github.com/mapbox/mapbox-gl-draw/blob/main/src/modes/draw_polygon.js

import { area, bearing, distance, lineArc } from "@turf/turf";
import { LngLat } from "mapbox-gl";
import { sqMetersToSqFeet } from "../../../../config/config";
import {
  formatCommas,
  formatDistanceLabel,
  getShortAreaLabel,
  latitudeIndex,
  longitudeIndex
} from "../../../../config/utils";
import store from "../../../../store";
import { DrawFeature, DrawMode, DrawMouseEvent } from "../../commonTypes";
import { AngleSnappingValue, Modes, multiLineId, projectBoundaryId } from "../../constants";
import {
  angleBetween,
  createSupplementaryPoints,
  isFeatureIntersectingItself,
  removeRepeatedLastCoord,
  snapAtAngle
} from "../../utils";
import { getRandomId, removeParcels } from "./utils";

type EditPolygonState = {
  originalPolygon: DrawFeature;
  editedPolygon: DrawFeature;
  draggedCoordPath: string | null;
  dragPreviousLocation: LngLat | null;
  isDragging: boolean;
  isMetric: boolean;
};

const EditPolygon: DrawMode = {
  onSetup: function(opts: { polygonId: string }) {
    const originalPolygon = this.getFeature(opts?.polygonId ?? projectBoundaryId);
    const editedPolygon = this.newFeature({
      type: "Feature",
      properties: {
        intersects: "false"
      },
      geometry: {
        type: "Polygon",
        coordinates: [[]]
      }
    });

    if (originalPolygon !== undefined) {
      let coords = originalPolygon.getCoordinates()[0];
      // Note(@nicoluce): getCoordinates() adds a duplicate of the first coordinate
      // to the end of the list we need to remove it before copying them
      coords = removeRepeatedLastCoord(coords);
      editedPolygon.setCoordinates([coords]);
    }

    this.addFeature(editedPolygon);

    this.clearSelectedFeatures();
    this.setSelected(editedPolygon.id);

    // Note (@nicoluce): If the user presses Enter instead of clicking the Confirm button
    // this event handler will remain. See onConfirmation for more information about this scenario
    const modeId = getRandomId();
    editedPolygon.properties.modeId = modeId;
    this.map.once("draw.confirm", () => this.onConfirmation(modeId));

    this.state = {
      originalPolygon,
      editedPolygon,
      draggedCoordPath: null,
      dragPreviousLocation: null,
      isDragging: false,
      isMetric: store.getState().users.metric
    };
    return this.state;
  },

  isIntersecting: function(polygon: DrawFeature) {
    return isFeatureIntersectingItself(polygon);
  },

  onConfirmation: function(modeId: string) {
    try {
      const { originalPolygon, editedPolygon } = this.state;

      // Note (@nicoluce): Since the function can be executed when the event draw.confirm is fired,
      // we use the randomId to prevent any old dangling event handler from executing along with
      // the current one.
      if (modeId !== editedPolygon?.properties?.modeId) return;

      if (editedPolygon.properties.intersects === "false") {
        let coords = editedPolygon.getCoordinates()[0];
        coords = removeRepeatedLastCoord(coords);
        originalPolygon.setCoordinates([coords]);
        const geometry = originalPolygon.toGeoJSON().geometry;

        removeParcels(this);
        this.deleteFeature(projectBoundaryId);
        this.deleteFeature(multiLineId);
        return this.changeMode(Modes.site_selected, {
          sites: [geometry],
          projectBoundary: geometry
        });
      }
    } catch (err) {
      console.error(err);
    }
  },

  onMouseDown: function(state: EditPolygonState, e: DrawMouseEvent) {
    if (e.originalEvent.button === 0 && e.featureTarget !== undefined) {
      const target = e.featureTarget;
      switch (target.properties.meta) {
        case "vertex":
          this.onVertexDrag(state, e);
          break;
        case "midpoint":
          this.onMidpointDrag(state, e);
          break;
      }
    }
  },

  onMouseUp: function(state: EditPolygonState) {
    this.stopDragging(state);
  },

  onMouseMove: function(state: EditPolygonState, e: DrawMouseEvent) {
    const target = e.featureTarget;
    if (state.isDragging) {
      this.updateUIClasses({ mouse: "move" });
    } else if (target !== undefined && target.properties.meta === "vertex") {
      // on vertex hover
      this.updateUIClasses({ mouse: "drag" });
    }
  },

  onClick: function(state: EditPolygonState, e: DrawMouseEvent) {
    const target = e.featureTarget;
    if (target !== undefined && target.properties.meta === "vertex") {
      const vertexPath = e.featureTarget.properties.coord_path;
      state.draggedCoordPath = vertexPath;
      if (e.originalEvent.button === 2 && !state.isDragging) {
        state.editedPolygon.removeCoordinate(vertexPath);

        if (state.editedPolygon.getCoordinates().length === 0) {
          return this.changeMode(Modes.site_selected);
        }

        state.editedPolygon.properties.intersects = String(
          this.isIntersecting(state.editedPolygon)
        );
      }
    }
  },

  onVertexDrag: function(state: EditPolygonState, e: DrawMouseEvent) {
    this.startDragging(state, e);
    state.draggedCoordPath = e.featureTarget.properties.coord_path;
    this.doRender(multiLineId);
  },

  onMidpointDrag: function(state: EditPolygonState, e: DrawMouseEvent) {
    this.startDragging(state, e);
    const about = e.featureTarget.properties;
    state.editedPolygon.addCoordinate(about.coord_path, about.lng, about.lat);
    state.draggedCoordPath = about.coord_path;
    this.doRender(multiLineId);
  },

  onDrag: function(state: EditPolygonState, e: DrawMouseEvent) {
    if (state.draggedCoordPath === null) return;
    state.isDragging = true;
    e.originalEvent.stopPropagation();

    const newCoord = [e.lngLat.lng, e.lngLat.lat];
    this.updateVertexPosition(state, state.draggedCoordPath, newCoord, e.originalEvent.shiftKey);
  },

  startDragging: function(state: EditPolygonState, e: DrawMouseEvent) {
    this.map.dragPan.disable();
    state.draggedCoordPath = null;
  },

  stopDragging: function(state: EditPolygonState) {
    this.map.dragPan.enable();
    state.isDragging = false;
  },

  updateVertexPosition: function(
    state: EditPolygonState,
    vertexPath: string,
    newPosition: [number, number],
    snapAtAngles = false
  ) {
    if (snapAtAngles) {
      const [, vertexIdx] = vertexPath.split(".").map(Number);
      const coords = state.editedPolygon.getCoordinates()[0];
      coords.pop();
      for (let i = 0; i < coords.length; i++) {
        if (vertexIdx === i) continue;
        const p1 = coords[i];
        const prevIdx = i === 0 ? coords.length - 1 : i - 1;
        const nextIdx = i === coords.length - 1 ? 0 : i + 1;
        const p2 = nextIdx === vertexIdx ? coords[prevIdx] : coords[nextIdx];

        const angle = Math.abs(angleBetween(p2, p1, newPosition)) % AngleSnappingValue;
        const diff = Math.min(angle, AngleSnappingValue - angle);
        if (diff < 2) {
          newPosition = snapAtAngle(p2, p1, newPosition) || newPosition;
        }
      }
    }

    // If line.coordinates.length === vertexIdx
    // line.updateCoordinate() will create and add the new vertex
    state.editedPolygon.updateCoordinate(vertexPath, newPosition[0], newPosition[1]);

    // Check for intersections
    state.editedPolygon.properties.intersects = String(this.isIntersecting(state.editedPolygon));
  },

  onKeyUp: function(state: EditPolygonState, e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.deleteFeature([state.editedPolygon.id], { silent: true });
      this.changeMode(Modes.site_selected);
    } else if (e.key === "Enter") {
      this.onConfirmation(state.editedPolygon.properties.modeId);
    }
  },

  displayMeasurements: function(polygon: DrawFeature, display: Function) {
    const coords = [...polygon.geometry.coordinates[0]];
    coords.pop();
    coords.forEach((p2: [number, number], coordinateId: number) => {
      const prevIdx = coordinateId === 0 ? coords.length - 1 : coordinateId - 1;

      const p1 = coords[prevIdx];

      const geometry = {
        type: "LineString",
        coordinates: [
          [p1[longitudeIndex], p1[latitudeIndex]],
          [p2[longitudeIndex], p2[latitudeIndex]]
        ]
      };
      display({
        type: "Feature",
        properties: {
          active: "true",
          parent: polygon.properties.id,
          user_length: formatDistanceLabel(geometry)
        },
        geometry: geometry
      });

      if (coords.length <= 2) return;
      const nextIdx = (coordinateId + 1) % coords.length;
      const p3 = coords[nextIdx];
      const b1 = bearing(p2, p1);
      const b2 = bearing(p2, p3);

      let fb1 = Math.min(b1, b2);
      let fb2 = Math.max(b1, b2);

      if (Math.abs(fb2 - fb1) > 180) {
        const swap = fb1;
        fb1 = fb2;
        fb2 = swap;
      }

      const dist12 = distance(p1, p2, { units: "kilometers" });
      const dist23 = distance(p2, p3, { units: "kilometers" });

      const arcRadius = Math.min(0.003, dist12, dist23);
      const angle = Math.round((fb2 - fb1 + 360) % 180);

      if (arcRadius < 2 ** -11 || angle < 2 ** -11) return;

      const angleArc = lineArc(p2, arcRadius, fb1, fb2);

      display({
        type: "Feature",
        properties: {
          parent: polygon.properties.id,
          active: "true",
          user_length: `${angle}°`
        },
        geometry: angleArc.geometry
      });
    });
  },

  displayProjectBoundary: function(
    state: EditPolygonState,
    geojson: DrawFeature,
    display: Function
  ) {
    geojson.properties.meta = "feature";
    geojson.properties.active = "true";
    display(geojson);
    geojson.properties.user_label = `${formatCommas(
      Math.round(area(geojson.geometry) * (state.isMetric ? 1 : sqMetersToSqFeet))
    )} ${getShortAreaLabel()}`;
    this.displayMeasurements(geojson, display);
    this.map.fire("draw.canSubdivide", {
      canSubdivide: geojson.properties.user_intersects === "false"
    });
  },

  toDisplayFeatures: function(state: EditPolygonState, geojson: DrawFeature, display: Function) {
    const isActivePolygon = geojson.properties.id === state.editedPolygon.id;
    if (!isActivePolygon) return;
    geojson.properties.active = String(isActivePolygon);

    createSupplementaryPoints(geojson, {
      parentId: state.editedPolygon.id,
      midpoints: true,
      selectedPaths: state.draggedCoordPath ? [state.draggedCoordPath] : []
    }).forEach(point => display(point));
    return this.displayProjectBoundary(state, geojson, display);
  },

  onStop: function(state: EditPolygonState) {
    this.deleteFeature(state.editedPolygon.id, { silent: true });
    if (state.originalPolygon.id !== projectBoundaryId) {
      this.deleteFeature(state.originalPolygon.id, { silent: true });
    }
  }
};

export default EditPolygon;
