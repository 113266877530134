import React, { Component, MouseEventHandler } from "react";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { Modal, Button, Form } from "semantic-ui-react";
import { Operation } from "../store/operations";
import { Region } from "../types/region";
import { InformativeButton } from "./common";
import DropdownInput from "./forms/DropdownInput";

type CustomProps = {
  open: boolean;
  initialValues: { id: number };
  cancelAction: MouseEventHandler;
  saveAction: MouseEventHandler;
  regions: Region[];
  regionSwitchingOperation: Operation;
};

class RegionSelector extends Component<CustomProps & InjectedFormProps<{}, CustomProps>> {
  render() {
    const {
      open,
      cancelAction,
      saveAction,
      initialValues,
      regions,
      regionSwitchingOperation
    } = this.props;
    return (
      <Modal as={Form} open={open} onClose={cancelAction} autoFocus={false}>
        <Modal.Header>
          Select region
          <Button
            style={{ float: "right" }}
            circular
            icon="cancel"
            onClick={cancelAction}
            type="button"
          />
        </Modal.Header>
        <Modal.Content>
          <Field
            name="region-selector"
            component={DropdownInput}
            placeholder="Select region"
            fluid
            selection
            options={regions.map(item => {
              return { text: item.name, value: item.id };
            })}
            defaultValue={initialValues.id}
          />
        </Modal.Content>
        <Modal.Actions>
          <InformativeButton
            content={"Switch"}
            operation={regionSwitchingOperation}
            onClick={saveAction}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const RegionSelectorForm = reduxForm<{}, CustomProps>({
  form: "RegionSelectorForm"
})(RegionSelector);

export default RegionSelectorForm;
