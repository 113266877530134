import React from "react";
import { Field } from "redux-form";
import { Button, Modal } from "semantic-ui-react";
import { required } from "./../config/config";
import TextInput from "./forms/TextInput";

export const ZoningSaveButton = props => {
  return props.enabled ? (
    <Button
      onClick={() => {
        props.confirm(true);
      }}
    >
      Save to Favorites
    </Button>
  ) : null;
};

export const ZoningSaveModal = props => {
  return (
    <Modal size="mini" open={props.open}>
      <Modal.Header>
        Save to Favorites
        <Button style={{ float: "right" }} circular icon="cancel" onClick={props.cancelAction} />
      </Modal.Header>
      <Modal.Content>
        <Field
          name="zoning_name"
          label="New Zoning Name: "
          component={TextInput}
          validate={[required]}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.saveAction}>Save</Button>
      </Modal.Actions>
    </Modal>
  );
};
