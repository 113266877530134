import React from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { Router, Route } from "react-router-dom";
import Authenticated from "./components/Authenticated";
import ErrorBoundary from "./components/ErrorBoundary";
import history from "./components/history";
import "./App.css";
import { googleAnalyticsId } from "./config/config";
import env from "./config/env";
import { AppStore } from "./store";
import MainView from "./views/MainView";

type Props = {
  store: AppStore;
};

class App extends React.Component<Props> {
  componentDidMount() {
    ReactGA.initialize(googleAnalyticsId, { gaOptions: { cookieDomain: "auto" } });
    ReactGA.pageview("/");
    // NODE_ENV is handled by create-react-app and it's "production" for all deployed versions.
    // We want to add the error-overlay for our non-productive, deployed environments.
    if (env.isStagingEnvironment) {
      import("react-error-overlay").then(overlay => {
        overlay.startReportingRuntimeErrors({
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onError: () => {}
        });
      });
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <Provider store={this.props.store}>
          <Router history={history}>
            <div>
              <Route
                exact
                path="/"
                render={props => <Authenticated component={MainView} {...props} />}
              />
            </div>
          </Router>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
