import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ZoningSlice, Zoning, ZoningIdentifier } from "./types";

const initialState: ZoningSlice = {
  isComplete: false,
  parcelZoning: [],
  userDefinedZonings: [],
  selectedZoning: {},
  listIsLoading: false,
  zoningInitialValues: {},
  zoningValues: {},
  editZoning: false,
  confirmZoningFavorites: false,
  selectedZoningName: null,
  userZoningIsSelected: false,
  derivedZoningId: null,
  derivedZoningData: {}
};

const zoningSlice = createSlice({
  name: "zoning",
  initialState,
  reducers: {
    updateLoadingStatus(state, action: PayloadAction<boolean>) {
      state.listIsLoading = action.payload;
    },
    setZoningList(state, action: PayloadAction<{ regional: Zoning[]; userDefined: Zoning[] }>) {
      const { regional, userDefined } = action.payload;
      state.parcelZoning = regional;
      state.userDefinedZonings = userDefined;
    },
    addUserDefinedZoning(state, action: PayloadAction<ZoningIdentifier>) {
      state.userDefinedZonings.push(action.payload);
    },
    setSelectedZoning(state, action: PayloadAction<Zoning>) {
      state.selectedZoning = action.payload;
    },
    setZoningComplete(state, action: PayloadAction<boolean>) {
      state.isComplete = action.payload;
    },
    setZoningInitialValues(state, action: PayloadAction<Zoning>) {
      state.zoningInitialValues = action.payload;
    },
    setZoningValues(state, action: PayloadAction<Zoning>) {
      state.zoningValues = action.payload;
    },
    cleanZoningValues(state) {
      state.zoningValues = {};
    },
    toggleEditZoning(state, action: PayloadAction<boolean>) {
      state.editZoning = action.payload;
    },
    toggleConfirmZoningFavorites(state, action: PayloadAction<boolean>) {
      state.confirmZoningFavorites = action.payload;
    },
    setSelectedZoningName(state, action: PayloadAction<string>) {
      state.selectedZoningName = action.payload;
    },
    setUserZoningIsSelected(state, action: PayloadAction<boolean>) {
      state.userZoningIsSelected = action.payload;
    },
    setDerivedZoning(state, action: PayloadAction<{ zoningId: number | null; zoning: Zoning }>) {
      const { zoningId, zoning } = action.payload;
      state.derivedZoningId = zoningId;
      state.derivedZoningData = zoning;
    },
    cleanDerivedZoning(state) {
      state.derivedZoningId = null;
      state.derivedZoningData = {};
    }
  }
});

export const {
  updateLoadingStatus,
  setZoningList,
  addUserDefinedZoning,
  setSelectedZoning,
  setZoningComplete,
  setZoningInitialValues,
  setZoningValues,
  cleanZoningValues,
  toggleEditZoning,
  toggleConfirmZoningFavorites,
  setSelectedZoningName,
  setUserZoningIsSelected,
  setDerivedZoning,
  cleanDerivedZoning
} = zoningSlice.actions;

export default zoningSlice.reducer;
