import { BuildingTypeCode } from "../types/buildings";

export const palette = {
  border: "#212526",

  fullParcel: "#DCE7D2",
  reducedParcel: "#C7DAB2",
  fullParcelHover: "#00FFFF",
  hoverLineThickness: 2,
  lineThickness: 1,
  outlineColor: "#FFFFFF",

  groundFloor: "#355E5D",
  lightGroundFloor: "#858A88",

  evenResidentialFloors: "#0289B7",
  hoverEvenResidentialFloors: "#9a60a3",

  oddResidentialFloors: "#2AA9D2",
  hoverOddResidentialFloors: "#c983d4",

  podiumParkingFloors: "gray",

  interiorCirculation: "#F3ECE4",
  interiorOpenSpace: "#B8B4AB",

  unit0: "#8c96c6",
  unit1: "#9ebcda",
  unit2: "#bfd3e6",
  unit3: "#e0ecf4",
  unit4: "#f7fcfd",
  unit5: "#f7fcfd",
  sides: { primary: "#6EB5C9", hover: "#8ce6ff" },
  front: { primary: "#fe7452", hover: "#ffb19e" },
  rear: { primary: "#f3c56b", hover: "#fcdd9f" },
  defaultBoundary: "#808080",
  defaultBoundaryHover: "#D3D3D3",
  pieHover: ["#ffffff", "#d3dce2", "#a3c0d1", "#84a0b5", "#9db3c9"]
};

export const buildingFeatureColor = (buildingTypeCode, featureType = null, unitId = null) => {
  switch (buildingTypeCode) {
    case BuildingTypeCode.apartment:
      return _apartmentFeatureColor(featureType);
    case BuildingTypeCode.nplex:
      return _nplexFeatureColor(featureType, unitId);
    default:
      return "none";
  }
};

const _apartmentFeatureColor = unitType => {
  switch (unitType) {
    case "unit_0":
      return palette.unit0;
    case "unit_1":
      return palette.unit1;
    case "unit_2":
      return palette.unit2;
    case "unit_3":
      return palette.unit3;
    case "unit_4":
      return palette.unit4;
    case "free_space":
      return palette.interiorOpenSpace;
    case "corridor":
    case "elevator":
    case "stair":
      return palette.interiorCirculation;
    default:
      return "none";
  }
};

const _nplexFeatureColor = (unitType, unitId) => {
  switch (unitType) {
    case "unit_0":
    case "unit_1":
    case "unit_2":
    case "unit_3":
    case "unit_4":
      return unitId % 2 === 0 ? palette.unit0 : palette.unit2;
    case "free_space":
      return palette.interiorOpenSpace;
    case "corridor":
    case "elevator":
    case "stair":
      return palette.interiorCirculation;
    default:
      return "none";
  }
};
