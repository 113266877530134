import { RootState } from "../";
import { assert, NotFoundError } from "../../lib/errors";
import { Project } from "../../types/projects";
import { regionSelectorCreator } from "./region";

export const mainSelectorCreator = (state: RootState) => ({
  getProjectById: (id: number): Project => {
    assert.id(`'getProjectById' requires a valid id. Got ${JSON.stringify(id)} instead.`, id);
    const project = state.projects.projectList.byId[id];
    if (!project) {
      throw new NotFoundError(`Could not find project of id ${id}`);
    }
    return project;
  },
  getAllProjects: (): Project[] => {
    return state.projects.projectList.ids.map(id => state.projects.projectList.byId[id]);
  },
  region: regionSelectorCreator(state.regions)
});
