import { configureStore } from "@reduxjs/toolkit";
import { routerReducer } from "react-router-redux";
import { combineReducers, Action } from "redux";
import { reducer as formReducer } from "redux-form";
import thunkMiddleware, { ThunkDispatch } from "redux-thunk";

import { env } from "../config/env";

// Slices
import buildings from "./buildings";
import cities from "./cities";
import errors from "./errors";
import map, { setMapViewer } from "./map";
import operations from "./operations";
import proforma from "./proforma";
import programs from "./programs";
import projects from "./projects";
import regions from "./regions";
import ui, { updateMapViewport } from "./ui";
import users, { resetApp } from "./users";
import zoning from "./zoning";

const allReducers = combineReducers({
  ui,
  operations,
  users,
  projects,
  map,
  zoning,
  programs,
  buildings,
  proforma,
  cities,
  regions,
  errors,
  routing: routerReducer,
  form: formReducer
});

export type RootState = ReturnType<typeof allReducers>;

export const rootReducer = (state: RootState | undefined, action: Action): RootState => {
  // See https://stackoverflow.com/a/35641992/3456605
  if (action.type === resetApp.type) {
    state = undefined;
  }
  return allReducers(state, action);
};

const middleware = [thunkMiddleware];
const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: !env.isProductionEnvironment && {
    stateSanitizer: state => ({ ...state, map: "<<LONG_BLOB>>" }),
    actionSanitizer: action => {
      const sanitizedTypes = [setMapViewer.type, updateMapViewport.type];
      return sanitizedTypes.includes(action.type)
        ? { ...action, payload: "<<LONG_BLOB>>" }
        : action;
    }
  }
});

export type AppDispatch = ThunkDispatch<RootState, void, Action>;
export type AppStore = typeof store;

// In order for Cypress tests to access the store we need to assign it to window.store
if (env.isTestEnvironment) {
  window.store = store;
}

export default store;
