// Note (@nicoluce): The implementation of this draw mode has been based on
// https://github.com/mapbox/mapbox-gl-draw/blob/main/src/modes/draw_polygon.js

import { lineString, point } from "@turf/helpers";
import { bearing, destination, distance, lineArc, lineIntersect } from "@turf/turf";
import { formatDistanceLabel, latitudeIndex, longitudeIndex } from "../../../../config/utils";
import { DrawFeature, DrawMode, DrawMouseEvent } from "../../commonTypes";
import { AngleSnappingValue, boundarySnappingDistance, Modes } from "../../constants";
import {
  angleBetween,
  createSupplementaryPoints,
  isFeatureIntersectingItself,
  snapAtAngle
} from "../../utils";

type DrawPolygonState = {
  polygon: DrawFeature;
  currentVertexPosition: number;
  confirmed: boolean;
  lastMousePos: [number, number];
};

const DrawPolygon: DrawMode = {
  onSetup: function() {
    const polygon = this.newFeature({
      type: "Feature",
      properties: {
        intersects: "false"
      },
      geometry: {
        type: "Polygon",
        coordinates: [[]]
      }
    });

    polygon.addCoordinate(`0.0`, 0, 0);
    this.addFeature(polygon);

    this.clearSelectedFeatures();
    this.updateUIClasses({ mouse: "add" });
    this.setActionableState({
      trash: true
    });

    return {
      polygon,
      currentVertexPosition: 0,
      confirmed: false,
      lastMousePos: [0, 0]
    };
  },

  isIntersecting: function(polygon: DrawFeature) {
    return isFeatureIntersectingItself(polygon);
  },

  removeLastVertex(state: DrawPolygonState) {
    if (state.currentVertexPosition > 0) {
      const firstVertex = [...state.polygon.coordinates[0][0]];
      // Remove last placed vertex of the current line
      state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`);
      state.currentVertexPosition -= 1;
      if (state.polygon.getCoordinates().length === 0) {
        state.polygon.setCoordinates([[]]);
      }
      if (state.currentVertexPosition === 1) {
        state.polygon.addCoordinate("0.0", ...firstVertex);
      }
      this.updateVertexPosition(state, state.lastMousePos);
    }
  },

  onLeftClick: function(state: DrawPolygonState, e: DrawMouseEvent) {
    const initialVertex = state.polygon.coordinates[0][0];
    const newVertex = point([e.lngLat.lng, e.lngLat.lat]);
    if (
      state.currentVertexPosition > 0 &&
      distance(initialVertex, newVertex, { units: "meters" }) <= boundarySnappingDistance
    ) {
      state.confirmed = true;
      state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`);
      return this.changeMode(Modes.edit_polygon, { polygonId: state.polygon.id });
    }
    this.updateUIClasses({ mouse: "add" });
    state.currentVertexPosition++;
    state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat);
  },

  onRightClick: function(state: DrawPolygonState, e: DrawMouseEvent) {
    this.removeLastVertex(state);
  },

  onClick: function(state: DrawPolygonState, e: DrawMouseEvent) {
    const button = e.originalEvent.button;
    switch (button) {
      // Left Click
      case 0:
        this.onLeftClick(state, e);
        break;
      // Right Click
      case 2:
        this.onRightClick(state, e);
        break;
    }
  },

  updateVertexPosition: function(
    state: DrawPolygonState,
    newPosition: [number, number],
    snapAtAngles = false
  ) {
    const coords = state.polygon.getCoordinates()[0];
    const initialPos = coords[0];

    const isCloseToBeggining =
      state.currentVertexPosition > 0 &&
      distance(point(initialPos), point(newPosition), { units: "meters" }) <=
        boundarySnappingDistance;

    if (isCloseToBeggining) {
      // Snap point to boundary
      newPosition = initialPos;
    } else if (snapAtAngles && coords.length >= 2) {
      const previousPos = coords[state.currentVertexPosition - 1];

      coords.pop();
      coords.pop();

      for (let i = 0; i < coords.length - 1; i++) {
        const p1 = coords[i];
        const p2 = coords[i + 1];

        const angle = Math.abs(angleBetween(p2, p1, newPosition)) % AngleSnappingValue;
        const diff = Math.min(angle, AngleSnappingValue - angle);

        if (diff < 2) {
          const extended = destination(newPosition, 100, bearing(previousPos, newPosition), {
            units: "meters"
          });

          newPosition = snapAtAngle(p2, p1, newPosition) || newPosition;

          const extended2 = destination(newPosition, 100, bearing(p1, newPosition), {
            units: "meters"
          });

          const intersections = lineIntersect(
            lineString([previousPos, extended.geometry?.coordinates]),
            lineString([p1, extended2.geometry?.coordinates])
          );

          if (intersections.features.length) {
            newPosition = intersections.features[0].geometry?.coordinates as [number, number];
          }
        }
      }
    }

    // If line.coordinates.length === vertexIdx
    // line.updateCoordinate() will create and add the new vertex
    state.polygon.updateCoordinate(
      `0.${state.currentVertexPosition}`,
      newPosition[0],
      newPosition[1]
    );

    // Check for intersections
    if (!isCloseToBeggining) {
      state.polygon.properties.intersects = String(this.isIntersecting(state.polygon));
    }
  },

  onMouseMove: function(state: DrawPolygonState, e: DrawMouseEvent) {
    if (e.originalEvent.shiftKey && state.currentVertexPosition >= 2) {
      const coords = state.polygon.getCoordinates()[0];
      const A = coords[state.currentVertexPosition - 2];
      const O = coords[state.currentVertexPosition - 1];
      let B: [number, number] = [e.lngLat.lng, e.lngLat.lat];
      B = snapAtAngle(A, O, B) || B;

      this.updateVertexPosition(state, B, true);
    } else {
      this.updateVertexPosition(state, [e.lngLat.lng, e.lngLat.lat]);
    }

    state.lastMousePos = [e.lngLat.lng, e.lngLat.lat];

    if (e?.featureTarget?.properties?.meta === "vertex") {
      this.updateUIClasses({ mouse: "pointer" });
    }
  },

  onKeyUp: function(state: DrawPolygonState, e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.deleteFeature([state.polygon.id], { silent: true });
      this.changeMode(Modes.site_selected);
    } else if (e.key === "Enter") {
      state.confirmed = true;
      state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`);
      return this.changeMode(Modes.edit_polygon, { polygonId: state.polygon.id });
    } else if ((e.ctrlKey || e.metaKey) && e.key === "z") {
      this.removeLastVertex(state);
    }
  },

  displayMeasurements: function(polygon: DrawFeature, display: Function) {
    const coords = [...polygon.geometry.coordinates[0]];
    coords.pop();
    coords.forEach((p2: [number, number], coordinateId: number) => {
      const prevIdx = coordinateId === 0 ? coords.length - 1 : coordinateId - 1;

      const p1 = coords[prevIdx];

      const geometry = {
        type: "LineString",
        coordinates: [
          [p1[longitudeIndex], p1[latitudeIndex]],
          [p2[longitudeIndex], p2[latitudeIndex]]
        ]
      };
      display({
        type: "Feature",
        properties: {
          active: "true",
          parent: polygon.properties.id,
          user_length: formatDistanceLabel(geometry)
        },
        geometry: geometry
      });

      if (coords.length <= 2) return;
      const nextIdx = (coordinateId + 1) % coords.length;
      const p3 = coords[nextIdx];
      const b1 = bearing(p2, p1);
      const b2 = bearing(p2, p3);

      let fb1 = Math.min(b1, b2);
      let fb2 = Math.max(b1, b2);

      if (Math.abs(fb2 - fb1) > 180) {
        const swap = fb1;
        fb1 = fb2;
        fb2 = swap;
      }

      const dist12 = distance(p1, p2, { units: "kilometers" });
      const dist23 = distance(p2, p3, { units: "kilometers" });

      const arcRadius = Math.min(0.003, dist12, dist23);
      const angle = Math.round((fb2 - fb1 + 360) % 180);

      if (arcRadius === 0 || angle === 0) return;

      const angleArc = lineArc(p2, arcRadius, fb1, fb2);

      display({
        type: "Feature",
        properties: {
          parent: polygon.properties.id,
          active: "true",
          user_length: `${angle}°`
        },
        geometry: angleArc.geometry
      });
    });
  },

  displayProjectBoundary: function(
    state: DrawPolygonState,
    geojson: DrawFeature,
    display: Function
  ) {
    geojson.properties.meta = "feature";
    geojson.properties.active = "true";
    display(geojson);
    this.displayMeasurements(geojson, display);
  },

  toDisplayFeatures: function(state: DrawPolygonState, geojson: DrawFeature, display: Function) {
    const isActivePolygon = geojson.properties.id === state.polygon.id;
    geojson.properties.active = String(isActivePolygon);
    if (!isActivePolygon) return;

    createSupplementaryPoints(
      geojson,
      {
        parentId: state.polygon.id,
        midpoints: false,
        selectedPaths: []
      },
      String(state.currentVertexPosition)
    ).forEach(point => display(point));
    return this.displayProjectBoundary(state, geojson, display);
  },

  onStop(state: DrawPolygonState) {
    if (!state.confirmed) {
      this.deleteFeature(state.polygon.id);
    }
  }
};

export default DrawPolygon;
