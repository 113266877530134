import { MapBoxDrawControl } from "../../commonTypes";
import { MaximumAmountOfSubSites } from "../../constants";

export const parcelFeatureIdPrefix = "site-";

export const getParcelFeatureId = (idx: number) => `${parcelFeatureIdPrefix}${idx}`;

export const getParcelUserId = (idx: number) => `Parcel ${String.fromCharCode(65 + idx)}`;

export const getFirstUsedSiteId = (drawObj: MapBoxDrawControl): [number, string | null] => {
  let i = 0;
  let id = getParcelFeatureId(i);
  while (i < MaximumAmountOfSubSites && drawObj.getFeature(id) === undefined) {
    id = getParcelFeatureId(++i);
  }
  return [i, i < MaximumAmountOfSubSites ? id : null];
};

export const removeParcels = (
  drawObj: MapBoxDrawControl,
  from = 0,
  amount: number = MaximumAmountOfSubSites
) => {
  let i = from;
  let id = getParcelFeatureId(i);
  const to = Math.min(from + amount, MaximumAmountOfSubSites);
  while (i < to) {
    if (drawObj.getFeature(id) !== undefined) {
      const subparcelId = drawObj.getFeature(id).properties.subparcelId;
      drawObj.deleteFeature(id);
      drawObj.map.fire("draw.siteDeleted", {
        siteIdRemoved: subparcelId
      });
    }
    id = getParcelFeatureId(++i);
  }
};

export const getRandomId = () =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(2, 10);
