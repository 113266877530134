import React from "react";
import { WrappedFieldProps } from "redux-form";
import { Form, Input, Popup, Icon, Button } from "semantic-ui-react";
import { Footnote } from "./Footnote";

type BaseProps = {
  label: string;
  defaultValue: string;
  inline: any;
  unitLabel: string;
  footnoteText: string;
  hasInfo: boolean;
  infoText: string;
};

type MyProps = WrappedFieldProps & BaseProps;

type MyState = {
  type: string;
};

class PasswordInput extends React.Component<MyProps, MyState> {
  state: MyState = {
    type: "password"
  };

  handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text"
    }));
  };

  render() {
    const {
      label,
      defaultValue,
      inline,
      unitLabel,
      footnoteText,
      hasInfo,
      infoText,
      meta: { error, touched },
      ...rest
    } = this.props;
    return (
      <Form.Field inline={inline} defaultValue={defaultValue} error={error && touched}>
        <label className="password">
          {label}{" "}
          {hasInfo ? (
            <Popup
              trigger={<Icon name="info circle" style={{ opacity: 0.4 }} />}
              content={infoText}
            />
          ) : null}
          <Input
            label={unitLabel}
            error={!!error}
            {...rest}
            type={this.state.type}
            className="password__input"
            action={
              <Button icon labelPosition="right" onClick={this.handleClick} type="button">
                {this.state.type === "text" ? <Icon name="eye slash" /> : <Icon name="eye" />}
                <span className="password__show">
                  {this.state.type === "text" ? "Hide" : "Show"}
                </span>
              </Button>
            }
            actionPosition={undefined}
          />
        </label>
        {error && touched ? <span style={{ color: "red" }}>*{error}</span> : ""}
        <Footnote footnoteText={footnoteText} />
      </Form.Field>
    );
  }
}

export default PasswordInput;
