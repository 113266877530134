import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { GeographicCoordinates } from "../../types";

import { DataLayersSlice } from "../slices/ui";

const initialState: DataLayersSlice = {
  popupCoordinates: null,
  popupIsAnchored: false,
  focusedFeatures: {
    zonings: null,
    parcels: null
  }
};

const slice = createSlice({
  name: "dataLayers",
  initialState,
  reducers: {
    cleanDataLayersPopupCoordinates(state) {
      state.popupCoordinates = null;
    },
    updateDataLayersPopupCoordinates(state, action: PayloadAction<GeographicCoordinates>) {
      state.popupCoordinates = action.payload;
    },
    unanchorDataLayersPopup(state) {
      state.popupIsAnchored = false;
    },
    anchorDataLayersPopup(state) {
      state.popupIsAnchored = true;
    },
    assignFocusedFeature(
      state,
      action: PayloadAction<{ name: string; feature: MapboxGeoJSONFeature | null }>
    ) {
      (state as DataLayersSlice).focusedFeatures[action.payload.name] = action.payload.feature;
    }
  }
});

export const {
  anchorDataLayersPopup,
  assignFocusedFeature,
  cleanDataLayersPopupCoordinates,
  unanchorDataLayersPopup,
  updateDataLayersPopupCoordinates
} = slice.actions;

export default slice.reducer;
