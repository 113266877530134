import { Map } from "mapbox-gl";
import React from "react";
import { connect } from "react-redux";
import layersIconBackgroundImage from "../../data/images/icons8-layers-80.png";
import { toggleLayersControls } from "../../store/map";
import { withMap } from "../common/map/ReactMapboxContext";

/*
 * Provides a component which creates an instance of IControl
 *      docs: https://docs.mapbox.com/mapbox-gl-js/api/markers/#icontrol
 * */
class LayersIControl {
  _map?: Map;
  _container?: any;
  _clickEventListener?: any;
  toggleLayersControls: any;

  constructor(toggleLayersControls: any) {
    this.toggleLayersControls = toggleLayersControls;
  }

  onAdd(map: Map) {
    this._map = map;

    this._container = document.createElement("div");
    this._container.id = "layers-icon";
    this._container.className = "mapboxgl-ctrl layer-toggle";
    this._container.title = "Display layer controls";
    this._container.style.position = "block";

    const img = document.createElement("img");
    img.src = layersIconBackgroundImage;
    img.height = 40;
    img.width = 40;
    this._container.appendChild(img);

    this._clickEventListener = this.toggleLayersControls.bind(this);
    this._container.addEventListener("click", this._clickEventListener);

    return this._container;
  }

  onRemove() {
    this._container.removeEventListener("click", this._clickEventListener);
    this._container.parentNode?.removeChild(this._container);
    this._map = undefined;
  }
}

class LayersIcon extends React.Component<{ map: Map; toggleLayersControls: any }> {
  control: LayersIControl;

  constructor(props: { map: Map; toggleLayersControls: any }) {
    super(props);
    this.control = new LayersIControl(props.toggleLayersControls);
  }

  componentDidMount() {
    this.props.map.addControl(this.control, "top-right");
  }

  render() {
    return null;
  }
}

export default connect(undefined, { toggleLayersControls })(withMap(LayersIcon));
