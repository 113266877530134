import { createSelector } from "@reduxjs/toolkit";
import { escapeRegExp, isUndefined } from "lodash";
import { RootState } from "..";
import { defaultParcelUsageId } from "../../config/config";
import { parcelIsValidSize } from "../../config/utils";
import { is } from "../../lib";
import { Building } from "../../types/buildings";
import { Project } from "../../types/projects";

// Project Creation selectors. Sites are all candidates

export const candidateSitesSelector = (state: RootState) => state.projects.candidateSites.byId;

export const selectedCandidateSiteIdSelector = (state: RootState) =>
  state.projects.candidateSites.selected;

export const candidateSitesIdsSelector = (state: RootState) => state.projects.candidateSites.ids;

export const getSelectedCandidateSite = createSelector(
  [candidateSitesSelector, selectedCandidateSiteIdSelector],
  (candidateSitesById, siteId) => {
    if (is.null(siteId)) return null;
    return candidateSitesById[siteId];
  }
);

export const areAllSitesValid = createSelector(candidateSitesSelector, candidateSites => {
  return Object.entries(candidateSites).every(([, site]) => parcelIsValidSize(site.area));
});

// Commited Projects/Sites selectors. Sites are all definitive

export const getProjectMainBuildingsIds = (project: Project) => {
  const idsDict: { [siteId: number]: number | null } = {};
  project.development_sites.forEach(site => {
    if (site.parcel_usage_id === defaultParcelUsageId) {
      idsDict[site.id] = site.main_building_id;
    }
  });
  return idsDict;
};

export const selectedProjectSitesSelector = (state: RootState) =>
  state.projects.selectedProjectSites.byId;

export const selectedSiteIdSelector = (state: RootState) =>
  state.projects.selectedProjectSites.selected;

export const getSelectedProjectSelectedSite = createSelector(
  selectedProjectSitesSelector,
  selectedSiteIdSelector,
  (projectSitesById, siteId) => {
    if (is.null(siteId)) return null;
    return projectSitesById[siteId];
  }
);

export const selectedSiteIsValidSelector = createSelector(getSelectedProjectSelectedSite, site => {
  if (is.null(site)) return false;
  return site.segments_indexes.front.length > 0;
});

export const selectedSiteUsageIsBuildingSelector = createSelector(
  getSelectedProjectSelectedSite,
  site => {
    if (is.null(site)) return true;
    return site.parcel_usage_id === defaultParcelUsageId;
  }
);

export const projectSitesIdsSelector = (state: RootState) =>
  state.projects.selectedProjectSites.ids;

export const buildingUsageSitesIdsSelector = (state: RootState) =>
  projectSitesIdsSelector(state).filter(
    siteId =>
      state.projects.selectedProjectSites.byId[siteId].parcel_usage_id === defaultParcelUsageId
  );

export const buildingsBySiteSelector = (state: RootState) => state.projects.projectBuildingsBySite;

export const projectSiteAreaSelector = (state: RootState, siteId: number) => {
  const site = state.projects.selectedProjectSites.byId[siteId];
  if (isUndefined(site)) return 0;
  return site.size.area;
};

export const getSiteBuildings = createSelector(
  selectedSiteIdSelector,
  buildingsBySiteSelector,
  (siteId, buildingsBySite) => {
    if (is.null(siteId) || !buildingsBySite[siteId]) return [];
    return buildingsBySite[siteId].ids.map(id => buildingsBySite[siteId].byId[id]);
  }
);

export const getSiteBuildingById = createSelector(
  selectedSiteIdSelector,
  buildingsBySiteSelector,
  (state: RootState, buildingId: number) => buildingId,
  (siteId, buildingsBySite, buildingId) => {
    if (is.null(siteId) || !buildingsBySite[siteId]) return {};
    return buildingsBySite[siteId].byId[buildingId];
  }
);

export const getSelectedProjectMainBuildings = createSelector(
  buildingUsageSitesIdsSelector,
  buildingsBySiteSelector,
  (sitesIds, buildingsBySite) => {
    return sitesIds
      .filter(siteId => is.id(buildingsBySite[siteId].mainId))
      .map(siteId => {
        return buildingsBySite[siteId].byId[buildingsBySite[siteId].mainId as number];
      });
  }
);

export const buildingSearchTextSelector = (state: RootState) => state.projects.buildingSearch;

export const getFilteredSiteBuildings = createSelector(
  getSiteBuildings,
  buildingSearchTextSelector,
  (siteBuildings, searchText) => {
    const re = new RegExp(escapeRegExp(searchText), "i");
    return siteBuildings.filter(building => re.test(building.name));
  }
);

export const getSelectedBuildingId = createSelector(
  selectedSiteIdSelector,
  buildingsBySiteSelector,
  (siteId, buildingsBySite): number | null => {
    if (is.null(siteId)) return null;

    const buildingsThisSite = buildingsBySite[siteId];
    if (buildingsThisSite === undefined) return null;

    return buildingsThisSite.selectedId;
  }
);

export const getSelectedBuilding = createSelector(
  selectedSiteIdSelector,
  buildingsBySiteSelector,
  getSelectedBuildingId,
  (siteId, buildingsBySite, selectedBuildingId): Building | {} => {
    if (is.null(selectedBuildingId) || is.null(siteId)) return {};
    const buildingsThisSite = buildingsBySite[siteId];
    return buildingsThisSite.byId[selectedBuildingId] ?? {};
  }
);
