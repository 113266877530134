import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LineString } from "@turf/helpers";
import { isNullOrUndefined } from "../config/utils";
import { is } from "../lib";
import { Building } from "../types/buildings";
import { DevelopmentSite, isProject, Project } from "../types/projects";
import {
  ActiveTable,
  SidebarComponent,
  ActiveModalForm,
  ParcelInformation,
  SiteInformation
} from "../types/ui";
import { ProjectsSlice, Zoning } from "./types";

const initialState: ProjectsSlice = {
  // Variables for building option table and map view
  projectList: { byId: {}, ids: [] },
  toggleEdit: false,
  toggleBuildingEdit: false,
  buildingSearch: "",
  editProjectList: [],
  editBuildingList: [],
  sidebarVisible: true,
  bottomBarVisible: false,
  activeTable: ActiveTable.summary,
  addBuildingModalOpen: false,
  activeModalForm: "main",
  currentProjectDefaultZoning: null, // stores the default zoning of the currently selected project

  // Variables for zoning and program wizards
  editZoning: false,
  wizardModalOpen: false,

  // Variables for add new project feature
  selectedParcel: null,
  siteSubdivisionLine: null,
  candidateSites: { byId: {}, ids: [], selected: null },
  clickedParcel: {},
  clickedParcelIsNeighborOfSelectedParcel: false,
  clickedSegmentId: undefined,
  selectedProject: {},
  selectedProjectSites: { byId: {}, ids: [], selected: null },
  projectBuildingsBySite: {},
  selectedProjectCountryCode: null,
  isSelectedProjectInsideUSA: false,
  sideSegments: [],
  frontSegments: [],
  rearSegments: [],
  parcelSearchError: false,
  buildingOptionPanelExpanded: true,
  displayAboutModal: false,
  activeAccordionIndex: 1,
  sideBarComponent: SidebarComponent.projectList,
  editFrontage: false,
  showSegmentError: false,
  isValidParcel: true
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjectList(state, action: PayloadAction<Project[]>) {
      const ids: number[] = [];
      action.payload.forEach(project => {
        project.mainBuildings = {};
        state.projectList.byId[project.id] = project;
        ids.push(project.id);
      });
      state.projectList.ids = ids;
    },
    addProject(state, action: PayloadAction<Project>) {
      if (!state.projectList.byId[action.payload.id]) {
        state.projectList.ids.push(action.payload.id);
      }
      state.projectList.byId[action.payload.id] = action.payload;
      state.projectList.byId[action.payload.id].mainBuildings = {};
    },
    toggleEditBuildings(state) {
      state.toggleBuildingEdit = !state.toggleBuildingEdit;
    },
    setEditBuildings(state, action: PayloadAction<boolean>) {
      state.toggleBuildingEdit = action.payload;
      if (!action.payload) {
        state.editBuildingList = [];
      }
    },
    checkEditBox(state, action: PayloadAction<number[]>) {
      state.editProjectList = action.payload;
    },
    toggleEditProjectList(state) {
      state.toggleEdit = !state.toggleEdit;
    },
    setDefaultZoning(state, action: PayloadAction<Zoning>) {
      state.currentProjectDefaultZoning = action.payload;
    },
    cleanDefaultZoning(state) {
      state.currentProjectDefaultZoning = null;
    },
    checkBuildingEditBox(state, action: PayloadAction<Building[]>) {
      state.editBuildingList = action.payload;
    },
    setSelectedProject(state, action: PayloadAction<Project>) {
      const project = action.payload;
      if (!project.mainBuildings) {
        project.mainBuildings = {};
      }
      state.selectedProject = project;
      const ids: number[] = [];
      project.development_sites.forEach(site => {
        state.selectedProjectSites.byId[site.id] = site;
        ids.push(site.id);
      });
      state.selectedProjectSites.ids = ids.sort((a, b) => a - b);
      state.selectedProjectSites.selected = ids[0];
    },
    updateSelectedProjectSite(state, action: PayloadAction<DevelopmentSite>) {
      const updatedSite = action.payload;
      state.selectedProjectSites.byId[updatedSite.id] = updatedSite;
      const projectId = updatedSite.project_id;
      if (!isProject(state.selectedProject)) return;
      const idx = state.selectedProject.development_sites.findIndex(
        site => site.id === updatedSite.id
      );
      if (idx >= 0) {
        state.selectedProject.development_sites[idx] = updatedSite;
      }
      state.projectList.byId[projectId] = state.selectedProject;
    },

    setSelectedProjectSelectedSite(state, action: PayloadAction<number>) {
      state.selectedProjectSites.selected = is.id(action.payload) ? action.payload : null;
    },
    clearSelectedProject(state) {
      state.selectedProject = {};
      state.selectedProjectSites = { byId: {}, ids: [], selected: null };
      state.projectBuildingsBySite = {};
    },
    toggleSidebarVisible(state) {
      state.sidebarVisible = !state.sidebarVisible;
      state.bottomBarVisible = !state.bottomBarVisible;
    },
    setSidebarVisible(
      state,
      action: PayloadAction<{ sidebarVisible: boolean; bottomBarVisible: boolean }>
    ) {
      const { sidebarVisible, bottomBarVisible } = action.payload;
      state.sidebarVisible = sidebarVisible;
      state.bottomBarVisible = bottomBarVisible;
    },
    setProjectBuildings(state, action: PayloadAction<{ [siteId: number]: Building[] }>) {
      const projectBuildingData = action.payload;
      const projectBuildingsBySite: typeof state["projectBuildingsBySite"] = {};

      Object.keys(projectBuildingData).forEach(siteIdString => {
        const siteId = parseInt(siteIdString);
        const siteBuildings = projectBuildingData[siteId];
        const buildingsById: { [buildingId: number]: Building } = {};
        const ids: number[] = [];
        siteBuildings.forEach(building => {
          buildingsById[building.id] = building;
          ids.push(building.id);
        });

        const selectedId =
          (state.projectBuildingsBySite[siteId] &&
            state.projectBuildingsBySite[siteId].selectedId) ??
          state.selectedProjectSites.byId[siteId].main_building_id;
        projectBuildingsBySite[siteId] = {
          byId: buildingsById,
          ids: ids,
          mainId: state.selectedProjectSites.byId[siteId].main_building_id,
          selectedId
        };
      });
      state.projectBuildingsBySite = projectBuildingsBySite;
    },
    renameSiteBuilding(state, action: PayloadAction<{ buildingId: number; name: string }>) {
      const { buildingId, name } = action.payload;
      const siteId = state.selectedProjectSites.selected;
      if (!is.id(siteId)) return;
      state.projectBuildingsBySite[siteId].byId[buildingId].name = name;
    },
    removeProjectSiteBuildings(
      state,
      action: PayloadAction<{ deletedIds: number[]; siteId: number }>
    ) {
      const { deletedIds, siteId } = action.payload;
      const existingIds = state.projectBuildingsBySite[siteId].ids;
      deletedIds.forEach(id => {
        if (existingIds.includes(id)) {
          delete state.projectBuildingsBySite[siteId].byId[id];
        }
      });
      state.projectBuildingsBySite[siteId].ids = existingIds.filter(id => !deletedIds.includes(id));
      //@ts-ignore
      if (deletedIds.includes(state.projectBuildingsBySite[siteId].mainId)) {
        state.projectBuildingsBySite[siteId].mainId = null;
      }
      //@ts-ignore
      if (deletedIds.includes(state.projectBuildingsBySite[siteId].selectedId)) {
        state.projectBuildingsBySite[siteId].selectedId = null;
      }
    },
    setProjectSiteMainBuildingId(state, action: PayloadAction<number | null>) {
      const buildingId = action.payload;
      if (!isProject(state.selectedProject)) return;
      const projectId = state.selectedProject.id;
      const siteId = state.selectedProjectSites.selected;
      if (!is.id(siteId)) return;

      const selectedProjectSiteIdx = state.selectedProject.development_sites.findIndex(
        site => site.id === siteId
      );
      if (selectedProjectSiteIdx >= 0) {
        state.selectedProject.development_sites[
          selectedProjectSiteIdx
        ].main_building_id = buildingId;
      }

      // Update cache of main buildings
      if (is.id(buildingId)) {
        // @ts-ignore. mainBuildings can't be undefined.
        state.selectedProject.mainBuildings[siteId] =
          state.projectBuildingsBySite[siteId].byId[buildingId];
      } else {
        // @ts-ignore. mainBuildings can't be undefined.
        delete state.selectedProject.mainBuildings[siteId];
      }

      state.projectBuildingsBySite[siteId].mainId = buildingId;
      state.projectList.byId[projectId] = state.selectedProject;
      state.selectedProjectSites.byId[siteId].main_building_id = buildingId;
    },
    setProjectSiteUsage(state, action: PayloadAction<{ siteId: number; usageId: number }>) {
      const { siteId, usageId } = action.payload;
      if (!isProject(state.selectedProject) || !is.id(siteId)) return;
      const selectedProjectSiteIdx = state.selectedProject.development_sites.findIndex(
        site => site.id === siteId
      );
      if (selectedProjectSiteIdx >= 0) {
        state.selectedProject.development_sites[selectedProjectSiteIdx].parcel_usage_id = usageId;
      }
      state.projectList.byId[state.selectedProject.id] = state.selectedProject;
      state.selectedProjectSites.byId[siteId].parcel_usage_id = usageId;
    },
    setProjectMainBuilding(
      state,
      action: PayloadAction<{ projectId: number; siteId: number; building: Building }>
    ) {
      const { projectId, siteId, building } = action.payload;
      //@ts-ignore (shouldn't fail)
      state.projectList.byId[projectId].mainBuildings[siteId] = building;
      if (isProject(state.selectedProject) && state.selectedProject.id === projectId) {
        //@ts-ignore
        state.selectedProject.mainBuildings[siteId] = building;
      }
    },
    setSelectedBuildingId(state, action: PayloadAction<number | null>) {
      const buildingId = action.payload;
      const siteId = state.selectedProjectSites.selected;
      if (!is.id(siteId)) return;
      if (!state.projectBuildingsBySite[siteId]) return;
      if (is.id(buildingId) && !state.projectBuildingsBySite[siteId].ids.includes(buildingId))
        return;
      state.projectBuildingsBySite[siteId].selectedId = buildingId;
    },
    setActiveTable(state, action: PayloadAction<ActiveTable>) {
      state.activeTable = action.payload;
    },
    toggleAddBuildingModal(state, action: PayloadAction<boolean>) {
      state.addBuildingModalOpen = action.payload;
    },
    toggleWizardModal(state, action: PayloadAction<boolean>) {
      state.wizardModalOpen = action.payload;
    },
    setActiveModalForm(state, action: PayloadAction<ActiveModalForm>) {
      state.activeModalForm = action.payload;
    },
    setSideBarComponent(state, action: PayloadAction<SidebarComponent>) {
      state.sideBarComponent = action.payload;
    },
    toggleBuildingOption(state, action: PayloadAction<boolean>) {
      state.buildingOptionPanelExpanded = action.payload;
    },
    setSelectedParcel(state, action: PayloadAction<ParcelInformation>) {
      if (isNullOrUndefined(action.payload.address)) {
        throw new Error("Cannot select parcel with null address.");
      }
      state.selectedParcel = action.payload;
      state.candidateSites.byId = {
        0: {
          theGeomGeoJSON: action.payload.theGeomGeoJSON,
          area: action.payload.area,
          centroid: action.payload.centroid,
          longitude: action.payload.centroid.geometry.coordinates[0],
          latitude: action.payload.centroid.geometry.coordinates[1],
          usage: action.payload.usage
        }
      };
      state.candidateSites.ids = [0];
      state.candidateSites.selected = 0;
      state.clickedParcelIsNeighborOfSelectedParcel = false;
    },
    addCandidateSite(state, action: PayloadAction<{ id: number; site: SiteInformation }>) {
      const { id, site } = action.payload;
      state.candidateSites.byId[id] = site;
      if (state.candidateSites.ids.includes(id)) {
        state.candidateSites.ids.splice(state.candidateSites.ids.indexOf(id), 1);
      }
      state.candidateSites.ids.push(id);
    },
    removeCandidateSite(state, action: PayloadAction<number>) {
      if (!state.candidateSites.ids.includes(action.payload)) {
        throw new Error(`Candidate site with id ${action.payload} doesn't exist.`);
      }
      delete state.candidateSites.byId[action.payload];
      state.candidateSites.ids.splice(state.candidateSites.ids.indexOf(action.payload), 1);

      if (state.selectedParcel !== null && Object.keys(state.candidateSites).length === 0) {
        state.candidateSites.byId = {
          0: {
            theGeomGeoJSON: state.selectedParcel.theGeomGeoJSON,
            area: state.selectedParcel.area,
            centroid: state.selectedParcel.centroid,
            longitude: state.selectedParcel.centroid.geometry.coordinates[0],
            latitude: state.selectedParcel.centroid.geometry.coordinates[1]
          }
        };
        state.candidateSites.ids = [0];
        state.candidateSites.selected = 0;
        state.clickedParcelIsNeighborOfSelectedParcel = false;
      }
    },
    setCandidateSiteUsage(state, action: PayloadAction<number>) {
      const selectedCandidateSiteId = state.candidateSites.selected;
      if (is.null(selectedCandidateSiteId)) {
        throw new Error("There's no candidate site selected");
      }
      state.candidateSites.byId[selectedCandidateSiteId].usage = action.payload;
    },
    cleanCandidateSites(state) {
      state.candidateSites = initialState.candidateSites;
    },
    setSiteSubdivision(state, action: PayloadAction<LineString | null>) {
      state.siteSubdivisionLine = action.payload;
    },
    setSelectedCandidateSiteId(state, action: PayloadAction<number>) {
      if (!state.candidateSites.ids.includes(action.payload)) {
        throw new Error(`Candidate site with id ${action.payload} doesn't exist.`);
      }
      state.candidateSites.selected = action.payload;
    },
    cleanClickedParcel(state) {
      state.clickedParcel = {};
      state.clickedParcelIsNeighborOfSelectedParcel = false;
    },
    setClickedParcel(
      state,
      action: PayloadAction<{ parcel: ParcelInformation; isNeighborOfSelectedParcel: boolean }>
    ) {
      state.clickedParcel = action.payload.parcel;
      state.clickedParcelIsNeighborOfSelectedParcel =
        action.payload.isNeighborOfSelectedParcel || false;
    },
    setClickedSegmentId(state, action: PayloadAction<number>) {
      state.clickedSegmentId = action.payload;
    },
    setFrontSegments(state, action: PayloadAction<number[]>) {
      state.frontSegments = action.payload;
    },
    setRearSegments(state, action: PayloadAction<number[]>) {
      state.rearSegments = action.payload;
    },
    setSideSegments(state, action: PayloadAction<number[]>) {
      state.sideSegments = action.payload;
    },
    cleanPreviousParcelSelection(state) {
      state.selectedParcel = initialState.selectedParcel;
      state.candidateSites = initialState.candidateSites;
    },
    parcelSearchFail(state, action: PayloadAction<boolean>) {
      state.parcelSearchError = action.payload;
      state.selectedParcel = initialState.selectedParcel;
      state.candidateSites = initialState.candidateSites;
    },
    setParcelIsValid(state, action: PayloadAction<boolean>) {
      state.isValidParcel = action.payload;
    },
    setBuildingSearch(state, action: PayloadAction<string>) {
      state.buildingSearch = action.payload;
    },
    toggleDisplayAbout(state, action: PayloadAction<boolean>) {
      state.displayAboutModal = action.payload;
    },
    setActiveAccordionIndex(state, action: PayloadAction<number>) {
      state.activeAccordionIndex = action.payload;
    },
    toggleEditFrontage(state, action: PayloadAction<boolean>) {
      state.editFrontage = action.payload;
    },
    showSegmentError(state, action: PayloadAction<boolean>) {
      state.showSegmentError = action.payload;
    },
    setSelectedProjectCountryCode(state, action: PayloadAction<string>) {
      state.selectedProjectCountryCode = action.payload;
      state.isSelectedProjectInsideUSA = action.payload === "us";
    }
  }
});

export const {
  addProject,
  setProjectList,
  toggleEditBuildings,
  setEditBuildings,
  checkEditBox,
  toggleEditProjectList,
  setDefaultZoning,
  cleanDefaultZoning,
  checkBuildingEditBox,
  setSelectedProject,
  updateSelectedProjectSite,
  setSelectedProjectSelectedSite,
  clearSelectedProject,
  toggleSidebarVisible,
  setSidebarVisible,
  setProjectBuildings,
  renameSiteBuilding,
  removeProjectSiteBuildings,
  setProjectSiteMainBuildingId,
  setProjectSiteUsage,
  setProjectMainBuilding,
  setSelectedBuildingId,
  setActiveTable,
  toggleAddBuildingModal,
  toggleWizardModal,
  setActiveModalForm,
  setSideBarComponent,
  toggleBuildingOption,
  setSelectedParcel,
  setSiteSubdivision,
  cleanCandidateSites,
  addCandidateSite,
  removeCandidateSite,
  setCandidateSiteUsage,
  setSelectedCandidateSiteId,
  cleanClickedParcel,
  setClickedParcel,
  setClickedSegmentId,
  setFrontSegments,
  setRearSegments,
  setSideSegments,
  cleanPreviousParcelSelection,
  parcelSearchFail,
  setParcelIsValid,
  setBuildingSearch,
  toggleDisplayAbout,
  setActiveAccordionIndex,
  toggleEditFrontage,
  showSegmentError,
  setSelectedProjectCountryCode
} = projectsSlice.actions;

export default projectsSlice.reducer;
