/**
 * Created by jmartinez on 9/7/18.
 */
import { isUndefined } from "lodash";
import AddNewProject from "../components/AddNewProject";
import EditFrontage from "../components/EditFrontage";
import ProjectList from "../components/ProjectList";
import { getUCToken } from "../helpers/auth";
import { asyncGetRegion } from "../promises/users/users";
import store from "../store";
import { doesNotExist } from "../utils";
import {
  getAreaLabel,
  getBound,
  getConvertedDistanceValue,
  getDistanceLabel,
  isInRange,
  isNullOrUndefinedOrBlank
} from "./utils";

export const programSteps = {
  program_step_1: "UnitMixStep",
  program_step_2: "NonResStep",
  program_step_3: "CirculationStep",
  program_step_4: "AmenitiesStep"
};

export const zoningSteps = {
  zoning_step_1: "ZoningStep",
  zoning_step_2: "HDStep",
  zoning_step_3: "SetbacksStep",
  zoning_step_4: "OpenspaceStep",
  zoning_step_5: "ParkingInputsStep"
};

export const validateInt = value =>
  isNullOrUndefinedOrBlank(value) || /^\d+$/i.test(value) ? undefined : "Must be an Integer";

export const validatePositiveInteger = value =>
  doesNotExist(value) || /^[1-9]\d*$/i.test(value) ? undefined : "Must be a positive integer";

export const required = value => {
  return !isNullOrUndefinedOrBlank(value) ? undefined : "Required";
};

export const hasDuplicateName = (value, allValues, props) => {
  const scenarioNames = props.allBuildings.map(building => building.name);
  return !scenarioNames.includes(value) ? undefined : "Duplicate building option name";
};

export const validatePercentage = value => {
  if (isNullOrUndefinedOrBlank(value)) {
    return undefined;
  }
  return parseFloat(value) >= 0 && parseFloat(value) <= 100
    ? undefined
    : "Percentage must be between 0 and 100";
};

export const validateUnitSqft = value => {
  const isMetric = store.getState().users.metric;
  if (isUndefined(validateInt(value))) {
    return isInRange(parseInt(value), isMetric, "unitArea") || isNullOrUndefinedOrBlank(value)
      ? undefined
      : parseInt(value) === 0
      ? undefined
      : `Square footage must be between ${getBound("unitArea", "min")} and ${getBound(
          "unitArea",
          "max"
        )} ${getAreaLabel()}`;
  } else {
    return validateInt(value);
  }
};

export const validateGroundFloorHeight = value => {
  const isMetric = store.getState().users.metric;
  return isUndefined(isPositiveNumeric(value)) &&
    isInRange(parseFloat(value), isMetric, "groundFloorHeight")
    ? undefined
    : `Ground floor height must be between ${getBound(
        "groundFloorHeight",
        "min"
      )}${getDistanceLabel()} and ${getBound("groundFloorHeight", "max")}${getDistanceLabel()}`;
};

export const validateLotCoverage = value => {
  return isUndefined(isPositiveNumeric(value)) &&
    isUndefined(validatePercentage(value)) &&
    value >= 1
    ? undefined
    : "Maximum lot coverage must be between 1 and 100";
};

export const isZeroOrPositiveNumeric = value => {
  return !isNullOrUndefinedOrBlank(value) && !/^[0-9]+(\.[0-9]+)?$/i.test(value)
    ? "Must be numeric. If value is less than 1, add a 0 before the decimal."
    : undefined;
};

export const isPositiveNumeric = value =>
  !isNullOrUndefinedOrBlank(value) &&
  !/(^([0-9]*[1-9][0-9]*)(\.[0-9]+)?$)|(^([0-9]+)(\.[0-9]+)$)/i.test(value)
    ? "Must be numeric and greater than 0. If value is less than 1, add a 0 before the decimal."
    : undefined;

export const validateBasisBoost = value => {
  if (isUndefined(validateInt(value))) {
    return parseInt(value) >= 100 && parseInt(value) <= 130
      ? undefined
      : "Basis boost must be between 100 and 130";
  } else {
    validateInt(value);
  }
};

export const validateDebtServiceRatio = value => {
  if (isUndefined(isZeroOrPositiveNumeric(value))) {
    return parseFloat(value, 10) > 0
      ? undefined
      : "Debt service coverage ratio must be greater than 0.";
  }
};

export const validateCapRate = value => {
  if (isUndefined(validatePercentage(value))) {
    return parseFloat(value) > 0 ? undefined : "Cap rate must be greater than 0";
  } else {
    return validatePercentage(value);
  }
};

export const validateMaxPodiumHeight = (value, allValues) => {
  return value <= allValues.max_height
    ? undefined
    : "Maximum podium height must be less than maximum building height";
};

export const parseNumber = value => (isNullOrUndefinedOrBlank(value) ? value : parseFloat(value));

export const getDefaultMapCenter = async () => {
  try {
    const regionData = await asyncGetRegion();
    const mapCenter = [parseFloat(regionData.longitude), parseFloat(regionData.latitude)];
    return mapCenter;
  } catch (error) {
    console.error(`actions::users::getRegion -> ${error}`);
  }
};

export const initMap = {
  center: [-122.4876913, 37.757815],
  zoom: [11],
  pitch: [0],
  bearing: 0
};

export const leftSideBarWidth = "350px";
export const buildingOptionPanelHeight = "300px";
export const buildingOptionPanelMinHeight = "54px";
export const buildingOptionPanelHeightMinimized = "44px";
export const initAccessibleRoof = true;
export const flyToZoom = 17.75;
export const flyToPitch = 35;
export const initElevators = "1";
export const initHallwayWidth = "5";
export const getInitCourtyardWidth = () => {
  const isMetric = store.getState().users.metric;
  return isMetric ? "8" : "25";
};
export const initStairwells = "2";
export const initCeilingHeight = "10";
export const defaultTimeout = 5000;
export const mapOffset = 0.0005;
export const mainMenuHeight = 60;
export const projectListHeaderHeight = 36.3;
export const feetToMeters = 0.3048;
export const sqMetersToSqFeet = 10.7639;

export const dollarsPerAreaConvertUnitFactor = toMetric =>
  toMetric ? sqMetersToSqFeet : 1 / sqMetersToSqFeet;

export const devCostDetails = [
  "residential_construction_cost_per_sf",
  "commercial_construction_cost_per_sf",
  "acquistion_cost",
  "podium_parking_space_construction_cost",
  "underground_parking_space_construction_cost",
  "impact_fees_per_unit",
  "developer_fee",
  "soft_cost_percentage"
];

export const devCostBasic = ["development_cost_per_unit"];

export const roomButtonNames = {
  "0BR": "Studio",
  "1BR": "1 Bedroom",
  "2BR": "2 Bedroom",
  "3BR": "3 Bedroom",
  "4BR": "4 Bedroom"
};

export const roomLabels = {
  studio: "0BR",
  onebed: "1BR",
  twobed: "2BR",
  threebed: "3BR",
  fourbed: "4BR"
};

export const unitLabels = {
  apartments: {
    // As exported from penciler-layout
    layout: ["studio", "onebed", "twobed", "threebed", "fourbed"],
    // As standarized in the rents table at UC's appserver
    abbreviated: ["0BR", "1BR", "2BR", "3BR", "4BR"],
    full: {
      singular: ["Studio", "One Bedroom", "Two Bedroom", "Three Bedroom", "Four Bedroom"]
    },
    // As fields for proportions in programs
    proportions: ["prop_studio", "prop_1", "prop_2", "prop_3", "prop_4"],
    shortNumbers: {
      singular: ["Studio", "1 Bedroom", "2 Bedroom", "3 Bedroom", "4 Bedroom"]
    }
  },
  nplex: {
    unit: "Unit"
  }
};

export const unrestrictedLabel = "unrestricted";
export const unavailableLabel = "unavailable";

export const floorplanVisualization = {
  featuresStrokeColor: "#222",
  unitsPopupDelay: 200
};

export const floorPlanMargin = 40;
export const floorPlanSvgHeight = 575;
export const floorPlanSvgWidth = 600;
export const floorPlanScaleBarLeft = 0.08;
export const floorPlanScaleBarTop = 0.98;
export const pdfMapWidth = 500;
export const pdfMapHeight = 500;
export const tau = 2 * Math.PI;
export const mapBoxStyle = {
  api: "https://api.mapbox.com/styles/v1/mapbox/light-v9/tiles",
  style: "mapbox://styles/mapbox/light-v9"
};

export const validateDensityBonusPercentage = receivedInput => {
  const parsedInput = parseFloat(receivedInput);
  return isUndefined(isZeroOrPositiveNumeric(receivedInput)) &&
    0 <= parsedInput &&
    parsedInput <= 35
    ? undefined
    : "Percentage density bonus can go from 0% included up to 35% included.";
};
export const densityPercentageDisclaimer =
  "To qualify for the California state density bonus program, affordable units must be included on site. The percentage bonus is based on the number of affordable units provided. See State of California Government Code Section 65915 for specific requirements.";

export const podiumMaxHeightStartingOffset = () => getConvertedDistanceValue(20);
export const residentialFloorHeightDefault = () => Math.round(getConvertedDistanceValue(10));

export const cashflowMeasures = [
  "residential_income",
  "commercial_income",
  "other_income",
  "vacancy",
  "estimated_gross_income",
  "operating_expenses",
  "reserves",
  "NOI"
];

export const buildingWizardFormNames = [
  "AmenitiesStepForm",
  "CirculationStepForm",
  "HDStepForm",
  "NonResStepForm",
  "OpenspaceStepForm",
  "SetbacksStepForm",
  "UnitMixStepForm",
  "ZoningStepForm"
];

export const typologyTogglesFields = [
  "typology_all",
  "typology_i",
  "typology_c",
  "typology_h",
  "typology_t",
  "typology_l",
  "typology_e"
];

/**
 * Converts cities list from reducer to a dict by ID.
 * #TODO: when supporting multiple regions, this should take a regionId as param
 *
 * @returns {{[cityId: number]: string}}      - Dict
 */
export const getCitiesPlaceholder = () => {
  const cities = {};
  store.getState().cities.citiesList.forEach(city => (cities[city.id] = city.name.toUpperCase()));
  return cities;
};

export const linkedInputs = ["effective_height", "effective_ground_floor_height"];

export const demoUserId = process.env.DEMO_USER_ID || 6;

export const googleAnalyticsId = process.env.REACT_APP_GA_ID || "UA-138044959-1";

export const zoningInfoMessage =
  "Information about the zoning designation of this site. View and edit the zoning constraints that will be applied to your building option.";
export const scenarioInfoMessage =
  "Name your building option. You can use descriptive names like 'Density bonus option' or 'All studio option'";
export const programInfoMessage =
  "Create and save building programs to use for your building options. Add information about unit sizes, unit mix, circulation and ground floor retail.The suggested building type is based on the permitted uses and/or the density limit. If you select both types, the one that generates the most units will be displayed.";
export const multifamilyInfoMessage =
  "A multifamily building typology is a building in which you access units through an internal corridor.";
export const singlefamilyInfoMessage =
  "A single family attached building typology is a building in which two or more units are not connected by a corridor but rather have their own entrances on the street or through a single stairwell.";

export const projectParcelMaximumArea = 120000; // Value in sqft

export const mapboxStyles = [
  { key: 1, value: "streets-v10", text: "Mapbox: Streets" },
  { key: 2, value: "outdoors-v10", text: "Mapbox: Outdoors" },
  { key: 3, value: "light-v9", text: "Mapbox: Light" },
  { key: 4, value: "dark-v9", text: "Mapbox: Dark" },
  // Note(@nicoluce): Satellite style is commented out to quickly solve
  //                  the following issue for release v2.15.0.
  // https://github.com/urbansim/penciler-planning/issues/347
  // { key: 5, value: "satellite-v9", text: "Mapbox: Satellite" },
  { key: 6, value: "satellite-streets-v10", text: "Mapbox: Satellite Streets" }
];

export const zoningCategories = {
  "0": "Low Density Multifamily Residential",
  "1": "Medium Density Multifamily Residential",
  "2": "Neighborhood Commercial",
  "3": "High Density Multifamily Residential",
  "4": "Single Family Residential",
  "5": "Mixed Use",
  "6": "Industrial",
  "7": "Public And Open Space",
  "8": "Miscellaneous"
};

export const sideBarComponents = {
  ProjectList: ProjectList,
  AddNewProject: AddNewProject,
  SegmentRequirements: EditFrontage
};

export const minArea = () => {
  const isMetric = store.getState().users.metric;
  return isMetric ? 325 : 3500;
};

export const maxArea = () => {
  const isMetric = store.getState().users.metric;
  return isMetric ? 11148 : 120000;
};

const accountManagement = `${process.env.REACT_APP_EXTERNAL_LINKS_ACCOUNT_MANAGEMENT ||
  "https://cloud.urbansim.com/am"}?uc_token=${getUCToken()}`;

export const externalLinks = {
  accountManagement,
  documentation:
    process.env.REACT_APP_EXTERNAL_LINKS_DOCUMENTATION || "https://cloud.penciler.org/docs",
  support: process.env.REACT_APP_EXTERNAL_LINKS_SUPPORT || "http://www.urbansim.com/support",
  amSignUp:
    process.env.REACT_APP_EXTERNAL_LINKS_AM_SIGNUP ||
    "https://cloud.urbansim.com/am/account_create?is_demo=true",
  amForgotPassword:
    process.env.REACT_APP_EXTERNAL_LINKS_AM_FORGOT_PASSWORD ||
    "https://cloud.urbansim.com/am/login?open_forgot_password=true",
  blankHref: "#"
};

export const singleFamilyDensityLimit = 4;

export const parcelSearchMinimumInputLength = 3;

export const defaultParcelUsageId = 1;

export const reCaptchaSiteKey =
  process.env.REACT_RECAPTCHA_SITE_KEY ?? "6LeKRmkcAAAAABRdDpIn0wnFw45x5fTS7k43k6Cu";
