/* eslint react/display-name: 0 */
import { isEqual } from "lodash";
import { Map } from "mapbox-gl";
import React from "react";

import { mapboxSourceID } from "../../queriers/region";
import { DataLayer } from "../../types/region";
import { withMap } from "../common/map/ReactMapboxContext";

type Props = {
  map: Map;
  parcelsDataLayer: DataLayer;
  selectedParcelNeighborsIds: Set<number>;
};

type State = {
  previousSelectedParcelNeighborsIds: Set<number>;
};

export default withMap(
  class extends React.Component<Props, State> {
    // The neighbors vis consists in highlighting the neighboring parcels of the currently selected
    // parcel for project creation. To avoid adding unneeded Mapbox layers, this is done by setting
    // a flag in the corresponding features' state.
    // To achieve this in a reactive way, each time the selected parcel changes, this components
    // needs to:
    //  1 - Unset the state of the previous neighbors
    //  2 - Set the state the state of the new neighbors
    // Given that the component's prop refer only to the current neighbors we need to keep track of
    // the previous neighbors so that we can correctly remove then when the new ones arrive.
    constructor(props: Props) {
      super(props);
      this.state = { previousSelectedParcelNeighborsIds: new Set() };
    }

    shouldComponentUpdate(nextProps: Props, nextState: State) {
      // Since state changes start a new render we need to prevent re-renderings when we store the
      // current neighbors ids.
      // After setting the state with the current ids, the state will have the just rendered ids.
      // Before the re-render occurs, that state is passed as `nextState` which is why the second if
      // statement returns `false` if both the next state and the current state is the same.
      // If we only leave that check however, the component will never render the vis since when
      // props change both the next state and the old state will have the same values. The first if
      // statement ensures the vis gets rendered by checking if the next props are different than
      // the current state.
      if (
        !isEqual(
          nextProps.selectedParcelNeighborsIds,
          this.state.previousSelectedParcelNeighborsIds
        )
      ) {
        return true;
      }
      if (
        isEqual(
          nextState.previousSelectedParcelNeighborsIds,
          this.state.previousSelectedParcelNeighborsIds
        )
      ) {
        return false;
      }
      return true;
    }

    render() {
      this._markFeatures(this.state.previousSelectedParcelNeighborsIds, false);
      this._markFeatures(this.props.selectedParcelNeighborsIds, true);
      return null;
    }

    componentDidUpdate() {
      this.setState({ previousSelectedParcelNeighborsIds: this.props.selectedParcelNeighborsIds });
    }

    _markFeatures(ids: Set<number>, asNeighbors: boolean) {
      ids.forEach(id =>
        this.props.map.setFeatureState(
          {
            id,
            source: mapboxSourceID(this.props.parcelsDataLayer),
            sourceLayer: "features"
          },
          { isNeighbor: asNeighbors }
        )
      );
    }
  }
);
