import { invert, range } from "lodash";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { FieldArray, InjectedFormProps, reduxForm } from "redux-form";
import { Form } from "semantic-ui-react";
import { roomLabels, unitLabels } from "../../config/config";
import { RootState } from "../../store";
import { getCityRents } from "../../store/cities";
import { getSelectedBuilding } from "../../store/selectors/projects";
import { CityRents, UnitType } from "../../store/types";
import { Building, BuildingTypeCode } from "../../types/buildings";
import { RentProfile } from "../../types/proforma";
import { Project } from "../../types/projects";
import RentProfilesColumn from "./RentProfilesColumn";

const citiesActions = { getCityRents };
const actionObj = { ...citiesActions };

const mapStateToProps = (state: RootState) => {
  const selectedProject = state.projects.selectedProject;
  const selectedBuilding = getSelectedBuilding(state) as Building;
  const cityId = (selectedProject as Project).parcel_data.city_id;
  const rentsLabels: CityRents["roomType"][] = [];
  state.cities.currentCityRents.forEach(rent => {
    if (
      !rentsLabels.includes(rent.roomType) &&
      (selectedBuilding as any)[invert(roomLabels)[rent.roomType]] > 0
    )
      rentsLabels.push(rent.roomType);
  });
  const incompatibleProformaDetected = state.ui.incompatibleProforma.detected;
  const newUnitTypes = state.ui.incompatibleProforma.data.newUnitTypes;
  const buildingUnitTypes = incompatibleProformaDetected
    ? unitLabels.apartments.abbreviated.filter(
        (room, index) =>
          // @ts-ignore
          state.programs.selectedPrograms.multi_family[unitLabels.apartments.proportions[index]] > 0
      )
    : unitLabels.apartments.abbreviated.filter(
        // @ts-ignore
        (room, index) => selectedBuilding[unitLabels.apartments.layout[index]] > 0
      );
  return {
    selectedBuilding,
    selectedProject: selectedProject,
    cityRents: state.cities.currentCityRents,
    rentsLabels: rentsLabels.sort(),
    cityId: cityId,
    amountOfColumns: buildingUnitTypes.length,
    isCanada: state.projects.selectedProjectCountryCode === "ca",
    incompatibleProformaDetected,
    newUnitTypes,
    buildingUnitTypes,
    isApartment:
      selectedBuilding.building_type.code === BuildingTypeCode.apartment ||
      incompatibleProformaDetected
  };
};
const connector = connect(mapStateToProps, actionObj);
type RentProfileModalContentProps = {
  /** Default rent profiles (handy when editing) **/
  alreadySubmittedRentProfiles: RentProfile[];
  setRentModalSize: any;
} & ConnectedProps<typeof connector>;

class RentProfileModalContent extends React.Component<
  RentProfileModalContentProps & InjectedFormProps<{}, RentProfileModalContentProps>
> {
  // NOTE(ffigari): Implementation details
  // Nplex units are exported as studios but the studio's rents don't appply to those units. Neither
  // do nplexes currently have default rent info.
  // The other detail is that nplexes rents are set individually for each unit (instead of applying
  // them to multiple units as it is the case with apartment units).
  // Because of this in the default nplex case (when submitting rent profiles the first time) we
  // need to manually create as many profiles as the nplex has units.
  _emptyNplexRentProfile = {
    roomType: "nplex",
    percentageAmi: null,
    rent: 0
  };
  _buildApartmentRentsMatrix() {
    const {
      cityRents,
      selectedBuilding,
      alreadySubmittedRentProfiles,
      buildingUnitTypes,
      incompatibleProformaDetected
    } = this.props;
    return unitLabels.apartments.abbreviated.map((room, index) => {
      const shouldShowColumn = buildingUnitTypes.includes(room as UnitType);
      //@ts-ignore
      let totalUnits = shouldShowColumn ? selectedBuilding[unitLabels.apartments.layout[index]] : 0;
      let relevantOldProfiles = alreadySubmittedRentProfiles.filter(r => r.name === room);
      if (incompatibleProformaDetected) {
        const oldTotalUnits = relevantOldProfiles.reduce(
          (acc, profile) => acc + profile.unit_count,
          0
        );
        if (oldTotalUnits > 0) {
          relevantOldProfiles = relevantOldProfiles.map(profile => ({
            ...profile,
            unit_count: Math.round((profile.unit_count / oldTotalUnits) * 100)
          }));
        }
        if (shouldShowColumn) {
          totalUnits = 100;
        }
      }

      return {
        relevantCityRents: shouldShowColumn ? cityRents.filter(r => r.roomType === room) : [],
        totalUnits,
        relevantOldProfiles: relevantOldProfiles,
        name: room
      };
    });
  }
  _buildNplexesRentsMatrix() {
    const { selectedBuilding, alreadySubmittedRentProfiles } = this.props;
    return range(selectedBuilding.studio).map(roomId => {
      const profileName = `Unit ${roomId}`;
      return {
        relevantCityRents: [this._emptyNplexRentProfile],
        totalUnits: 1,
        relevantOldProfiles: alreadySubmittedRentProfiles.filter(r => r.name === profileName),
        name: profileName
      };
    });
  }
  _buildRentsMatrix() {
    const { selectedBuilding, incompatibleProformaDetected } = this.props;
    const rentsMatrixBuilder =
      selectedBuilding.building_type.code === BuildingTypeCode.apartment ||
      incompatibleProformaDetected
        ? this._buildApartmentRentsMatrix
        : this._buildNplexesRentsMatrix;
    return rentsMatrixBuilder.bind(this)();
  }
  _requiredColumnsToModalSize(requiredColumns: number) {
    if (requiredColumns <= 2) return "small";

    if (requiredColumns <= 4) return "large";

    return "fullscreen";
  }
  _updateModalSize() {
    const { amountOfColumns, isApartment, selectedBuilding, setRentModalSize } = this.props;
    const requiredColumns = isApartment ? amountOfColumns : selectedBuilding.studio;
    setRentModalSize(this._requiredColumnsToModalSize(requiredColumns));
  }

  componentDidMount() {
    const { cityId, getCityRents } = this.props;
    getCityRents(cityId);
    this._updateModalSize();
  }
  componentDidUpdate(prevProps: RentProfileModalContentProps) {
    if (prevProps.amountOfColumns !== this.props.amountOfColumns) {
      this._updateModalSize();
    }
  }
  render() {
    const {
      handleSubmit,
      selectedProject,
      isCanada,
      isApartment,
      incompatibleProformaDetected
    } = this.props;
    const rentsMatrix = this._buildRentsMatrix();
    return (
      <Form onSubmit={handleSubmit}>
        {this.props.error ? <h4 style={{ color: "red" }}>{this.props.error}</h4> : ""}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center"
          }}
        >
          {//@ts-expect-error
          rentsMatrix.map((rentColumn, columnIndex) => {
            if (rentColumn.totalUnits === 0) return null;

            return (
              <FieldArray
                key={columnIndex}
                name={rentColumn.name}
                component={RentProfilesColumn}
                props={{
                  isApartment,
                  selectedProject: selectedProject,
                  columnTotalUnits: rentColumn.totalUnits,
                  columnRelevantCityRents: rentColumn.relevantCityRents,
                  columnRelevantOldProfiles: rentColumn.relevantOldProfiles,
                  enablePercentageAmi: rentColumn.relevantCityRents.length !== 0,
                  isCanada: isCanada,
                  showUnitCounts: !incompatibleProformaDetected
                }}
              />
            );
          })}
        </div>
      </Form>
    );
  }
}

const RentProfileModalForm = reduxForm<{}, RentProfileModalContentProps>({
  form: "RentProfileModalForm",
  destroyOnUnmount: true,
  enableReinitialize: true
})(RentProfileModalContent);
export default connector(RentProfileModalForm);
