/**
 * Created by jmartinez on 8/27/18.
 */

import axios from "axios";
import IPData from "ipdata";
import jwtDecode from "jwt-decode";
import ReactGA from "react-ga";
import { formValueSelector, SubmissionError } from "redux-form";
import history from "../components/history";
import { ipData } from "../config/keys";
import { appCode, appUrl, baseUrl, ucUrl } from "../config/urls";
import { isNullOrUndefinedOrBlank } from "../config/utils";
import {
  asyncGetParcelUsages,
  asyncGetRegion,
  asyncGetUrbanCanvasUser,
  asyncGetUserSettings
} from "../promises/users/users";
import { setMapCenter } from "../store/map";
import { operations, safeOperation } from "../store/operations";
import { setDevcostsMetric } from "../store/proforma";
import { setActiveAccordionIndex } from "../store/projects";
import { setSelectedRegion } from "../store/regions";
import { closeRegionSelectorModal } from "../store/ui";
import { setParcelUsages } from "../store/ui/parcelUsages";
import * as userActions from "../store/users";
import { toCamelCase } from "../utils";
import { hydrateUserAvailableRegions, makeRegionInitializationFetch } from "./region";

export const authenticate = token => {
  return async dispatch => {
    dispatch(userActions.startAuthenticating());

    try {
      const pencilerTokenPayload = jwtDecode(token);

      // Update store with permissions data
      const permissionsRespons = await axios.get(`${ucUrl}/acls/list`);
      dispatch(userActions.setPermissions(permissionsRespons.data));

      // Update store with region data
      const regionData = await asyncGetRegion();
      const ucUserData = await asyncGetUrbanCanvasUser();
      const ucRegionData = toCamelCase(ucUserData.user.selectedRegion);
      const ucUserSettings = await asyncGetUserSettings();
      const parcelUsages = await asyncGetParcelUsages();
      dispatch(setParcelUsages(parcelUsages));
      let proformaIsMetric = regionData.metric;
      if (ucUserSettings.proformaMeasurementSystem) {
        const title = ucUserSettings.proformaMeasurementSystem.title;
        if (title === "Metric") proformaIsMetric = true;
        if (title === "Imperial") proformaIsMetric = false;
      }

      // Warning: consider that `user.region.users` probably won't be populated due to lazy
      // load & ORM deepness settings
      dispatch(setSelectedRegion(ucRegionData));

      dispatch(userActions.setMetric(regionData.metric));
      dispatch(setDevcostsMetric(proformaIsMetric));
      dispatch(userActions.setEPSG(regionData.epsg_code));
      dispatch(userActions.setProj4EPSGDefinition(regionData.proj4_epsg_definition));
      dispatch(setActiveAccordionIndex(regionData.default_city_id));
      dispatch(
        setMapCenter([parseFloat(regionData.longitude, 10), parseFloat(regionData.latitude, 10)])
      );

      // Finish user authentication
      dispatch(userActions.setUserClaims(pencilerTokenPayload.user_claims));
      dispatch(userActions.authenticatedSuccess());
      dispatch(makeRegionInitializationFetch());
      dispatch(hydrateUserAvailableRegions());
    } catch (error) {
      console.error(`actions::users::authenticate -> ${error}`);
      dispatch(userActions.authenticatedFail());
    }
  };
};

export const loginUser = (username, password, captchaToken, redirect) => async dispatch => {
  dispatch(userActions.startAuthenticating());

  let info = null;
  try {
    const ipdata = new IPData(ipData.apiKey);
    info = await ipdata.lookup();
  } catch (error) {
    console.error("IPData lookup failed, silently resuming app flow...", error);
  }
  try {
    const {
      data: { access_token: token, uc_token: ucToken }
    } = await axios.post(`${baseUrl}/login`, {
      email: username,
      password: password,
      captchaToken: captchaToken,
      frontendUrl: appUrl,
      frontendApp: appCode,
      connectionMetadata: info
    });
    dispatch(userActions.updateLoginSuccess());
    localStorage.setItem("token", token);
    localStorage.setItem("uc_token", ucToken);
    const ucTokenClaims = jwtDecode(ucToken);
    dispatch(authenticate(token));
    ReactGA.set({ userId: ucTokenClaims.id });
    history.push(redirect);
  } catch (error) {
    console.error(error.response);
    if (error.response) {
      dispatch(userActions.updateLoginFail(error.response.data.message));
    } else {
      dispatch(userActions.updateLoginFail("Server error."));
    }

    dispatch(userActions.authenticatedFail());
  }
};

export const validateSignUpForm = formValues => {
  return dispatch => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const passwordRegex = /^.{8,}/;
    if (isNullOrUndefinedOrBlank(formValues["firstName"])) {
      throw new SubmissionError({ firstName: "First Name is a required field." });
    }

    if (isNullOrUndefinedOrBlank(formValues["lastName"])) {
      throw new SubmissionError({ lastName: "Last Name is a required field." });
    }

    if (!emailRegex.test(formValues["email"])) {
      throw new SubmissionError({
        email: "Email address is required."
      });
    }

    if (!passwordRegex.test(formValues["password1"])) {
      throw new SubmissionError({
        password1: "Password must be a minimum of 8 characters."
      });
    }

    if (formValues["password1"] !== formValues["password2"]) {
      throw new SubmissionError({
        password1: "Passwords must match.",
        password2: "Passwords must match."
      });
    }

    dispatch(apiCreateNewUser(formValues));
  };
};

export const apiCreateNewUser = values => {
  return dispatch => {
    axios
      .post(`${ucUrl}/users/penciler_create`, {
        name: `${values["firstName"]} ${values["lastName"]}`,
        user: values["email"],
        email: values["email"],
        password: values["password1"],
        organization: values["company"]
      })
      .then(() => {
        ReactGA.event({
          category: "User",
          action: "Created new user account"
        });
        dispatch(loginUser(values["email"], values["password1"]));
      })
      .catch(errorResponse => {
        console.error(errorResponse);
      });
  };
};

const selector = formValueSelector("RegionSelectorForm");
export const regionSelectorSubmit = () =>
  safeOperation(operations.regionSwitching, async (dispatch, getState) => {
    const selectedItem = selector(getState(), "region-selector");
    if (!selectedItem || selectedItem.value === getState().regions.selectedRegion.id) {
      dispatch(closeRegionSelectorModal());
      return;
    }
    const regionId = selectedItem.value;
    await dispatch(renewUCToken(regionId));
  });

export const renewUCToken = regionId => async () => {
  return axios.post(`${baseUrl}/renew_token`, { region_id: regionId }).then(response => {
    const token = response.data.access_token;
    const ucToken = response.data.uc_token;
    localStorage.setItem("token", token);
    localStorage.setItem("uc_token", ucToken);
    window.location.reload();
  });
};
