import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Header, Icon } from "semantic-ui-react";
import {
  aggregatedTableData,
  buildingTableData,
  proFormaTableData,
  summaryTableData
} from "../config/buildingOptionTableData";
import { is } from "../lib";
import { RootState } from "../store";
import { operations } from "../store/operations";
import {
  getFilteredSiteBuildings,
  getSelectedProjectMainBuildings,
  getSiteBuildings,
  selectedSiteIdSelector,
  selectedSiteUsageIsBuildingSelector
} from "../store/selectors/projects";
import { ActiveTable } from "../types/ui";
import BuildingOptionTable from "./BuildingOptionTable";
import { TableLoader } from "./Loaders";

const mapStateToProps = (state: RootState) => {
  const isSiteView = is.id(selectedSiteIdSelector(state));
  const usageIsBuilding = selectedSiteUsageIsBuildingSelector(state);
  return {
    allBuildings: isSiteView ? getSiteBuildings(state) : getSelectedProjectMainBuildings(state),
    activeTable: state.projects.activeTable,
    filteredSiteBuildings: isSiteView
      ? getFilteredSiteBuildings(state)
      : getSelectedProjectMainBuildings(state),
    buildingsFetchInProgress: state.operations[operations.projectBuildingsFetch].isLoading,
    usageIsBuilding
  };
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;
type BuildingsViewProps = PropsFromRedux;

class BuildingsView extends React.Component<BuildingsViewProps> {
  render() {
    const { activeTable, filteredSiteBuildings } = this.props;
    return this.props.buildingsFetchInProgress ? (
      <TableLoader />
    ) : this.props.usageIsBuilding && this.props.allBuildings.length > 0 ? (
      <BuildingOptionTable
        data={
          activeTable === ActiveTable.summary
            ? filteredSiteBuildings.map(summaryTableData)
            : activeTable === ActiveTable.buildings
            ? filteredSiteBuildings.map(buildingTableData)
            : activeTable === ActiveTable.proforma
            ? filteredSiteBuildings.map(proFormaTableData)
            : []
        }
        summaryRow={aggregatedTableData(filteredSiteBuildings)}
      />
    ) : (
      <div className="empty-table-container">
        <Header as="h3" icon>
          <Icon name="building outline" />
          No buildings to display
          <Header.Subheader>
            {this.props.usageIsBuilding
              ? "Click Add Scenario to get started"
              : "Parcel is marked as Landscape"}
          </Header.Subheader>
        </Header>
      </div>
    );
  }
}

export default connector(BuildingsView);
