import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Region, DataLayer } from "../types/region";
import { regionSelectorCreator } from "./selectors/region";
import { RegionSlice } from "./slices";

const initialState: RegionSlice = {
  selectedRegion: {},
  dataLayers: {
    parcels: {
      name: "parcels",
      remoteName: "parcels_penciler_v1",
      remoteId: null,
      isVisible: true,
      errorMessage: "The parcel's hover effect will not be working."
    },
    zonings: {
      name: "zonings",
      remoteName: "zoning",
      remoteId: null,
      isVisible: false,
      errorMessage: "The zoning overlay will be unavailable."
    }
  }
};

const _toggleDataLayerVisibility = (state: RegionSlice, name: string) => {
  const oldDataLayer: DataLayer = regionSelectorCreator(state).dataLayers.byName(name);
  const newDataLayer: DataLayer = Object.assign({}, oldDataLayer, {
    isVisible: !oldDataLayer.isVisible
  });
  state.dataLayers[name] = newDataLayer;
};

const regionsSlice = createSlice({
  name: "regions",
  initialState,
  reducers: {
    //TODO (@ngoldenberg): Request regions list from UC after endpoint is ready
    setSelectedRegion(state, action: PayloadAction<Region>) {
      state.selectedRegion = action.payload;
    },
    updateDataLayerRemoteId(state, action: PayloadAction<{ name: string; remoteId: number }>) {
      const newDataLayer: DataLayer = Object.assign(
        {},
        regionSelectorCreator(state).dataLayers.byName(action.payload.name),
        { remoteId: action.payload.remoteId }
      );
      state.dataLayers[action.payload.name] = newDataLayer;
    },
    toggleParcelsLayerVisibility(state: RegionSlice) {
      _toggleDataLayerVisibility(state, "parcels");
    },
    toggleZoningsLayerVisibility(state: RegionSlice) {
      _toggleDataLayerVisibility(state, "zonings");
    }
  }
});

export const {
  setSelectedRegion,
  toggleParcelsLayerVisibility,
  toggleZoningsLayerVisibility,
  updateDataLayerRemoteId
} = regionsSlice.actions;

export default regionsSlice.reducer;
