import { isUndefined } from "lodash";
import store from "../store";
import { isPositiveNumeric } from "./config";
import { getBound, getDistanceLabel, isInRange } from "./utils";

export const validateStairsDeadEnd = (value?: string) => {
  const isMetric = store.getState().users.metric;
  return value &&
    isUndefined(isPositiveNumeric(value)) &&
    isInRange(parseFloat(value), isMetric, "stairsDeadEndDistance")
    ? undefined
    : `Max dead end must be between ${getBound(
        "stairsDeadEndDistance",
        "min"
      )}${getDistanceLabel()} and ${getBound("stairsDeadEndDistance", "max")}${getDistanceLabel()}`;
};

export const validateStairsTravelDistance = (value?: string) => {
  const isMetric = store.getState().users.metric;
  return value &&
    isUndefined(isPositiveNumeric(value)) &&
    isInRange(parseFloat(value), isMetric, "stairsMaxTravelDistance")
    ? undefined
    : `Stairs max travel distance must be between ${getBound(
        "stairsMaxTravelDistance",
        "min"
      )}${getDistanceLabel()} and ${getBound(
        "stairsMaxTravelDistance",
        "max"
      )}${getDistanceLabel()}`;
};
