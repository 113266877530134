import axios from "axios";
import { pencilerEndpointsUrls, ucUrl } from "../../config/urls";

export const asyncGetBaseZoningList = project =>
  new Promise((resolve, reject) => {
    axios
      .get(`${ucUrl}/zoning/${project.parcel_data.city_id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncGetUserZoningList = () =>
  new Promise((resolve, reject) => {
    axios
      .get(pencilerEndpointsUrls.zoningFavoritesByThisOrganization)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncGetZoningInfo = (zoningId, cityId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${ucUrl}/zoning/${cityId}/${zoningId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncGetUserZoningInfo = zoningId =>
  new Promise((resolve, reject) => {
    axios
      .get(`${pencilerEndpointsUrls.zoningFavorite}/${zoningId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncPostZoning = dataToSend =>
  new Promise((resolve, reject) => {
    axios
      .post(pencilerEndpointsUrls.zoningFavorite, dataToSend)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncGetUserDefinedZonings = () =>
  new Promise((resolve, reject) => {
    axios
      .get(pencilerEndpointsUrls.zoningUserDefined)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncPostUserDefinedZoning = dataToSend =>
  new Promise((resolve, reject) => {
    axios
      .post(pencilerEndpointsUrls.zoningFavorite, dataToSend)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncPostGeoJSONZoning = dataToSend =>
  new Promise((resolve, reject) => {
    const uc_token = localStorage.getItem("uc_token");
    axios
      .post(`${ucUrl}/zoning/search`, dataToSend, { params: { token: uc_token } })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
