/*
 * Module to handle URLs.
 * */
import env from "./env";

export const publicUrl =
  process.env.REACT_APP_PUBLIC_URL ||
  `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? ":" + window.location.port : ""
  }`;

export const appUrl = publicUrl;

export const appCode = "penciler";

export const baseUrl = env.isTestEnvironment
  ? "http://penciler"
  : process.env.REACT_APP_APPSERVER_URL || "https://penciler-server-dev.herokuapp.com";

export const ucUrl = env.isTestEnvironment
  ? "http://uc"
  : process.env.REACT_APP_UC_URL || "https://test.urbansim.com/api";

export const buildingURLs = {
  edit: buildingId => `${baseUrl}/buildings/id/${buildingId}`,
  duplicate: (siteId, buildingId) => `${baseUrl}/buildings/${siteId}?duplicate_id=${buildingId}`,
  post: siteId => `${baseUrl}/buildings/${siteId}`
};

export const pencilerEndpoints = {
  zoning: "/zoning",
  zoningFavorite: "/zoning/favorite",
  zoningFavoritesByThisOrganization: "/zoning/favorite/organization",
  zoningUserDefined: "/zoning/user_defined"
};

export const pencilerEndpointsUrls = {};
Object.keys(pencilerEndpoints).forEach(
  k => (pencilerEndpointsUrls[k] = `${baseUrl}${pencilerEndpoints[k]}`)
);
