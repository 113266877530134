import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import centroid from "@turf/centroid";
import { Coord } from "@turf/helpers";
import { escapeRegExp } from "lodash";
import { combineReducers } from "redux";
import { Modes } from "../../components/DrawControls/constants";
import { BoundingBox, is } from "../../lib/index";
import { ParcelCandidate } from "../../lib/ParcelCandidate";
import { Project } from "../../types/projects";
import { setProjectList } from "../projects";
import { IndexUISlice } from "./../slices/ui";
import boundaryVisualizationReducer from "./boundaryVisualization";
import dataLayersReducer from "./dataLayers";
import dimmedLoaderReducer from "./dimmedLoader";
import incompatibleConstraintsReducer from "./incompatibleConstraints";
import incompatibleProformaReducer from "./incompatibleProforma";
import infoMessageReducer from "./infoMessage";
import parcelUsagesReducer from "./parcelUsages";

const initialState: IndexUISlice = {
  mapLoaded: false,
  mapViewport: new BoundingBox(),
  projectsNameFilter: "",
  displayedProjectsIDs: [],
  disabledProjectsIDs: [],
  focusedProjectId: null,
  parcelSearchCandidates: [],
  displayRegionSelectorModal: false,
  currentParcelEditionMode: null,
  displayProformaUpdatedModal: false,
  displayProjectRenameModal: false
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    focusProject(state, action: PayloadAction<number>) {
      if (!is.id(action.payload)) {
        throw new TypeError("'projectId' needs to be a valid id.");
      }
      state.focusedProjectId = action.payload;
    },
    unfocusProject(state) {
      state.focusedProjectId = null;
    },
    setMapLoaded(state) {
      state.mapLoaded = true;
    },
    updateMapViewport(
      state,
      action: PayloadAction<{ projects: Project[]; mapViewport: BoundingBox }>
    ) {
      const { projects, mapViewport } = action.payload;
      if (!(mapViewport instanceof BoundingBox)) {
        throw new TypeError(
          `[ui::updateMapViewport::error] 'mapViewport' is not instance of BoundingBox. ` +
            `Received '${JSON.stringify(mapViewport)}' of type '${typeof mapViewport}'.`
        );
      }
      state.mapViewport = mapViewport;
      state.displayedProjectsIDs = filterProjectsIdsBy(
        projects,
        state.projectsNameFilter,
        state.mapViewport
      );
    },
    updateProjectsNameFilter(
      state,
      action: PayloadAction<{ projects: Project[]; projectsNameFilter: string }>
    ) {
      const { projects, projectsNameFilter } = action.payload;
      if (typeof projectsNameFilter !== "string") {
        throw new TypeError(
          `[ui::updateProjectsNameFilter::error] 'projectsNameFilter' is not of type string.` +
            `Received '${JSON.stringify(
              projectsNameFilter
            )}' of type '${typeof projectsNameFilter}'.`
        );
      }

      state.projectsNameFilter = projectsNameFilter;
      state.displayedProjectsIDs = filterProjectsIdsBy(
        projects,
        state.projectsNameFilter,
        state.mapViewport
      );
    },
    updateParcelSearchCandidates(state, action: PayloadAction<ParcelCandidate[]>) {
      if (!Array.isArray(action.payload)) {
        throw new TypeError(
          `[ui::updateParcelSearchCandidates::error] 'candidates' needs to be an Array.` +
            `Got '${JSON.stringify(action.payload)}'`
        );
      }
      action.payload.forEach(candidate => {
        if (candidate.parcelId === null && candidate.address === null) {
          throw new TypeError(
            `[ui::updateParcelSearchCandidates::error] Parcel search candidates are expected` +
              `to have 'parcelId' or 'address'.`
          );
        }
      });

      state.parcelSearchCandidates = action.payload;
    },
    setDisableProjectsIds(state, action: PayloadAction<number[]>) {
      state.disabledProjectsIDs = action.payload;
    },
    toggleDisableProjectId(state, action: PayloadAction<number>) {
      const index = state.disabledProjectsIDs.indexOf(action.payload);
      if (index > -1) {
        state.disabledProjectsIDs.splice(index, 1);
      } else {
        state.disabledProjectsIDs.push(action.payload);
      }
    },
    openRegionSelectorModal(state) {
      state.displayRegionSelectorModal = true;
    },
    closeRegionSelectorModal(state) {
      state.displayRegionSelectorModal = false;
    },
    openProformaUpdatedModal(state) {
      state.displayProformaUpdatedModal = true;
    },
    closeProformaUpdatedModal(state) {
      state.displayProformaUpdatedModal = false;
    },
    openProjectRenameModal(state) {
      state.displayProjectRenameModal = true;
    },
    closeProjectRenameModal(state) {
      state.displayProjectRenameModal = false;
    },
    setParcelEditionMode(state, action: PayloadAction<Modes | null>) {
      state.currentParcelEditionMode = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(setProjectList, (state, action: PayloadAction<Project[]>) => {
      state.displayedProjectsIDs = filterProjectsIdsBy(
        action.payload,
        state.projectsNameFilter,
        state.mapViewport
      );
    });
  }
});

const filterProjectsIdsBy = (
  projects: Project[],
  projectsNameFilter: string,
  mapViewport: BoundingBox
): number[] => {
  const projectsNameRegExp = new RegExp(escapeRegExp(projectsNameFilter), "i");
  return projects
    .filter(project =>
      booleanPointInPolygon(
        centroid(project.parcel_data.geometry) as Coord,
        mapViewport.asPolygon()
      )
    )
    .filter(project => projectsNameRegExp.test(project.name))
    .map(project => project.id);
};

export const {
  focusProject,
  unfocusProject,
  setMapLoaded,
  updateMapViewport,
  updateProjectsNameFilter,
  updateParcelSearchCandidates,
  openRegionSelectorModal,
  closeRegionSelectorModal,
  setDisableProjectsIds,
  toggleDisableProjectId,
  openProformaUpdatedModal,
  closeProformaUpdatedModal,
  openProjectRenameModal,
  closeProjectRenameModal,
  setParcelEditionMode
} = uiSlice.actions;

export default combineReducers({
  index: uiSlice.reducer,
  boundaryVisualization: boundaryVisualizationReducer,
  dataLayers: dataLayersReducer,
  incompatibleConstraints: incompatibleConstraintsReducer,
  incompatibleProforma: incompatibleProformaReducer,
  dimmedLoader: dimmedLoaderReducer,
  infoMessage: infoMessageReducer,
  parcelUsages: parcelUsagesReducer
});
