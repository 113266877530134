import { differenceBy } from "lodash";
import React from "react";
import { makeResizable, Resizer } from "react-make-resizable";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  Menu,
  Header,
  Input,
  Confirm,
  Dropdown,
  DropdownProps,
  Popup
} from "semantic-ui-react";

import "../stylesheets/ProjectControls.css";
import {
  buildingOptionPanelHeight,
  buildingOptionPanelMinHeight,
  buildingOptionPanelHeightMinimized,
  defaultParcelUsageId
} from "../config/config";
import { toggleBuildingOptionControls } from "../config/utils";
import { actions, ownerships, resources } from "../constants/permissions";
import { changeSelectedSite, changeSelectedSiteUsage } from "../controllers/developmentSites";
import * as projectCtrl from "../controllers/projects";
import * as uiCtrl from "../controllers/ui/index";
import { userIsAllowedToAny } from "../helpers/permissions";
import { is } from "../lib";
import { RootState } from "../store";
import { operations } from "../store/operations";
import * as projectActions from "../store/projects";
import {
  getFilteredSiteBuildings,
  getSelectedBuilding,
  getSelectedProjectSelectedSite,
  getSiteBuildings,
  projectSitesIdsSelector,
  selectedSiteIdSelector,
  selectedSiteIsValidSelector
} from "../store/selectors/projects";
import { isProject, Project } from "../types/projects";
import { ActiveTable } from "../types/ui";
import BuildingsView from "./BuildingsView";
import ZoningRefreshPopup from "./ZoningRefreshPopup";

const mapStateToProps = (state: RootState) => {
  const projectZoningIsOutdated =
    is.null(state.projects.currentProjectDefaultZoning) ||
    (is.nonEmptyString((state.projects.selectedProject as Project).parcel_data?.zoning) &&
      (state.projects.selectedProject as Project).parcel_data?.zoning !==
        state.projects.currentProjectDefaultZoning.zoning_code);
  const allBuildings = getSiteBuildings(state);
  const filteredProjectBuildingData = getFilteredSiteBuildings(state);
  const selectedSiteId = selectedSiteIdSelector(state);
  const selectedSite = getSelectedProjectSelectedSite(state);
  return {
    allBuildings,
    sidebarVisible: state.projects.sidebarVisible,
    bottomBarVisible: state.projects.bottomBarVisible,
    map: state.map.map as mapboxgl.Map | undefined,
    center: state.map.center,
    zoom: state.map.zoom,
    pitch: state.map.pitch,
    bearing: state.map.bearing,
    activeTable: state.projects.activeTable,
    filteredProjectBuildingData: filteredProjectBuildingData,
    projectSitesIds: projectSitesIdsSelector(state),
    selectedSiteId,
    isSiteView: is.id(selectedSiteId),
    toggleEdit: state.projects.toggleBuildingEdit,
    selectedProject: state.projects.selectedProject,
    buildingOptionPanelExpanded: state.projects.buildingOptionPanelExpanded,
    editBuildingList: state.projects.editBuildingList,
    buildingSearch: state.projects.buildingSearch,
    selectedBuilding: getSelectedBuilding(state),
    siteHasValidFrontage: selectedSiteIsValidSelector(state),
    parcelUsages: state.ui.parcelUsages.parcelUsages,
    selectedSiteUsage: is.null(selectedSite) ? null : selectedSite.parcel_usage_id,
    editFrontage: state.projects.editFrontage,
    zoningGetOperation: state.operations[operations.getZoningInfo],
    zoningRefreshOperation: state.operations[operations.projectZoningRefresh],
    projectOpeningOperation: state.operations[operations.projectOpening],
    flags: {
      buildingIsLoading:
        state.buildings.flags.buildingIsLoading ||
        state.operations[operations.projectBuildingsFetch].isLoading,
      projectZoningIsOutdated
    }
  };
};

const connector = connect(mapStateToProps, {
  ...projectActions,
  ...projectCtrl,
  changeSelectedSite,
  changeSelectedSiteUsage,
  ...uiCtrl
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type ProjectControlsProps = PropsFromRedux;
type ProjectControlsState = { confirmOpen: boolean };

class ProjectControls extends React.Component<ProjectControlsProps, ProjectControlsState> {
  constructor(props: ProjectControlsProps) {
    super(props);
    this.state = {
      confirmOpen: false
    };
  }

  handleChangeSearch(value: string) {
    this.props.setBuildingSearch(value);
  }

  handleBackButtonClick() {
    if (!this.props.map) return;
    this.props.returnToInitialView(this.props.map);
  }

  handleTableChange(name: ActiveTable) {
    this.props.setActiveTable(name);
  }

  handleClickEditButton() {
    this.props.toggleEditBuildings();
  }

  handleAddBuildingClick() {
    this.props.toggleAddBuildingModal(true);
  }

  handleToggleBuildingOption(currentState: boolean) {
    toggleBuildingOptionControls(currentState);
    this.props.toggleBuildingOption(!currentState);
  }

  handleClickSelectAll() {
    this.props.checkBuildingEditBox(
      //@ts-ignore
      this.props.filteredProjectBuildingData.map(buildingOption => {
        return {
          id: buildingOption.id
        };
      })
    );
  }

  handleClickSelectNone() {
    this.props.checkBuildingEditBox([]);
  }

  handleClickInvert() {
    const data = this.props.filteredProjectBuildingData.map(buildingOption => {
      return {
        id: buildingOption.id
      };
    });

    //@ts-ignore
    this.props.checkBuildingEditBox(differenceBy(data, this.props.editBuildingList, "id"));
  }

  handleSiteSelectionChange(value: number) {
    this.props.changeSelectedSite(value);
    this.props.setEditBuildings(false);
  }

  handleDeleteBuildings() {
    const { editBuildingList, selectedSiteId } = this.props;
    if (!is.id(selectedSiteId)) return;
    this.props.apiDeleteBuildingOptions(
      editBuildingList.map(item => item.id),
      selectedSiteId
    );
    this.props.checkBuildingEditBox([]);
  }

  render() {
    const { selectedProject, buildingOptionPanelExpanded, flags, isSiteView } = this.props;
    const projectIsReady =
      isProject(selectedProject) && !this.props.projectOpeningOperation.isLoading;

    const AddScenarioButton = (
      <Button
        onClick={this.handleAddBuildingClick.bind(this)}
        loading={!projectIsReady}
        disabled={
          !projectIsReady ||
          this.props.zoningGetOperation.isLoading ||
          this.props.flags.projectZoningIsOutdated ||
          !this.props.siteHasValidFrontage ||
          this.props.selectedSiteUsage !== defaultParcelUsageId
        }
      >
        Add Scenario
      </Button>
    );

    const editFrontageButton = (
      <Button
        onClick={() => {
          if (this.props.editFrontage) {
            this.props.hideEditFrontageControls();
          } else {
            this.props.showEditFrontageControls();
          }
          this.props.toggleEditFrontage(!this.props.editFrontage);
        }}
        loading={!projectIsReady}
        disabled={!projectIsReady || flags.buildingIsLoading}
      >
        Change Site Frontage
      </Button>
    );

    return (
      <div
        id="building-options-panel"
        style={{
          height: buildingOptionPanelHeight,
          minHeight: buildingOptionPanelExpanded
            ? buildingOptionPanelMinHeight
            : buildingOptionPanelHeightMinimized
        }}
      >
        {buildingOptionPanelExpanded ? <Resizer position="top" /> : null}
        <div>
          <Menu borderless style={{ margin: "0px" }}>
            <Menu.Item
              id="close-project-controls"
              icon="caret left"
              onClick={() => {
                this.handleBackButtonClick();
              }}
              disabled={!projectIsReady || flags.buildingIsLoading}
            />
            <Menu.Item>
              <Header as="h5" textAlign="center">
                {(selectedProject as Project).name}
              </Header>
            </Menu.Item>
            <Menu.Item>
              <Dropdown
                onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) =>
                  this.handleSiteSelectionChange.bind(this)(data.value as number)
                }
                options={this.props.projectSitesIds
                  .map((id, index) => {
                    return {
                      key: id,
                      text: `Parcel ${String.fromCharCode(65 + index)}`,
                      value: id
                    };
                  })
                  .concat([{ key: 0, text: "All Properties", value: 0 }])}
                value={this.props.selectedSiteId ?? 0}
                disabled={
                  this.props.projectOpeningOperation.isLoading ||
                  this.props.projectSitesIds.length <= 1 ||
                  this.props.editFrontage
                }
                scrolling
              />
            </Menu.Item>
            <Menu.Item className={isSiteView ? "" : "hidden"}>
              <Header as="h5" textAlign="center">
                Parcel Usage:
              </Header>
            </Menu.Item>
            <Menu.Item className={isSiteView ? "" : "hidden"}>
              <Dropdown
                onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                  if (!is.id(this.props.selectedSiteId) || !is.id(data.value)) return;
                  this.props.changeSelectedSiteUsage(this.props.selectedSiteId, data.value);
                }}
                options={Object.entries(this.props.parcelUsages).map(([id, usage]) => ({
                  key: Number(id),
                  text: usage,
                  value: Number(id)
                }))}
                value={this.props.selectedSiteUsage ?? defaultParcelUsageId}
                disabled={this.props.projectOpeningOperation.isLoading || this.props.editFrontage}
              />
            </Menu.Item>
            <Menu.Menu position="right">
              {userIsAllowedToAny(
                actions.update,
                [ownerships.own, ownerships.organization],
                resources.project
              ) ? (
                <Menu.Item className={isSiteView ? "" : "hidden"}>{editFrontageButton}</Menu.Item>
              ) : null}

              <Menu.Item
                icon={buildingOptionPanelExpanded ? "caret down" : "caret up"}
                onClick={() => this.handleToggleBuildingOption(buildingOptionPanelExpanded)}
              />
            </Menu.Menu>
          </Menu>
        </div>
        {this.props.toggleEdit ? (
          <Menu secondary size="mini" fluid style={{ margin: "0px", backgroundColor: "white" }}>
            <Menu.Item>
              <Header as="h5">Select: </Header>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.handleClickSelectAll();
              }}
            >
              <p>All</p>
            </Menu.Item>
            <Menu.Item
              disabled={this.props.editBuildingList.length === 0}
              onClick={() => {
                this.handleClickSelectNone();
              }}
            >
              <p>None</p>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.handleClickInvert();
              }}
            >
              <p>Invert</p>
            </Menu.Item>
            <Menu.Item
              disabled={this.props.editBuildingList.length === 0}
              onClick={() => {
                this.setState({ confirmOpen: true });
              }}
            >
              Delete
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.props.setEditBuildings(false);
              }}
            >
              Done
            </Menu.Item>
          </Menu>
        ) : (
          <div />
        )}
        <div>
          <Menu attached="top" compact tabular>
            {userIsAllowedToAny(
              [actions.update, actions.delete],
              [ownerships.own, ownerships.organization],
              resources.buildingOption
            ) ? (
              <Menu.Item className={isSiteView ? "" : "hidden"}>
                <Button
                  icon="trash"
                  onClick={() => this.handleClickEditButton()}
                  disabled={this.props.selectedSiteUsage !== defaultParcelUsageId}
                />
              </Menu.Item>
            ) : null}
            <Menu.Item
              color="teal"
              active={this.props.activeTable === ActiveTable.summary}
              style={{ marginLeft: "2%" }}
              onClick={() => {
                this.handleTableChange(ActiveTable.summary);
              }}
            >
              Summary
            </Menu.Item>
            <Menu.Item
              color="teal"
              active={this.props.activeTable === ActiveTable.buildings}
              onClick={() => {
                this.handleTableChange(ActiveTable.buildings);
              }}
            >
              Buildings
            </Menu.Item>
            <Menu.Item
              color="teal"
              active={this.props.activeTable === ActiveTable.proforma}
              onClick={() => {
                this.handleTableChange(ActiveTable.proforma);
              }}
            >
              Pro Forma Analysis
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item className={isSiteView ? "" : "hidden"}>
                <Input
                  icon="search"
                  placeholder="Search"
                  onChange={(e, data) => {
                    this.handleChangeSearch(data.value);
                  }}
                  value={this.props.buildingSearch}
                />
              </Menu.Item>
              {userIsAllowedToAny(actions.create, ownerships.all, resources.buildingOption) ? (
                <Menu.Item className={isSiteView ? "" : "hidden"}>
                  {this.props.flags.projectZoningIsOutdated ? (
                    <ZoningRefreshPopup
                      onClick={() =>
                        this.props.refreshProjectZoning((selectedProject as Project).id)
                      }
                      trigger={AddScenarioButton}
                      zoningRefreshOperation={this.props.zoningRefreshOperation}
                    />
                  ) : !this.props.siteHasValidFrontage && !this.props.editFrontage ? (
                    <Popup hoverable trigger={<span> {AddScenarioButton} </span>}>
                      <h5>No frontage was selected</h5>
                      This site needs to have at least one frontage selected to allow new buildings.
                      {editFrontageButton}
                    </Popup>
                  ) : (
                    AddScenarioButton
                  )}
                </Menu.Item>
              ) : null}
            </Menu.Menu>
          </Menu>
        </div>

        <BuildingsView />

        <Confirm
          content="Are you sure you want to delete these buildings?"
          open={this.state.confirmOpen}
          onCancel={() => {
            this.setState({ confirmOpen: false });
          }}
          onConfirm={() => {
            this.handleDeleteBuildings();
            this.setState({ confirmOpen: false });
          }}
          size="mini"
        />
      </div>
    );
  }
}

export default connector(makeResizable(ProjectControls));
