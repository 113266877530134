import axios from "axios";

import { ucUrl } from "../../config/urls";
import { IntegrationError } from "../../lib/errors";
import { RootState, AppDispatch } from "../../store";
import { pushIntegrationError } from "../../store/errors";
import { safeOperation, operations } from "../../store/operations";
import { updateDataLayerRemoteId } from "../../store/regions";
import { regionSelectorCreator } from "../../store/selectors/region";
import { setAvailableRegions } from "../../store/users";

export const makeRegionInitializationFetch = (): any =>
  safeOperation(
    operations.regionInitializationFetch,
    async (dispatch: AppDispatch, getState: () => RootState) => {
      const { data: remoteLayers } = await axios.get(`${ucUrl}/layers/list`);
      const { dataLayers } = regionSelectorCreator(getState().regions);
      dataLayers.all
        .map((dataLayer: any) => ({
          name: dataLayer.name,
          remoteId: remoteLayers.find(
            (remoteLayer: any) => remoteLayer.name === dataLayer.remoteName
          )?.id
        }))
        .forEach(({ name, remoteId }: any) => {
          if (!remoteId) {
            dispatch(
              pushIntegrationError(
                new IntegrationError(name, dataLayers.byName(name).errorMessage, {
                  missingLayer: true
                })
              )
            );
            return;
          }
          dispatch(updateDataLayerRemoteId({ name, remoteId }));
        });
    }
  );

export const hydrateUserAvailableRegions = (): any =>
  safeOperation(operations.availableRegionsFetch, async (dispatch: AppDispatch) => {
    const { data } = await axios.get(`${ucUrl}/users/this/available_regions`);
    dispatch(setAvailableRegions(data));
  });
