import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Table, Button, Icon } from "semantic-ui-react";
import { mainMenuHeight } from "../config/config";
import { apiUpdateSiteSegments } from "../controllers/developmentSites";
import * as projectCtrl from "../controllers/projects";
import { RootState } from "../store";
import * as projectActions from "../store/projects";
import { selectedSiteIdSelector } from "../store/selectors/projects";
import SegmentRequirements from "./SegmentRequirements";

const mapStateToProps = (state: RootState) => {
  return {
    sideSegments: state.projects.sideSegments,
    frontSegments: state.projects.frontSegments,
    rearSegments: state.projects.rearSegments,
    selectedSiteId: selectedSiteIdSelector(state),
    selectedProject: state.projects.selectedProject
  };
};

const actionObj = { ...projectActions, ...projectCtrl, apiUpdateSiteSegments };

const connector = connect(mapStateToProps, actionObj);
type EditFrontageProps = ConnectedProps<typeof connector>;

class EditFrontage extends React.Component<EditFrontageProps> {
  render() {
    return (
      <div
        style={{
          height: window.innerHeight - mainMenuHeight,
          background: "white",
          overflowX: "hidden"
        }}
      >
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Icon
                  name="close"
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={() => {
                    this.props.hideEditFrontageControls();
                    this.props.toggleEditFrontage(false);
                  }}
                />
                <SegmentRequirements />

                <Button
                  onClick={() => {
                    const { selectedSiteId } = this.props;
                    const updatedSegments = {
                      front: this.props.frontSegments,
                      rear: this.props.rearSegments,
                      sides: this.props.sideSegments
                    };
                    this.props.apiUpdateSiteSegments(selectedSiteId, updatedSegments);
                  }}
                >
                  Update
                </Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default connector(EditFrontage);
