import { GeographicCoordinates } from "../types";

export const coordinates = (coordinates: GeographicCoordinates) => ({
  to: {
    get mapboxPointLike(): [number, number] {
      return [coordinates.longitude, coordinates.latitude];
    }
  }
});

export const mapbox = {
  // https://docs.mapbox.com/mapbox-gl-js/api/events/#mapmouseevent
  mouseEventLngLat: (lngLat: { lat: number; lng: number }) => ({
    to: {
      get coordinates(): GeographicCoordinates {
        return {
          longitude: lngLat.lng,
          latitude: lngLat.lat
        };
      }
    }
  }),
  // https://docs.mapbox.com/mapbox-gl-js/api/geography/#pointlike
  pointLike: (mapboxPointLike: [number, number]) => ({
    to: {
      get coordinates(): GeographicCoordinates {
        return {
          longitude: mapboxPointLike[0],
          latitude: mapboxPointLike[1]
        };
      }
    }
  })
};
