import { schemeBlues } from "d3-scale-chromatic";
import { pie, arc } from "d3-shape";
import { isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Segment, Form, Divider, Grid, Button } from "semantic-ui-react";
import { isZeroOrPositiveNumeric, validatePercentage, required } from "../../config/config";
import { isNullOrUndefined, getAreaLabel } from "../../config/utils";
import * as proformaCtrl from "../../controllers/proforma";
import { is } from "../../lib";
import * as proformaActions from "../../store/proforma";
import * as projectActions from "../../store/projects";
import { getSelectedBuilding, getSelectedBuildingId } from "../../store/selectors/projects";
import { BuildingTypeCode } from "../../types/buildings";
import RentProfileModal from "../rentProfile/index";
import Steps from "../Steps";
import WizardStep from "../WizardStep";
import TextInput from "./../forms/TextInput";
import { DemoMessage } from "./DemoMessage";

const actionObj = Object.assign({}, projectActions, proformaActions, proformaCtrl);
const footerButtons = props => {
  return (
    <div>
      <DemoMessage isDemoBuilding={props.isDemoBuilding} />
      <Button
        onClick={() => {
          props.setActiveModalForm("DevCostsStep");
        }}
      >
        Back
      </Button>
      <Button
        onClick={() => {
          props.submitOperatingStep();
        }}
      >
        Next
      </Button>
    </div>
  );
};

export const OperatingStepFooter = connect(state => {
  return {
    isDemoBuilding: is.null(getSelectedBuildingId(state))
  };
}, actionObj)(footerButtons);

const errorsDivId = "rent-error-msg";
const OperatingStepError = props => (
  <div id={errorsDivId}>
    <h4 style={{ color: "red" }}>
      {props.hasErrorInChild
        ? "An error occured inside the operating step and we could not fix it!"
        : props.submitError
        ? props.submitError
        : null}
    </h4>
  </div>
);

class OperatingStep extends WizardStep {
  constructor(props) {
    super(props);
    this.state = {
      hasErrorInChild: false,
      managerUnits: 0,
      rentProfileOpen: false,
      pieHover: false,
      rentModalSize: "large"
    };

    this.setRentModalSize = this.setRentModalSize.bind(this);
  }

  setRentModalSize(size) {
    this.setState({
      rentModalSize: size
    });
  }

  static getDerivedStateFromError() {
    return { hasErrorInChild: true };
  }

  render() {
    const {
      handleSubmit,
      selectedBuilding,
      managerUnits,
      toggleRentProfile,
      rentProfiles
    } = this.props;
    const svgHeight = 100;
    const svgWidth = 100;
    const arcs = isEmpty(rentProfiles) ? [] : pie().value(d => d.unit_count)(rentProfiles);

    const arcFunction = arc()
      .innerRadius(0)
      .outerRadius(Math.min(svgWidth, svgHeight) / 2 - 1);

    const stepData = [
      { title: "Development Costs", active: false, disabled: false },
      { title: "Operating Period Assumptions", active: true, disabled: false },
      { title: "Sources of Funds", active: false, disabled: true }
    ];

    const isApartment = selectedBuilding.building_type.code === BuildingTypeCode.apartment;
    return this.state.hasErrorInChild ? (
      <OperatingStepError
        hasErrorInChild={this.state.hasErrorInChild}
        submitError={this.props.error}
      />
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Steps stepData={stepData} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form onSubmit={handleSubmit}>
                <Segment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <Field
                          name="residential_vacancy"
                          component={TextInput}
                          label="Residential Vacancy"
                          unitLabel="%"
                          labelPosition="right"
                          readOnly={this.readOnlyMode}
                          validate={[isZeroOrPositiveNumeric, validatePercentage, required]}
                        />
                        {isApartment ? (
                          <Field
                            name="commercial_vacancy"
                            component={TextInput}
                            label="Commercial Vacancy"
                            unitLabel="%"
                            labelPosition="right"
                            readOnly={this.readOnlyMode}
                            validate={[isZeroOrPositiveNumeric, validatePercentage, required]}
                          />
                        ) : null}
                        <Field
                          name="operating_expenses_per_unit_per_annum"
                          component={TextInput}
                          label="Operating Expenses per Unit per Annum"
                          unitLabel="$"
                          labelPosition="right"
                          readOnly={this.readOnlyMode}
                          validate={[isZeroOrPositiveNumeric, required]}
                        />
                        <Field
                          name="expense_inflator"
                          component={TextInput}
                          label="Expense Inflator"
                          unitLabel="%"
                          labelPosition="right"
                          readOnly={this.readOnlyMode}
                          validate={[isZeroOrPositiveNumeric, validatePercentage, required]}
                        />
                        <Field
                          name="reserves_per_unit_per_annum"
                          component={TextInput}
                          label="Reserves per Unit per Annum"
                          unitLabel="$"
                          labelPosition="right"
                          readOnly={this.readOnlyMode}
                          validate={[isZeroOrPositiveNumeric, required]}
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Segment
                          as={Button}
                          fluid={true}
                          onClick={e => {
                            e.preventDefault();
                            if (this.readOnly) return;

                            this.props.toggleRentProfileModal(true);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                            <h5>Residential Rent Profile</h5>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                              }}
                            >
                              <svg height={svgHeight} width={svgWidth}>
                                <g transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}>
                                  {arcs.map((slice, index) => {
                                    return (
                                      <path
                                        key={index}
                                        fill={
                                          this.state[slice.data.name]
                                            ? "#2282e2"
                                            : schemeBlues[5][index]
                                        }
                                        stroke="white"
                                        d={arcFunction(slice)}
                                        onMouseEnter={() => {
                                          const stateObj = {};
                                          stateObj[slice.data.name] = true;
                                          this.setState(stateObj);
                                        }}
                                        onMouseLeave={() => {
                                          const stateObj = {};
                                          stateObj[slice.data.name] = false;
                                          this.setState(stateObj);
                                        }}
                                      >
                                        <title>
                                          {`${slice.data.name}: 
                                            ${slice.data.unit_count} units, AMI: ${slice.data.ami}`}
                                        </title>
                                      </path>
                                    );
                                  })}
                                </g>
                              </svg>
                              <span>{`${selectedBuilding.studio +
                                selectedBuilding.onebed +
                                selectedBuilding.twobed +
                                selectedBuilding.threebed +
                                selectedBuilding.fourbed} Units`}</span>
                            </div>
                          </div>
                        </Segment>
                        <Field
                          name="residential_rent_inflator"
                          component={TextInput}
                          label="Residential Rent Inflator"
                          unitLabel="%"
                          labelPosition="right"
                          readOnly={this.readOnlyMode}
                          validate={[isZeroOrPositiveNumeric, required]}
                        />
                        {isApartment ? (
                          <div>
                            <Field
                              name="commercial_rent_per_sf_per_annum"
                              component={TextInput}
                              label={`Commercial Rent per ${getAreaLabel()} per Annum`}
                              unitLabel="$"
                              labelPosition="right"
                              readOnly={this.readOnlyMode}
                              validate={[isZeroOrPositiveNumeric, required]}
                            />
                            <Field
                              name="commercial_rent_inflator"
                              component={TextInput}
                              label="Commercial Rent Inflator"
                              unitLabel="%"
                              labelPosition="right"
                              readOnly={this.readOnlyMode}
                              validate={[isZeroOrPositiveNumeric, required]}
                            />
                            <Field
                              name="laundry_income_per_unit_per_month"
                              component={TextInput}
                              label="Laundry Income per Unit per Month"
                              unitLabel="$"
                              labelPosition="right"
                              readOnly={this.readOnlyMode}
                              validate={[isZeroOrPositiveNumeric, required]}
                            />
                          </div>
                        ) : null}
                        <Divider />
                        <p>{`Manager's Unit`}</p>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginRight: "5px" }}>
                            <Field
                              name="manager_units"
                              component="input"
                              type="radio"
                              value={1}
                              checked={managerUnits === 1}
                              readOnly={this.readOnlyMode}
                              onChange={() => {
                                this.props.changeManagersUnit(1);
                              }}
                            />
                          </div>
                          <div style={{ marginRight: "10px" }}>
                            <span>Yes</span>
                          </div>
                          <div style={{ marginRight: "5px" }}>
                            <Field
                              name="manager_units"
                              component="input"
                              type="radio"
                              value={0}
                              checked={managerUnits === 0}
                              onChange={() => {
                                this.props.changeManagersUnit(0);
                              }}
                              readOnly={this.readOnlyMode}
                            />
                          </div>
                          <span>No</span>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
                <OperatingStepError
                  hasErrorInChild={this.state.hasErrorInChild}
                  submitError={this.props.error}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <RentProfileModal
          fixingBeforeBuildingEdition={false}
          open={toggleRentProfile}
          onClose={() => this.props.toggleRentProfileModal(false)}
          onSubmit={this.props.handleResRentProfileSubmit}
        />
      </div>
    );
  }
}

const selector = formValueSelector("OperatingStepForm");

export const mapStateToProps = state => {
  const selectedBuilding = getSelectedBuilding(state);
  return {
    selectedBuilding: selectedBuilding,
    rentProfileSpinner: state.proforma.rentProfileSpinner,
    managerUnits: parseInt(selector(state, "manager_units")),
    toggleRentProfile: state.proforma.toggleRentProfile,
    rentProfiles: state.proforma.rentProfiles,
    initialValues: {
      residential_vacancy: !isNullOrUndefined(selectedBuilding.rents)
        ? selectedBuilding.rents.inputs.residential_vacancy * 100
        : 0,
      commercial_vacancy: !isNullOrUndefined(selectedBuilding.rents)
        ? selectedBuilding.rents.inputs.commercial_vacancy * 100
        : 0,
      operating_expenses_per_unit_per_annum: !isNullOrUndefined(selectedBuilding.rents)
        ? selectedBuilding.rents.inputs.operating_expenses_per_unit_per_annum
        : 0,
      expense_inflator: !isNullOrUndefined(selectedBuilding.rents)
        ? selectedBuilding.rents.inputs.expense_inflator * 100
        : 0,
      reserves_per_unit_per_annum: !isNullOrUndefined(selectedBuilding.rents)
        ? selectedBuilding.rents.inputs.reserves_per_unit_per_annum
        : 0,
      residential_rent_inflator: !isNullOrUndefined(selectedBuilding.rents)
        ? selectedBuilding.rents.inputs.residential_rent_inflator * 100
        : 0,
      commercial_rent_per_sf_per_annum: !isNullOrUndefined(selectedBuilding.rents)
        ? selectedBuilding.rents.inputs.commercial_rent_per_sf_per_annum
        : 0,
      commercial_rent_inflator: !isNullOrUndefined(selectedBuilding.rents)
        ? selectedBuilding.rents.inputs.commercial_rent_inflator * 100
        : 0,
      laundry_income_per_unit_per_month: !isNullOrUndefined(selectedBuilding.rents)
        ? selectedBuilding.rents.inputs.laundry_income_per_unit_per_month
        : 0,
      manager_units: !isNullOrUndefined(selectedBuilding.rents)
        ? selectedBuilding.rents.inputs.manager_units
        : 0
    }
  };
};

const OperatingStepForm = reduxForm({
  form: "OperatingStepForm",
  destroyOnUnmount: false,
  onSubmitFail: () => {
    document.getElementById(errorsDivId).scrollIntoView({ behavior: "smooth" });
  }
})(OperatingStep);

export default connect(mapStateToProps, actionObj)(OperatingStepForm);
