import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { Polygon, Properties, Coord, point, Feature, Point } from "@turf/helpers";
import {
  area,
  union,
  pointOnLine,
  pointOnFeature,
  polygonToLine,
  polygonize,
  destination,
  bearing,
  distance
} from "@turf/turf";
import { sqMetersToSqFeet } from "../../../../config/config";
import {
  formatCommas,
  formatDistanceLabel,
  getShortAreaLabel,
  latitudeIndex,
  longitudeIndex
} from "../../../../config/utils";
import store from "../../../../store";
import { DrawFeature, DrawMode, DrawMouseEvent } from "../../commonTypes";
import {
  boundarySnappingDistance,
  endpointDistanceExtension,
  Modes,
  multiLineId,
  projectBoundaryId,
  vertexSnappingDistance
} from "../../constants";
import {
  getClosestPoint,
  isFeatureIntersectingItself,
  createSupplementaryPoints
} from "../../utils";
import { getRandomId, removeParcels } from "./utils";

const editedMultiLineId = `${multiLineId}-clone`;

type EditLineModeState = {
  originalMultiLine: DrawFeature;
  editedMultiLine: DrawFeature;
  projectBoundary: DrawFeature;
  parcelAsLine: DrawFeature;
  draggedCoordPath: string | null;
  isDragging: boolean;
  isMetric: boolean;
  amountOfSubsitesShown: number;
};

const EditLineMode: DrawMode = {
  onSetup: function() {
    const projectBoundary = this.getFeature(projectBoundaryId);
    const originalMultiLine = this.getFeature(multiLineId);
    const editedMultiLine = this.newFeature({
      type: "Feature",
      id: editedMultiLineId,
      properties: {
        meta: "feature",
        intersects: "false",
        canBeCompleted: "false"
      },
      geometry: {
        type: "MultiLineString",
        coordinates: []
      }
    });
    const isMetric = store.getState().users.metric;

    if (originalMultiLine !== undefined) {
      editedMultiLine.setCoordinates(originalMultiLine.getCoordinates());
    }
    this.addFeature(editedMultiLine);

    // Note (@nicoluce): If the user presses Enter instead of clicking the Confirm button
    // this event handler will remain. See onConfirmation for more information about this scenario
    const modeId = getRandomId();
    editedMultiLine.properties.modeId = modeId;
    this.map.once("draw.confirm", () => this.onConfirmation(modeId));

    this.setActionableState({
      trash: true
    });

    return {
      originalMultiLine,
      editedMultiLine,
      projectBoundary,
      parcelAsLine: polygonToLine(projectBoundary),
      draggedCoordPath: null,
      isDragging: false,
      isMetric,
      amountOfSubsitesShown: 0
    };
  },

  fireActionable: function(state: EditLineModeState) {
    this.setActionableState({
      combineFeatures: false,
      uncombineFeatures: false,
      trash: state.draggedCoordPath !== null
    });
  },

  isIntersecting: function(line: DrawFeature) {
    return isFeatureIntersectingItself(line);
  },

  parseVertexPath: function(vertexPath: string) {
    return vertexPath.split(".").map(Number);
  },

  onConfirmation: function(modeId: string) {
    try {
      const originalMultiLine = this.getFeature(multiLineId);
      const editedMultiLine = this.getFeature(editedMultiLineId);
      const parcel = this.getFeature(projectBoundaryId);

      // Note (@nicoluce): Since the function can be executed when the event draw.confirm is fired,
      // we use the randomId to prevent any old dangling event handler from executing along with
      // the current one.
      if (modeId !== editedMultiLine?.properties?.modeId) return;

      if (editedMultiLine.properties.intersects === "false") {
        originalMultiLine.setCoordinates(editedMultiLine.getCoordinates());
        const subparcels = this.divideParcel(editedMultiLine, parcel);
        removeParcels(this, subparcels.length);
        this.changeMode(Modes.site_selected, {
          sites: subparcels.map(
            (subparcel: GeoJSON.Feature<Polygon, Properties>) => subparcel.geometry
          )
        });
      }
    } catch (err) {
      console.error(err);
    }
  },

  onMouseDown: function(state: EditLineModeState, e: DrawMouseEvent) {
    if (e.originalEvent.button === 0 && e.featureTarget !== undefined) {
      const target = e.featureTarget;
      switch (target.properties.meta) {
        case "vertex":
          this.onVertexDrag(state, e);
          break;
        case "midpoint":
          this.onMidpointDrag(state, e);
          break;
      }
    }
    this.fireActionable(state);
  },

  onMouseUp: function(state: EditLineModeState) {
    this.stopDragging(state);
    this.updateUIClasses({ mouse: "none" });
  },

  onMouseMove: function(state: EditLineModeState, e: DrawMouseEvent) {
    const target = e.featureTarget;
    if (state.isDragging) {
      this.updateUIClasses({ mouse: "move" });
    } else if (target !== undefined && target.properties.meta === "vertex") {
      // on vertex hover
      this.updateUIClasses({ mouse: "pointer" });
    }
  },

  onClick: function(state: EditLineModeState, e: DrawMouseEvent) {
    if (e.originalEvent.button === 2 && !state.isDragging) {
      const target = e.featureTarget;
      if (target !== undefined && target.properties.meta === "vertex") {
        const vertexPath = e.featureTarget.properties.coord_path;
        const vertexLineIdx = this.parseVertexPath(vertexPath)[0];
        state.editedMultiLine.removeCoordinate(vertexPath);
        if (state.editedMultiLine.features[vertexLineIdx].coordinates.length < 2) {
          const lines = state.editedMultiLine.getCoordinates();
          lines.splice(vertexLineIdx, 1);
          state.editedMultiLine.setCoordinates(lines);
        } else {
          state.editedMultiLine.properties.intersects = String(
            this.isIntersecting(state.editedMultiLine.features[vertexLineIdx])
          );
        }

        if (state.editedMultiLine.features.length === 0) {
          return;
        }
      }
    }
  },

  onVertexDrag: function(state: EditLineModeState, e: DrawMouseEvent) {
    this.startDragging(state, e);
    state.draggedCoordPath = e.featureTarget.properties.coord_path;
    this.doRender(editedMultiLineId);
  },

  onMidpointDrag: function(state: EditLineModeState, e: DrawMouseEvent) {
    this.startDragging(state, e);
    const about = e.featureTarget.properties;
    state.editedMultiLine.addCoordinate(about.coord_path, about.lng, about.lat);
    state.draggedCoordPath = about.coord_path;
    this.doRender(editedMultiLineId);
  },

  onDrag: function(state: EditLineModeState, e: DrawMouseEvent) {
    state.isDragging = true;
    e.originalEvent.stopPropagation();
    const newCoord = [e.lngLat.lng, e.lngLat.lat];
    this.updateVertexPosition(state, state.draggedCoordPath, newCoord);
  },

  startDragging: function(state: EditLineModeState, e: DrawMouseEvent) {
    this.map.dragPan.disable();
    state.draggedCoordPath = null;
  },

  stopDragging: function(state: EditLineModeState) {
    this.map.dragPan.enable();
    state.isDragging = false;
  },

  onKeyUp: function(state: EditLineModeState, e: KeyboardEvent) {
    if (e.key === "Enter") {
      this.onConfirmation(state.editedMultiLine.properties.modeId);
    } else if (e.key === "Escape") {
      this.onExit(state.editedMultiLine);
    } else if (e.key === "Supr") {
      this.onTrash(state);
    }
  },

  // Join parcel's outline with the multiLine
  getParcelWithLines: function(state: EditLineModeState, lineIdxToOmit: number) {
    const coords = state.editedMultiLine.getCoordinates();
    const multiLine: any = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "MultiLineString",
        coordinates: coords.filter((_: [number, number][], i: number) => i !== lineIdxToOmit)
      }
    };
    return union(polygonToLine(state.projectBoundary) as any, multiLine);
  },

  updateVertexPosition: function(
    state: EditLineModeState,
    vertexPath: string,
    newPosition: [number, number]
  ) {
    let newPoint = point(newPosition);
    const [multiLineIdx, vertexIdx] = this.parseVertexPath(vertexPath);
    const parcelWithLines = this.getParcelWithLines(state, multiLineIdx);

    const pointOnBoundary = pointOnLine(parcelWithLines, newPoint) as Feature<Point, Properties>;
    if (
      vertexIdx === 0 ||
      (state.isDragging &&
        vertexIdx === state.editedMultiLine.features[multiLineIdx].coordinates.length - 1) ||
      !booleanPointInPolygon(newPoint, state.projectBoundary) ||
      distance(newPoint, pointOnBoundary, { units: "meters" }) <= boundarySnappingDistance
    ) {
      // Snap point to boundary
      newPoint = pointOnBoundary;
    }

    const [closestPoint, dist] = getClosestPoint(parcelWithLines, newPoint, {
      units: "meters"
    });
    if (dist <= vertexSnappingDistance) {
      // Snap point to vertex
      newPoint = closestPoint as Feature<Point, Properties>;
    }

    // If line.coordinates.length === vertexIdx
    // line.updateCoordinate() will create and add the new vertex
    state.editedMultiLine.updateCoordinate(
      vertexPath,
      newPoint.geometry?.coordinates[0],
      newPoint.geometry?.coordinates[1]
    );

    // Check for intersections
    state.editedMultiLine.properties.intersects = String(
      this.isIntersecting(state.editedMultiLine.features[multiLineIdx])
    );
  },

  divideParcel: function(multiLine: DrawFeature, projectBoundary: DrawFeature) {
    const polyAsLine: any = polygonToLine(projectBoundary);

    const lineCoordinates = multiLine.getCoordinates();
    // For each line we need to extend a bit their endpoints so polygonize works as expected
    const resultingCoords = lineCoordinates
      .map((lineCoords: [number, number][]) => {
        try {
          const resultingCoords = [];

          const extendPoint = (p: [number, number], q: [number, number]) => {
            return destination(point(q), endpointDistanceExtension, bearing(point(p), point(q)), {
              units: "meters"
            }).geometry?.coordinates;
          };

          let extendedPoint = extendPoint(lineCoords[1], lineCoords[0]);
          if (extendedPoint !== undefined) {
            resultingCoords.push(extendedPoint);
          }

          resultingCoords.push(...lineCoords);

          // In order to make the subdivision work the edges of the
          // line have to be outside of the polygon
          extendedPoint = extendPoint(
            lineCoords[lineCoords.length - 2],
            lineCoords[lineCoords.length - 1]
          );
          if (extendedPoint !== undefined) {
            resultingCoords.push(extendedPoint);
          }

          return resultingCoords;
        } catch (err) {
          console.error(err);
          return [];
        }
      })
      .filter((coords: [number, number][]) => coords !== []);

    // We need to convert the multiLine to a proper GeoJson format for union to work
    const multiLineGeoJSON: any = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "MultiLineString",
        coordinates: resultingCoords
      }
    };

    try {
      const unionedLines: any = union(multiLineGeoJSON, polyAsLine);
      return polygonize(unionedLines).features.filter(feature => {
        const coord = pointOnFeature(feature).geometry?.coordinates as Coord;
        // Filter out polygons outside of the original
        return booleanPointInPolygon(coord, projectBoundary);
      });
    } catch (e) {
      return [];
    }
  },

  displayProjectBoundary: function(
    state: EditLineModeState,
    geojson: DrawFeature,
    display: Function
  ) {
    if (state.amountOfSubsitesShown < 2) {
      geojson.properties.meta = "feature";
      geojson.properties.active = "true";
      display(geojson);
    }
  },

  displaySubSites: function(state: EditLineModeState, geojson: DrawFeature, display: Function) {
    const subsites =
      state.editedMultiLine.properties.intersects === "true"
        ? []
        : this.divideParcel(state.editedMultiLine, state.projectBoundary);
    if (subsites.length > 1) {
      subsites.forEach((site: DrawFeature) => {
        // Display Subsite
        display({
          type: "Feature",
          properties: {
            meta: "feature",
            active: "true",
            parent: state.editedMultiLine.id,
            user_label: `${formatCommas(
              Math.round(area(site) * (state.isMetric ? 1 : sqMetersToSqFeet))
            )} ${getShortAreaLabel()}`
          },
          geometry: site.geometry
        });

        // Display Measurements
        site.geometry.coordinates[0].forEach((endCoord: [number, number], coordinateId: number) => {
          if (coordinateId === 0) return;
          const startCoord = site.geometry.coordinates[0][coordinateId - 1];
          const geometry = {
            type: "LineString",
            coordinates: [
              [startCoord[longitudeIndex], startCoord[latitudeIndex]],
              [endCoord[longitudeIndex], endCoord[latitudeIndex]]
            ]
          };
          display({
            type: "Feature",
            properties: {
              active: "true",
              parent: state.editedMultiLine.id,
              user_length: formatDistanceLabel(geometry)
            },
            geometry: geometry
          });
        });
      });
    }

    if (
      state.amountOfSubsitesShown !== subsites.length &&
      (subsites.length <= 1 || state.amountOfSubsitesShown <= 1)
    ) {
      this.map.fire("draw.canSubdivide", { canSubdivide: subsites.length > 1 });
      state.amountOfSubsitesShown = subsites.length;
      this.doRender(projectBoundaryId);
    } else {
      state.amountOfSubsitesShown = subsites.length;
    }
  },

  toDisplayFeatures: function(state: EditLineModeState, geojson: DrawFeature, display: Function) {
    const geojsonId = geojson?.properties?.id;
    if (geojsonId === projectBoundaryId) {
      this.displayProjectBoundary(state, geojson, display);
    } else if (geojsonId === editedMultiLineId) {
      geojson.properties.active = "true";
      // Display line
      display(geojson);
      // Display line's points and midpoints
      const lastLineIdx = state.editedMultiLine.getCoordinates().length - 1;
      if (lastLineIdx < 0) {
        this.map.fire("draw.canSubdivide", { canSubdivide: true });
        return;
      }
      createSupplementaryPoints(
        geojson,
        {
          parentId: state.editedMultiLine.id,
          midpoints: true,
          selectedPaths: state.draggedCoordPath ? [state.draggedCoordPath] : []
        },
        String(lastLineIdx)
      ).forEach(point => display(point));
      this.displaySubSites(state, geojson, display);
    }
  },

  onStop: function() {
    this.deleteFeature(editedMultiLineId, { silent: true });
  },

  onExit: function() {
    this.changeMode(Modes.site_selected);
  }
};

export default EditLineMode;
