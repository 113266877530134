import { MapboxGeoJSONFeature } from "mapbox-gl";
import { change } from "redux-form";
import { singleFamilyDensityLimit } from "../../config/config";
import { calculateDensityLimit, parcelIsValidSize } from "../../config/utils";
import { ensureAddressFieldForParcel } from "../../helpers/geocoding";
import { ParcelCandidate } from "../../lib/ParcelCandidate";
import { asyncParcelRetrieval } from "../../promises/projects/projects";
import { asyncGetZoningInfo } from "../../promises/zoning/zoning";
import { dataLayersQuerier } from "../../queriers/region";
import { RootState, AppDispatch } from "../../store";
import { pushNewError } from "../../store/errors";
import { safeOperation, operations } from "../../store/operations";
import {
  parcelSearchFail,
  setParcelIsValid,
  cleanClickedParcel,
  setClickedParcel
} from "../../store/projects";
import { Zoning } from "../../store/types";
import { cleanBoundaryVisualization } from "../../store/ui/boundaryVisualization";
import { setSelectedZoning, setSelectedZoningName } from "../../store/zoning";
import { Project } from "../../types/projects";
import { doesNotExist } from "../../utils";
import { asyncParcelRetrievalAction, setAndFocus } from "../projects";

/**
 * Retrieves and selects a candidate parcel.
 * @param {ParcelCandidate} candidate
 * @returns {Function} Thunk
 */
const selectCandidateParcel = (candidate: ParcelCandidate) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  if (!(candidate instanceof ParcelCandidate)) {
    throw new Error("A parcel can only be selected by providing an instance of 'ParcelCandidate'");
  }

  dispatch(cleanBoundaryVisualization());
  dispatch(parcelSearchFail(false));

  const dataLayerId = dataLayersQuerier(getState().regions.dataLayers).byName("parcels").remoteId;
  const searchMethod = candidate.isAddress
    ? { method: "geocoding", address: candidate.address, layerId: dataLayerId }
    : { method: "id", parcelId: candidate.parcelId, layerId: dataLayerId };
  const parcelInformation = await asyncParcelRetrieval(searchMethod);

  await dispatch(setAndFocus(parcelInformation));
  const parcelZoning = await asyncGetZoningInfo(
    parcelInformation.zoningId,
    parcelInformation.cityId
  );
  dispatch(setSelectedZoning(parcelZoning));
  dispatch(setParcelIsValid(parcelIsValidSize(parcelInformation.area)));
};

/**
 * Selects a candidate parcel from Search
 * @param {ParcelCandidate} candidate
 * @returns {Function} Thunk
 */
export const selectSearchParcel = (candidate: ParcelCandidate): any =>
  safeOperation(
    operations.parcelSelection,
    async (dispatch: AppDispatch) => dispatch(selectCandidateParcel(candidate)),
    (error, dispatch) => dispatch(parcelSearchFail(true))
  );

/**
 * Selects a candidate parcel from freehand drawing tool.
 * @param {Number} candidateId
 * @returns {Function} Thunk
 */
export const selectDrawnParcel = (candidateId: number): any =>
  safeOperation(
    operations.parcelSelection,
    async (dispatch: AppDispatch) =>
      dispatch(selectCandidateParcel(new ParcelCandidate(candidateId))),
    (error, dispatch) =>
      dispatch(
        pushNewError({
          name: `Error while retrieving and selecting drawn parcel with id ${candidateId}`,
          description: `controllers::ui::projectCreation::selectDrawnParcel -> ${error}`
        })
      )
  );

export const updateProgramToggles = (zoning: Zoning) => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const { selectedProject } = getState().projects;
  const density_limit =
    (selectedProject as Project).density_limit ||
    calculateDensityLimit((selectedProject as Project).parcel_size.area, zoning);

  const enableMultifamilyToggle: boolean =
    !!zoning.multifamily_permitted &&
    (density_limit <= 0 || density_limit > singleFamilyDensityLimit);

  dispatch(change("MainModalForm", "multifamily_toggle", enableMultifamilyToggle));
  dispatch(change("MainModalForm", "singlefamily_toggle", !enableMultifamilyToggle));
};

export const selectClickedParcel = (feature: MapboxGeoJSONFeature) =>
  safeOperation(
    operations.clickedParcelSelection,
    async (dispatch: AppDispatch, getState: () => RootState) => {
      const parcelId = feature.id;
      dispatch(cleanClickedParcel());
      dispatch(setSelectedZoning({}));
      const querier = dataLayersQuerier(getState().regions.dataLayers);
      const parcelInformation = await asyncParcelRetrievalAction({
        method: "id",
        layerId: querier.byName("parcels").remoteId,
        parcelId
      })();
      dispatch(setParcelIsValid(parcelIsValidSize(parcelInformation.area)));
      await ensureAddressFieldForParcel(parcelInformation);
      dispatch(
        setClickedParcel({
          parcel: parcelInformation,
          isNeighborOfSelectedParcel: feature.state.isNeighbor
        })
      );
      const zoningData = await asyncGetZoningInfo(
        parcelInformation.zoningId,
        parcelInformation.cityId
      );
      dispatch(setSelectedZoning(zoningData));
      const zoningName = doesNotExist(zoningData.name) ? "Custom" : zoningData.zoning_code;
      dispatch(setSelectedZoningName(zoningName));
    },
    async (error, dispatch: AppDispatch) => {
      dispatch(cleanClickedParcel());
      dispatch(setSelectedZoning({}));
    }
  );

export default { selectDrawnParcel, selectSearchParcel, updateProgramToggles };
