import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button, Modal, ModalProps } from "semantic-ui-react";
import { handleResRentProfileSubmit, submitResRentProfile } from "../../controllers/proforma";
import { RootState } from "../../store";
import { toggleRentProfileModal } from "../../store/proforma";
import RentProfileModalContent from "./RentProfileModalContent";

const proformaActions = { toggleRentProfileModal };
const proformaCtrl = { handleResRentProfileSubmit, submitResRentProfile };
const actionsObj = { ...proformaActions, ...proformaCtrl };

const mapStateToProps = (state: RootState) => ({
  rentProfiles: state.proforma.rentProfiles,
  rentProfileSpinner: state.proforma.rentProfileSpinner
});

const connector = connect(mapStateToProps, actionsObj);

type PropsFromRedux = ConnectedProps<typeof connector>;
type RentProfileModalProps = {
  fixingBeforeBuildingEdition: boolean;
  onClose: any;
  onSubmit: any;
  open: boolean;
} & PropsFromRedux;
type RentProfileModalState = {
  rentModalSize: ModalProps["size"];
};

class RentProfileModal extends React.Component<RentProfileModalProps, RentProfileModalState> {
  constructor(props: RentProfileModalProps) {
    super(props);
    this.state = {
      rentModalSize: "large"
    };
    this.setRentModalSize = this.setRentModalSize.bind(this);
  }

  setRentModalSize(size: ModalProps["size"]) {
    this.setState({
      rentModalSize: size
    });
  }

  render() {
    const { fixingBeforeBuildingEdition, open, rentProfiles, rentProfileSpinner } = this.props;

    return (
      <Modal open={open} size={this.state.rentModalSize}>
        <Modal.Header>
          {fixingBeforeBuildingEdition
            ? "Update Residential Rent Profiles"
            : "Add Residential Rent Profile"}

          <Button
            type="button"
            style={{ float: "right" }}
            circular
            icon="cancel"
            onClick={this.props.onClose}
          />
        </Modal.Header>
        <Modal.Content scrolling>
          <RentProfileModalContent
            setRentModalSize={this.setRentModalSize}
            onSubmit={this.props.onSubmit}
            alreadySubmittedRentProfiles={rentProfiles}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="submit"
            loading={rentProfileSpinner}
            onClick={this.props.submitResRentProfile}
          >
            {fixingBeforeBuildingEdition ? "Submit" : "Apply"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connector(RentProfileModal);
