import area from "@turf/area";
import { sum } from "lodash";
import {
  Building,
  BuildingTypeCode,
  SubBuildingBlock,
  UnitFeature
} from "../../../types/buildings";
import { DevcostsData } from "../../../types/proforma";
import { exists } from "../../../utils";

export const buildingsQuerier = (building: Building) => ({
  get isNplex() {
    return building.building_type.code === BuildingTypeCode.nplex;
  },
  get isApartment() {
    return building.building_type.code === BuildingTypeCode.apartment;
  },
  get totalUnits() {
    return (
      building.studio + building.onebed + building.twobed + building.threebed + building.fourbed
    );
  },
  get totalDevelopmentCosts() {
    let total_development_costs = 0;
    if (exists(building.finance)) {
      total_development_costs = building.finance.outputs.final_total_development_costs;
    } else if (exists(building.devcosts)) {
      total_development_costs = (building.devcosts as DevcostsData).outputs
        .preliminary_total_development_costs;
    }
    return total_development_costs;
  },
  get totalDevelopmentCostPerUnit() {
    return this.totalDevelopmentCosts / this.totalUnits;
  },
  get height() {
    return this.isNplex
      ? building.stories * building.building_program.ceiling_height
      : building.zoning.ground_floor_ceiling_height +
          (building.stories - 1) * building.building_program.ceiling_height;
  },
  get floorplateArea() {
    let footprintArea;
    if (building.total_podium_parking_levels > 0) {
      const reducedParcel = building.geometry.reduced_parcel.geometry;
      // Due to projection and rounding errors reduced parcel may be greater
      // than the site if no setback were appplied
      footprintArea = area(reducedParcel);
    } else {
      const geojson = building.geometry.sub_buildings[0][0].geojson;
      const footprintFeature = geojson.features.find(
        a => a.properties.type === "building_footprint"
      );
      if (footprintFeature === undefined) return NaN;
      footprintArea = area(footprintFeature);
    }
    return footprintArea;
  },
  get unitsPerType() {
    // res will accumulate all unit areas by type
    const res: Record<number, number[]> = { 0: [], 1: [], 2: [], 3: [], 4: [] };
    building.geometry.sub_buildings[0].forEach((block: SubBuildingBlock) => {
      const units = block.geojson.features.map((unit: UnitFeature) => {
        return { type: unit.properties.type, area: unit.properties.gross_area };
      });
      units.reduce((dict: Record<number, number[]>, current: { type: string; area: number }) => {
        const match = current.type.match(/unit_(?<type>\d+)/);
        if (!match || !match.groups?.type) return dict;
        const unitTypeNumber = Number(match.groups.type);
        for (let i = 0; i < block.stories; i++) {
          dict[unitTypeNumber].push(current.area);
        }
        return dict;
      }, res);
    });
    return res;
  },
  get netRentableArea() {
    let sumAreas = 0;
    const unitsPerType = this.unitsPerType;
    [0, 1, 2, 3, 4].forEach(bedrooms => (sumAreas += sum(unitsPerType[bedrooms])));
    return sumAreas;
  },
  get averageUnitSize() {
    return this.netRentableArea / this.totalUnits;
  }
});
