import React from "react";
import { Icon, Segment } from "semantic-ui-react";

class Header extends React.Component<{
  closeMenu: any;
}> {
  render() {
    return (
      <Segment>
        <h5>
          Layers{" "}
          <Icon
            name="close"
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => this.props.closeMenu()}
          />
        </h5>
      </Segment>
    );
  }
}

export default Header;
