import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  row: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    borderBottom: 1,
    borderBottomColor: "#E5E5E5",
    marginBottom: "1mm"
  },
  noLine: {
    borderBottom: 0
  },
  rowText: {
    marginLeft: "1mm",
    marginRight: "1mm",
    fontSize: 7.5,
    fontWeight: 400
  },
  nameText: {
    textAlign: "left",
    textOverflow: "-"
  },
  valueText: {
    textAlign: "right",
    textOverflow: "-"
  }
});

type RowProps = {
  name: string;
  value: string | number;
  drawLine?: boolean;
};

const Row: React.FunctionComponent<RowProps> = props => {
  const { name, value, drawLine } = props;
  return (
    <View style={[styles.row, drawLine ? {} : styles.noLine]}>
      <Text
        style={[
          styles.rowText,
          styles.nameText,
          {
            flex: name.length,
            minWidth: "10%"
          }
        ]}
      >
        {name}
      </Text>
      <Text
        style={[
          styles.rowText,
          styles.valueText,
          {
            flex: String(value).length,
            minWidth: "10%"
          }
        ]}
      >
        {value}
      </Text>
    </View>
  );
};

Row.defaultProps = {
  drawLine: true
};

export default Row;
