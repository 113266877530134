/**
 * Created by jmartinez on 9/24/18.
 */
import { isNull, omit, keys, without } from "lodash";
import { SubmissionError, submit, destroy } from "redux-form";
import { programSteps, typologyTogglesFields } from "../config/config";
import { formatProgramObject, isNullOrUndefinedOrBlank } from "../config/utils";
import * as programPromises from "../promises/programs/programs";
import { setVirtualMultifamilyProgram, setVirtualSinglefamilyProgram } from "../store/buildings";
import { pushNewError } from "../store/errors";
import * as programActions from "../store/programs";
import { setActiveModalForm, toggleWizardModal } from "../store/projects";
import { BuildingTypeCode, BuildingTypeId } from "../types/buildings";
import { doesNotExist } from "../utils";

export const apiGetProgramsList = () => {
  return async dispatch => {
    dispatch(programActions.updateProgramLoadingStatus(true));
    let programList;
    try {
      programList = await programPromises.asyncGetProgramList();
      programList = doesNotExist(programList) ? [] : programList;
    } catch (error) {
      console.error(`actions::programs::apiGetProgramList -> ${error}`);
    }
    dispatch(programActions.updateProgramLoadingStatus(false));
    dispatch(programActions.setProgramList(programList));
  };
};

export const apiGetProgramInfo = programId => {
  return async dispatch => {
    let programInfo;
    try {
      programInfo = await programPromises.asyncGetProgram(programId);
      dispatch(setSelectedProgram(programInfo));
    } catch (error) {
      dispatch(
        pushNewError({
          name: "Error getting program from its ID",
          description: `actions::programs::apiGetProgramInfo -> ${error}`
        })
      );
    }
  };
};

export const setSelectedProgram = programInfo => dispatch => {
  if (programInfo.building_type.code === BuildingTypeCode.apartment) {
    dispatch(programActions.setSelectedMultifamilyProgram(programInfo));
  } else if (programInfo.building_type.code === BuildingTypeCode.nplex)
    dispatch(programActions.setSelectedSinglefamilyProgram(programInfo));
  else
    dispatch(
      pushNewError({
        name: "Error selecting building program",
        description: `Unknown building_type's code in ${programInfo}`
      })
    );
};

export const handleUnitMixSubmit = values => {
  return dispatch => {
    without(
      keys(values),
      "name",
      "multiple_buildings",
      "accessible_roof",
      "ceiling_height",
      "building_type",
      ...typologyTogglesFields,
      "stairs_max_dead_end",
      "stairs_max_travel_distance"
    ).forEach(key => {
      if (!isNull(values[key])) {
        if (!/^\d+$/.test(values[key]) && values[key] !== "") {
          throw new SubmissionError({
            _error: "All fields in this form must be a valid integer."
          });
        }
      }
    });

    const allSfNull =
      isNullOrUndefinedOrBlank(values.area_studio) &&
      isNullOrUndefinedOrBlank(values.area_1) &&
      isNullOrUndefinedOrBlank(values.area_2) &&
      isNullOrUndefinedOrBlank(values.area_3) &&
      isNullOrUndefinedOrBlank(values.area_4);

    if (allSfNull) {
      throw new SubmissionError({
        _error: "Building program must have at least one unit type."
      });
    }
    const dataToStore = Object.assign({}, values);
    const unitMixSum =
      (!isNullOrUndefinedOrBlank(values["prop_studio"]) ? parseInt(values["prop_studio"], 10) : 0) +
      (!isNullOrUndefinedOrBlank(values["prop_1"]) ? parseInt(values["prop_1"], 10) : 0) +
      (!isNullOrUndefinedOrBlank(values["prop_2"]) ? parseInt(values["prop_2"], 10) : 0) +
      (!isNullOrUndefinedOrBlank(values["prop_3"]) ? parseInt(values["prop_3"], 10) : 0) +
      (!isNullOrUndefinedOrBlank(values["prop_4"]) ? parseInt(values["prop_4"], 10) : 0);
    if (unitMixSum !== 100) {
      throw new SubmissionError({
        field: "oneBedPct",
        _error: "Percentages must sum to 100."
      });
    }
    dataToStore["prop_studio"] = values["prop_studio"] / 100;
    dataToStore["prop_1"] = values["prop_1"] / 100;
    dataToStore["prop_2"] = values["prop_2"] / 100;
    dataToStore["prop_3"] = values["prop_3"] / 100;
    dataToStore["prop_4"] = values["prop_4"] / 100;

    dispatch(programActions.setProgramValues(dataToStore));
    dispatch(setActiveModalForm(programSteps["program_step_2"]));
  };
};

export const handleNonResSubmit = values => {
  return (dispatch, getState) => {
    const { programValues, editProgram } = getState().programs;
    const newValues = !editProgram.multi_family ? Object.assign({}, programValues, values) : values;
    dispatch(programActions.setProgramValues(newValues));
    dispatch(setActiveModalForm(programSteps["program_step_3"]));
  };
};

export const handleCirculationSubmit = values => {
  return async (dispatch, getState) => {
    const { programValues, editProgram, programList } = getState().programs;
    values = omit(values, "specified_typologies");
    const newValues = !editProgram.multi_family ? Object.assign({}, programValues, values) : values;
    const finalValues = newValues.hasOwnProperty("program_name")
      ? Object.assign({}, omit(newValues, "program_name"), {
          name: newValues["program_name"]
        })
      : newValues;
    if (typologyTogglesFields.every(field => !finalValues[field])) {
      throw new SubmissionError({
        field: "typology_all",
        _error: "At least one typology should be selected."
      });
    }

    const saveProgram = !editProgram.multi_family && finalValues.hasOwnProperty("name");
    const dataToSend = formatProgramObject(finalValues);
    dataToSend["building_type"] = {
      name: "Apartment",
      id: BuildingTypeId.apartment,
      code: BuildingTypeCode.apartment
    };

    if (saveProgram) {
      let newProgram;
      try {
        newProgram = await programPromises.asyncPostNewProgram(dataToSend);
      } catch (error) {
        dispatch(
          pushNewError({
            name: "Error while saving new program",
            description: `actions::programs::asyncPostNewProgram -> ${error}`
          })
        );
      }
      const newListItem = dataToSend;
      dispatch(programActions.setProgramList([newListItem, ...programList]));
      dispatch(apiGetProgramInfo(newProgram.id));
    } else {
      dispatch(setVirtualMultifamilyProgram(dataToSend));
      dispatch(setSelectedProgram(dataToSend));
    }

    programActions.setSelectedMultifamilyProgram({});
    dispatch(toggleWizardModal(false));
    dispatch(programActions.toggleConfirmProgramFavorites(false));
    dispatch(programActions.setMultiProgramComplete(true));
    dispatch(programActions.cleanProgramValues());
    dispatch(destroyProgramForms());
    const programName = newValues.hasOwnProperty("program_name")
      ? newValues["program_name"]
      : "Custom";
    dispatch(programActions.setSelectedProgramName(programName));
  };
};

export const handleSingleFamilyAttachedSubmit = values => {
  return async (dispatch, getState) => {
    const { programValues, editProgram, programList } = getState().programs;
    const newValues = !editProgram.single_family
      ? Object.assign({}, programValues, values)
      : values;
    const finalValues = newValues.hasOwnProperty("program_name")
      ? Object.assign({}, omit(newValues, "program_name"), {
          name: newValues["program_name"]
        })
      : newValues;

    const saveProgram = !editProgram.single_family && finalValues.hasOwnProperty("name");
    const dataToSend = formatProgramObject(finalValues);
    dataToSend["building_type"] = {
      name: "Nplex",
      id: BuildingTypeId.nplex,
      code: BuildingTypeCode.nplex
    };

    if (saveProgram) {
      let newProgram;
      try {
        newProgram = await programPromises.asyncPostNewProgram(dataToSend);
      } catch (error) {
        dispatch(
          pushNewError({
            name: "Error while saving new program",
            description: `actions::programs::asyncPostNewProgram -> ${error}`
          })
        );
      }

      const newListItem = dataToSend;
      dispatch(programActions.setProgramList([newListItem, ...programList]));
      dispatch(apiGetProgramInfo(newProgram.id));
    } else {
      dispatch(setVirtualSinglefamilyProgram(dataToSend));
      dispatch(setSelectedProgram(dataToSend));
    }

    programActions.setSelectedSinglefamilyProgram({});
    dispatch(toggleWizardModal(false));
    dispatch(programActions.toggleConfirmProgramFavorites(false));
    dispatch(programActions.setSingleProgramComplete(true));
    dispatch(programActions.cleanProgramValues());
    dispatch(destroyProgramForms());
    const programName = newValues.hasOwnProperty("program_name")
      ? newValues["program_name"]
      : "Custom";
    dispatch(programActions.setSelectedProgramName(programName));
  };
};

export const submitUnitMix = () => {
  return dispatch => {
    dispatch(submit("UnitMixForm"));
  };
};

export const submitNonRes = () => {
  return dispatch => {
    dispatch(submit("NonResForm"));
  };
};

export const submitCirculation = () => {
  return dispatch => {
    dispatch(submit("CirculationForm"));
  };
};

export const submitAmenities = () => {
  return dispatch => {
    dispatch(submit("AmenitiesForm"));
  };
};

export const submitSingleFamilyAttached = () => {
  return dispatch => {
    dispatch(submit("SingleFamilyAttachedForm"));
  };
};

export const destroyProgramForms = () => {
  return dispatch => {
    dispatch(destroy("UnitMixForm"));
    dispatch(destroy("NonResForm"));
    dispatch(destroy("CirculationForm"));
    dispatch(destroy("AmenitiesForm"));
  };
};
