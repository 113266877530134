import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { unrestrictedLabel } from "../../config/config";
import {
  getDistanceLabel,
  getShortAreaLabel,
  calculateAndGetDensityLimitLabel,
  formatCommas,
  formatDecimal
} from "../../config/utils";
import { amountOfAcres } from "../../helpers/unitsConversion";
import { Zoning } from "../../store/types";
import { Project } from "../../types/projects";
import { exists } from "../../utils";
import Row from "./Row";

const styles = StyleSheet.create({
  title: {
    fontWeight: 400,
    fontSize: 11,
    textTransform: "uppercase",
    color: "#54B2AB",
    marginBottom: "4mm"
  }
});

type SiteInformationProps = {
  project: Project;
  defaultZoning: Zoning;
};

const SiteInformation: React.FunctionComponent<SiteInformationProps> = props => {
  const { project, defaultZoning } = props;
  return (
    <View style={{ marginTop: "6mm" }}>
      <Text style={styles.title}>Site Information</Text>
      <View>
        <Row name="Parcel ID" value={project.parcel_data.id} />
        <Row
          name="Site Area"
          value={`${formatCommas(project.parcel_size.area)} ${getShortAreaLabel()} (${formatDecimal(
            amountOfAcres(project.parcel_size.area)
          )} ac)`}
        />
        <Row name="Zoning" value={defaultZoning.zoning_code} />
        <Row
          name="Height Limit"
          value={
            exists(project.parcel_data.height)
              ? `${project.parcel_data.height} ${getDistanceLabel()}`
              : unrestrictedLabel
          }
        />
        <Row
          name="Density Limit"
          value={calculateAndGetDensityLimitLabel(project.parcel_size.area, defaultZoning)}
        />
      </View>
    </View>
  );
};

export default SiteInformation;
