export const resources: { [key: string]: PermissionResource } = {
  project: "project",
  buildingOption: "building_option",
  customZoning: "zoning",
  customProgram: "program",
  financialAnalysis: "financial_analysis"
};

export type PermissionResource =
  | "building_option"
  | "financial_analysis"
  | "program"
  | "project"
  | "zoning";

export const actions: { [key in PermissionAction]: PermissionAction } = {
  create: "create",
  read: "read",
  duplicate: "duplicate",
  update: "update",
  delete: "delete"
};

export type PermissionAction = "create" | "read" | "duplicate" | "update" | "delete";

export const ownerships: { [key in PermissionOwnership]: PermissionOwnership } = {
  all: "all",
  own: "own",
  organization: "organization"
};

export type PermissionOwnership = "all" | "own" | "organization";
