import { isNull, isUndefined, some, sum } from "lodash";
import { amountOfAcres } from "../helpers/unitsConversion";
import { buildingsQuerier } from "../queriers/users/building";
import store from "../store";
import { projectSiteAreaSelector } from "../store/selectors/projects";
import { Building, BuildingType, BuildingTypeCode } from "../types/buildings";
import { Project } from "../types/projects";
import { exists } from "../utils";
import { sqMetersToSqFeet, unitLabels } from "./config";
import {
  formatCommas,
  formatDecimal,
  formatDollars,
  formatPercentage,
  formatRoundedPercentage,
  getAreaPerUnitLabel,
  getDistanceLabel,
  getGrossAreaLabel,
  getGSFTooltip,
  getResAreaLabel,
  getRSFTooltip,
  isNullOrUndefined
} from "./utils";

export type BuildingTableRow = {
  name: string;
  value: string | number;
  tooltip?: string;
  sortableValue?: number;
}[];

export enum ColumnNames {
  Id = "ID",
  BuildingOptionName = "Building Option",
  Stories = "Stories",
  TotalUnits = "Total Units",
  TDC = "TDC",
  Debt = "Debt",
  Equity = "Equity",
  Gap = "Gap",
  GapPerUnit = "Gap Per Unit",
  Sources = "Sources",
  Height = "Height",
  AvgUnitSize = "Avg. Unit Size",
  NRA = "NRA",
  FAR = "FAR",
  LotCoverage = "Lot Coverage",
  BuildingFootprint = "Building Footprint",
  DUA = "DUA",
  PkRatio = "Pk Ratio",
  Studio = "Studio",
  OneBed = "1-bed",
  TwoBed = "2-bed",
  ThreeBed = "3-bed",
  FourBed = "4-bed",
  AvgStudio = "Avg. Studio",
  AvgOneBed = "Avg. 1-bed",
  AvgTwoBed = "Avg. 2-bed",
  AvgThreeBed = "Avg. 3-bed",
  AvgFourBed = "Avg. 4-bed",
  PodiumPkSpaces = "Podium Pk Spaces",
  UndergrPkSpaces = "Undergr. Pk Spaces",
  PodiumPkLevels = "Podium Pk Levels",
  UndergrPkLevels = "Undergr. Pk Levels",
  IOSR = "IOSR",
  CAR = "CAR",
  FirstYearEGI = "First Year EGI",
  FirstYearNOI = "First Year NOI",
  FirstMortgage = "First Mortgage",
  LIHTC = "LIHTC",
  TotalParkingCosts = "Total Parking Costs",
  CapValue = "Cap Value",
  Profit = "Profit",
  ROC = "ROC",
  YOC = "YOC"
}

export const projectViewFilteredColumns: string[] = [
  ColumnNames.Height,
  ColumnNames.PodiumPkLevels,
  ColumnNames.UndergrPkLevels
];

export const summaryTableData = (row: Building): BuildingTableRow => {
  const querier = buildingsQuerier(row);
  return [
    { name: ColumnNames.Id, value: row.id },
    { name: ColumnNames.BuildingOptionName, value: row.name },
    { name: ColumnNames.Stories, value: row.stories },
    { name: ColumnNames.TotalUnits, value: formatCommas(querier.totalUnits) },
    {
      name: getGrossAreaLabel(),
      value: formatCommas(row.gsf),
      tooltip: getGSFTooltip()
    },
    {
      name: getResAreaLabel(),
      value: formatCommas(row.rsf),
      tooltip: getRSFTooltip()
    },
    {
      name: ColumnNames.TDC,
      value: !isUndefined(querier.totalDevelopmentCosts)
        ? formatDollars(querier.totalDevelopmentCosts)
        : "",
      tooltip: "Total Development Cost"
    },
    {
      name: ColumnNames.FirstYearNOI,
      value: !isNullOrUndefined(row.rents) ? formatDollars(row.rents.outputs.cashflow[0].NOI) : "",
      tooltip: "Year 1 Net Operating Income"
    },
    {
      name: ColumnNames.Debt,
      value: !isNullOrUndefined(row.finance) ? formatDollars(row.finance.outputs.total_debt) : ""
    },
    {
      name: ColumnNames.Equity,
      value: !isNullOrUndefined(row.finance) ? formatDollars(row.finance.outputs.total_equity) : ""
    },
    {
      name: ColumnNames.Gap,
      value: !isNullOrUndefined(row.finance) ? formatDollars(row.finance.outputs.gap) : ""
    },
    {
      name: ColumnNames.GapPerUnit,
      value: !isNullOrUndefined(row.finance) ? formatDollars(row.finance.outputs.gap_per_unit) : ""
    },
    {
      name: ColumnNames.Sources,
      value: !isNullOrUndefined(row.finance) ? formatDollars(row.finance.outputs.total_sources) : ""
    }
  ];
};

const blankColumnValue = "-";
const blankIfNPlex = (buildingType: BuildingType, value: string) => {
  return buildingType.code === BuildingTypeCode.nplex ? blankColumnValue : value;
};

export const buildingTableData = (row: Building): BuildingTableRow => {
  const querier = buildingsQuerier(row);
  const unitsPerType = querier.unitsPerType;
  const totalUnits = querier.totalUnits;
  const siteArea = projectSiteAreaSelector(store.getState(), row.development_site_id);

  let floorplateArea = querier.floorplateArea;
  if (!store.getState().users.metric) {
    floorplateArea *= sqMetersToSqFeet;
  }
  // Note (@nicoluce): Both variables are geometry areas that could have some rounding errors.
  floorplateArea = Math.min(siteArea, floorplateArea);

  const showUnitsAndPercentage = (bedrooms: number) => {
    const units = unitsPerType[bedrooms];
    if (units.length === 0) return "0";
    return `${formatCommas(units.length)} (${formatRoundedPercentage(units.length / totalUnits)})`;
  };
  const averageUnitSize = (bedrooms: number): string => {
    const units = unitsPerType[bedrooms];
    if (units.length === 0) return "";
    return formatCommas(sum(units) / units.length);
  };
  return [
    { name: ColumnNames.Id, value: row.id },
    { name: ColumnNames.BuildingOptionName, value: row.name },
    { name: ColumnNames.Stories, value: row.stories },
    { name: ColumnNames.Height, value: formatDecimal(querier.height) },
    {
      name: ColumnNames.TotalUnits,
      value: formatCommas(totalUnits)
    },
    { name: ColumnNames.AvgUnitSize, value: formatDecimal(querier.averageUnitSize) },
    {
      name: getAreaPerUnitLabel(),
      value: formatCommas(row.gsf / totalUnits),
      tooltip: store.getState().users.metric ? "Square Meters per Unit" : "Square Feet per Unit"
    },
    {
      name: getGrossAreaLabel(),
      value: formatCommas(row.gsf),
      tooltip: getGSFTooltip()
    },
    {
      name: getResAreaLabel(),
      value: formatCommas(row.rsf),
      tooltip: getRSFTooltip()
    },
    {
      name: ColumnNames.NRA,
      value: formatCommas(querier.netRentableArea),
      tooltip: "Net Rentable Area"
    },
    {
      name: ColumnNames.FAR,
      value: formatDecimal(
        row.far !== null
          ? row.far / siteArea
          : row.gsf / siteArea - row.total_underground_parking_levels
      ),
      tooltip: "Floor Area Ratio"
    },
    {
      name: ColumnNames.LotCoverage,
      value: formatPercentage(floorplateArea / siteArea)
    },
    { name: ColumnNames.BuildingFootprint, value: formatCommas(floorplateArea) },
    {
      name: ColumnNames.DUA,
      value: formatDecimal(totalUnits / amountOfAcres(siteArea)),
      tooltip: "Dwelling Units per Acre"
    },
    {
      name: ColumnNames.PkRatio,
      value: blankIfNPlex(row.building_type, formatDecimal(row.total_parking_slots / totalUnits)),
      tooltip: "Parking Ratio"
    },
    {
      name: `Comm. Sq.${getDistanceLabel()}`,
      value: blankIfNPlex(row.building_type, formatCommas(row.actual_commercial_retail_gsf)),
      tooltip: "Commercial Retail"
    },
    {
      name: ColumnNames.Studio,
      value: blankIfNPlex(row.building_type, showUnitsAndPercentage(0)),
      sortableValue: row.building_type.code === BuildingTypeCode.nplex ? 0 : row.studio
    },
    {
      name: ColumnNames.OneBed,
      value: blankIfNPlex(row.building_type, showUnitsAndPercentage(1)),
      sortableValue: row.onebed ?? 0
    },
    {
      name: ColumnNames.TwoBed,
      value: blankIfNPlex(row.building_type, showUnitsAndPercentage(2)),
      sortableValue: row.twobed ?? 0
    },
    {
      name: ColumnNames.ThreeBed,
      value: blankIfNPlex(row.building_type, showUnitsAndPercentage(3)),
      sortableValue: row.threebed ?? 0
    },
    {
      name: ColumnNames.FourBed,
      value: blankIfNPlex(row.building_type, showUnitsAndPercentage(4)),
      sortableValue: row.fourbed ?? 0
    },
    { name: ColumnNames.AvgStudio, value: blankIfNPlex(row.building_type, averageUnitSize(0)) },
    { name: ColumnNames.AvgOneBed, value: blankIfNPlex(row.building_type, averageUnitSize(1)) },
    { name: ColumnNames.AvgTwoBed, value: blankIfNPlex(row.building_type, averageUnitSize(2)) },
    { name: ColumnNames.AvgThreeBed, value: blankIfNPlex(row.building_type, averageUnitSize(3)) },
    { name: ColumnNames.AvgFourBed, value: blankIfNPlex(row.building_type, averageUnitSize(4)) },
    {
      name: `Pk ${getGrossAreaLabel()}`,
      value: blankIfNPlex(row.building_type, formatCommas(row.parking_gsf)),
      tooltip: `Total Parking ${getGrossAreaLabel()}`
    },
    {
      name: ColumnNames.PodiumPkSpaces,
      value: blankIfNPlex(row.building_type, formatCommas(row.total_podium_parking_slots)),
      tooltip: "Total Podium Parking Spaces"
    },
    {
      name: ColumnNames.PodiumPkLevels,
      value: blankIfNPlex(row.building_type, formatCommas(row.total_podium_parking_levels)),
      tooltip: "Total Podium Parking Levels"
    },
    {
      name: ColumnNames.UndergrPkSpaces,
      value: blankIfNPlex(row.building_type, formatCommas(row.total_underground_parking_slots)),
      tooltip: "Total Underground Parking Spaces"
    },
    {
      name: ColumnNames.UndergrPkLevels,
      value: blankIfNPlex(row.building_type, formatCommas(row.total_underground_parking_levels)),
      tooltip: "Total Underground Parking Levels"
    },
    {
      name: ColumnNames.IOSR,
      value: formatPercentage(row.interior_open_space_area / row.gsf),
      tooltip: "Interior Open Space Ratio"
    },
    {
      name: ColumnNames.CAR,
      value: formatPercentage(
        (row.interior_open_space_area + row.interior_circulation_area) / row.gsf
      ),
      tooltip: "Common Area Ratio"
    }
  ];
};

export const proFormaTableData = (row: Building): BuildingTableRow => {
  const querier = buildingsQuerier(row);

  return [
    { name: ColumnNames.Id, value: row.id },
    { name: ColumnNames.BuildingOptionName, value: row.name },
    { name: ColumnNames.Stories, value: row.stories },
    { name: ColumnNames.TotalUnits, value: formatCommas(querier.totalUnits) },
    {
      name: ColumnNames.FirstYearEGI,
      value: !isNullOrUndefined(row.rents)
        ? formatDollars(row.rents.outputs.cashflow[0].estimated_gross_income)
        : "",
      tooltip: "First Year Estimated Gross Income"
    },
    {
      name: ColumnNames.FirstYearNOI,
      value: !isNullOrUndefined(row.rents) ? formatDollars(row.rents.outputs.cashflow[0].NOI) : "",
      tooltip: "First Year Net Operating Income"
    },
    {
      name: ColumnNames.FirstMortgage,
      value: !isNullOrUndefined(row.finance)
        ? formatDollars(row.finance.outputs.total_mortgage)
        : ""
    },
    {
      name: ColumnNames.Debt,
      value: !isNullOrUndefined(row.finance) ? formatDollars(row.finance.outputs.total_debt) : ""
    },
    ...(store.getState().projects.isSelectedProjectInsideUSA
      ? [
          {
            name: ColumnNames.LIHTC,
            value: exists(row.finance) ? formatDollars(row.finance.outputs.LIHTC_equity) : "",
            tooltip: "Low Income Housing Tax Credit Equity"
          }
        ]
      : []),
    {
      name: ColumnNames.Equity,
      value: !isNullOrUndefined(row.finance) ? formatDollars(row.finance.outputs.total_equity) : ""
    },
    {
      name: ColumnNames.Gap,
      value: !isNullOrUndefined(row.finance) ? formatDollars(row.finance.outputs.gap) : ""
    },
    {
      name: ColumnNames.GapPerUnit,
      value: !isNullOrUndefined(row.finance) ? formatDollars(row.finance.outputs.gap_per_unit) : ""
    },
    {
      name: ColumnNames.TDC,
      value: !isNullOrUndefined(querier.totalDevelopmentCosts)
        ? formatDollars(querier.totalDevelopmentCosts)
        : "",
      tooltip: "Total Development Costs"
    },
    {
      name: ColumnNames.TotalParkingCosts,
      value: !isNull(row.devcosts)
        ? blankIfNPlex(
            row.building_type,
            formatDollars(row.devcosts.outputs.total_parking_costs ?? 0)
          )
        : ""
    },
    {
      name: ColumnNames.CapValue,
      value: !isNullOrUndefined(row.finance)
        ? formatDollars(row.finance.outputs.capitalized_value)
        : "",
      tooltip: "Capitalized Value"
    },
    {
      name: ColumnNames.Profit,
      value: !isNullOrUndefined(row.finance)
        ? formatDollars(row.finance.outputs.project_profit)
        : "",
      tooltip: "Project Profit"
    },
    {
      name: ColumnNames.ROC,
      value: !isNullOrUndefined(row.finance)
        ? formatPercentage(row.finance.outputs.return_on_cost)
        : "",
      tooltip: "Return on Cost"
    },
    {
      name: ColumnNames.YOC,
      value: !isNullOrUndefined(row.finance)
        ? formatPercentage(row.finance.outputs.yield_on_cost)
        : "",
      tooltip: "Yield on Cost"
    }
  ];
};

const isApartment = (building: Building) =>
  building.building_type.code === BuildingTypeCode.apartment;

export const aggregatedTableData = (buildings: Building[]) => {
  // First calculate some shared data
  const totalUnits = buildings.reduce(
    (sum, building) => sum + buildingsQuerier(building).totalUnits,
    0
  );

  const totalUnitsPerBedroom = unitLabels.apartments.layout.map(label =>
    // @ts-ignore
    buildings.reduce((sum, building) => sum + (isApartment(building) ? building[label] : 0), 0)
  );

  const unitsPerTypePerBuilding = buildings
    .filter(building => isApartment(building))
    .map(building => buildingsQuerier(building).unitsPerType);
  const unitAreasPerType = [0, 1, 2, 3, 4].map(bedrooms =>
    unitsPerTypePerBuilding.reduce(
      (acum: number[], buildingDict) => acum.concat(buildingDict[bedrooms]),
      []
    )
  );

  const averageUnitSize = (bedrooms: number): string => {
    const units = unitAreasPerType[bedrooms];
    if (units.length === 0) return "";
    return formatCommas(sum(units) / units.length);
  };

  const showUnitsAndPercentage = (bedrooms: number) => {
    const units = totalUnitsPerBedroom[bedrooms];
    if (totalUnits === 0) return "-";
    if (units === 0) return "0";
    return `${formatCommas(units)} (${formatRoundedPercentage(units / totalUnits)})`;
  };

  const totalGrossArea = buildings.reduce((sum, building) => sum + building.gsf, 0);
  const projectParcelArea = (store.getState().projects.selectedProject as Project).parcel_size.area;
  const netRentableArea = buildings.reduce(
    (sum, building) => sum + buildingsQuerier(building).netRentableArea,
    0
  );

  const someHasDevCosts = some(buildings.map(building => building.devcosts));
  const someHasRents = some(buildings.map(building => building.rents));
  const someHasFinance = some(buildings.map(building => building.finance));
  const someIsMultifamily = some(buildings.map(building => isApartment(building)));

  const totalGap = buildings.reduce(
    (sum, building) => sum + (building.finance ? building.finance.outputs.gap : 0),
    0
  );

  const TDC = buildings.reduce(
    (sum, building) => sum + buildingsQuerier(building).totalDevelopmentCosts,
    0
  );
  const totalProfit = buildings.reduce(
    (sum, building) => sum + (building.finance?.outputs.project_profit ?? 0),
    0
  );

  const firstYearNOI = buildings.reduce(
    (sum, building) => sum + (building.rents?.outputs.cashflow[0].NOI ?? 0),
    0
  );

  return {
    // Summary table data
    [ColumnNames.Stories]: buildings.map(building => building.stories).join(", "),
    [ColumnNames.TotalUnits]: totalUnits,
    [getGrossAreaLabel()]: formatCommas(totalGrossArea),
    [getResAreaLabel()]: formatCommas(buildings.reduce((sum, building) => sum + building.rsf, 0)),
    [ColumnNames.TDC]: formatDollars(TDC),
    [ColumnNames.FirstYearNOI]: someHasRents ? formatDollars(firstYearNOI) : null,
    [ColumnNames.Debt]: someHasFinance
      ? formatDollars(
          buildings.reduce((sum, building) => sum + (building.finance?.outputs.total_debt ?? 0), 0)
        )
      : null,
    [ColumnNames.Equity]: someHasFinance
      ? formatDollars(
          buildings.reduce(
            (sum, building) => sum + (building.finance?.outputs.total_equity ?? 0),
            0
          )
        )
      : null,
    [ColumnNames.Gap]: someHasFinance ? formatDollars(totalGap) : null,
    [ColumnNames.GapPerUnit]:
      totalUnits > 0 && someHasFinance ? formatDollars(totalGap / totalUnits) : null,
    [ColumnNames.Sources]: someHasFinance
      ? formatDollars(
          buildings.reduce(
            (sum, building) => sum + (building.finance?.outputs.total_sources ?? 0),
            0
          )
        )
      : null,

    // Building table data
    [ColumnNames.AvgUnitSize]: totalUnits > 0 ? formatDecimal(netRentableArea / totalUnits) : null,
    [getAreaPerUnitLabel()]: formatCommas(totalGrossArea / totalUnits),
    [ColumnNames.NRA]: formatCommas(netRentableArea),
    [ColumnNames.FAR]: formatDecimal(
      buildings.every(building => building.far !== null)
        ? buildings.reduce((sum, building) => sum + building.far, 0) / projectParcelArea
        : (totalGrossArea -
            buildings.reduce((sum, building) => sum + building.underground_parking_gsf, 0)) /
            projectParcelArea
    ),
    // Note (@nicoluce): Both variables are geometry areas that could have some rounding errors.
    [ColumnNames.LotCoverage]: formatPercentage(
      Math.min(
        1.0,
        buildings.reduce((sum, building) => sum + buildingsQuerier(building).floorplateArea, 0) /
          projectParcelArea
      )
    ),
    [ColumnNames.BuildingFootprint]: formatCommas(
      buildings.reduce((sum, building) => sum + buildingsQuerier(building).floorplateArea, 0)
    ),
    [ColumnNames.DUA]: formatDecimal(totalUnits / amountOfAcres(projectParcelArea)),
    [ColumnNames.PkRatio]:
      totalUnits > 0
        ? formatDecimal(
            buildings.reduce((sum, building) => sum + building.total_parking_slots, 0) / totalUnits
          )
        : null,
    [`Comm. Sq.${getDistanceLabel()}`]: someIsMultifamily
      ? formatCommas(
          buildings.reduce((sum, building) => sum + building.actual_commercial_retail_gsf, 0)
        )
      : null,
    [ColumnNames.Studio]: showUnitsAndPercentage(0),
    [ColumnNames.OneBed]: showUnitsAndPercentage(1),
    [ColumnNames.TwoBed]: showUnitsAndPercentage(2),
    [ColumnNames.ThreeBed]: showUnitsAndPercentage(3),
    [ColumnNames.FourBed]: showUnitsAndPercentage(4),
    [ColumnNames.AvgStudio]: averageUnitSize(0),
    [ColumnNames.AvgOneBed]: averageUnitSize(1),
    [ColumnNames.AvgTwoBed]: averageUnitSize(2),
    [ColumnNames.AvgThreeBed]: averageUnitSize(3),
    [ColumnNames.AvgFourBed]: averageUnitSize(4),
    [`Pk ${getGrossAreaLabel()}`]: someIsMultifamily
      ? formatCommas(buildings.reduce((sum, building) => sum + building.parking_gsf, 0))
      : null,
    [ColumnNames.PodiumPkSpaces]: someIsMultifamily
      ? buildings.reduce((sum, building) => sum + building.total_podium_parking_slots, 0)
      : null,
    [ColumnNames.UndergrPkSpaces]: someIsMultifamily
      ? buildings.reduce((sum, building) => sum + building.total_underground_parking_slots, 0)
      : null,
    [ColumnNames.IOSR]: formatPercentage(
      buildings.reduce((sum, building) => sum + building.interior_open_space_area, 0) /
        totalGrossArea
    ),
    [ColumnNames.CAR]: formatPercentage(
      buildings.reduce(
        (sum, building) =>
          sum + building.interior_open_space_area + building.interior_circulation_area,
        0
      ) / totalGrossArea
    ),

    // Pro Forma Analysis Table Data
    [ColumnNames.FirstYearEGI]: someHasRents
      ? formatDollars(
          buildings.reduce(
            (sum, building) =>
              sum + (building.rents?.outputs.cashflow[0].estimated_gross_income ?? 0),
            0
          )
        )
      : null,
    [ColumnNames.FirstYearNOI]: someHasRents ? formatDollars(firstYearNOI) : null,
    [ColumnNames.FirstMortgage]: someHasFinance
      ? formatDollars(
          buildings.reduce(
            (sum, building) => sum + (building.finance?.outputs.total_mortgage ?? 0),
            0
          )
        )
      : null,
    [ColumnNames.LIHTC]: someHasFinance
      ? formatDollars(
          buildings.reduce(
            (sum, building) => sum + (building.finance?.outputs.LIHTC_equity ?? 0),
            0
          )
        )
      : null,
    [ColumnNames.TotalParkingCosts]: someHasDevCosts
      ? formatDollars(
          buildings.reduce(
            (sum, building) =>
              sum +
              (isApartment(building) ? building.devcosts?.outputs.total_parking_costs ?? 0 : 0),
            0
          )
        )
      : null,
    [ColumnNames.CapValue]: someHasFinance
      ? formatDollars(
          buildings.reduce(
            (sum, building) => sum + (building.finance?.outputs.capitalized_value ?? 0),
            0
          )
        )
      : null,
    [ColumnNames.Profit]: someHasFinance ? formatDollars(totalProfit) : null,
    [ColumnNames.ROC]: someHasFinance ? formatPercentage(totalProfit / TDC) : null,
    [ColumnNames.YOC]: someHasFinance ? formatPercentage(firstYearNOI / TDC) : null
  };
};
