import {
  flatten,
  fromPairs,
  isNull,
  keys,
  pick,
  isEmpty,
  without,
  values as _values,
  round
} from "lodash";
import {
  SubmissionError,
  submit,
  change,
  unregisterField,
  destroy,
  formValueSelector,
  isDirty
} from "redux-form";

import { isToggleChecked } from "../components/forms/CheckboxInput";
import {
  devCostBasic,
  devCostDetails,
  dollarsPerAreaConvertUnitFactor,
  roomLabels,
  unitLabels
} from "../config/config";
import { isNullOrUndefinedOrBlank } from "../config/utils";
import * as proformaPromises from "../promises/proforma/proforma";
import { asyncGetMeasurementSystems, asyncPutUserSettings } from "../promises/users/users";
import { pushNewError } from "../store/errors";
import * as proformaActions from "../store/proforma";
import { setActiveModalForm, toggleAddBuildingModal, toggleWizardModal } from "../store/projects";
import { getSelectedBuilding } from "../store/selectors/projects";
import { closeIncompatibleProformaModal } from "../store/ui/incompatibleProforma";
import { BuildingTypeCode } from "../types/buildings";
import { exists, parsePercentage } from "../utils";
import { apiGetBuildingsFromProject } from "./projects";

export const changeRentProfileFormField = (fieldName, newValue) => {
  return dispatch => {
    dispatch(change("RentProfileModalForm", fieldName, isNull(newValue) ? 0 : newValue));
  };
};

export const destroyField = (formName, fieldName) => {
  return dispatch => {
    dispatch(unregisterField(formName, fieldName));
    dispatch(change(formName, fieldName));
  };
};

export const changeTaxCreditRadio = value => {
  return dispatch => {
    dispatch(change("SourceOfFundsStepForm", "tax_credit_percentage", value));
  };
};

export const changeManagersUnit = value => {
  return dispatch => {
    dispatch(change("OperatingStepForm", "manager_units", value));
  };
};

export const devCostPerAreaFields = [
  "residential_construction_cost_per_sf",
  "commercial_construction_cost_per_sf",
  "podium_parking_space_construction_cost",
  "underground_parking_space_construction_cost"
];

const selector = formValueSelector("DevCostsStepForm");
export const handleDevCostsChangeUnit = metric => async (dispatch, getState) => {
  const state = getState();
  const factor = dollarsPerAreaConvertUnitFactor(metric);
  devCostPerAreaFields.forEach(field => {
    if (isDirty("DevCostsStepForm")(state, field)) {
      dispatch(change("DevCostsStepForm", field, Math.round(selector(state, field) * factor)));
    }
    dispatch(proformaActions.setDevcostsMetric(metric));
  });
  const measurementSystems = await asyncGetMeasurementSystems();
  const measurementSystem = measurementSystems.find(
    system => system.title === (metric ? "Metric" : "Imperial")
  );
  if (!measurementSystem) {
    pushNewError(
      "Error setting Proforma unit system.",
      `controllers::proforma::handleDevCostsChangeUnit -> Couldn't find ${
        metric ? "Metric" : "Imperial"
      } measurement system in ${measurementSystems}`
    );
  }
  await asyncPutUserSettings({ proformaMeasurementSystemId: measurementSystem.id });
};

export const handleDevCostsSubmit = values => {
  const raiseErrorIfNotNumeric = key => {
    if (isNaN(values[key])) {
      const errorObj = fromPairs([[key, "Value must be numeric."]]);
      throw new SubmissionError(errorObj);
    }
  };

  const isRequired = key => {
    if (isNullOrUndefinedOrBlank(values[key]) || isNaN(values[key])) {
      const errorObj = fromPairs([[key, "Value is required"]]);
      throw new SubmissionError(errorObj);
    }
  };

  return async (dispatch, getState) => {
    const selectedBuilding = getSelectedBuilding(getState());
    const buildingId = selectedBuilding.id;
    const useFullDetail = isToggleChecked(values.useFullDetail);
    if (isNullOrUndefinedOrBlank(buildingId)) {
      dispatch(setActiveModalForm("OperatingStep"));
      return;
    }
    // Build development costs input
    let inputData;
    if (useFullDetail) {
      if (selectedBuilding.building_type.code === BuildingTypeCode.nplex) {
        values.commercial_construction_cost_per_sf = 0.0;
        values.podium_parking_space_construction_cost = 0.0;
        values.underground_parking_space_construction_cost = 0.0;
      }

      devCostDetails.forEach(key => {
        isRequired(key);
        raiseErrorIfNotNumeric(key);
      });
      inputData = {
        development_cost_type: "use_complete_development_costs",
        soft_cost_percentage: parsePercentage(values.soft_cost_percentage)
      };
      [
        "residential_construction_cost_per_sf",
        "commercial_construction_cost_per_sf",
        "acquistion_cost",
        "podium_parking_space_construction_cost",
        "underground_parking_space_construction_cost",
        "impact_fees_per_unit",
        "developer_fee"
      ].forEach(key => (inputData[key] = parseFloat(values[key])));

      // If selected unit is different from region's, convert values before submitting.
      const userRegionIsMetric = getState().users.metric;
      const metricSelection = getState().proforma.devcostsMetric ?? userRegionIsMetric;
      if (metricSelection !== userRegionIsMetric) {
        const factor = dollarsPerAreaConvertUnitFactor(userRegionIsMetric);
        devCostPerAreaFields.forEach(
          field => (inputData[field] = round(inputData[field] * factor, 2))
        );
      }
    } else {
      devCostBasic.forEach(key => {
        isRequired(key);
        raiseErrorIfNotNumeric(key);
      });
      inputData = {
        development_cost_type: "use_simplified_development_costs",
        development_cost_per_unit: parseFloat(values["development_cost_per_unit"])
      };
    }
    let devCostResponse;
    try {
      devCostResponse = await proformaPromises.asyncPutDevCosts(buildingId, inputData);
    } catch (error) {
      console.error(
        `actions::proforma::handleDevCostsSubmit -> Development costs submit failed: ${error}`
      );
    }
    dispatch(proformaActions.setDevcostsData(devCostResponse.development_costs));

    const projectId = getState().projects.selectedProject.id;
    dispatch(apiGetBuildingsFromProject(projectId, buildingId));
    dispatch(setActiveModalForm("OperatingStep"));
  };
};

export const handleOperatingStepSubmit = values => {
  const isRequired = key => {
    if (isNaN(values[key]) || values[key] === "") {
      const errorObj = fromPairs([[key, "Value is required"]]);
      throw new SubmissionError(errorObj);
    }
  };

  return async (dispatch, getState) => {
    const selectedBuilding = getSelectedBuilding(getState());
    const buildingId = selectedBuilding.id;
    if (isNullOrUndefinedOrBlank(buildingId)) {
      dispatch(setActiveModalForm("SourceOfFundsStep"));
      return;
    }

    if (selectedBuilding.building_type.code === BuildingTypeCode.nplex) {
      values.commercial_vacancy = 0.0;
      values.commercial_rent_per_sf_per_annum = 0.0;
      values.commercial_rent_inflator = 0.0;
      values.laundry_income_per_unit_per_month = 0.0;
    }
    const inputData = {
      residential_vacancy: parsePercentage(values["residential_vacancy"]),
      commercial_vacancy: parsePercentage(values["commercial_vacancy"]),
      operating_expenses_per_unit_per_annum: parseFloat(
        values["operating_expenses_per_unit_per_annum"]
      ),
      expense_inflator: parsePercentage(values["expense_inflator"]),
      reserves_per_unit_per_annum: parseFloat(values["reserves_per_unit_per_annum"]),
      residential_rent_inflator: parsePercentage(values["residential_rent_inflator"]),
      commercial_rent_per_sf_per_annum: parseFloat(values["commercial_rent_per_sf_per_annum"]),
      commercial_rent_inflator: parsePercentage(values["commercial_rent_inflator"]),
      laundry_income_per_unit_per_month: parseFloat(values["laundry_income_per_unit_per_month"]),
      manager_units: parseInt(values["manager_units"]),
      rent_profiles: getState().proforma.rentProfiles
    };

    without(keys(inputData), "rent_profiles").forEach(key => {
      isRequired(key);
    });

    if (isEmpty(inputData.rent_profiles)) {
      throw new SubmissionError({
        _error:
          "No rent profile added. Click on the Residential Rent Profile button to add rent data."
      });
    }
    try {
      await proformaPromises.asyncPutOperating(buildingId, inputData);
    } catch (error) {
      console.error(`actions::proforma::handleOperatingStepSubmit-> ${error}`);
    }
    dispatch(setActiveModalForm("SourceOfFundsStep"));
  };
};

export const handleSourceOfFundsStepSubmit = values => {
  return (dispatch, getState) => {
    const selectedBuilding = getSelectedBuilding(getState());
    const buildingId = selectedBuilding.id;
    const isRequired = key => {
      if (isNaN(values[key]) || values[key] === "") {
        const errorObj = fromPairs([[key, "Value is required"]]);
        throw new SubmissionError(errorObj);
      }
    };

    const inputData = {
      // Debt input
      interest_rate: parsePercentage(values["interest_rate"]),
      armortization_period: parseInt(values["armortization_period"]),
      debt_service_coverage_ratio: parseFloat(values["debt_service_coverage_ratio"]),
      origination_fee: parsePercentage(values["origination_fee"]),
      other_debt: parseFloat(values["other_debt"]),

      // LIHTC input
      has_LIHTC: isToggleChecked(values["has_LIHTC"]),
      has_mortgage: isToggleChecked(values["has_mortgage"]),
      eligible_basis: getState().proforma.eligibleBasis,
      tax_credit_type: values["tax_credit"],
      tax_credit_percentage: parsePercentage(values["tax_credit_percentage"]),
      tax_credit_pricing: parseFloat(values["tax_credit_pricing"]),
      percent_affordable: parsePercentage(values["percent_affordable"]),
      basis_boost: parsePercentage(values["basis_boost"]),
      compliance_period: parseInt(values["compliance_period"]),

      // Other input
      other_equity: parseFloat(values["other_equity"]),
      residential_cap_rate: parsePercentage(values["residential_cap_rate"]),
      actual_commercial_retail_gsf: selectedBuilding.actual_commercial_retail_gsf
    };

    // Raise an exception if one of the required fields is not present
    without(
      keys(inputData),
      "eligible_basis",
      "interest_rate",
      "armortization_period",
      "debt_service_coverage_ratio",
      "origination_fee",
      "other_debt",
      "has_LIHTC",
      "has_mortgage",
      "tax_credit_type",
      "actual_commercial_retail_gsf"
    ).forEach(key => {
      isRequired(key);
    });

    proformaPromises
      .asyncPutSourceOfFunds(buildingId, inputData)
      .then(() => {
        const projectId = getState().projects.selectedProject.id;
        dispatch(apiGetBuildingsFromProject(projectId, buildingId));
        dispatch(toggleAddBuildingModal(false));
        dispatch(toggleWizardModal(false));
        dispatch(destroyProformaForms());
      })
      .catch(error => {
        console.error(`actions::proforma::handleSourceOfFundsStepSubmit -> ${error}`);
      });
  };
};

const _formatRentProfilesToSend = (values, useUnitCount) => dispatch => {
  const valuesWithNames = {};
  keys(values).forEach(key => {
    valuesWithNames[key] = values[key].map(row => {
      return Object.assign({}, row, { name: key });
    });
  });
  const dataToSend = flatten(_values(valuesWithNames)).map(row => {
    const rentProfileAmi =
      exists(row.ami) && exists(row.ami.value) ? row.ami.value : exists(row.ami) ? row.ami : null;
    return {
      allowance: parseInt(row.allowance),
      ami: rentProfileAmi,
      name: row.name,
      rent: parseInt(row.rent),
      unit_count: useUnitCount ? parseInt(row.unit_count) : parseInt(row.unit_percentage)
    };
  });
  if (dataToSend.length === 0) {
    dispatch(proformaActions.setRentProfileSpinner(false));
    throw new SubmissionError({
      _error: "No profiles added. Add rent profiles to calculate residential rent."
    });
  }
  return dataToSend;
};

const _validateRentProfilesToSend = (values, roomObj, useUnitCount) => dispatch => {
  keys(roomObj).forEach(key => {
    const roomTypeTotal = roomObj[key];
    const groupName = roomLabels[key];
    const groupUnitCount = values[groupName].map(record =>
      parseInt(useUnitCount ? record.unit_count : record.unit_percentage)
    );
    const groupTotal = groupUnitCount.reduce((total, cv) => total + cv, 0);
    if (groupTotal !== roomTypeTotal) {
      dispatch(proformaActions.setRentProfileSpinner(false));
      throw new SubmissionError({
        _error: `The ${useUnitCount ? "unit count" : "percentages"} for ${groupName} must add to ${
          useUnitCount ? roomTypeTotal : "100%"
        }`
      });
    }
  });
};

export const handleRentProfileFixSubmit = values => async (dispatch, getState) => {
  const dataToSend = dispatch(_formatRentProfilesToSend(values, false));
  const roomObj = {};
  const program = getState().programs.selectedPrograms.multi_family;
  unitLabels.apartments.proportions.forEach((field, index) => {
    if (program[field] > 0) {
      roomObj[unitLabels.apartments.layout[index]] = 100;
    }
  });

  dispatch(_validateRentProfilesToSend(values, roomObj, false));
  dispatch(proformaActions.setRentProfiles(dataToSend));
  dispatch(proformaActions.setRentProfileSpinner(false));
  dispatch(closeIncompatibleProformaModal());
  dispatch(submit("MainModalForm"));
};

export const handleResRentProfileSubmit = values => {
  return (dispatch, getState) => {
    const selectedBuilding = getSelectedBuilding(getState());
    dispatch(proformaActions.setRentProfileSpinner(true));

    const dataToSend = dispatch(_formatRentProfilesToSend(values, true));

    if (selectedBuilding.building_type.code === BuildingTypeCode.apartment) {
      const unitTypes = unitLabels.apartments.layout.filter(label => selectedBuilding[label] > 0);
      const roomObj = pick(selectedBuilding, unitTypes);

      dispatch(_validateRentProfilesToSend(values, roomObj, true));
      const totalBuildingUnits = _values(roomObj).reduce((total, cv) => total + cv);
      const totalProfileUnits = dataToSend
        .map(row => parseInt(row.unit_count))
        .reduce((total, cv) => total + cv);

      if (totalBuildingUnits !== totalProfileUnits) {
        dispatch(proformaActions.setRentProfileSpinner(false));
        throw new SubmissionError({
          _error: `Unit counts must add up to total building unit count: ${totalBuildingUnits}`
        });
      }
    }
    dispatch(proformaActions.setRentProfiles(dataToSend));
    dispatch(proformaActions.setRentProfileSpinner(false));
    dispatch(proformaActions.toggleRentProfileModal(false));
  };
};

export const submitDevCosts = () => {
  return dispatch => {
    dispatch(submit("DevCostsStepForm"));
  };
};

export const submitOperatingStep = () => {
  return dispatch => {
    dispatch(submit("OperatingStepForm"));
  };
};

export const submitSourceOfFundsStep = () => {
  return dispatch => {
    dispatch(submit("SourceOfFundsStepForm"));
  };
};

export const submitResRentProfile = () => {
  return dispatch => {
    dispatch(submit("RentProfileModalForm"));
  };
};

export const updateBasisBoost = value => {
  return dispatch => {
    dispatch(change("SourceOfFundsStepForm", "basis_boost", value));
  };
};

export const destroyProformaForms = () => {
  return dispatch => {
    dispatch(destroy("OperatingStepForm"));
    dispatch(destroy("DevCostsStepForm"));
    dispatch(destroy("SourceOfFundsStepForm"));
  };
};

export const updateDdaQct = fields => {
  return dispatch => {
    fields.forEach(fieldObj => {
      dispatch(change("SourceOfFundsStepForm", fieldObj.field, fieldObj.value));
    });
  };
};
