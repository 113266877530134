import { assert, NotFoundError } from "../../lib/errors";
import { DataLayer, NormalizedDataLayers } from "../../types/region";

export const dataLayersQuerier = (dataLayers: NormalizedDataLayers) => ({
  get all(): DataLayer[] {
    return Object.values(dataLayers);
  },
  byName(name: string): DataLayer {
    const dataLayer = dataLayers[name];
    if (!dataLayer) {
      throw new NotFoundError(`Could not find data layer of name ${name}`);
    }
    return dataLayer;
  },
  byRemoteId(remoteId: number): DataLayer {
    assert.id(
      `'dataLayers.byRemoteId' requires a valid ID. Got ${JSON.stringify(remoteId)} instead.`,
      remoteId
    );
    const dataLayer = this.all.find(l => l.remoteId === remoteId);
    if (!dataLayer) {
      throw new NotFoundError(`Could not find data layer of remote ID ${remoteId}`);
    }
    return dataLayer;
  }
});

export const mapboxSourceID = (dataLayer: DataLayer) => `${dataLayer.name}:source`;

export const mapboxFillLayerID = (dataLayer: DataLayer) => `${dataLayer.name}:fill-layer`;

export const mapboxOutlineLayerID = (dataLayer: DataLayer) => `${dataLayer.name}:outline-layer`;
