/**
 * Created by nluce on 9/24/19.
 */

import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Grid, Segment, Form, Button } from "semantic-ui-react";
import {
  required,
  validateUnitSqft,
  validateGroundFloorHeight,
  validatePositiveInteger,
  residentialFloorHeightDefault
} from "../../config/config";
import { getDistanceLabel, getGrossAreaLabel } from "../../config/utils";
import * as programCtrl from "../../controllers/programs";
import * as projectCtrl from "../../controllers/projects";
import TextInput from "./../forms/TextInput";
import WizardTextInput from "./../forms/WizardTextInput";

const actionsObj = Object.assign({}, programCtrl, projectCtrl);

const footerButtons = props => {
  return (
    <div>
      <Button
        onClick={() => {
          props.submitSingleFamilyAttached();
        }}
      >
        Submit
      </Button>
    </div>
  );
};

export const SingleFamilyAttachedStepFooter = connect(() => {
  return {};
}, actionsObj)(footerButtons);

class SingleFamilyAttachedStep extends React.Component {
  componentDidMount() {
    this.props.apiGetProgramsList();
  }

  render() {
    const { handleSubmit, editProgram } = this.props;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Grid>
            {!editProgram ? (
              <Grid.Row>
                <Grid.Column width={16}>
                  <Segment>
                    <Field
                      name="name"
                      component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                      label="New Building Program Name"
                      required
                      validate={required}
                      placeholder="Program Name"
                      readOnly={this.readOnlyMode}
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            ) : null}
            <Grid.Row>
              <Grid.Column width={8}>
                <Segment>
                  <label>
                    <b>Units</b>
                  </label>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignItems: "stretch",
                      alignContent: "stretch",
                      justifyContent: "left",
                      marginRight: "10px",
                      marginTop: "50px"
                    }}
                  >
                    <div style={{}}>
                      <Field
                        name="minimum_unit_size"
                        component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label="Minimum Unit Size"
                        unitLabel={getGrossAreaLabel()}
                        labelPosition="right"
                        required
                        placeholder=""
                        validate={[required, validateUnitSqft]}
                        readOnly={this.readOnlyMode}
                      />
                      <Field
                        id="ceiling_height"
                        name="ceiling_height"
                        component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label="Residential Floor Height"
                        unitLabel={getDistanceLabel()}
                        labelPosition="right"
                        required
                        defaultValue="10"
                        validate={[required, validateGroundFloorHeight]}
                        readOnly={this.readOnlyMode}
                      />
                    </div>
                  </div>
                </Segment>
              </Grid.Column>
              <Grid.Column width={8}>
                <Segment>
                  <label>
                    <b>Circulation</b>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      justifyContent: "right",
                      alignItems: "stretch",
                      alignContent: "stretch",
                      marginLeft: "10px",
                      marginTop: "50px"
                    }}
                  >
                    <div style={{}}>
                      <Field
                        name="maximum_adjacent_units"
                        component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label="Maximum Adjacent Units"
                        unitLabel={"Units"}
                        labelPosition="right"
                        required
                        validate={[required, validatePositiveInteger]}
                        readOnly={this.readOnlyMode}
                      />
                    </div>
                  </div>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { programInitialValues } = state.programs;
  const initValues = Object.assign({}, programInitialValues);
  initValues["ceiling_height"] = initValues["ceiling_height"] || residentialFloorHeightDefault();
  return {
    initialValues: initValues,
    editProgram: state.programs.editProgram.single_family
  };
};

const SingleFamilyAttachedForm = reduxForm({
  form: "SingleFamilyAttachedForm",
  destroyOnUnmount: true,
  onSubmitFail: () => {
    document.getElementById("ceiling_height").scrollIntoView({ behavior: "smooth" });
  }
})(SingleFamilyAttachedStep);

export default connect(mapStateToProps, actionsObj)(SingleFamilyAttachedForm);
