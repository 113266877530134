import { Map } from "mapbox-gl";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import { pushNewError, toggleErrorModal } from "../../store/errors";
import {
  showDimmedLoader,
  hideDimmedLoader,
  setLoadingMessage,
  setLoaderStyle
} from "../../store/ui/dimmedLoader";
import { Building } from "../../types/buildings";
import { Project } from "../../types/projects";

export type PDFDocument = any;

const mapStateToProps = (state: RootState) => {
  const cities = state.cities.citiesList;
  const metric = state.users.metric;
  const informationIdx = state.buildings.visualizationFloorIdx;
  const information = state.buildings.floorplanVisualizationInformation;
  const isCanada = state.projects.selectedProjectCountryCode === "ca";
  const defaultZoning = state.projects.currentProjectDefaultZoning;
  return { cities, metric, information, informationIdx, isCanada, defaultZoning };
};

const mapDispatchToProps = {
  pushNewError,
  toggleErrorModal,
  showDimmedLoader,
  hideDimmedLoader,
  setLoadingMessage,
  setLoaderStyle
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
export type ReportProps = {
  building: Building;
  project: Project;
  map: Map;
} & PropsFromRedux;
