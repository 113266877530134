import area from "@turf/area";
import centroid from "@turf/centroid";
import { polygon } from "@turf/helpers";
import { length } from "@turf/turf";
import { format, select } from "d3";
import { includes, keys } from "lodash";
import { isNull, isUndefined } from "lodash";
import { squareFeetPerAcre, squareMetersPerAcre } from "../constants/unitsSystems";
import store from "../store";
import { exists } from "../utils";
import {
  buildingOptionPanelHeight,
  buildingOptionPanelHeightMinimized,
  feetToMeters,
  sqMetersToSqFeet,
  unrestrictedLabel,
  unavailableLabel,
  maxArea
} from "./config";

export const longitudeIndex = 0;
export const latitudeIndex = 1;
export const formatDollars = format("$,.0f");
export const formatDecimal = format(".2f");
export const formatDecimalOne = format(".1f");
export const formatCommas = format(",.0f");
export const formatPercentage = format(".2%");
export const formatRoundedPercentage = format(".0%");
export const isNullOrUndefined = value => isNull(value) || isUndefined(value);
export const isNullOrUndefinedOrBlank = value =>
  isNull(value) || isUndefined(value) || value === "";

export const formatFormInteger = value => (typeof value === "number" ? formatCommas(value) : value);
export const parseFormInteger = value => {
  const formattedNumber = Number(String(value).replaceAll(",", ""));
  return isNaN(formattedNumber) ? value : formattedNumber;
};

const formatNumericFields = (
  unformattedInputData,
  numericPercentageFields,
  numericNonPercentageFields,
  booleanFields,
  { percentagesAreEncodedBetweenZeroAndOne }
) => {
  const formattedInputData = Object.assign({}, unformattedInputData);
  keys(formattedInputData).forEach(field => {
    const isNumericPercentage = includes(numericPercentageFields, field);
    const isNumericNonPercentage = includes(numericNonPercentageFields, field);
    const isBoolean = includes(booleanFields, field);
    if (isNumericPercentage || isNumericNonPercentage) {
      let parsedValue = parseFloat(formattedInputData[field]);
      if (isNumericPercentage && !percentagesAreEncodedBetweenZeroAndOne) {
        parsedValue /= 100;
      }
      formattedInputData[field] = isNaN(parsedValue) ? null : parsedValue;
    }
    if (isBoolean) {
      formattedInputData[field] = formattedInputData[field] === true;
    }
  });
  return formattedInputData;
};

export const formatZoningObject = (formData, { percentagesAreEncodedBetweenZeroAndOne }) => {
  const numericPercentageFields = [
    "front_setback_percent",
    "side_setback_percent",
    "rear_yard_setback_percent",
    "density_bonus_percentage",
    "lot_coverage"
  ];
  const numericNonPercentageFields = [
    "units_permitted",
    "area_of_lot_per_unit",
    "dwelling_units_per_acre",
    "max_height",
    "effective_height",
    "ground_floor_ceiling_height",
    "effective_ground_floor_height",
    "front_setback",
    "side_setback",
    "rear_yard_setback",
    "rear_yard_setback_min",
    "street_frontage",
    "open_sp_per_unit",
    "parking_req",
    "min_lot_area",
    "basic_far",
    "density_bonus_height",
    "podium_max_height",
    "lower_tower_shrink_value",
    "upper_tower_shrink_value",
    "maximum_floor_area_ratio",
    "max_level_underground_parking",
    "ratio"
  ];

  const booleanFields = ["density_bonus", "multifamily_permitted", "bulk_requirements_enabled"];

  return formatNumericFields(
    formData,
    numericPercentageFields,
    numericNonPercentageFields,
    booleanFields,
    { percentagesAreEncodedBetweenZeroAndOne }
  );
};

export const formatProgramObject = formData => {
  const numericNonPercentageFields = [
    "area_1",
    "area_2",
    "area_3",
    "area_4",
    "area_studio",
    "prop_studio",
    "prop_1",
    "prop_2",
    "prop_3",
    "prop_4",
    "common_ground",
    "common_res",
    "counseling",
    "commercial_public_use",
    "commercial_retail",
    "office",
    "laundry_ground",
    "laundry_res",
    "ceiling_height",
    "utilities",
    "hallway_width",
    "bike_storage",
    "other_space",
    "number_stairwells",
    "number_elevators",
    "minimum_courtyard_width",
    "minimum_unit_size",
    "maximum_adjacent_units",
    "minimum_courtyard_width",
    "stairs_max_dead_end",
    "stairs_max_travel_distance"
  ];
  return formatNumericFields(formData, [], numericNonPercentageFields, undefined, {
    percentagesAreEncodedBetweenZeroAndOne: false
  });
};

export const toggleBuildingOptionControls = currentState => {
  if (currentState) {
    select("#building-options-panel")
      .transition(500)
      .style("height", buildingOptionPanelHeightMinimized);
  } else {
    select("#building-options-panel")
      .transition(500)
      .style("height", buildingOptionPanelHeight);
  }
};

export const formatParcelData = (query, parcelData) => {
  let theGeomGeoJSON = parcelData.the_geom_geojson;
  if (theGeomGeoJSON.type === "MultiPolygon")
    theGeomGeoJSON = polygon(theGeomGeoJSON.coordinates[0]).geometry;
  const parcelCentroid = centroid(theGeomGeoJSON);
  const isMetric = store.getState().users.metric;
  return {
    theGeomGeoJSON: theGeomGeoJSON,
    area: isMetric ? area(theGeomGeoJSON) : area(theGeomGeoJSON) * sqMetersToSqFeet,
    centroid: parcelCentroid,

    longitude: parcelCentroid.geometry.coordinates[0],
    latitude: parcelCentroid.geometry.coordinates[1],
    address: parcelData.address,

    parcelId: parseInt(parcelData.parcel_id),
    zoningId: parseInt(parcelData.zoning_id),
    heightId: parseInt(parcelData.height_id),
    cityId: parseInt(parcelData.city_id),
    composingParcelsIds: [parseInt(parcelData.parcel_id)]
  };
};

export const parcelIsValidSize = area => area < maxArea();

export const formatDistanceLabel = geometry => {
  const { metric } = store.getState().users;
  return `${formatDecimalOne(
    length(geometry, { units: "meters" }) * (metric ? 1 : 1 / feetToMeters)
  )} ${getDistanceLabel()}`;
};

export const getDistanceLabel = () => {
  const { metric } = store.getState().users;
  return metric ? "m" : "ft";
};

export const getGrossAreaLabel = () => {
  const { metric } = store.getState().users;
  return metric ? "GSM" : "GSF";
};

export const getResAreaLabel = () => {
  const { metric } = store.getState().users;
  return metric ? "RSM" : "RSF";
};

export const getAreaPerUnitLabel = () => {
  const { metric } = store.getState().users;
  return metric ? "SMU" : "SFU";
};

export const getAreaLabel = () => {
  const { metric } = store.getState().users;
  return metric ? "square meters" : "square feet";
};

export const getShortAreaLabel = () => {
  const { metric } = store.getState().users;
  return metric ? "m2" : "sf";
};

export const getAcreLabel = () => "acres";

export const getDollarsPerAreaLabel = metric => {
  return metric ? "$ per sq meter" : "$ per sq ft";
};

export const getRSFTooltip = () => {
  const { metric } = store.getState().users;
  return metric ? "Residential Square Meters" : "Residential Square Footage";
};

export const getGSFTooltip = () => {
  const { metric } = store.getState().users;
  return metric ? "Gross Square Meters" : "Gross Square Footage";
};

export const upperLowerBounds = {
  groundFloorHeight: { metric: { min: 2, max: 8 }, imperial: { min: 6, max: 25 } },
  unitArea: { metric: { min: 4, max: 1394 }, imperial: { min: 50, max: 15000 } },
  stairsDeadEndDistance: { metric: { min: 6, max: 14 }, imperial: { min: 20, max: 45 } },
  stairsMaxTravelDistance: { metric: { min: 15, max: 92 }, imperial: { min: 50, max: 300 } }
};

export const getUnitSystem = () => {
  const { metric } = store.getState().users;
  return metric ? "metric" : "imperial";
};

/**
 * Get distance value based on distance system
 *
 * @param {Number}value       - Value to convert
 * @param {String} system     - Distance system: ['metric', 'imperial']
 * @returns {Number}          - Converted distance
 */
export const getConvertedDistanceValue = (value, system = "imperial") => {
  if (getUnitSystem() === system) return value;
  if (getUnitSystem() === "metric") return Math.round(10000 * (value / 3.281)) / 10000;
  return Math.round(10000 * (value * 3.281)) / 10000;
};

export const getBound = (variable, type) => {
  const unitSystem = getUnitSystem();
  return upperLowerBounds[variable][unitSystem][type];
};

export const isInRange = (value, metric, variable) => {
  const systemProp = metric ? "metric" : "imperial";

  return (
    upperLowerBounds[variable][systemProp].min <= value &&
    value <= upperLowerBounds[variable][systemProp].max
  );
};

export const getDensityLimitLabel = densityLimit => {
  if (densityLimit === 1) {
    return `${densityLimit} unit`;
  } else if (densityLimit > 1) {
    return `${densityLimit} units`;
  } else if (densityLimit === 0) {
    return unavailableLabel;
  } else {
    return unrestrictedLabel;
  }
};

export const calculateAndGetDensityLimitLabel = (lotArea, zoningInfo) => {
  const densityLimit = calculateDensityLimit(lotArea, zoningInfo);
  return getDensityLimitLabel(densityLimit);
};

/**
 * @param {number} lotArea
 * @param {Zoning} zoningInfo
 * @returns {number}
 */
export const calculateDensityLimit = (lotArea, zoningInfo) => {
  if (isNullOrUndefined(zoningInfo)) {
    return 0;
  }
  const unitsPermitted = zoningInfo.units_permitted;
  const dwellingUnitsPerAcre = zoningInfo.dwelling_units_per_acre;
  const areaOfLotPerUnit = zoningInfo.area_of_lot_per_unit;
  const isMetric = store.getState().users.metric;
  const areaOfOneAcre = isMetric ? squareMetersPerAcre : squareFeetPerAcre;

  let densityLimitCalculations = [];

  if (exists(dwellingUnitsPerAcre)) {
    densityLimitCalculations.push((dwellingUnitsPerAcre * lotArea) / areaOfOneAcre);
  }
  if (exists(areaOfLotPerUnit) && areaOfLotPerUnit > 0) {
    densityLimitCalculations.push(lotArea / areaOfLotPerUnit);
  }
  if (exists(unitsPermitted) && unitsPermitted > 0) {
    densityLimitCalculations.push(unitsPermitted);
  }

  densityLimitCalculations = densityLimitCalculations
    .map(calculation => Math.round(calculation))
    .filter(calculation => calculation > 0);

  return Math.round(
    densityLimitCalculations.length > 0 ? Math.min(...densityLimitCalculations) : -1
  );
};
