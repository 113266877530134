/**
 * Created by jmartinez on 8/30/18.
 */

import centroid from "@turf/centroid";
import union from "@turf/union";
import axios from "axios";
import { isNull, omit } from "lodash";
import { SubmissionError, reset, formValueSelector } from "redux-form";
import {
  buildingWizardFormNames,
  flyToPitch,
  flyToZoom,
  initMap,
  mapOffset,
  unitLabels
} from "../config/config";
import { baseUrl } from "../config/urls";
import {
  isNullOrUndefined,
  toggleBuildingOptionControls,
  parcelIsValidSize
} from "../config/utils";
import { ensureAddressFieldForParcel } from "../helpers/geocoding";
import { is } from "../lib";
import { assert } from "../lib/errors";
import * as buildingPromises from "../promises/buildings/buildings";
import * as projectPromises from "../promises/projects/projects";
import { asyncGetZoningInfo } from "../promises/zoning/zoning";
import { dataLayersQuerier } from "../queriers/region";
import { pushNewError } from "../store/errors";
import { getCountryCodeByProject } from "../store/map";
import { safeOperation, operations } from "../store/operations";
import * as projectActions from "../store/projects";
import {
  getProjectMainBuildingsIds,
  getSelectedBuildingId,
  getSelectedCandidateSite,
  getSelectedProjectSelectedSite,
  selectedSiteIdSelector
} from "../store/selectors/projects";
import { setDisableProjectsIds } from "../store/ui";
import {
  cleanBoundaryVisualization,
  setBoundaryVisualization
} from "../store/ui/boundaryVisualization";
import { unanchorDataLayersPopup } from "../store/ui/dataLayers";
import { setIncompatibleProforma } from "../store/ui/incompatibleProforma";
import { exists, doesNotExist } from "../utils";
import { lineSegmentsFromPolygon } from "./../config/map";
import {
  apiGetBuildingInfo,
  hideNearbyBuildings,
  selectBuildingById,
  deselectBuilding
} from "./buildings";
import { generateBuilding } from "./user/building";
import { apiGetZoningInfo } from "./zoning";

export const asyncParcelRetrievalAction = query => () =>
  projectPromises.asyncParcelRetrieval(query);

export const selectPreviouslyClickedParcel = () =>
  safeOperation(operations.previouslyClickedParcelSelection, async (dispatch, getState) => {
    const clickedParcel = getState().projects.clickedParcel;
    await dispatch(setAndFocus(clickedParcel));
  });

/** Wrapper for submitting a POST Parcel request to UC
 * @param {Object} parcelInformation
 * @param {GeoJSON} parcelInformation.theGeomGeoJSON      GeomGeoJSON of the Parcel being posted
 * @param {[Number, Number]} parcelInformation.centroid   Centroid as [latitude, longitude]
 * @returns {Function}                                    Thunk returning posted parcel's ID
 */
export const postParcel = parcelInformation => async dispatch => {
  try {
    return await projectPromises.postParcel(parcelInformation);
  } catch (error) {
    dispatch(
      pushNewError({
        name: "Error while submitting drawn parcel",
        description: `controllers::projects::postParcel -> ${error}`
      })
    );
  }
};

export const setAndFocus = parcelInformation => async dispatch => {
  try {
    await ensureAddressFieldForParcel(parcelInformation);
    dispatch(projectActions.cleanPreviousParcelSelection());
    dispatch(projectActions.cleanClickedParcel());
    dispatch(cleanBoundaryVisualization());
    dispatch(unanchorDataLayersPopup());
    dispatch(projectActions.setSelectedParcel(parcelInformation));
    const parcelZoning = await asyncGetZoningInfo(
      parcelInformation.zoningId,
      parcelInformation.cityId
    );
    dispatch(projectActions.setDefaultZoning(parcelZoning));
    dispatch(focusCandidateParcel());
  } catch (error) {
    dispatch(projectActions.cleanDefaultZoning());
  }
};

const focusCandidateParcel = () => (dispatch, getState) => {
  dispatch(projectActions.setSideBarComponent("AddNewProject"));
  const map = getState().map.map;
  const parcelGeometry = getState().projects.selectedParcel.theGeomGeoJSON;
  map.flyTo({
    center: centroid(parcelGeometry).geometry.coordinates,
    zoom: flyToZoom,
    pitch: flyToPitch
  });
  dispatch(reset("AddNewProjectForm"));
  dispatch(hideNearbyBuildings({ geometry: parcelGeometry }, map));
};

export const combineParcel = (options = {}) =>
  safeOperation(operations.parcelCombination, async (dispatch, getState) => {
    options.useNeighborInformation = options.useNeighborInformation || false;
    const { selectedParcel, clickedParcel } = getState().projects;
    const selectedCandidateSite = getSelectedCandidateSite(getState());

    const combinedParcel = { ...selectedParcel, ...selectedCandidateSite };

    const combinedParcelGeometry = union(
      selectedCandidateSite.theGeomGeoJSON,
      clickedParcel.theGeomGeoJSON
    ).geometry;
    // @turf/union reverses the order of coordinates in the polygon
    // resulting in clockwise instead of anticlockwise enumeration of vertices
    combinedParcelGeometry.coordinates[0] = combinedParcelGeometry.coordinates[0].slice().reverse();
    const combinedParcelCentroid = centroid(combinedParcel.theGeomGeoJSON);

    combinedParcel.theGeomGeoJSON = combinedParcelGeometry;
    combinedParcel.area = selectedCandidateSite.area + clickedParcel.area;
    combinedParcel.centroid = combinedParcelCentroid;
    combinedParcel.longitude = combinedParcelCentroid.geometry.coordinates[0];
    combinedParcel.latitude = combinedParcelCentroid.geometry.coordinates[1];
    combinedParcel.composingParcelsIds = selectedParcel.composingParcelsIds.concat([
      clickedParcel.parcelId
    ]);

    ["address", "height", "zoningCode", "parcelId", "zoningId", "heightId", "cityId"].forEach(
      key =>
        (combinedParcel[key] = options.useNeighborInformation
          ? clickedParcel[key]
          : selectedParcel[key])
    );
    const neighborParcelInformation = await projectPromises.asyncParcelRetrieval({
      method: "id",
      parcelId: clickedParcel.parcelId,
      layerId: dataLayersQuerier(getState().regions.dataLayers).byName("parcels").remoteId
    });

    combinedParcel.neighbors = selectedParcel.neighbors
      .concat(neighborParcelInformation.neighbors)
      .filter(neighbor => !combinedParcel.composingParcelsIds.includes(neighbor.parcelId));
    dispatch(projectActions.setParcelIsValid(parcelIsValidSize(combinedParcel.area)));
    dispatch(setAndFocus(combinedParcel));
  });

export const createBoundaryVisualization = boundaryGeoJSON => {
  /**
   * Creates new project's front, side, and rear yard boundaries
   * */
  return dispatch => {
    dispatch(setBoundaryVisualization(boundaryGeoJSON));

    const sideSegments = boundaryGeoJSON.features.map(feature => {
      return feature.id - 1;
    });

    dispatch(projectActions.setFrontSegments([]));
    dispatch(projectActions.setRearSegments([]));
    dispatch(projectActions.setSideSegments(sideSegments));
  };
};

export const updateBoundarySegments = (type, id, fromServer = false) => {
  return (dispatch, getState) => {
    const sideSegments = getState().projects.sideSegments;
    const frontSegments = getState().projects.frontSegments;
    const rearSegments = getState().projects.rearSegments;

    const segmentIndex = fromServer ? id : id - 1;

    const ensureAbsence = (src, id) => src.filter(val => val !== id);

    let newFrontSegments = ensureAbsence(frontSegments, segmentIndex);
    let newRearSegments = ensureAbsence(rearSegments, segmentIndex);
    let newSideSegments = ensureAbsence(sideSegments, segmentIndex);

    switch (type) {
      case "front":
        newFrontSegments = [...newFrontSegments, segmentIndex];
        break;
      case "rear":
        newRearSegments = [...newRearSegments, segmentIndex];
        break;
      case "sides":
        newSideSegments = [...newSideSegments, segmentIndex];
        break;
      default:
        console.warn("components::handleSetSegment -> Incorrect segment type");
    }

    dispatch(projectActions.setFrontSegments(newFrontSegments));
    dispatch(projectActions.setRearSegments(newRearSegments));
    dispatch(projectActions.setSideSegments(newSideSegments));
  };
};

export const apiGetProjectList = () =>
  safeOperation(operations.projectListFetch, async dispatch => {
    const projects = await projectPromises.asyncProjectList();
    const hiddenProjectsIds = projects.filter(project => project.hidden).map(project => project.id);
    dispatch(projectActions.setProjectList(projects.map(project => omit(project, "hidden"))));
    dispatch(setDisableProjectsIds(hiddenProjectsIds));
  });

const _getBuildingsFromProject = projectId =>
  safeOperation(operations.projectBuildingsFetch, async dispatch => {
    const projectBuildingsData = await projectPromises.asyncProjectBuildings(projectId);
    dispatch(projectActions.setProjectBuildings(projectBuildingsData));
  });

/**
 * @deprecated - Leaving in case we use it later.
 */
// eslint-disable-next-line
const _getBuilding = (buildingId = null) =>
  safeOperation(operations.buildingFetch, async (dispatch, getState) => {
    const map = getState().map.map;
    const project = getState().projects.selectedProject;
    const center = [parseFloat(project.longitude), parseFloat(project.latitude) - mapOffset];
    if (isNull(buildingId)) {
      if (exists(project["main_building_id"])) {
        await dispatch(apiGetBuildingInfo(project["main_building_id"]));
      } else {
        map.flyTo({
          center: center,
          zoom: flyToZoom,
          pitch: flyToPitch,
          bearing: initMap.bearing
        });
      }
    } else {
      await dispatch(apiGetBuildingInfo(buildingId));
    }
  });

export const apiGetBuildingsFromProject = (projectId, buildingId = undefined) => async (
  dispatch,
  getState
) => {
  await dispatch(_getBuildingsFromProject(projectId, buildingId));
  if (buildingId === undefined) {
    buildingId = getSelectedBuildingId(getState());
  }
  if (is.id(buildingId)) {
    dispatch(selectBuildingById(buildingId));
  }
};

export const apiAddNewProject = projectObj =>
  safeOperation(operations.projectCreation, async dispatch => {
    assert.string("Projects must be created with a valid address.", projectObj.address);
    const newProjectObj = await projectPromises.asyncNewProject(projectObj);
    dispatch(cleanBoundaryVisualization());
    await dispatch(apiGetProjectList());
    await dispatch(projectActions.cleanCandidateSites());
    dispatch(projectActions.setSideBarComponent("ProjectList"));

    return newProjectObj.id;
  });

export const apiDeleteProject = projectId => {
  return async dispatch => {
    try {
      await projectPromises.asyncDeleteProject(projectId);
      dispatch(apiGetProjectList());
      dispatch(cleanBoundaryVisualization());
    } catch (error) {
      console.error(`actions::projects::apiDeleteProjet -> Failed with ${error}`);
    }
  };
};

export const apiUpdateMainBuilding = buildingId => {
  return async (dispatch, getState) => {
    const siteId = selectedSiteIdSelector(getState());
    try {
      await projectPromises.asyncUpdateMainBuilding(siteId, buildingId);
      dispatch(projectActions.setProjectSiteMainBuildingId(buildingId));
    } catch (error) {
      dispatch(
        pushNewError({
          name: `Error updating site ${siteId}'s main building id to ${buildingId}`,
          description: `actions::buildings::apiUpdateMainBuilding -> ${error}`
        })
      );
    }
  };
};

// TODO (https://github.com/urbansim/penciler-planning/issues/712): Move to site controller
export const apiDeleteBuildingOptions = (buildingIdsList, siteId) => {
  return async dispatch => {
    try {
      if (doesNotExist(buildingIdsList)) return;
      if (!is.id(siteId)) return;

      const nextBuildingId =
        (buildingIdsList.length === 1
          ? await projectPromises.asyncDeleteBuilding(buildingIdsList[0])
          : await projectPromises.asyncDeleteBuildingList(buildingIdsList, siteId))[
          "main_building_id"
        ] ?? null;

      dispatch(projectActions.removeProjectSiteBuildings({ deletedIds: buildingIdsList, siteId }));

      dispatch(projectActions.setProjectSiteMainBuildingId(nextBuildingId));
      if (is.id(nextBuildingId)) {
        dispatch(selectBuildingById(nextBuildingId));
      } else {
        dispatch(deselectBuilding());
      }
    } catch (error) {
      console.error(`actions::projects::apiDeleteBuildingOptions -> Failed with ${error}`);
    }
  };
};

const _mainModalFormSelector = formValueSelector("MainModalForm");
export const handleNewBuildingSubmit = values => {
  return (dispatch, getState) => {
    const name = values["scenario-name"];
    if (name === "") {
      throw new SubmissionError({
        "scenario-name": "Scenario name is required"
      });
    }

    if (getState().buildings.editBuildingMode) {
      const newUnitTypes = _mainModalFormSelector(getState(), "multifamily_toggle")
        ? _validateRentProfilesMultifamily(
            getState().proforma.rentProfiles,
            getState().programs.selectedPrograms.multi_family
          )
        : [];
      if (newUnitTypes.length > 0) {
        dispatch(setIncompatibleProforma({ newUnitTypes }));
        return;
      }
    }
    dispatch(generateBuilding(name));
  };
};

/**
 * @param {RentProfile[]} rentProfiles
 * @param {any} program
 * @return {UnitType[]}
 */
const _validateRentProfilesMultifamily = (rentProfiles, program) => {
  if (rentProfiles.length === 0) {
    return [];
  }
  const rentProfilesUnitTypes = rentProfiles
    .filter(profile => profile.unit_count > 0)
    .map(profile => profile.name)
    .filter((v, i, a) => a.indexOf(v) === i);
  return unitLabels.apartments.abbreviated.filter(
    (field, index) =>
      program[unitLabels.apartments.proportions[index]] > 0 &&
      !rentProfilesUnitTypes.includes(field)
  );
};

export const resetBuildingWizardForms = () => {
  return dispatch => {
    buildingWizardFormNames.forEach(field => {
      dispatch(reset(field));
    });
  };
};

export const updateMainBuilding = (projectId, siteId) => {
  return async (dispatch, getState) => {
    try {
      const project = getState().projects.projectList.byId[projectId];
      const building = await buildingPromises.asyncGetBuildingInfo(
        getProjectMainBuildingsIds(project)[siteId]
      );
      dispatch(projectActions.setProjectMainBuilding({ projectId, siteId, building }));
    } catch (error) {
      console.error(`actions::projects::updateMainBuilding -> Failed with ${error}`);
    }
  };
};

const fetchProjectDefaultZoning = projectId => async (dispatch, getState) => {
  const project = getState().projects.projectList.byId[projectId];
  const cityId = project.parcel_data.city_id;
  try {
    const defaultZoning = await asyncGetZoningInfo(project.parcel_data.zoning_id, cityId);
    dispatch(projectActions.setDefaultZoning(defaultZoning));
  } catch {
    dispatch(projectActions.cleanDefaultZoning());
  }
};

export const focusOnProject = projectId => {
  return async (dispatch, getState) => {
    const { map, center, zoom, pitch, bearing } = getState().map;
    const selectedProject = getState().projects.selectedProject;
    const project = getState().projects.projectList.byId[projectId];
    if (isNullOrUndefined(map)) {
      throw new Error("MapBox 'map' instance is not yet defined.");
    }

    const allFeatures = map.queryRenderedFeatures({
      layers: ["3d-buildings-layer"]
    });
    allFeatures.forEach(item => {
      map.setFeatureState(
        {
          source: "composite",
          id: item.id,
          sourceLayer: "building"
        },
        { hidden: false }
      );
    });

    if (selectedProject.id === project.id) {
      map.flyTo({
        center: center,
        zoom: zoom,
        pitch: pitch,
        bearing: bearing
      });
    } else {
      const projectCenter = [
        parseFloat(project.longitude),
        parseFloat(project.latitude) - mapOffset
      ];
      map.flyTo({
        center: projectCenter,
        zoom: flyToZoom,
        pitch: flyToPitch
      });
      dispatch(projectActions.setSelectedProject(project));
      dispatch(projectActions.setSidebarVisible({ sidebarVisible: false, bottomBarVisible: true }));
      dispatch(getCountryCodeByProject(project));
      dispatch(projectActions.setBuildingSearch(""));
      await Promise.all([
        dispatch(fetchProjectDefaultZoning(projectId)),
        dispatch(apiGetZoningInfo(project.parcel_data.zoning_id)),
        dispatch(apiGetBuildingsFromProject(project.id))
      ]);
      map.on("moveend", () => {
        dispatch(hideNearbyBuildings(project.parcel_data, map));
      });
      map.on("rotateend", () => {
        dispatch(hideNearbyBuildings(project.parcel_data, map));
      });
    }
  };
};

export const showEditFrontageControls = () => {
  return (dispatch, getState) => {
    const selectedSite = getSelectedProjectSelectedSite(getState());
    if (selectedSite === null) return;
    dispatch(projectActions.showSegmentError(false));
    dispatch(projectActions.setSideBarComponent("SegmentRequirements"));
    dispatch(projectActions.setSidebarVisible({ sidebarVisible: true, bottomBarVisible: true }));
    toggleBuildingOptionControls(true);
    dispatch(projectActions.toggleBuildingOption(true));
    const geoJSON = lineSegmentsFromPolygon(selectedSite.geometry);
    dispatch(setBoundaryVisualization(geoJSON));
    const { segments_indexes } = selectedSite;
    dispatch(projectActions.setFrontSegments(segments_indexes["front"]));
    dispatch(projectActions.setSideSegments(segments_indexes["sides"]));
    dispatch(projectActions.setRearSegments(segments_indexes["rear"]));
  };
};

export const hideEditFrontageControls = () => {
  return (dispatch, getState) => {
    const buildingId = getSelectedBuildingId(getState());
    dispatch(projectActions.setSideBarComponent("ProjectList"));
    dispatch(projectActions.setSidebarVisible({ sidebarVisible: false, bottomBarVisible: true }));
    dispatch(projectActions.toggleEditFrontage(false));
    dispatch(projectActions.setFrontSegments([]));
    dispatch(projectActions.setSideSegments([]));
    dispatch(projectActions.setRearSegments([]));
    toggleBuildingOptionControls(false);
    dispatch(cleanBoundaryVisualization());
    dispatch(projectActions.showSegmentError(false));
    if (is.id(buildingId)) {
      dispatch(selectBuildingById(buildingId));
    }
  };
};

export const refreshProjectZoning = projectId =>
  safeOperation(operations.projectZoningRefresh, async dispatch => {
    assert.id(
      `'projectId' should be the id of a project. Got ${JSON.stringify(projectId)} instead.`,
      projectId
    );
    const {
      data: { updated_project: updatedProject }
    } = await axios.put(`${baseUrl}/projects/${projectId}`, { action: "refresh-default-zoning" });
    dispatch(projectActions.addProject(updatedProject));
    dispatch(projectActions.setSelectedProject(updatedProject));
    await Promise.all([
      dispatch(fetchProjectDefaultZoning(updatedProject.id)),
      dispatch(apiGetZoningInfo(updatedProject.parcel_data.zoning_id))
    ]);
  });
