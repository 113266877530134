import { Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import { userDefinedParcelsCityName } from "../../constants/keywords";
import { City } from "../../store/types";
import { Project } from "../../types/projects";

const styles = StyleSheet.create({
  title: {
    color: "#FFFFFF",
    justifyContent: "center",
    height: "32mm",
    backgroundColor: "#54B2AB"
  },
  project: {
    fontWeight: 700,
    fontSize: 15,
    marginLeft: "8mm",
    marginRight: "4mm",
    marginTop: "6mm"
  },
  address: {
    fontWeight: 400,
    fontSize: 11,
    marginLeft: "8mm",
    marginRight: "4mm",
    marginTop: "2mm"
  },
  projectInfoText: {
    fontWeight: 400,
    fontSize: 9.5
  },
  inline: { display: "flex", flexDirection: "row" },
  authorAndDateContainer: {
    justifyContent: "space-between",
    color: "#22556A",
    marginLeft: "8mm",
    marginRight: "4mm",
    marginTop: "6mm",
    marginBottom: "6mm",
    display: "flex",
    flexDirection: "row"
  }
});

type TitleProps = {
  project: Project;
  cities: City[];
};

const Title: React.FunctionComponent<TitleProps> = props => {
  const { project, cities } = props;
  const city = cities.find(
    city =>
      (String(city.id) === String(project.parcel_data.city_id) &&
        city.name !== userDefinedParcelsCityName) ||
      project.address.includes(city.name)
  );

  // Note (@nicoluce): address doesn't have any particular format
  // this is needed to provide consistency
  let address = project.address;
  if (city !== undefined && !address.includes(city.name)) {
    address += `, ${city.name}`;
  } else if (city !== undefined && address.includes(city.name)) {
    address = address.substr(0, address.indexOf(city.name) + city.name.length);
  }

  return (
    <View style={styles.title}>
      <Text style={styles.project}>{project.name}</Text>
      <Text style={styles.address}>{address ?? ""}</Text>
      <View style={styles.authorAndDateContainer}>
        <View style={styles.inline}>
          <Text style={styles.projectInfoText}>Created by: </Text>
          <Text style={[styles.projectInfoText, { fontWeight: "bold" }]}>{project.author}</Text>
        </View>
        <View style={styles.inline}>
          <Text style={styles.projectInfoText}>Date: </Text>
          <Text style={[styles.projectInfoText, { fontWeight: "bold" }]}>
            {moment().format("MM.DD.YYYY")}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default Title;
