import { range } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { feetToMeters } from "../config/config";
import { allBuildingsExtrusionLayer } from "../config/map";
import { palette } from "../constants/palette";
import { hideNearbyBuildings } from "../controllers/buildings";
import { BuildingTypeCode } from "../types/buildings";
import { doesNotExist } from "../utils";
import FloorVisualization from "./FloorVisualization";
import { levelTypes } from "./mapVisualization";

const actions = Object.assign({}, {});

class BuildingVisualization extends React.Component {
  bottomHeightOf(visGeoJSON, floorLevel) {
    if (floorLevel <= 1) return 0;

    return (
      visGeoJSON.properties.groundFloorCeilingHeight +
      (floorLevel - 2) * visGeoJSON.properties.ceilingHeight
    );
  }

  topHeightOf(visGeoJSON, floorLevel) {
    const ceilingHeight =
      floorLevel <= 1
        ? visGeoJSON.properties.groundFloorCeilingHeight
        : visGeoJSON.properties.ceilingHeight;
    return ceilingHeight + this.bottomHeightOf(visGeoJSON, floorLevel);
  }

  colorOf(feature, floorNumber) {
    const levelType = feature.properties.levelType;
    if (levelType === levelTypes.podiumParking) return palette.podiumParkingFloors;

    const floorsPalette = this.props.focused
      ? [palette.hoverEvenResidentialFloors, palette.hoverOddResidentialFloors]
      : [palette.evenResidentialFloors, palette.oddResidentialFloors];
    if (levelType === levelTypes.residentialNPlex) {
      const unitId = feature.properties.unitId;
      return floorsPalette[unitId % 2];
    }

    if (floorNumber === 1) return palette.groundFloor;

    return floorsPalette[floorNumber % 2];
  }

  prepareBuildingGeoJSON(buildingData) {
    const isMetric = this.props.isMetric;

    const groundFloorHeightInMeters = isMetric
      ? buildingData.zoning.effective_ground_floor_height
      : buildingData.zoning.effective_ground_floor_height * feetToMeters;
    const ceilingHeightInMeters = isMetric
      ? buildingData.building_program.ceiling_height
      : buildingData.building_program.ceiling_height * feetToMeters;

    const dataAdapter =
      buildingData.building_type.code === BuildingTypeCode.apartment
        ? this.adaptBuildingDataToVisualizationFormat
        : this.adaptNPlexDataToVisualizationFormat;
    const visGeoJSON = dataAdapter(buildingData, groundFloorHeightInMeters, ceilingHeightInMeters);
    return visGeoJSON;
  }

  adaptBuildingDataToVisualizationFormat(
    buildingData,
    groundFloorHeightInMeters,
    ceilingHeightInMeters
  ) {
    const visGeoJSON = {
      type: "FeatureCollection",
      properties: {
        groundFloorCeilingHeight: groundFloorHeightInMeters,
        ceilingHeight: ceilingHeightInMeters
      },
      features: []
    };

    const generatedFloors = buildingData.geometry.sub_buildings[0];
    const firstFloor = 1;
    let floorCount = firstFloor;

    if (buildingData.total_podium_parking_levels > 0) {
      const podiumParkingGeometry = buildingData.geometry.reduced_parcel.geometry;
      visGeoJSON.features.push({
        type: "Feature",
        properties: {
          startingFloor: floorCount,
          endingFloor: floorCount + buildingData.total_podium_parking_levels - 1,
          levelType: levelTypes.podiumParking
        },
        geometry: podiumParkingGeometry
      });
      floorCount += buildingData.total_podium_parking_levels;
    } else {
      const groundFloorGeometry = generatedFloors[0].geojson.features.find(
        feature => feature.properties.type === "building_footprint"
      ).geometry;
      visGeoJSON.features.push({
        type: "Feature",
        properties: {
          startingFloor: floorCount,
          endingFloor: floorCount,
          levelType: levelTypes.empty
        },
        geometry: groundFloorGeometry
      });
      floorCount++;
    }

    generatedFloors.forEach(floorInfo => {
      const geometry = floorInfo.geojson.features.find(
        feature => feature.properties.type === "building_footprint"
      ).geometry;
      visGeoJSON.features.push({
        type: "Feature",
        properties: {
          startingFloor: floorCount,
          endingFloor: floorCount + floorInfo.stories - 1,
          levelType: levelTypes.residentialApartment,
          projectId: buildingData.project_id
        },
        geometry: geometry
      });
      floorCount += floorInfo.stories;
    });

    return visGeoJSON;
  }

  adaptNPlexDataToVisualizationFormat(
    buildingData,
    groundFloorHeightInMeters,
    ceilingHeightInMeters
  ) {
    const visGeoJSON = {
      type: "FeatureCollection",
      properties: {
        groundFloorCeilingHeight: ceilingHeightInMeters,
        ceilingHeight: ceilingHeightInMeters
      },
      features: []
    };

    const generatedFloors = buildingData.geometry.sub_buildings[0];
    let floorLevel = 1;

    let unitMap = {};
    let unitIds = [];

    generatedFloors.forEach(floorInfo => {
      floorInfo.geojson.features.forEach(feature => {
        if (feature.properties.type === "unit_0") {
          if (feature.properties.id in unitMap) {
            unitMap[feature.properties.id].stories++;
          } else {
            unitIds.push(feature.properties.id);
            unitMap[feature.properties.id] = {
              level: floorLevel,
              stories: 1,
              geometry: feature.geometry
            };
          }
        }
      });
      floorLevel++;
    });

    unitIds.forEach(id => {
      const unit = unitMap[id];
      visGeoJSON.features.push({
        type: "Feature",
        properties: {
          startingFloor: unit.level,
          endingFloor: unit.level + unit.stories - 1,
          unitId: id,
          levelType: levelTypes.residentialNPlex,
          projectId: buildingData.project_id
        },
        geometry: unit.geometry
      });
    });

    return visGeoJSON;
  }
  componentDidMount() {
    const { map, building } = this.props;
    hideNearbyBuildings(building.geometry.full_parcel, map)();
    this.props.map.moveLayer(allBuildingsExtrusionLayer);
  }

  componentDidUpdate() {
    this.props.map.moveLayer(allBuildingsExtrusionLayer);
    const { map, building } = this.props;
    hideNearbyBuildings(building.geometry.full_parcel, map)();
  }

  render() {
    const { building } = this.props;
    const buildingId = building.id;
    const visGeoJSON = this.prepareBuildingGeoJSON(building);
    return doesNotExist(visGeoJSON)
      ? null
      : visGeoJSON.features.map((feature, index) => (
          <div key={index}>
            {feature.properties.levelType === levelTypes.residentialNPlex ? (
              <FloorVisualization
                buildingId={buildingId}
                feature={feature}
                floorNumber={feature.properties.unitId}
                color={this.colorOf(feature)}
                bottomHeight={this.bottomHeightOf(visGeoJSON, feature.properties.startingFloor)}
                topHeight={this.topHeightOf(visGeoJSON, feature.properties.endingFloor)}
              />
            ) : (
              range(
                feature.properties.startingFloor,
                feature.properties.endingFloor + 1
              ).map(floor => (
                <FloorVisualization
                  key={floor}
                  buildingId={buildingId}
                  feature={feature}
                  floorNumber={floor}
                  color={this.colorOf(feature, floor)}
                  bottomHeight={this.bottomHeightOf(visGeoJSON, floor)}
                  topHeight={this.topHeightOf(visGeoJSON, floor)}
                />
              ))
            )}
          </div>
        ));
  }
}

const mapStateToProps = state => {
  return {
    isMetric: state.users.metric,
    map: state.map.map
  };
};

export default connect(mapStateToProps, actions)(BuildingVisualization);
