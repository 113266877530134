import React from "react";
import { Table } from "semantic-ui-react";

export default class ZoningsSegment extends React.Component<{
  focusedFeature: any;
}> {
  render() {
    const {
      focusedFeature: {
        properties: { zoning_code: zoningCode, category }
      }
    } = this.props;

    return (
      <>
        <h5> Zonings </h5>
        <Table>
          <Table.Body>
            {[
              ["Category", category],
              ["Zoning Code", zoningCode]
            ].map(([key, value]) => (
              <Table.Row key={key}>
                <Table.Cell> {key} </Table.Cell>
                <Table.Cell> {value} </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    );
  }
}
