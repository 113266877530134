import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppDispatch } from "./";

// An `Operation` object will be created for each element in the following list: it will be
// available at `state.operations.<the operation's name>`
// Only static operations are supported at the moment.
const operationsNames = [
  "availableRegionsFetch",
  "buildingDuplication",
  "buildingFetch",
  "buildingGeneration",
  "buildingGenerationWithSuggestion",
  "buildingRename",
  "clickedParcelSelection",
  "getCandidateZoningIdentifier",
  "getZoningInfo",
  "hiddenProjectSet",
  "hiddenProjectsFetch",
  "parcelCombination",
  "parcelSearchByAddress",
  "parcelSearchById",
  "parcelSelection",
  "previouslyClickedParcelSelection",
  "projectBuildingsFetch",
  "projectCreation",
  "projectListFetch",
  "projectOpening",
  "projectRename",
  "projectZoningRefresh",
  "regionInitializationFetch",
  "regionSwitching",
  "siteUsageChanging",
  "suggestionSubmission",
  "updateMapViewport",
  "zoningSubmission"
] as const;

export const operations = Object.fromEntries(operationsNames.map(x => [x, x]));
type OperationName = typeof operations[string];

const delays = {
  success: 800,
  failure: 2000
};

const isReady = "isReady";
const isLoading = "isLoading";
const justSucceeded = "justSucceeded";
const justFailed = "justFailed";

export class Operation {
  name: string;
  isReady!: boolean;
  isLoading!: boolean;
  justSucceeded!: boolean;
  justFailed!: boolean;

  constructor(name: string, status: string) {
    this.name = name;
    ([isLoading, justSucceeded, justFailed] as const).forEach(x => (this[x] = status === x));
    this.isReady = status === justSucceeded || status === isReady;
  }
}

const _set = (state: any, operationName: string, status: string): void => {
  state[operationName] = new Operation(operationName, status);
};

const initialState = Object.fromEntries(
  Object.values(operations).map(x => [x, new Operation(x, isReady)])
);

const slice = createSlice({
  name: "operations",
  initialState,
  reducers: {
    start(state: any, action: PayloadAction<string>) {
      _set(state, action.payload, isLoading);
    },
    succeed(state: any, action: PayloadAction<string>) {
      _set(state, action.payload, justSucceeded);
    },
    fail(state: any, action: PayloadAction<string>) {
      _set(state, action.payload, justFailed);
    },
    end(state: any, action: PayloadAction<string>) {
      _set(state, action.payload, isReady);
    }
  }
});

export const safeOperation = (
  name: OperationName,
  action: (dispatch: AppDispatch, getState: () => RootState) => any,
  onFailure?: (error: any, dispatch: AppDispatch, getState: () => RootState) => any,
  options?: { force?: boolean }
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  options = options || {};
  options.force = options.force || false;

  const operation = getState().operations[name];
  if (!operation) {
    throw new Error(`Operation with name='${name}' is not registered.`);
  }

  if (!operation.isReady && !options.force) {
    return { succeeded: false, error: new Error(`Safe operation with name='${name}' isn't ready`) };
  }

  const endIn = (msCount: number): void => {
    setTimeout(() => {
      if (getState().operations[name].justSucceeded || getState().operations[name].justFailed) {
        dispatch(slice.actions.end(name));
      }
    }, msCount);
  };
  dispatch(slice.actions.start(name));
  try {
    const output = await action(dispatch, getState);
    dispatch(slice.actions.succeed(name));
    endIn(delays.success);
    return { succeeded: true, output };
  } catch (error) {
    dispatch(slice.actions.fail(name));
    onFailure && onFailure(error, dispatch, getState);
    console.error(`Operation '${name}' failed with`, error);
    endIn(delays.failure);
    return { succeeded: false, error };
  }
};

export const { succeed, fail } = slice.actions;

// @ts-expect-error
export const mandatoryOperation = (name: OperationName, action, onFailure?, options?) =>
  safeOperation(name, action, onFailure, Object.assign(options || {}, { force: true }));

export default slice.reducer;
