import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeatureCollection } from "@turf/helpers";

import { BoundaryVisualizationSlice } from "../slices/ui";

const initialState: BoundaryVisualizationSlice = {
  isActive: false,
  geoJSON: null
};

const slice = createSlice({
  name: "boundaryVisualization",
  initialState,
  reducers: {
    setBoundaryVisualization(state, action: PayloadAction<FeatureCollection>) {
      state.isActive = true;
      state.geoJSON = action.payload;
    },
    cleanBoundaryVisualization(state) {
      state.isActive = false;
      state.geoJSON = null;
    }
  }
});

export const { cleanBoundaryVisualization, setBoundaryVisualization } = slice.actions;

export default slice.reducer;
