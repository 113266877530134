import { isEmpty, pick } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Button, Form, Grid, Segment } from "semantic-ui-react";

import { validateInt, zoningSteps, isZeroOrPositiveNumeric } from "../../config/config";
import { actions, ownerships, resources } from "../../constants/permissions";
import * as projectCtrl from "../../controllers/projects";
import * as zoningCtrl from "../../controllers/zoning";
import { userIsAllowedToAny } from "../../helpers/permissions";
import * as projectActions from "../../store/projects";
import * as zoningActions from "../../store/zoning";
import { assignIfYetNotPresent, exists } from "../../utils";
import { defaultParkingInput } from "../config/zoningConstants";
import Steps from "../Steps";
import WizardStep from "../WizardStep";
import { ZoningSaveModal, ZoningSaveButton } from "../ZoningSave";
import { CheckboxInput, isToggleChecked } from "./../forms/CheckboxInput";
import TextInput from "./../forms/TextInput";
import WizardTextInput from "./../forms/WizardTextInput";

const actionsObj = Object.assign({}, projectActions, projectCtrl, zoningActions, zoningCtrl);

const footerButtons = props => {
  const canCreateOrUpdate = userIsAllowedToAny(
    [actions.update, actions.create],
    [ownerships.all, ownerships.own, ownerships.organization],
    resources.customZoning
  );

  return (
    <div>
      <Button
        onClick={() => {
          props.setActiveModalForm(zoningSteps["zoning_step_4"]);
        }}
      >
        Back
      </Button>

      {canCreateOrUpdate ? (
        <ZoningSaveButton confirm={props.toggleConfirmZoningFavorites} enabled={props.editZoning} />
      ) : null}

      {canCreateOrUpdate ? <Button onClick={() => props.submitParkingInputs()}>Done</Button> : null}
    </div>
  );
};

export const ParkingInputsStepFooter = connect(state => {
  return {
    editZoning: state.zoning.editZoning,
    confirmZoningFavorites: state.zoning.confirmZoningFavorites
  };
}, actionsObj)(footerButtons);

class ParkingInputsStep extends WizardStep {
  render() {
    const {
      podiumParkingToggle,
      undergroundParkingToggle,
      editMode,
      derivedZoningData,
      confirmZoningFavorites
    } = this.props;
    const stepData = [
      { title: "Zoning", active: false, disabled: false },
      { title: "Height & Density", active: false, disabled: false },
      { title: "Setbacks", active: false, disabled: false },
      { title: "Open Space", active: false, disabled: false },
      { title: "Parking", active: true, disabled: false }
    ];

    const podiumParkingIsEnabled = isToggleChecked(podiumParkingToggle);
    const undergroundParkingIsEnabled = isToggleChecked(undergroundParkingToggle);
    const originalData = editMode ? { originalData: derivedZoningData } : {};

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Steps stepData={stepData} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form onSubmit={this.props.handleSubmit}>
                <Segment>
                  <div>
                    <Field
                      name="podium_parking_enabled"
                      label="Enable Podium Parking"
                      component={CheckboxInput}
                      toggle
                      readOnly={this.readOnlyMode}
                    />
                  </div>

                  <div>
                    <br />
                    <Field
                      name="underground_parking_enabled"
                      label="Enable Subsurface Parking"
                      component={CheckboxInput}
                      toggle
                      readOnly={this.readOnlyMode}
                    />
                  </div>

                  {podiumParkingIsEnabled || undergroundParkingIsEnabled ? (
                    <div>
                      <br />
                      <Field
                        name="ratio"
                        component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label="Residential Parking Ratio (spaces per unit)"
                        validate={[isZeroOrPositiveNumeric]}
                        disabled={!podiumParkingIsEnabled && !undergroundParkingIsEnabled}
                        readOnly={this.readOnlyMode}
                        {...originalData}
                      />
                    </div>
                  ) : null}

                  {undergroundParkingIsEnabled ? (
                    <div>
                      <br />
                      <Field
                        name="max_level_underground_parking"
                        component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label="Maximum Subsurface Levels"
                        validate={[validateInt]}
                        readOnly={this.readOnlyMode}
                        {...originalData}
                      />
                    </div>
                  ) : null}

                  <>
                    <br />
                    <label className="textarea-label">Parking Notes:</label>
                    <Field
                      name="parking_notes"
                      component="textarea"
                      label="Parking Notes"
                      rows={3}
                      readOnly={this.readOnlyMode}
                      {...originalData}
                    />
                  </>
                </Segment>

                <ZoningSaveModal
                  open={confirmZoningFavorites}
                  cancelAction={() => {
                    this.props.toggleConfirmZoningFavorites(false);
                  }}
                  saveAction={() => {
                    this.props.submitParkingInputs();
                  }}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const selector = formValueSelector("ParkingInputsStepForm");

const mapStateToProps = state => {
  const ratio = state.zoning.zoningValues.ratio;
  const podiumParkingHasToBeEnabled = exists(ratio) && ratio > 0;
  return {
    initialValues: pick(
      assignIfYetNotPresent(
        {},
        state.zoning.selectedZoning,
        { podium_parking_enabled: podiumParkingHasToBeEnabled },
        defaultParkingInput
      ),
      [
        "podium_parking_enabled",
        "underground_parking_enabled",
        "ratio",
        "max_level_underground_parking",
        "parking_notes"
      ]
    ),
    podiumParkingToggle: selector(state, "podium_parking_enabled"),
    undergroundParkingToggle: selector(state, "underground_parking_enabled"),
    editMode: !isEmpty(state.zoning.zoningInitialValues),
    confirmZoningFavorites: state.zoning.confirmZoningFavorites,
    derivedZoningData: state.zoning.derivedZoningData
  };
};

const ParkingInputsStepForm = reduxForm({
  form: "ParkingInputsStepForm",
  destroyOnUnmount: false
})(ParkingInputsStep);

export default connect(mapStateToProps, actionsObj)(ParkingInputsStepForm);
