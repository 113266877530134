import React from "react";
import { Layer, Feature, Source } from "react-mapbox-gl";
import { connect } from "react-redux";

const actions = Object.assign({}, {});

class FloorVisualization extends React.Component {
  render() {
    const { buildingId, feature, floorNumber, color, bottomHeight, topHeight } = this.props;
    const layerId = `layer-building-${buildingId}-floor-${floorNumber}`;
    const sourceId = `source-building-${buildingId}-floor-${floorNumber}`;

    return (
      <>
        <Source
          id={sourceId}
          geoJsonSource={{
            type: "geojson",
            data: feature
          }}
        />
        <Layer
          sourceId={sourceId}
          type="fill-extrusion"
          id={layerId}
          layout={{ visibility: "visible" }}
          paint={{
            "fill-extrusion-color": color,
            "fill-extrusion-opacity": 1,
            "fill-extrusion-height": topHeight,
            "fill-extrusion-base": bottomHeight
          }}
        >
          <Feature coordinates={feature.geometry.coordinates} />
        </Layer>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    map: state.map.map
  };
};

export default connect(mapStateToProps, actions)(FloorVisualization);
