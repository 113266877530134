import { isNullOrUndefined } from "util";
import { groupBy } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Modal, Button, Segment, Grid, Divider } from "semantic-ui-react";

import { floorPlanSvgHeight, floorPlanSvgWidth, unitLabels } from "../../config/config";
import { getShortAreaLabel } from "../../config/utils";
import { palette } from "../../constants/palette";
import * as buildingCtrl from "../../controllers/buildings";
import * as buildingsActions from "../../store/buildings";
import { getSelectedBuilding } from "../../store/selectors/projects";
import { BuildingTypeCode, isBuilding } from "../../types/buildings";
import FloorPlanSVG from "./FloorPlanSVG";

const FloorPlanModal = props => {
  const {
    floorplanVisualizationIsOpen,
    selectedBuilding,
    informationIdx,
    information,
    isMetric
  } = props;

  if (!isBuilding(selectedBuilding)) return <div />;

  const legendRowData = [
    {
      colorKey: "unit0",
      dataKey: "unit_0",
      buildingKey: "studio"
    },
    {
      colorKey: "unit1",
      dataKey: "unit_1",
      buildingKey: "onebed"
    },
    {
      colorKey: "unit2",
      dataKey: "unit_2",
      buildingKey: "twobed"
    },
    {
      colorKey: "unit3",
      dataKey: "unit_3",
      buildingKey: "threebed"
    },
    {
      colorKey: "unit4",
      dataKey: "unit_4",
      buildingKey: "fourbed"
    }
  ];
  legendRowData.forEach((row, i) => (row.label = unitLabels.apartments.shortNumbers.singular[i]));

  const legendRows = legendRowData.map((row, idx) => {
    return selectedBuilding.geometry && information.length > 0 ? (
      <Grid.Row key={idx}>
        <Grid.Column width={10}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <svg height="40" width="40">
              <rect
                width="40"
                height="40"
                style={{
                  fill: palette[row.colorKey],
                  stroke: "black",
                  strokeWidth: 2
                }}
              />
            </svg>
            <span style={{ marginLeft: "10px" }}>{row.label}</span>
          </div>
        </Grid.Column>
        <Grid.Column
          width={3}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <p>
            {selectedBuilding.geometry.sub_buildings.reduce((accumulator, currentValue, subIdx) => {
              const blockIdx = information[informationIdx].blockIndexes[subIdx];
              let thisSubBuildingFloorAmount = 0;
              if (!isNullOrUndefined(blockIdx)) {
                const correspondingTypeFeatures = groupBy(
                  currentValue[blockIdx].geojson.features,
                  "properties.type"
                );
                if (correspondingTypeFeatures.hasOwnProperty(row.dataKey)) {
                  thisSubBuildingFloorAmount = correspondingTypeFeatures[row.dataKey].length;
                }
              }
              const total = accumulator + thisSubBuildingFloorAmount;
              return total;
            }, 0)}
          </p>
        </Grid.Column>
        <Grid.Column
          width={3}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <p>{selectedBuilding[row.buildingKey]}</p>
        </Grid.Column>
      </Grid.Row>
    ) : (
      <div />
    );
  });

  const unitLegendRows = () => {
    return Object.keys(information[informationIdx].unitProperties).map((key, idx) => {
      return (
        <Grid.Row key={idx}>
          <Grid.Column width={6}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p>{information[informationIdx].unitProperties[key].id}</p>
            </div>
          </Grid.Column>
          <Grid.Column
            width={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <p>
              {information[informationIdx].unitProperties[key].area + " " + getShortAreaLabel()}
            </p>
          </Grid.Column>
          <Grid.Column
            width={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <p>
              {information[informationIdx].unitProperties[key].total_sf + " " + getShortAreaLabel()}
            </p>
          </Grid.Column>
        </Grid.Row>
      );
    });
  };

  const nonResidentialLevels =
    selectedBuilding.total_podium_parking_levels > 0
      ? selectedBuilding.total_podium_parking_levels
      : 1;

  const ApartmentModal = () => {
    return (
      <Modal open={floorplanVisualizationIsOpen} size="large">
        <Modal.Header>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start"
            }}
          >
            <div>
              <h1>Floor Plan</h1>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                basic
                circular
                icon="chevron left"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  props.decreaseVisualizationFloor();
                }}
              />
              <div
                style={{
                  border: "solid 1px",
                  borderRadius: "5px",
                  paddingLeft: "20px",
                  paddingRight: "20px"
                }}
              >
                <h1>
                  Floor{" "}
                  {props.information[props.informationIdx].startingFloor + nonResidentialLevels} to{" "}
                  {props.information[props.informationIdx].endingFloor + nonResidentialLevels}
                </h1>
              </div>
              <Button
                basic
                circular
                icon="chevron right"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  props.increaseVisualizationFloor();
                }}
              />
            </div>
            <div>
              <Button
                style={{ float: "right" }}
                circular
                icon="cancel"
                onClick={() => {
                  props.toggleFloorLayout(false);
                }}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Segment>
                  Note: Building layout is used to optimize unit count only and is not intended to
                  provide a realistic floor plan.
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10}>
                <Segment>
                  <FloorPlanSVG
                    selectedBuilding={selectedBuilding}
                    informationIdx={informationIdx}
                    information={information}
                    height={floorPlanSvgHeight}
                    width={floorPlanSvgWidth}
                    showTiles={true}
                    showNorthArrow={true}
                    isMetric={isMetric}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column width={6}>
                <Segment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={10}>
                        <h5>Color Key</h5>
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <h5>This Floor</h5>
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <h5>Building Total</h5>
                      </Grid.Column>
                    </Grid.Row>
                    <Divider />
                    {legendRows}
                    <Grid.Row>
                      <Grid.Column width={10}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <svg height="40" width="40">
                            <rect
                              width="40"
                              height="40"
                              style={{
                                fill: palette.interiorCirculation,
                                stroke: "black",
                                strokeWidth: 2
                              }}
                            />
                          </svg>
                          <span style={{ marginLeft: "10px" }}>Interior Circulation</span>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={10}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <svg height="40" width="40">
                            <rect
                              width="40"
                              height="40"
                              style={{
                                fill: palette.interiorOpenSpace,
                                stroke: "black",
                                strokeWidth: 2
                              }}
                            />
                          </svg>
                          <span style={{ marginLeft: "10px" }}>Interior Open Space</span>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  };

  const NPlexModal = () => {
    return (
      <Modal open={floorplanVisualizationIsOpen} size="large">
        <Modal.Header>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start"
            }}
          >
            <div>
              <h1>Site Plan</h1>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                basic
                circular
                icon="chevron left"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  props.decreaseVisualizationFloor();
                }}
              />
              <div
                style={{
                  border: "solid 1px",
                  borderRadius: "5px",
                  paddingLeft: "20px",
                  paddingRight: "20px"
                }}
              >
                <h1>
                  Floor {props.information[props.informationIdx].startingFloor} to{" "}
                  {props.selectedBuilding.stories}
                </h1>
              </div>
              <Button
                basic
                circular
                icon="chevron right"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  props.increaseVisualizationFloor();
                }}
              />
            </div>
            <div>
              <Button
                style={{ float: "right" }}
                circular
                icon="cancel"
                onClick={() => {
                  props.toggleFloorLayout(false);
                }}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Segment>
                  Note: Building layout is used to optimize unit count only and is not intended to
                  provide a realistic floor plan.
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10}>
                <Segment>
                  <FloorPlanSVG
                    selectedBuilding={selectedBuilding}
                    informationIdx={informationIdx}
                    information={information}
                    height={floorPlanSvgHeight}
                    width={floorPlanSvgWidth}
                    showTiles={true}
                    showNorthArrow={true}
                    isMetric={isMetric}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column width={6}>
                <Segment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <h5>Unit #</h5>
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <h5>This Floor</h5>
                      </Grid.Column>
                      <Grid.Column width={6}>
                        <h5>Unit Total</h5>
                      </Grid.Column>
                    </Grid.Row>
                    <Divider />
                    {unitLegendRows()}
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  };

  const modal =
    selectedBuilding.building_type.code === BuildingTypeCode.apartment
      ? ApartmentModal
      : NPlexModal;

  return selectedBuilding.hasOwnProperty("geometry") && information.length > 0 ? modal() : <div />;
};

const mapStateToProps = state => {
  return {
    selectedBuilding: getSelectedBuilding(state),
    informationIdx: state.buildings.visualizationFloorIdx,
    information: state.buildings.floorplanVisualizationInformation,
    isMetric: state.users.metric
  };
};

export default connect(
  mapStateToProps,
  Object.assign({}, buildingsActions, buildingCtrl)
)(FloorPlanModal);
