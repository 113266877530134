import React from "react";
import { change } from "redux-form";
import { Form, Input, Popup } from "semantic-ui-react";
import { emptyHref, exists } from "../../../src/utils";
import { isNullOrUndefinedOrBlank } from "../../config/utils";
import { Footnote } from "./Footnote.js";

const TextInput = ({
  label,
  defaultValue,
  inline,
  originalData,
  unitLabel,
  footnoteText,
  meta: { error, touched, dispatch, form },
  ...rest
}) => {
  // Note (@nicoluce): A clone is needed since originalData comes
  //                   from directly from the state and can't be modified
  const originalDataClone = { ...originalData };
  const fieldName = rest.input.name;
  let value = rest.input.value;
  let isNotOriginal = false;
  if (exists(originalDataClone)) {
    value = isNullOrUndefinedOrBlank(value) ? null : value;
    originalDataClone[fieldName] = isNullOrUndefinedOrBlank(originalDataClone[fieldName])
      ? null
      : originalDataClone[fieldName];
    isNotOriginal =
      String(originalDataClone[fieldName]) !== String(value) &&
      parseFloat(originalDataClone[fieldName]) !== parseFloat(value);
  }
  const editLabel = (
    <span>
      <a href={emptyHref}>(Edited)</a>
      {`  ${label}  `}
    </span>
  );

  const editPopup = (
    <Popup trigger={editLabel} flowing hoverable>
      <p>{`Original Value: ${originalDataClone[fieldName] ?? ""}`}</p>
      <p style={{ float: "right" }}>
        <a
          href={emptyHref}
          onClick={() => {
            if (fieldName === "max_height") {
              dispatch(
                change(form, "effective_height", originalDataClone["effective_height"] ?? "")
              );
            } else if (fieldName === "ground_floor_ceiling_height") {
              dispatch(
                change(
                  form,
                  "effective_ground_floor_height",
                  originalDataClone["effective_ground_floor_height"] ?? ""
                )
              );
            }
            dispatch(change(form, fieldName, originalDataClone[fieldName] ?? ""));
          }}
        >
          Revert
        </a>
      </p>
    </Popup>
  );
  return (
    <Form.Field inline={inline} defaultValue={defaultValue} error={error && touched}>
      <label>{isNotOriginal ? editPopup : label}</label>
      <Input
        label={unitLabel}
        {...rest}
        style={isNotOriginal ? { borderRadius: "5px", border: "solid purple 2px" } : {}}
      />
      {error && touched ? <span style={{ color: "red" }}>*{error}</span> : ""}
      <Footnote footnoteText={footnoteText} />
    </Form.Field>
  );
};

export default TextInput;
