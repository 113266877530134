import React from "react";
import { Layer, Feature } from "react-mapbox-gl";
import { AnyShapeCoordinates } from "react-mapbox-gl/lib/util/types";
import { parcelVisualizationsSeparatorLayerId } from "../config/map";
import { palette } from "../constants/palette";
import { visualizationsLayerIds } from "./mapVisualization";

class ParcelVisualization extends React.Component<{
  parcelCoordinates: AnyShapeCoordinates;
  buildingId: number;
}> {
  render() {
    const { parcelCoordinates, buildingId } = this.props;
    return (
      <div>
        <Layer
          type="fill"
          id={`${visualizationsLayerIds.setbackedParcel}-${buildingId}`}
          layout={{ visibility: "visible" }}
          paint={{ "fill-color": palette.reducedParcel }}
          before={parcelVisualizationsSeparatorLayerId}
        >
          <Feature coordinates={parcelCoordinates} />
        </Layer>
      </div>
    );
  }
}

export default ParcelVisualization;
