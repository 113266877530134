import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import { RootState } from "../store";
import { setActiveModalForm, toggleWizardModal } from "../store/projects";
import { getSelectedBuildingId } from "../store/selectors/projects";
import { closeProformaUpdatedModal, openProformaUpdatedModal } from "../store/ui";

const projectActions = { setActiveModalForm, toggleWizardModal };
const uiActions = { closeProformaUpdatedModal, openProformaUpdatedModal };

const mapStateToProps = (state: RootState) => ({
  open: state.ui.index.displayProformaUpdatedModal,
  selectedBuildingId: getSelectedBuildingId(state)
});

export const connector = connect(mapStateToProps, { ...projectActions, ...uiActions });

type PropsFromRedux = ConnectedProps<typeof connector>;

const ProformaUpdatedModal: React.FunctionComponent<PropsFromRedux> = props => {
  const { open } = props;

  const editProformaClick = () => {
    props.closeProformaUpdatedModal();
    props.setActiveModalForm("DevCostsStep");
    props.toggleWizardModal(true);
  };

  return (
    <Modal
      className="informative-modal"
      size="small"
      open={open}
      closeIcon={true}
      onClose={props.closeProformaUpdatedModal}
      dimmer={"blurring"}
    >
      <Modal.Header>{"Proforma inputs updated!"}</Modal.Header>
      <Modal.Content>
        <label className="content">
          {
            "The change in a number of stories would change the construction costs, so these values have been updated to reflect this change."
          }
        </label>
        <label className="content">
          {
            "You can close to accept these changes, or edit to replace them with your own assumptions."
          }
        </label>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={editProformaClick} content="Edit Proforma" />
        <Button primary onClick={props.closeProformaUpdatedModal} content="Close" />
      </Modal.Actions>
    </Modal>
  );
};

export default connector(ProformaUpdatedModal);
