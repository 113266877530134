import axios from "axios";
import { baseUrl } from "../../config/urls";

export const asyncGetProgram = programId =>
  new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/programs/${programId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncGetProgramList = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/programs`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncPostNewProgram = dataToSend =>
  new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/programs`, dataToSend)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
