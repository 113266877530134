import axios from "axios";
import { baseUrl } from "../../config/urls";
import { DevelopmentSite } from "../../types/projects";

export const asyncUpdateSegments = (
  siteId: number,
  segments: { front: number[]; sides: number[]; rear: number[] }
): Promise<{ updated_site: DevelopmentSite }> =>
  new Promise((resolve, reject) => {
    const url = `${baseUrl}/sites/${siteId}`;
    axios
      .put<{ updated_site: DevelopmentSite }>(url, { segments_indexes: segments })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncUpdateParcelUsage = (
  siteId: number,
  usageId: number
): Promise<{ updated_site: DevelopmentSite }> =>
  new Promise((resolve, reject) => {
    const url = `${baseUrl}/sites/${siteId}`;
    axios
      .put<{ updated_site: DevelopmentSite }>(url, { parcel_usage_id: usageId })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
