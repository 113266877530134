export const levelTypes = {
  residentialApartment: "residentialApartment",
  residentialNPlex: "residentialNPlex",
  empty: "empty",
  podiumParking: "podiumParking"
};

/*
 * Object to store the visualizations layers ids so that we can clean up if needed.
 * */
export const visualizationsLayerIds = {
  fullParcel: "fullParcel",
  fullParcelOutline: "fullParcelOutline",
  setbackedParcel: "setbackedParcel",
  buildingLevels: {
    main: [],
    pdf: []
  },
  getTargetBuildingLevelsKey: handlingPDF => (handlingPDF ? "pdf" : "main"),
  getBuildingLevels: function(handlingPDF) {
    return this.buildingLevels[this.getTargetBuildingLevelsKey(handlingPDF)];
  },
  resetBuildingLevels: function(handlingPDF) {
    this.buildingLevels[this.getTargetBuildingLevelsKey(handlingPDF)] = [];
  }
};
