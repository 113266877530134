/**
 * Created by jmartinez on 8/24/18.
 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Login from "../components/Login";
import * as userCtrl from "../controllers/users";

class LoginView extends React.Component {
  handleLogin(values) {
    this.props.loginUser(
      values.username,
      values.password,
      values.captchaToken,
      this.props.location.pathname
    );
  }

  render() {
    return (
      <Login
        onSubmit={values => {
          this.handleLogin(values);
        }}
      />
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps, userCtrl)(LoginView));
