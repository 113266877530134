import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Segment, Form, Divider, Grid, Button, Radio } from "semantic-ui-react";
import {
  validatePercentage,
  isZeroOrPositiveNumeric,
  dollarsPerAreaConvertUnitFactor
} from "../../config/config";
import { developmentCostsDefaults } from "../../config/defaultValues";
import { getDollarsPerAreaLabel } from "../../config/utils";
import {
  devCostPerAreaFields,
  handleDevCostsChangeUnit,
  submitDevCosts
} from "../../controllers/proforma";
import { is } from "../../lib";
import { RootState } from "../../store";
import { getSelectedBuilding, getSelectedBuildingId } from "../../store/selectors/projects";
import { Building, BuildingTypeCode } from "../../types/buildings";
import { DevcostsData, hasCompleteInputs } from "../../types/proforma";
import { Region } from "../../types/region";
import { exists } from "../../utils";
import { CheckboxInput, CheckboxInputField } from "../forms/CheckboxInput";
import TextInput from "../forms/TextInput";
import Steps from "../Steps";
import WizardStep from "../WizardStep";
import { DemoMessage } from "./DemoMessage";

const footerMapStateToProps = (state: RootState) => {
  return {
    isDemoBuilding: is.null(getSelectedBuildingId(state))
  };
};

const footerConnector = connect(footerMapStateToProps, { submitDevCosts });
type FooterProps = ConnectedProps<typeof footerConnector>;

const footerButtons: React.FC<FooterProps> = props => {
  return (
    <div>
      <DemoMessage isDemoBuilding={props.isDemoBuilding} />
      <Button
        onClick={() => {
          props.submitDevCosts();
        }}
      >
        Next
      </Button>
    </div>
  );
};

export const DevCostsStepFooter = footerConnector(footerButtons);

const mapStateToProps = (state: RootState) => {
  const selectedBuilding = getSelectedBuilding(state) as Building;
  const devcosts = selectedBuilding.devcosts as DevcostsData;
  const parkingEnabled = selectedBuilding.total_parking_slots > 0;
  const useFullDetail = exists(devcosts) && hasCompleteInputs(devcosts.inputs);
  const metricSelection = state.proforma.devcostsMetric ?? state.users.metric;

  const stories = selectedBuilding.stories;
  const defaultValuesByRegion =
    developmentCostsDefaults[
      (state.regions.selectedRegion as Region).name as "Vancouver" | "Toronto"
    ];
  const unitCost =
    defaultValuesByRegion?.unitCostPerHeight?.find(
      range => range.start <= stories && stories < range.end
    )?.unitCost ?? "";
  let initialValues: any = {
    ...defaultValuesByRegion?.rest,
    residential_construction_cost_per_sf: unitCost
  };

  if (exists(devcosts)) {
    initialValues = {
      ...devcosts.inputs,
      soft_cost_percentage: hasCompleteInputs(devcosts.inputs)
        ? (devcosts.inputs.soft_cost_percentage * 100).toFixed()
        : ""
    };
  }
  initialValues.useFullDetail = useFullDetail;
  if (!parkingEnabled) {
    [
      "podium_parking_space_construction_cost",
      "underground_parking_space_construction_cost"
    ].forEach(field => {
      initialValues[field] = initialValues[field] ?? 0;
    });
  }

  if (metricSelection !== state.users.metric) {
    // Toggle is different from region's metric. We need to convert initial values
    const factor = dollarsPerAreaConvertUnitFactor(metricSelection);
    devCostPerAreaFields.forEach(field => {
      if (typeof initialValues[field] === "number") {
        initialValues[field] = Math.round(initialValues[field] * factor);
      }
    });
  }

  return {
    selectedBuilding: selectedBuilding,
    metric: metricSelection,
    initialValues
  };
};

const connector = connect(mapStateToProps, { handleDevCostsChangeUnit });

type DevCostsStepProps = ConnectedProps<typeof connector>;
type DevCostsStepFormProps = DevCostsStepProps & InjectedFormProps<{}, DevCostsStepProps>;
type DevCostsStepState = { useFullDetail: boolean };

class DevCostsStep extends WizardStep<DevCostsStepFormProps, DevCostsStepState> {
  constructor(props: DevCostsStepFormProps) {
    super(props);
    const { selectedBuilding } = props;
    const devcosts = selectedBuilding.devcosts;
    const useFullDetail =
      devcosts !== null && "inputs" in devcosts && hasCompleteInputs(devcosts.inputs);
    this.state = {
      useFullDetail
    };
  }

  handleMetricChange() {
    this.props.handleDevCostsChangeUnit(!this.props.metric);
  }

  render() {
    const { handleSubmit, metric } = this.props;
    const { useFullDetail } = this.state;

    const stepData = [
      { title: "Development Costs", active: true, disabled: false },
      { title: "Operating Period Assumptions", active: false, disabled: true },
      { title: "Sources of Funds", active: true, disabled: true }
    ];

    const isApartment =
      this.props.selectedBuilding.building_type.code === BuildingTypeCode.apartment;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Steps stepData={stepData} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form onSubmit={handleSubmit}>
                <Segment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Field
                          name="development_cost_per_unit"
                          component={TextInput}
                          label="Total Development Cost per Unit ($)"
                          disabled={useFullDetail}
                          unitLabel="$ per unit"
                          labelPosition="right"
                          readOnly={this.readOnlyMode}
                        />
                        <Divider />
                        <CheckboxInputField
                          name="useFullDetail"
                          label="Development Cost Pro Forma"
                          component={CheckboxInput}
                          toggle
                          onChange={(e, checked) => {
                            this.setState({
                              useFullDetail: checked
                            });
                          }}
                          readOnly={this.readOnlyMode}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <label>Input costs per:</label>
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <Radio
                          name="devcostMetric"
                          label="square meter"
                          checked={this.props.metric}
                          disabled={!useFullDetail}
                          onChange={() => this.handleMetricChange()}
                        />
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <Radio
                          name="devcostMetric"
                          label="square foot"
                          checked={!this.props.metric}
                          disabled={!useFullDetail}
                          onChange={() => this.handleMetricChange()}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <Field
                          name="residential_construction_cost_per_sf"
                          component={TextInput}
                          label="Residential Construction Cost"
                          disabled={!useFullDetail}
                          unitLabel={getDollarsPerAreaLabel(metric)}
                          labelPosition="right"
                          fluid
                          readOnly={this.readOnlyMode}
                        />
                        {isApartment ? (
                          <Field
                            name="commercial_construction_cost_per_sf"
                            component={TextInput}
                            label="Commercial Construction Cost"
                            disabled={!useFullDetail}
                            unitLabel={getDollarsPerAreaLabel(metric)}
                            labelPosition="right"
                            fluid
                            readOnly={this.readOnlyMode}
                          />
                        ) : null}
                        <Field
                          name="acquistion_cost"
                          component={TextInput}
                          label="Acquisition Cost"
                          disabled={!useFullDetail}
                          unitLabel="$"
                          labelPosition="right"
                          fluid
                          readOnly={this.readOnlyMode}
                        />
                        {isApartment ? (
                          <React.Fragment>
                            <Field
                              name="podium_parking_space_construction_cost"
                              component={TextInput}
                              label="Podium Parking Construction Cost"
                              disabled={!useFullDetail}
                              unitLabel={getDollarsPerAreaLabel(metric)}
                              labelPosition="right"
                              fluid
                              readOnly={this.readOnlyMode}
                              validate={[isZeroOrPositiveNumeric]}
                            />
                            <Field
                              name="underground_parking_space_construction_cost"
                              component={TextInput}
                              label="Subsurface Parking Construction Cost"
                              disabled={!useFullDetail}
                              unitLabel={getDollarsPerAreaLabel(metric)}
                              labelPosition="right"
                              fluid
                              readOnly={this.readOnlyMode}
                              validate={[isZeroOrPositiveNumeric]}
                            />
                          </React.Fragment>
                        ) : null}
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Field
                          name="impact_fees_per_unit"
                          component={TextInput}
                          label="Impact Fees Per Unit"
                          disabled={!useFullDetail}
                          unitLabel="$ per unit"
                          labelPosition="right"
                          fluid
                          readOnly={this.readOnlyMode}
                        />
                        <Field
                          name="developer_fee"
                          component={TextInput}
                          label="Developer Fee"
                          disabled={!useFullDetail}
                          unitLabel="$"
                          labelPosition="right"
                          fluid
                          readOnly={this.readOnlyMode}
                        />
                        <Field
                          name="soft_cost_percentage"
                          component={TextInput}
                          label="Soft Costs (% of hardcosts)"
                          disabled={!useFullDetail}
                          unitLabel="%"
                          labelPosition="right"
                          fluid
                          readOnly={this.readOnlyMode}
                          validate={[validatePercentage]}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const DevCostsStepForm = reduxForm<{}, DevCostsStepProps>({
  form: "DevCostsStepForm",
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(DevCostsStep);

export default connector(DevCostsStepForm);
