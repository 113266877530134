import React from "react";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";

import { RootState } from "../../../store";
import {
  toggleBuildingsLayerVisibility,
  toggleLayersControls,
  toggleLegendVisibility
} from "../../../store/map";
import { toggleParcelsLayerVisibility, toggleZoningsLayerVisibility } from "../../../store/regions";
import { regionSelectorCreator } from "../../../store/selectors/region";
import { DataLayer } from "../../../types/region";
import BaseMapSwitcher from "./BaseMapSwitcher";
import BuildingsLayerSegment from "./BuildingsLayerSegment";
import Header from "./Header";
import ParcelsLayerSegment from "./ParcelsLayerSegment";
import ZoningsLayerSegment from "./ZoningsLayerSegment";

class PureMenu extends React.Component<{
  buildingsLayerIsVisible: boolean;
  menuIsOpen: boolean;
  parcelsDataLayer: DataLayer;
  zoningsDataLayer: DataLayer;
  closeMenu: any;
  toggleBuildingsLayerVisibility: any;
  toggleLegendVisibility: any;
  toggleParcelsLayerVisibility: any;
  toggleZoningsLayerVisibility: any;
}> {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: "5px",
          right: "55px",
          zIndex: 99,
          width: "300px",
          display: `${this.props.menuIsOpen ? "block" : "none"}`
        }}
      >
        <Segment.Group>
          <Header closeMenu={this.props.closeMenu} />
          <BaseMapSwitcher />
          <BuildingsLayerSegment
            isVisible={this.props.buildingsLayerIsVisible}
            toggleVisibility={this.props.toggleBuildingsLayerVisibility}
          />
          <ParcelsLayerSegment
            isVisible={this.props.parcelsDataLayer.isVisible}
            toggleVisibility={this.props.toggleParcelsLayerVisibility}
          />
          <ZoningsLayerSegment
            dataLayer={this.props.zoningsDataLayer}
            isVisible={this.props.zoningsDataLayer.isVisible}
            toggleLayerVisibility={this.props.toggleZoningsLayerVisibility}
            toggleLegendVisibility={this.props.toggleLegendVisibility}
          />
        </Segment.Group>
      </div>
    );
  }
}

export const Menu = connect(
  (state: RootState) => {
    const { dataLayers } = regionSelectorCreator(state.regions);
    return {
      menuIsOpen: state.map.showLayerControls,
      buildingsLayerIsVisible: state.map.showBuildingsLayer,
      parcelsDataLayer: dataLayers.byName("parcels"),
      zoningsDataLayer: dataLayers.byName("zonings")
    };
  },
  {
    closeMenu: toggleLayersControls,
    toggleBuildingsLayerVisibility,
    toggleLegendVisibility,
    toggleParcelsLayerVisibility,
    toggleZoningsLayerVisibility
  }
)(PureMenu);
