export const env = {
  _PROD: "PROD",
  _TEST: "TEST",
  get deployedEnvironment() {
    return process.env.REACT_APP_ENV || this._PROD;
  },
  get isProductionEnvironment() {
    return this.deployedEnvironment === this._PROD;
  },
  /** Deployed -dev environment */
  get isStagingEnvironment() {
    return process.env.NODE_ENV === "production" && !this.isProductionEnvironment;
  },
  get isTestEnvironment() {
    return this.deployedEnvironment === this._TEST;
  },
  get errorReportShouldBeShown() {
    return !this.isProductionEnvironment;
  }
};

export default env;
