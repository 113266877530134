/**
 * Created by jmartinez on 9/18/18.
 */

import { isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { required } from "../../config/config";
import * as projectCtrl from "../../controllers/projects";
import * as zoningCtrl from "../../controllers/zoning";
import TextInput from "../forms/TextInput";
import WizardTextInput from "../forms/WizardTextInput";
import Steps from "../Steps";
import WizardStep from "../WizardStep";

const actionsObj = Object.assign({}, zoningCtrl, projectCtrl);

const footerButtons = props => {
  return (
    <div>
      <Button
        onClick={() => {
          props.zoningSubmissionStep();
        }}
      >
        Next
      </Button>
    </div>
  );
};

export const ZoningStepFooter = connect(() => {
  return {};
}, actionsObj)(footerButtons);

class ZoningStep extends WizardStep {
  render() {
    const { handleSubmit, editMode, editZoning, derivedZoningData } = this.props;

    const stepData = [
      { title: "Zoning", active: true, disabled: false },
      { title: "Height & Density", active: false, disabled: true },
      { title: "Setbacks", active: false, disabled: true },
      { title: "Open Space", active: false, disabled: true },
      { title: "Parking", active: false, disabled: true }
    ];
    const originalData = editMode ? { originalData: derivedZoningData } : {};
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Steps stepData={stepData} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form onSubmit={handleSubmit}>
                <Segment>
                  {!editZoning ? (
                    <Field
                      name="name"
                      component={editMode ? WizardTextInput : TextInput}
                      label="Zoning Name:"
                      validate={[required]}
                      readOnly={this.readOnlyMode}
                    />
                  ) : null}

                  <Field
                    name="description"
                    component={editMode ? WizardTextInput : TextInput}
                    label="Zoning Description:"
                    {...originalData}
                    readOnly={this.readOnlyMode}
                  />
                  <Field
                    name="planning_code_reference"
                    component={editMode ? WizardTextInput : TextInput}
                    label="Planning Code Reference:"
                    {...originalData}
                    readOnly={this.readOnlyMode}
                  />
                  <label className="textarea-label">General Zoning Notes:</label>
                  <Field
                    name="notes"
                    component="textarea"
                    label="General Zoning Notes:"
                    rows={3}
                    readOnly={this.readOnlyMode}
                  />
                  <br />
                  <label className="textarea-label">Other Principal Uses:</label>
                  <Field
                    name="other_principal_uses"
                    component="textarea"
                    label="Other Principal Uses:"
                    readOnly={this.readOnlyMode}
                  />
                  <br />
                  <label className="textarea-label">Other Conditional Uses:</label>
                  <Field
                    name="other_conditional_uses"
                    component="textarea"
                    label="Other Conditional Uses:"
                    readOnly={this.readOnlyMode}
                  />
                </Segment>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    initialValues: state.zoning.zoningInitialValues,
    editMode: !isEmpty(state.zoning.zoningInitialValues),
    editZoning: state.zoning.editZoning,
    derivedZoningData: state.zoning.derivedZoningData
  };
};

const ZoningStepForm = reduxForm({
  form: "ZoningStepForm",
  destroyOnUnmount: false
})(ZoningStep);

export default connect(mapStateToProps, actionsObj)(ZoningStepForm);
