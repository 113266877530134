import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ParcelUsages, ParcelUsagesSlice } from "../slices/ui";

const initialState: ParcelUsagesSlice = {
  parcelUsages: {}
};

const slice = createSlice({
  name: "parcelUsages",
  initialState,
  reducers: {
    setParcelUsages(state, action: PayloadAction<ParcelUsages>) {
      state.parcelUsages = action.payload;
    }
  }
});

export const { setParcelUsages } = slice.actions;

export default slice.reducer;
