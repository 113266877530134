import axios from "axios";
import { baseUrl } from "../../config/urls";
import {
  DevcostsData,
  OperatingInput,
  OperatingOutput,
  SourceOfFundsInput,
  SourceOfFundsOutput
} from "../../types/proforma";

export const asyncPutDevCosts = (
  buildingId: number,
  inputData: DevcostsData["inputs"]
): Promise<{ building_id: number; development_costs: DevcostsData }> =>
  new Promise((resolve, reject) => {
    axios
      .put<{ building_id: number; development_costs: DevcostsData }>(
        `${baseUrl}/development_costs/${buildingId}`,
        inputData
      )
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncPutOperating = (
  buildingId: number,
  inputData: OperatingInput
): Promise<{
  building_id: number;
  operating_period_assumptions: { inputs: OperatingInput; outputs: OperatingOutput };
}> =>
  new Promise((resolve, reject) => {
    axios
      .put<{
        building_id: number;
        operating_period_assumptions: { inputs: OperatingInput; outputs: OperatingOutput };
      }>(`${baseUrl}/operating_period_assumptions/${buildingId}`, inputData)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncPutSourceOfFunds = (
  buildingId: number,
  inputData: SourceOfFundsInput
): Promise<{
  building_id: number;
  source_of_funds: { inputs: SourceOfFundsInput; outputs: SourceOfFundsOutput };
}> =>
  new Promise((resolve, reject) => {
    axios
      .put<{
        building_id: number;
        source_of_funds: { inputs: SourceOfFundsInput; outputs: SourceOfFundsOutput };
      }>(`${baseUrl}/source_of_funds/${buildingId}`, inputData)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
