import MapboxPoint from "@mapbox/point-geometry";
import { LineString, Properties, Feature, Point, lineString, point } from "@turf/helpers";
import {
  explode,
  distance,
  Units,
  nearest,
  pointToLineDistance,
  kinks,
  bearing,
  transformRotate,
  Position,
  destination
} from "@turf/turf";
import { Map } from "mapbox-gl";
import { DrawFeature } from "./commonTypes";
import { AngleSnappingValue } from "./constants";

export type MapWithListeners = Map & { _listeners: { [type: string]: EventListener[] } };
export const removeAllEventListenerOfType = (map: MapWithListeners, type: string) =>
  (map._listeners[type] = []);

export const removeRepeatedLastCoord = (coordinates: Position[]) => {
  const first = coordinates[0];
  let last = coordinates[coordinates.length - 1];
  while (coordinates.length > 0 && first[0] === last[0] && first[1] === last[1]) {
    coordinates.pop();
    last = coordinates[coordinates.length - 1];
  }
  return coordinates;
};

export const isFeatureIntersectingItself = (lineStringFeature: DrawFeature) => {
  return kinks(lineStringFeature).features.length > 0;
};

export const angleBetween = (
  startingPoint: [number, number],
  midPoint: [number, number],
  endPoint: [number, number]
) => {
  const AO = bearing(startingPoint, midPoint);
  const BO = bearing(endPoint, midPoint);

  return (AO % 360) - (BO % 360);
};

export const snapAtAngle = (
  A: [number, number],
  O: [number, number],
  B: [number, number],
  angleSnapValue = AngleSnappingValue
) => {
  const expectedAngle = -1 * Math.round(angleBetween(A, O, B) / angleSnapValue) * angleSnapValue;
  const rotatedLine = transformRotate(lineString([A, O]), expectedAngle, {
    pivot: O,
    mutate: false
  });

  const R = rotatedLine.geometry?.coordinates[0];

  const OB = distance(O, B, { units: "meters" });
  const OR = distance(O, R, { units: "meters" });
  const destBearing =
    OB < OR
      ? bearing(point(R), point(rotatedLine.geometry?.coordinates[1]))
      : bearing(point(rotatedLine.geometry?.coordinates[1]), point(R));

  return destination(point(R), distance(R, B, { units: "meters" }), destBearing, {
    units: "meters"
  }).geometry?.coordinates as [number, number];
};

export const getClosestPoint = (
  // polygon: Feature<Polygon, Properties>,
  polygon: any,
  point: Feature<Point, Properties>,
  options?: {
    units?: Units;
  }
) => {
  const vertices = explode(polygon);
  const closestPoint = nearest(point, vertices);
  return [closestPoint, distance(point, closestPoint, options)];
};

export const isPointOnLine = (
  line: Feature<LineString, Properties>,
  point: Feature<Point, Properties>,
  epsilonInMeters = 0.001
) => {
  return pointToLineDistance(point, line, { units: "meters" }) <= epsilonInMeters;
};

export const mouseEventPoint = (mouseEvent: MouseEvent, container: HTMLElement) => {
  const rect = container.getBoundingClientRect();
  return new MapboxPoint(
    mouseEvent.clientX - rect.left - (container.clientLeft || 0),
    mouseEvent.clientY - rect.top - (container.clientTop || 0)
  );
};

export const createVertex = (
  parentId: string,
  coordinates: [number, number],
  path: string,
  selected: boolean
) => ({
  type: "Feature",
  properties: {
    meta: "vertex",
    parent: parentId,
    coord_path: path,
    active: String(selected)
  },
  geometry: {
    type: "Point",
    coordinates
  }
});

export const createMidpoint = (
  parent: string,
  startVertex: DrawFeature,
  endVertex: DrawFeature
) => {
  const startCoord = startVertex.geometry.coordinates;
  const endCoord = endVertex.geometry.coordinates;

  const mid = {
    lng: (startCoord[0] + endCoord[0]) / 2,
    lat: (startCoord[1] + endCoord[1]) / 2
  };

  return {
    type: "Feature",
    properties: {
      meta: "midpoint",
      parent,
      lng: mid.lng,
      lat: mid.lat,
      coord_path: endVertex.properties.coord_path
    },
    geometry: {
      type: "Point",
      coordinates: [mid.lng, mid.lat]
    }
  };
};

export const createSupplementaryPoints = (
  geojson: DrawFeature,
  options: {
    midpoints?: boolean;
    selectedPaths?: string[];
    parentId?: string;
  } = {},
  basePath: string | null = null
): DrawFeature[] => {
  const { type, coordinates } = geojson.geometry;
  const featureId = options.parentId || (geojson.properties && geojson.properties.id);

  let supplementaryPoints: DrawFeature[] = [];

  if (type === "Point" && basePath !== null) {
    // For points, just create a vertex
    supplementaryPoints.push(
      createVertex(featureId, coordinates, basePath, isSelectedPath(basePath))
    );
  } else if (type === "Polygon") {
    // Cycle through a Polygon's rings and
    // process each line
    coordinates.forEach((line: [number, number][], lineIndex: number) => {
      processLine(line, basePath !== null ? `${basePath}.${lineIndex}` : String(lineIndex));
    });
  } else if (type === "LineString") {
    processLine(coordinates, basePath);
  } else if (type.indexOf("Multi") === 0) {
    processMultiGeometry();
  }

  function processLine(line: [number, number][], lineBasePath: string | null) {
    let firstPointString = "";
    let lastVertex: DrawFeature | null = null;
    const isPolyLine = line[0] === line[line.length - 1];
    line.forEach((point, pointIndex) => {
      const pointPath =
        lineBasePath !== undefined && lineBasePath !== null
          ? `${lineBasePath}.${isPolyLine ? pointIndex % (line.length - 1) : pointIndex}`
          : String(pointIndex);
      const vertex = createVertex(featureId, point, pointPath, isSelectedPath(pointPath));

      // If we're creating midpoints, check if there was a
      // vertex before this one. If so, add a midpoint
      // between that vertex and this one.
      const previousPointPath = `${lineBasePath}.${
        pointIndex > 0 ? pointIndex - 1 : line.length - 1
      }`;
      if (
        options.midpoints &&
        lastVertex &&
        (!options.selectedPaths ||
          options.selectedPaths.length === 0 ||
          isSelectedPath(previousPointPath) ||
          isSelectedPath(pointPath))
      ) {
        const midpoint = createMidpoint(featureId, lastVertex, vertex);
        if (midpoint) {
          supplementaryPoints.push(midpoint);
        }
      }
      lastVertex = vertex;

      // A Polygon line's last point is the same as the first point. If we're on the last
      // point, we want to draw a midpoint before it but not another vertex on it
      // (since we already a vertex there, from the first point).
      const stringifiedPoint = JSON.stringify(point);
      if (firstPointString !== stringifiedPoint) {
        supplementaryPoints.push(vertex);
      }
      if (pointIndex === 0) {
        firstPointString = stringifiedPoint;
      }
    });
  }

  function isSelectedPath(path: string) {
    if (!options.selectedPaths) return false;
    return options.selectedPaths.indexOf(path) !== -1;
  }

  // Split a multi-geometry into constituent
  // geometries, and accumulate the supplementary points
  // for each of those constituents
  function processMultiGeometry() {
    const subType = type.replace("Multi", "");
    coordinates.forEach(
      (subCoordinates: [number, number][] | [number, number][][], index: number) => {
        const subFeature = {
          type: "Feature",
          properties: geojson.properties,
          geometry: {
            type: subType,
            coordinates: subCoordinates
          }
        };
        supplementaryPoints = supplementaryPoints.concat(
          createSupplementaryPoints(subFeature, options, String(index))
        );
      }
    );
  }

  return supplementaryPoints;
};
