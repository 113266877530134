import { createSlice } from "@reduxjs/toolkit";

const programsSlice = createSlice({
  name: "programs",
  initialState: {
    programValues: {},
    programInitialValues: {},
    programListIsLoading: false,
    programList: [],
    deletePrograms: false,
    deleteProgramList: [],
    selectedProgramName: null,
    selectedPrograms: {
      multi_family: null,
      single_family: null
    },
    programsIsComplete: {
      multi_family: false,
      single_family: false
    },
    editProgram: {
      multi_family: false,
      single_family: false
    },
    confirmProgramFavorites: false
  },
  reducers: {
    updateProgramLoadingStatus(state, action) {
      state.programListIsLoading = action.payload;
    },
    setProgramList(state, action) {
      state.programList = action.payload;
    },
    setSelectedMultifamilyProgram(state, action) {
      state.selectedPrograms.multi_family = action.payload;
    },
    setSelectedSinglefamilyProgram(state, action) {
      state.selectedPrograms.single_family = action.payload;
    },
    cleanSelectedPrograms(state) {
      state.selectedPrograms.multi_family = null;
      state.selectedPrograms.single_family = null;
    },
    setMultiProgramComplete(state, action) {
      state.programsIsComplete.multi_family = action.payload;
    },
    setSingleProgramComplete(state, action) {
      state.programsIsComplete.single_family = action.payload;
    },
    toggleEditProgram(state, action) {
      state.editProgram.multi_family = action.payload;
      state.editProgram.single_family = action.payload;
    },
    toggleEditMultiProgram(state, action) {
      state.editProgram.multi_family = action.payload;
    },
    toggleEditSingleProgram(state, action) {
      state.editProgram.single_family = action.payload;
    },
    setProgramValues(state, action) {
      state.programValues = action.payload;
    },
    cleanProgramValues(state) {
      state.programValues = {};
    },
    setProgramInitialValues(state, action) {
      state.programInitialValues = action.payload;
    },
    toggleConfirmProgramFavorites(state, action) {
      state.confirmProgramFavorites = action.payload;
    },
    toggleDeletePrograms(state, action) {
      state.deletePrograms = action.payload;
    },
    checkDeleteProgram(state, action) {
      state.deleteProgramList = action.payload;
    },
    setSelectedProgramName(state, action) {
      state.selectedProgramName = action.payload;
    }
  }
});

export const {
  updateProgramLoadingStatus,
  setProgramList,
  setSelectedMultifamilyProgram,
  setSelectedSinglefamilyProgram,
  cleanSelectedPrograms,
  setMultiProgramComplete,
  setSingleProgramComplete,
  setProgramValues,
  cleanProgramValues,
  setProgramInitialValues,
  toggleEditProgram,
  toggleEditMultiProgram,
  toggleEditSingleProgram,
  toggleConfirmProgramFavorites,
  toggleDeletePrograms,
  checkDeleteProgram,
  setSelectedProgramName
} = programsSlice.actions;

export default programsSlice.reducer;
