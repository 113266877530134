import axios from "axios";

import { ucUrl } from "../config/urls";
import { isNullOrUndefined } from "../config/utils.js";

const getAddressFor = async (longitude, latitude) => {
  if (isNaN(longitude) || isNaN(latitude)) {
    throw new Error(`geocoding::getAddressFor -> Invalid parameters (${longitude}, ${latitude}).`);
  }

  const reverseGeocodingURL = `${ucUrl}/geocoder/address`;
  try {
    const geocodingResponse = await axios.get(reverseGeocodingURL, {
      params: {
        longitude: longitude,
        latitude: latitude
      }
    });
    return geocodingResponse.data.address;
  } catch (error) {
    throw new Error(
      `geocoding::getAddressFor -> Reverse geocoding failed with ${JSON.stringify(error)}.`
    );
  }
};

const ensureAddressField = async (target, longitude, latitude) => {
  if (isNullOrUndefined(target.address)) {
    target.address = await getAddressFor(longitude, latitude);
  }
};

export const autocompleteAddress = async (address, sessionToken) => {
  const autocompleteURL = `${ucUrl}/geocoder/autocomplete`;
  return (
    await axios.get(autocompleteURL, {
      params: {
        input: address,
        session_token: sessionToken
      }
    })
  ).data;
};

export const ensureAddressFieldForParcel = async target => {
  const longitude = target.centroid.geometry.coordinates[0];
  const latitude = target.centroid.geometry.coordinates[1];
  return ensureAddressField(target, longitude, latitude);
};
