import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InfoMessageSlice } from "../slices/ui";

const initialState: InfoMessageSlice = {
  visible: false,
  header: "",
  content: ""
};

const slice = createSlice({
  name: "infoMessage",
  initialState,
  reducers: {
    showInfoMessage(state, action: PayloadAction<{ header: string; content: string }>) {
      state.visible = true;
      state.header = action.payload.header;
      state.content = action.payload.content;
    },
    hideInfoMessage(state) {
      state.visible = false;
      state.header = "";
      state.content = "";
    }
  }
});

export const { showInfoMessage, hideInfoMessage } = slice.actions;

export default slice.reducer;
