/**
 * Created by jmartinez on 9/25/18.
 */

import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Grid, Segment, Form, Button } from "semantic-ui-react";
import { validateInt, programSteps } from "../../config/config";
import { getGrossAreaLabel } from "../../config/utils";
import * as programCtrl from "../../controllers/programs";
import * as programActions from "../../store/programs";
import * as projectActions from "../../store/projects";
import Steps from "../Steps";
import WizardStep from "../WizardStep";
import TextInput from "./../forms/TextInput";
import WizardTextInput from "./../forms/WizardTextInput";

const actionsObj = Object.assign({}, programActions, programCtrl, projectActions);

const footerButtons = props => {
  return (
    <div>
      <Button
        onClick={() => {
          props.setActiveModalForm(programSteps["program_step_1"]);
        }}
      >
        Back
      </Button>
      <Button
        onClick={() => {
          props.submitNonRes();
        }}
      >
        Next
      </Button>
    </div>
  );
};

export const NonResStepFooter = connect(() => {
  return {};
}, actionsObj)(footerButtons);

class NonResStep extends WizardStep {
  handleBackClick(e) {
    e.preventDefault();
    this.props.setActiveModalForm("main");
  }

  render() {
    const { handleSubmit, editProgram, initialValues } = this.props;
    const stepData = [
      { title: "Unit Mix", active: false, disabled: false },
      { title: "Non-Residential", active: true, disabled: false },
      { title: "Circulation", active: false, disabled: true }
    ];
    const originalData = editProgram && !this.readOnlyMode ? { originalData: initialValues } : {};
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Steps stepData={stepData} />
        <Form onSubmit={handleSubmit}>
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Field
                    name="commercial_retail"
                    label="Commercial Retail:"
                    unitLabel={getGrossAreaLabel()}
                    labelPosition="right"
                    component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                    validate={[validateInt]}
                    readOnly={this.readOnlyMode}
                    {...originalData}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <h5>Non-residential spaces can be accommodated on the ground floor.</h5>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { programValues } = state.programs;
  return {
    initialValues: programValues,
    editProgram: state.programs.editProgram.multi_family
  };
};

const NonResForm = reduxForm({
  form: "NonResForm",
  destroyOnUnmount: false
})(NonResStep);

export default connect(mapStateToProps, actionsObj)(NonResForm);
