import bboxPolygon from "@turf/bbox-polygon";

export class Coordinate {
  longitude: number;
  latitude: number;
  constructor(longitude: number | string, latitude: number | string) {
    //@ts-ignore
    longitude = parseFloat(longitude);
    //@ts-ignore
    latitude = parseFloat(latitude);

    if (!is.longitude(longitude) || !is.latitude(latitude)) {
      throw new TypeError("Invalid input");
    }
    this.longitude = longitude;
    this.latitude = latitude;
  }
  get asArray() {
    return [this.longitude, this.latitude];
  }
}

export const boundingBoxFromMapBoxBounds = (mapBoxBounds: any) => {
  return new BoundingBox(
    new Coordinate(mapBoxBounds._sw.lng, mapBoxBounds._sw.lat),
    new Coordinate(mapBoxBounds._ne.lng, mapBoxBounds._ne.lat)
  );
};

export class BoundingBox {
  southwest: Coordinate;
  northeast: Coordinate;
  constructor(southwest?: Coordinate, northeast?: Coordinate) {
    this.southwest = southwest || new Coordinate(-180, -90);
    this.northeast = northeast || new Coordinate(180, 90);
  }
  asPolygon() {
    return bboxPolygon([
      this.southwest.longitude,
      this.southwest.latitude,
      this.northeast.longitude,
      this.northeast.latitude
    ]);
  }
}

export const is = {
  // String checks
  string(item: any): item is string {
    return typeof item === "string";
  },
  nonEmptyString(item: any): item is string {
    return this.string(item) && item !== "";
  },
  // Numeric checks
  number(item: any): item is number {
    // eslint-disable-next-line no-self-compare
    return item === item && typeof item === "number";
  },
  id(item: any): item is number {
    return this.number(item) && Number.isInteger(item) && item > 0;
  },
  longitude(item: any): item is number {
    return this.number(item) && Math.abs(item) <= 180;
  },
  latitude(item: any): item is number {
    return this.number(item) && Math.abs(item) <= 90;
  },
  // Others
  null(item: any): item is null {
    return item === null;
  },
  boolean(item: any): item is boolean {
    return item === true || item === false;
  },
  array(item: any): item is Array<any> {
    return Array.isArray(item);
  },
  function(item: any): item is Function {
    return typeof item === "function";
  },
  object(item: any): item is Record<string, any> {
    return typeof item === "object" && item !== null && !is.array(item);
  }
};

// Taken from https://stackoverflow.com/questions/105034/how-to-create-guid-uuid
export const generateUUIDV4 = () =>
  //@ts-ignore
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
