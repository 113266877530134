import { Map, MapboxGeoJSONFeature } from "mapbox-gl";
import { formValueSelector } from "redux-form";
import { is } from "../../lib";
import { asyncEditProject, asyncSetHiddenProject } from "../../promises/projects/projects";
import { mapboxSourceID } from "../../queriers/region";
import { RootState, AppDispatch } from "../../store";
import { pushNewError } from "../../store/errors";
import { setPopupCoordinates, toggleMapPopup } from "../../store/map";
import { safeOperation, operations } from "../../store/operations";
import {
  addProject,
  cleanPreviousParcelSelection,
  clearSelectedProject,
  parcelSearchFail,
  setActiveAccordionIndex,
  setSideBarComponent,
  setSidebarVisible
} from "../../store/projects";
import { mainSelectorCreator } from "../../store/selectors";
import {
  closeProjectRenameModal,
  toggleDisableProjectId,
  unfocusProject,
  updateProjectsNameFilter as updateProjectsNameFilterStore
} from "../../store/ui";
import { cleanBoundaryVisualization } from "../../store/ui/boundaryVisualization";
import { assignFocusedFeature } from "../../store/ui/dataLayers";
import { DataLayer } from "../../types/region";
import { SidebarComponent } from "../../types/ui";
import { doesNotExist } from "../../utils";
import { deselectBuilding } from "../buildings";
import { focusOnProject, hideEditFrontageControls } from "../projects";

import parcelSearch from "./parcelSearch";
import viewport from "./viewport";

export const openProject = (projectId: number): any =>
  safeOperation(
    operations.projectOpening,
    async (dispatch: AppDispatch, getState: () => RootState) => {
      dispatch(unfocusProject());
      const project = getState().projects.projectList.byId[projectId];
      await dispatch(focusOnProject(projectId));
      dispatch(setActiveAccordionIndex(project.parcel_data.city_id));
    }
  );

export const returnToInitialView = (map: Map) => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  dispatch(deselectBuilding());

  dispatch(clearSelectedProject());
  dispatch(cleanPreviousParcelSelection());
  map.flyTo({
    center: getState().map.center as [number, number],
    zoom: getState().map.zoom,
    pitch: getState().map.pitch,
    bearing: getState().map.bearing
  });
  dispatch(hideEditFrontageControls());
  dispatch(setSidebarVisible({ sidebarVisible: true, bottomBarVisible: false }));
  dispatch(setSideBarComponent(SidebarComponent.projectList));
  dispatch(cleanBoundaryVisualization());
  dispatch(parcelSearchFail(false));
  dispatch(toggleMapPopup(false));
  dispatch(setPopupCoordinates([]));
};

export const updateProjectsNameFilter = (newNameFilter: string) => (
  dispatch: AppDispatch,
  getState: () => RootState
) =>
  dispatch(
    updateProjectsNameFilterStore({
      projectsNameFilter: newNameFilter,
      projects: mainSelectorCreator(getState()).getAllProjects()
    })
  );

export const unmarkPreviousFocusedFeature = (map: Map, dataLayer: DataLayer) => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  try {
    const {
      ui: {
        dataLayers: { focusedFeatures }
      }
    } = getState();
    const feature = focusedFeatures[dataLayer.name];
    if (is.null(feature)) {
      return;
    }
    map.setFeatureState(
      {
        source: mapboxSourceID(dataLayer),
        id: feature?.properties?._uc_id,
        sourceLayer: "features"
      },
      { hover: false }
    );
    dispatch(assignFocusedFeature({ name: dataLayer.name, feature: null }));
  } catch (error) {
    console.error(error);
  }
};

export const markFocusedFeature = (
  map: Map,
  dataLayer: DataLayer,
  features: MapboxGeoJSONFeature[]
) => (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const { focusedFeatures } = getState().ui.dataLayers;
    if (features.length <= 0) {
      return;
    }
    const feature = features[0];
    const focusedFeatureShouldGetUpdated =
      is.null(focusedFeatures[dataLayer.name]) ||
      focusedFeatures[dataLayer.name]?.properties?._uc_id !== feature.id;
    if (!focusedFeatureShouldGetUpdated) {
      return;
    }
    dispatch(unmarkPreviousFocusedFeature(map, dataLayer));
    // Note (@nicoluce): When a parcel is selected the hover effect should not be display
    if (String(getState().projects.selectedParcel?.parcelId) !== feature.id) {
      map.setFeatureState(
        {
          source: mapboxSourceID(dataLayer),
          id: feature.properties?._uc_id,
          sourceLayer: "features"
        },
        { hover: true }
      );
    }
    dispatch(assignFocusedFeature({ name: dataLayer.name, feature }));
  } catch (error) {
    console.error(error);
  }
};

const selector = formValueSelector("RenameModalForm");
export const renameProject = () =>
  safeOperation(
    operations.projectRename,
    async (dispatch: AppDispatch, getState: () => RootState) => {
      const projectId = getState().projects.editProjectList[0];
      const name = selector(getState(), "name");
      if (doesNotExist(name)) {
        return;
      }

      await asyncEditProject(projectId, { name });
      dispatch(closeProjectRenameModal());

      const project = { ...getState().projects.projectList.byId[projectId], name };
      dispatch(addProject(project));
    },
    (error, dispatch: AppDispatch) =>
      dispatch(
        pushNewError({
          name: "Edition of Project name failed",
          description: `controllers::ui::index::renameProject -> ${error}`
        })
      )
  );

export const setHiddenProject = (projectId: number, hide: boolean) =>
  safeOperation(operations.hiddenProjectSet, async (dispatch: AppDispatch) => {
    dispatch(toggleDisableProjectId(projectId));
    asyncSetHiddenProject(projectId, hide);
  });

export default {
  viewport,
  openProject,
  parcelSearch,
  updateProjectsNameFilter,
  markFocusedFeature,
  unmarkPreviousFocusedFeature,
  setHiddenProject
};
