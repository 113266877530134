import axios from "axios";
import { ucUrl } from "../config/urls";
import store from "../store";
import { pushNewError } from "../store/errors";
import { City, CityRents } from "../store/types";

export const getCities = async (): Promise<City[]> => {
  try {
    const response = await axios.get<City[]>(`${ucUrl}/cities`);
    return response.data;
  } catch (error) {
    store.dispatch(
      pushNewError({
        name: "Couldn't retrieve cities from UC",
        description: `cities::getCities -> ${error}`
      })
    );
    throw error;
  }
};

export const getRents = async (cityId: number): Promise<CityRents[]> => {
  try {
    const response = await axios.get<CityRents[]>(`${ucUrl}/rents`, {
      params: { filter: { cityId: cityId } }
    });
    return response.data;
  } catch (error) {
    store.dispatch(
      pushNewError({
        name: `Couldn't retrieve city ${cityId} rents from UC`,
        description: `cities::getCityRents -> ${error}`
      })
    );
    throw error;
  }
};
