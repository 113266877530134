import { isNull } from "lodash";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { formValueSelector } from "redux-form";
import { Modal, Button, Loader } from "semantic-ui-react";
import { generateBuildingWithSuggestion } from "../controllers/user/building";
import { zoningSubmission } from "../controllers/zoning";
import { RootState, AppDispatch } from "../store";
import { mandatoryOperation, operations } from "../store/operations";
import { Zoning } from "../store/types";
import { closeSuggestionsModal } from "../store/ui/incompatibleConstraints";
import { toggleEditZoning } from "../store/zoning";
import "../stylesheets/InformativeModal.css";

const selector = formValueSelector("MainModalForm");
const mapStateToProps = (state: RootState) => {
  const open = state.ui.incompatibleConstraints.detected;
  const selectedZoning = state.zoning.selectedZoning;
  const operations = {
    zoningSubmission: state.operations.zoningSubmission,
    buildingGenerationWithSuggestion: state.operations.buildingGenerationWithSuggestion,
    suggestionSubmission: state.operations.suggestionSubmission
  };
  const scenarioName = selector(state, "scenario-name");
  const { message, suggestions } = state.ui.incompatibleConstraints.data;
  return { open, operations, scenarioName, suggestions, message, selectedZoning };
};

const zoningSuggestionSubmission = (zoning: Zoning, name: string) =>
  mandatoryOperation(operations.suggestionSubmission, async (dispatch: AppDispatch) => {
    await dispatch(
      zoningSubmission(zoning, {
        percentagesAreEncodedBetweenZeroAndOne: true
      })
    );
    await dispatch(generateBuildingWithSuggestion(name));
    await dispatch(closeSuggestionsModal());
  });

const mapDispatchToProps = {
  closeSuggestionsModal,
  toggleEditZoning,
  zoningSuggestionSubmission
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type State = {
  selectedSuggestionId: number | null;
};

class GenerationSuggestionsModal extends React.Component<PropsFromRedux, State> {
  constructor(props: PropsFromRedux) {
    super(props);
    this.state = {
      selectedSuggestionId: null
    };
  }
  close = (): void => {
    this.props.closeSuggestionsModal();
  };

  onSuggestionClick = (idx: number): void => {
    this.setState({ selectedSuggestionId: this.state.selectedSuggestionId !== idx ? idx : null });
  };

  onConfirmation = (): void => {
    this.props.toggleEditZoning(true);
    const selectedSuggestion = this.props.suggestions[this.state.selectedSuggestionId as number];
    const input = selectedSuggestion.input;
    Object.entries(input).forEach(([key, values]) => {
      switch (key) {
        case "zoning":
        case "parking_input":
          {
            const dataToSend = { ...this.props.selectedZoning, ...values };
            this.props.zoningSuggestionSubmission(dataToSend, this.props.scenarioName);
          }
          break;
        default:
          throw Error(`Unknown suggestion type ${key}`);
      }
    });
  };

  progresionText = (): string => {
    if (this.props.operations.zoningSubmission.isLoading) {
      return "Submitting edited zoning";
    } else if (this.props.operations.buildingGenerationWithSuggestion.isLoading) {
      return "Re-submitting building option";
    } else {
      return "";
    }
  };

  render(): JSX.Element {
    const { open, operations, suggestions, message } = this.props;
    return (
      <Modal
        className="informative-modal"
        size="tiny"
        open={open}
        closeIcon={true}
        onClose={() => operations.suggestionSubmission.isLoading || this.close()}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        dimmer={"blurring"}
      >
        <Modal.Header>{"Oops!"}</Modal.Header>
        <Modal.Description>{"You've entered incompatible constraints"}</Modal.Description>
        <Modal.Content>
          <label className="content">{message}</label>
          <label className="content">
            {"Please choose one of the following options to continue."}
          </label>
          {suggestions.map(suggestion => (
            <Button
              className={`suggestion ${
                this.state.selectedSuggestionId === suggestion.id ? "active" : ""
              }`}
              key={suggestion.id}
              fluid
              title={suggestion.description}
              disabled={operations.suggestionSubmission.isLoading}
              onClick={() => this.onSuggestionClick(suggestion.id)}
            >
              {suggestion.name}
            </Button>
          ))}
        </Modal.Content>
        <Modal.Actions>
          {operations.suggestionSubmission.isReady ? (
            <Button
              primary
              disabled={isNull(this.state.selectedSuggestionId)}
              onClick={() => this.onConfirmation()}
            >
              Ok
            </Button>
          ) : (
            <Loader active indeterminate inline inverted>
              {this.progresionText()}
            </Loader>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connector(GenerationSuggestionsModal);
