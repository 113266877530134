import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { buildingVisualizationZoomLevelThreshold } from "../../config/map";
import * as uiCtrl from "../../controllers/ui";
import { is } from "../../lib";
import { RootState } from "../../store";
import { mainSelectorCreator } from "../../store/selectors";
import { getProjectMainBuildingsIds } from "../../store/selectors/projects";
import * as uiActions from "../../store/ui";
import { doesNotExist } from "../../utils";
import PureProjectsMarkers from "./PureProjectsMarkers";

const actionsObj = Object.assign({}, uiActions, uiCtrl);

export default connect(
  (state: RootState) => ({
    focusedProjectId: state.ui.index.focusedProjectId,
    projects:
      doesNotExist(state.projects.selectedProject) && doesNotExist(state.projects.selectedParcel)
        ? mainSelectorCreator(state)
            .getAllProjects()
            .filter(project => !state.ui.index.disabledProjectsIDs.includes(project.id))
            .filter(
              project =>
                !Object.values(getProjectMainBuildingsIds(project)).some(id => is.id(id)) ||
                ((state.map.map as any) as Map)?.getZoom() < buildingVisualizationZoomLevelThreshold
            )
        : [],
    map: state.map.map
  }),
  actionsObj
)(PureProjectsMarkers);
