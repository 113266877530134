import { Feature, FeatureCollection, Polygon } from "@turf/helpers";
import { isEmpty } from "lodash";
import { MultifamilyProgram, SinglefamilyProgram, Zoning } from "../store/types";
import {
  DevcostsData,
  SourceOfFundsInput,
  SourceOfFundsOutput,
  OperatingInput,
  OperatingOutput
} from "./proforma";

export interface Building {
  actual_commercial_retail_gsf: number;
  building_program: MultifamilyProgram | SinglefamilyProgram;
  building_program_id: number;
  building_type: BuildingType;
  building_type_id: 1 | 2;
  devcosts: DevcostsData | null;
  development_site_id: number;
  finance: { inputs: SourceOfFundsInput; outputs: SourceOfFundsOutput };
  fourbed: number;
  geometry: {
    full_parcel: Feature<Polygon>;
    reduced_parcel: Feature<Polygon>;
    sub_buildings: SubBuilding[];
  };
  gsf: number;
  far: number;
  id: number;
  interior_circulation_area: number;
  interior_open_space_area: number;
  lot_coverage: number;
  name: string;
  onebed: number;
  parking_gsf: number;
  project_id: number;
  rents: { inputs: OperatingInput; outputs: OperatingOutput };
  rsf: number;
  stories: number;
  studio: number;
  threebed: number;
  total_parking_levels: number;
  total_parking_slots: number;
  total_podium_parking_levels: number;
  total_podium_parking_slots: number;
  total_underground_parking_levels: number;
  total_underground_parking_slots: number;
  twobed: number;
  underground_parking_gsf: number;
  user_id: number;
  user_zoning: boolean;
  zoning: Zoning;
  zoning_id: number;
}

export const isBuilding = (value: Building | {}): value is Building => !isEmpty(value);

export type UnitFeatureProperties = { gross_area: number; id: number; type: string };
export type UnitFeature = Feature<Polygon, UnitFeatureProperties>;

export type SubBuildingBlock = {
  geojson: FeatureCollection<Polygon, UnitFeatureProperties>;
  stories: number;
  type: string;
};
export type SubBuilding = SubBuildingBlock[];

export type BuildingType = {
  code: BuildingTypeCode;
  description: string | null;
  id: 1 | 2;
  name: "Apartment" | "Nplex";
};

export enum BuildingTypeCode {
  apartment = "apartment",
  nplex = "nplex"
}

export enum BuildingTypeId {
  apartment = 1,
  nplex = 2
}
