/**
 * Created by jmartinez on 8/27/18.
 */

import React from "react";
import { connect } from "react-redux";
import { Sidebar, Modal, Button, Item } from "semantic-ui-react";
import FloorPlanModal from "../components/floorPlanVisualization/FloorPlanModal";
import GenerationSuggestionsModal from "../components/GenerationSuggestionsModal";
import HeaderMenu from "../components/Header";
import InfoMessage from "../components/InfoMessage";
import { DimmedLoader } from "../components/Loaders";
import MainMap from "../components/MainMap";
import MainModalForm, { MainModalFooter } from "../components/MainModalForm";
import ProformaUpdatedModal from "../components/ProformaUpdatedModal";
import ProjectControls from "../components/ProjectControls";
import ProjectsMarkers from "../components/ProjectsMarkers/index";
import RegionSelectorForm from "../components/RegionSelector";
import RentProfileModal from "../components/rentProfile/index";
import CirculationStep, { CirculationStepFooter } from "../components/steps/CirculationStep";
import DevCostsStep, { DevCostsStepFooter } from "../components/steps/DevCostsStep";
import HDStep, { HDStepFooter } from "../components/steps/HDStep";
import NonResStep, { NonResStepFooter } from "../components/steps/NonResStep";
import OpenspaceStep, { OpenspaceStepFooter } from "../components/steps/OpenspaceStep";
import OperatingStep, { OperatingStepFooter } from "../components/steps/OperatingStep.js";
import ParkingInputsStep, { ParkingInputsStepFooter } from "../components/steps/ParkingInputsStep";
import SetbacksStep, { SetbacksStepFooter } from "../components/steps/SetbacksStep";
import SingleFamilyAttachedStep, {
  SingleFamilyAttachedStepFooter
} from "../components/steps/SingleFamilyAttachedStep";
import SourceOfFundsStep, { SourceOfFundsStepFooter } from "../components/steps/SourceOfFundsStep";
import UnitMixStep, { UnitMixStepFooter } from "../components/steps/UnitMixStep";
import ZoningStep, { ZoningStepFooter } from "../components/steps/ZoningStep";
import { sideBarComponents, leftSideBarWidth } from "../config/config";
import { isNullOrUndefinedOrBlank } from "../config/utils";
import { actions, ownerships, resources } from "../constants/permissions";
import * as buildingCtrl from "../controllers/buildings";
import * as proformaCtrl from "../controllers/proforma";
import * as programCtrl from "../controllers/programs";
import * as projectCtrl from "../controllers/projects";
import * as zoningCtrl from "../controllers/zoning";
import logo from "../data/images/logo.png";
import { userIsAllowedToAny } from "../helpers/permissions";
import * as buildingsActions from "../store/buildings";
import { operations } from "../store/operations";
import * as proformaActions from "../store/proforma";
import * as programActions from "../store/programs";
import * as projectActions from "../store/projects";
import { closeRegionSelectorModal } from "../store/ui";
import { closeIncompatibleProformaModal } from "../store/ui/incompatibleProforma";
import * as zoningActions from "../store/zoning";

class MainView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      aboutModalOpen: true
    };
  }

  render() {
    const { editProgram, editZoning, selectedZoningName, selectedProgramName } = this.props;
    const zoningWizardHeaderName = !userIsAllowedToAny(
      actions.create,
      ownerships.all,
      resources.customZoning
    )
      ? `View Zoning Code: ${selectedZoningName}`
      : editZoning
      ? `Edit Zoning Code: ${selectedZoningName}`
      : "Add New Zoning";

    const programWizardHeaderName = !userIsAllowedToAny(
      actions.create,
      ownerships.all,
      resources.customProgram
    )
      ? `View Program: ${selectedProgramName}`
      : (editProgram.multi_family || editProgram.single_family) &&
        !isNullOrUndefinedOrBlank(selectedProgramName)
      ? `Edit Program: ${selectedProgramName}`
      : "Add New Program";

    const financialAnalysisWizardHeaderName = "Financial Analysis";

    const displayForm = formName => {
      switch (formName) {
        case "ZoningStep":
          return {
            header: zoningWizardHeaderName,
            footer: <ZoningStepFooter />,
            body: <ZoningStep onSubmit={this.props.handleZoningStepSubmit} />
          };
        case "HDStep":
          return {
            header: zoningWizardHeaderName,
            footer: <HDStepFooter />,
            body: <HDStep onSubmit={this.props.handleHDSubmit} />
          };
        case "SetbacksStep":
          return {
            header: zoningWizardHeaderName,
            footer: <SetbacksStepFooter />,
            body: <SetbacksStep />
          };
        case "OpenspaceStep":
          return {
            header: zoningWizardHeaderName,
            footer: <OpenspaceStepFooter />,
            body: <OpenspaceStep onSubmit={this.props.handleOpenSpaceSubmit} />
          };
        case "ParkingInputsStep":
          return {
            header: zoningWizardHeaderName,
            footer: <ParkingInputsStepFooter />,
            body: <ParkingInputsStep onSubmit={this.props.handleParkingInputsSubmit} />
          };
        case "UnitMixStep":
          return {
            header: programWizardHeaderName,
            footer: <UnitMixStepFooter />,
            body: <UnitMixStep onSubmit={this.props.handleUnitMixSubmit} />
          };
        case "NonResStep":
          return {
            header: programWizardHeaderName,
            footer: <NonResStepFooter />,
            body: <NonResStep onSubmit={this.props.handleNonResSubmit} />
          };
        case "CirculationStep":
          return {
            header: programWizardHeaderName,
            footer: <CirculationStepFooter />,
            body: <CirculationStep onSubmit={this.props.handleCirculationSubmit} />
          };
        case "DevCostsStep":
          return {
            header: financialAnalysisWizardHeaderName,
            footer: <DevCostsStepFooter />,
            body: <DevCostsStep onSubmit={this.props.handleDevCostsSubmit} />
          };
        case "OperatingStep":
          return {
            header: financialAnalysisWizardHeaderName,
            footer: <OperatingStepFooter />,
            body: <OperatingStep onSubmit={this.props.handleOperatingStepSubmit} />
          };
        case "SourceOfFundsStep":
          return {
            header: financialAnalysisWizardHeaderName,
            footer: <SourceOfFundsStepFooter />,
            body: <SourceOfFundsStep onSubmit={this.props.handleSourceOfFundsStepSubmit} />
          };
        case "SingleFamilyAttachedStep":
          return {
            header: programWizardHeaderName,
            footer: <SingleFamilyAttachedStepFooter />,
            body: (
              <SingleFamilyAttachedStep onSubmit={this.props.handleSingleFamilyAttachedSubmit} />
            )
          };
        default:
          return <MainModalForm />;
      }
    };
    const SideBarComponent = sideBarComponents[this.props.sideBarComponentName];
    return (
      <div>
        {this.props.sidebarVisible && this.props.mapIsReady ? <ProjectsMarkers /> : null}
        <HeaderMenu />
        <Sidebar.Pushable style={{ overflowY: "hidden" }}>
          <Sidebar
            animation="overlay"
            visible={this.props.sidebarVisible}
            onHidden={() => {
              if (this.props.mapIsReady) this.props.map.resize();
            }}
            style={{ width: leftSideBarWidth, overflowX: "hidden" }}
          >
            <SideBarComponent />
          </Sidebar>
          <Sidebar
            animation="overlay"
            visible={this.props.bottomBarVisible}
            direction="bottom"
            style={{
              display: this.props.bottomBarVisible ? "block" : "none",
              overflowY: "visible"
            }}
          >
            {this.props.bottomBarVisible ? <ProjectControls /> : null}
          </Sidebar>
          <Sidebar.Pusher
            style={{ marginLeft: this.props.sidebarVisible ? leftSideBarWidth : "0px" }}
          >
            <MainMap />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        <DimmedLoader />
        <InfoMessage />
        <FloorPlanModal floorplanVisualizationIsOpen={this.props.floorplanVisualizationIsOpen} />
        <GenerationSuggestionsModal />
        <RentProfileModal
          open={this.props.fixingRentProfiles}
          fixingBeforeBuildingEdition={true}
          onClose={() => this.props.closeIncompatibleProformaModal()}
          onSubmit={this.props.handleRentProfileFixSubmit}
        />
        <ProformaUpdatedModal />
        <Modal open={this.props.modalOpen} size="small">
          <Modal.Header>
            {userIsAllowedToAny(
              [actions.update, actions.create],
              [ownerships.all, ownerships.own, ownerships.organization],
              resources.buildingOption
            )
              ? "Add"
              : "View"}{" "}
            Building Option
            <Button
              style={{ float: "right" }}
              circular
              icon="cancel"
              onClick={() => {
                this.props.toggleEditZoning(false);
                this.props.toggleEditProgram(false);
                this.props.setZoningComplete(false);
                this.props.setMultiProgramComplete(false);
                this.props.setSingleProgramComplete(false);
                this.props.toggleAddBuildingModal(!this.props.modalOpen);
                this.props.changeEditBuildingMode(false);
                this.props.setSelectedZoningName("");
                this.props.setSelectedZoning({});
                this.props.cleanSelectedPrograms();
                this.props.clearVirtualPrograms();
                this.props.cleanDerivedZoning();
              }}
            />
          </Modal.Header>
          <Modal.Content style={{ overflowY: "scroll" }}>
            <MainModalForm onSubmit={this.props.handleNewBuildingSubmit} />
          </Modal.Content>
          <Modal.Actions>
            <MainModalFooter />
          </Modal.Actions>
        </Modal>
        <Modal open={this.props.wizardModalOpen} size="small">
          <Modal.Header>
            {displayForm(this.props.activeModalForm)["header"]}
            <Button
              style={{ float: "right" }}
              circular
              icon="cancel"
              onClick={() => {
                if (this.props.wizardModalOpen) {
                  this.props.setActiveModalForm("main");
                }
                this.props.toggleWizardModal(!this.props.wizardModalOpen);
                this.props.destroyZoningForms();
                this.props.destroyProgramForms();
                this.props.destroyProformaForms();
                this.props.cleanDerivedZoning();
                this.props.cleanZoningValues();
                this.props.cleanProgramValues();
              }}
            />
          </Modal.Header>
          <Modal.Content style={{ height: "500px", overflowY: "scroll" }}>
            {displayForm(this.props.activeModalForm)["body"]}
          </Modal.Content>
          <Modal.Actions>{displayForm(this.props.activeModalForm)["footer"]}</Modal.Actions>
        </Modal>

        <RegionSelectorForm
          open={this.props.displayRegionSelectorModal}
          cancelAction={() => this.props.closeRegionSelectorModal()}
          regions={this.props.availableRegions}
          regionSwitchingOperation={this.props.regionSwitchingOperation}
          saveAction={() => this.props.regionSelectorSubmit()}
          initialValues={{ id: this.props.selectedRegion.id }}
        />

        <Modal open={this.props.displayAboutModal} size="small">
          <Modal.Header>About</Modal.Header>
          <Modal.Content>
            <Item.Group>
              <Item>
                <Item.Image src={logo} />
                <Item.Content verticalAlign="middle">
                  <Item.Header>Penciler</Item.Header>
                  <Item.Description>
                    <p>
                      {`© ${new Date().getFullYear()} `}{" "}
                      <a href="http://www.urbansim.com">UrbanSim Inc.</a> {` ALL RIGHTS RESERVED`}
                    </p>
                    <p>
                      <a href={process.env.PUBLIC_URL + `/tos.pdf`}>Terms of Service</a>
                    </p>
                    <p>
                      <a href={process.env.PUBLIC_URL + `/privacy.pdf`}>Privacy Policy</a>
                    </p>
                  </Item.Description>
                </Item.Content>
              </Item>
            </Item.Group>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.props.toggleDisplayAbout(false);
              }}
            >
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    map: state.map.map,
    mapIsReady: state.map.map !== undefined,
    sidebarVisible: state.projects.sidebarVisible,
    bottomBarVisible: state.projects.bottomBarVisible,
    modalOpen: state.projects.addBuildingModalOpen,
    activeModalForm: state.projects.activeModalForm,
    wizardModalOpen: state.projects.wizardModalOpen,
    floorplanVisualizationIsOpen: state.buildings.floorplanVisualizationIsOpen,
    editZoning: state.zoning.editZoning,
    editProgram: state.programs.editProgram,
    selectedZoningName: state.zoning.selectedZoningName,
    selectedProgramName: state.programs.selectedProgramName,
    displayAboutModal: state.projects.displayAboutModal,
    sideBarComponentName: state.projects.sideBarComponent,
    displayRegionSelectorModal: state.ui.index.displayRegionSelectorModal,
    availableRegions: state.users.availableRegions,
    selectedRegion: state.regions.selectedRegion,
    regionSwitchingOperation: state.operations[operations.regionSwitching],
    fixingRentProfiles: state.ui.incompatibleProforma.detected
  };
};

export default connect(
  mapStateToProps,
  Object.assign(
    {},
    projectActions,
    projectCtrl,
    zoningActions,
    zoningCtrl,
    programActions,
    programCtrl,
    proformaActions,
    proformaCtrl,
    buildingsActions,
    buildingCtrl,
    { closeRegionSelectorModal },
    { closeIncompatibleProformaModal }
  )
)(MainView);
