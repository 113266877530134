import { Map, LngLatBounds } from "mapbox-gl";
import React from "react";
import ReactDOM from "react-dom";
import { floorPlanSvgWidth } from "../../config/config";
import { initMap } from "../../config/config";
import arrow from "../../data/images/compass-arrow.svg";
import FloorPlanSvg from "../floorPlanVisualization/FloorPlanSVG";
import {
  imagesColumnSizeRatio,
  floorplanWidthToHeightRatio,
  imageYMargin,
  headerHeight,
  buildingRenderWidthToHeightRatio,
  northArrowSize
} from "./constants";
import { ReportProps, PDFDocument } from "./types";

type BoundingBox = {
  x: number;
  y: number;
  width: number;
  height: number;
};
let aerialSize: BoundingBox;
let buildingRenderSize: BoundingBox;
let floorplanSize: BoundingBox;

const addAerialView = (props: ReportProps, pdfLibDoc: PDFDocument) => {
  const { project } = props;

  const center: [number, number] = [parseFloat(project.longitude), parseFloat(project.latitude)];
  const aerialMap = new Map({
    container: "pdf-report-floorplan-container",
    center: center,
    bearing: initMap.bearing,
    style: `mapbox://styles/mapbox/satellite-streets-v10`
  });

  const coordinates = project.parcel_data.geometry.coordinates[0] as [number, number][];
  aerialMap.fitBounds(
    coordinates.reduce(
      (bounds, coord) => bounds.extend(coord),
      new LngLatBounds(coordinates[0], coordinates[0])
    ),
    { padding: 100 }
  );

  aerialMap.on("load", () => {
    aerialMap.addLayer({
      id: "parcel-outline",
      type: "line",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: project.parcel_data.geometry.coordinates
          },
          properties: {}
        }
      },
      paint: {
        "line-color": "#54B2AB",
        "line-width": 5
      }
    });
  });

  return new Promise<PDFDocument>(resolve => {
    aerialMap.on("idle", async () => {
      const aerialDataUrl = aerialMap.getCanvas().toDataURL("image/png");
      const aerialImage = await pdfLibDoc.embedPng(aerialDataUrl);
      const page = pdfLibDoc.getPages()[0];
      // The images column occupies 44% of the page's width
      const aerialDims = aerialImage.scaleToFit(
        page.getWidth() * imagesColumnSizeRatio,
        page.getHeight()
      );
      aerialSize = {
        x: 0,
        y: page.getHeight() - aerialDims.height - headerHeight,
        width: aerialDims.width,
        height: aerialDims.height
      };
      page.drawImage(aerialImage, aerialSize);

      resolve(pdfLibDoc);
    });
  });
};

const cropCanvas = (
  sourceCanvas: HTMLCanvasElement,
  left: number,
  top: number,
  width: number,
  height: number
) => {
  const destCanvas = document.createElement("canvas");
  destCanvas.width = width;
  destCanvas.height = height;
  (destCanvas.getContext("2d") as CanvasRenderingContext2D).drawImage(
    sourceCanvas,
    left,
    top,
    width,
    height, // source rect with content to crop
    0,
    0,
    width,
    height
  ); // newCanvas, same size as source rect
  return destCanvas;
};

const addBuildingRender = async (props: ReportProps, pdfLibDoc: PDFDocument) => {
  const { map: buildingRenderMap } = props;

  const canvas = buildingRenderMap.getCanvas();

  const height = canvas.height;
  const width = height * buildingRenderWidthToHeightRatio;
  const left = canvas.width / 2 - width / 2;
  const top = canvas.height / 2 - height / 2;

  const buildingRenderDataUrl = cropCanvas(canvas, left, top, width, height).toDataURL("image/png");

  const buildingRenderImage = await pdfLibDoc.embedPng(buildingRenderDataUrl);
  const page = pdfLibDoc.getPages()[0];
  const buildingRenderDims = buildingRenderImage.scaleToFit(
    page.getWidth() * imagesColumnSizeRatio,
    page.getHeight()
  );
  buildingRenderSize = {
    x: 0,
    y: aerialSize.y - buildingRenderDims.height - imageYMargin,
    width: buildingRenderDims.width,
    height: buildingRenderDims.height
  };
  page.drawImage(buildingRenderImage, buildingRenderSize);

  return pdfLibDoc;
};

const svgToPngURL = async (svg: string) => {
  const canvas = document.createElement("canvas");
  canvas.width = 100;
  canvas.height = 100;
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  // Dynamic import: https://reactjs.org/docs/code-splitting.html#import
  const CanvgLib = await import("canvg");
  const canvg = await CanvgLib.Canvg.from(ctx, svg, {
    anonymousCrossOrigin: true
  });

  // Wait until the canvas data is available
  canvg.start();
  await canvg.ready();

  return canvas.toDataURL();
};

const addFloorplan = async (props: ReportProps, pdfLibDoc: PDFDocument) => {
  // In order to obtain the floorplan svg string we need to render it somewhere
  const container = document.getElementById("pdf-report-floorplan-container") as HTMLElement;
  ReactDOM.render(
    <FloorPlanSvg
      selectedBuilding={props.building}
      informationIdx={props.informationIdx}
      information={props.information}
      width={floorPlanSvgWidth}
      height={floorPlanSvgWidth * floorplanWidthToHeightRatio}
      showTiles={true}
      showNorthArrow={false}
      isMetric={props.metric}
    />,
    container
  );

  const svg = new XMLSerializer().serializeToString(
    document.getElementById("floorplanSVG") as Node
  );
  const floorplanURL = await svgToPngURL(svg);
  ReactDOM.unmountComponentAtNode(container);

  const page = pdfLibDoc.getPages()[0];

  // Add floorplan image
  const floorplanImage = await pdfLibDoc.embedPng(floorplanURL);
  const floorplanDims = floorplanImage.scaleToFit(
    page.getWidth() * imagesColumnSizeRatio,
    page.getHeight()
  );
  floorplanSize = {
    x: 0,
    y: buildingRenderSize.y - floorplanDims.height - imageYMargin,
    width: floorplanDims.width,
    height: floorplanDims.height
  };
  page.drawImage(floorplanImage, floorplanSize);
  page.drawRectangle({
    ...floorplanSize,
    borderColor: (await import("pdf-lib")).grayscale(0.7),
    borderWidth: 0.5
  });

  // Add north arrow image
  // Note (@nicoluce): Adding the north arrow inside the floorplan svg
  // (like the rest of the code does) messes up with it's dimensions
  // the following workaround adds the image on top of the floorplan's
  // using PDF-lib
  const arrowURL = await svgToPngURL(arrow);
  const arrowImage = await pdfLibDoc.embedPng(arrowURL);
  const arrowSize = {
    x: floorplanDims.width - northArrowSize,
    y: buildingRenderSize.y - imageYMargin - northArrowSize,
    width: northArrowSize,
    height: northArrowSize
  };
  page.drawImage(arrowImage, arrowSize);

  return pdfLibDoc;
};

export const addImages = async (props: ReportProps, pdfLibDoc: PDFDocument) => {
  props.setLoadingMessage("Adding aerial satellite view image...");
  pdfLibDoc = await addAerialView(props, pdfLibDoc);
  props.setLoadingMessage("Adding 3D building image...");
  pdfLibDoc = await addBuildingRender(props, pdfLibDoc);
  props.setLoadingMessage("Adding floorplan image...");
  pdfLibDoc = await addFloorplan(props, pdfLibDoc);
  return pdfLibDoc;
};
