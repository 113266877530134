import React from "react";

export const DemoMessage = props => {
  if (!props.isDemoBuilding) return <span />;
  return (
    <span style={{ float: "left" }}>
      *Sample buildings cannot be modified, changes will not be saved.
    </span>
  );
};
