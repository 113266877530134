import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dropdown, Header, Image, List, Menu } from "semantic-ui-react";
import { externalLinks, mainMenuHeight, unrestrictedLabel } from "../config/config";
import { env } from "../config/env";
import {
  formatCommas,
  getShortAreaLabel,
  getDistanceLabel,
  getDensityLimitLabel,
  calculateAndGetDensityLimitLabel,
  formatDecimal,
  getAcreLabel
} from "../config/utils";
import * as keywords from "../constants/keywords";
import { returnToInitialView } from "../controllers/ui/index";
import logo from "../data/images/logo.png";
import { amountOfAcres } from "../helpers/unitsConversion";
import { RootState } from "../store";
import { operations } from "../store/operations";
import { toggleDisplayAbout } from "../store/projects";
import { UserClaims } from "../store/types";
import { openRegionSelectorModal } from "../store/ui";
import { logoutUser, resetApp } from "../store/users";
import { isProject } from "../types/projects";
import { Region } from "../types/region";
import { exists } from "../utils";
import { containsAnyKeyword } from "../utils";
import ErrorModal from "./ErrorModal";
import history from "./history";
import StatusSummary from "./StatusSummary";

const projectActions = { toggleDisplayAbout };
const uiActions = { openRegionSelectorModal };
const uiCtrl = { returnToInitialView };
const userActions = { logoutUser, resetApp };

const actions = { ...projectActions, ...uiActions, ...uiCtrl, ...userActions };

const mapStateToProps = (state: RootState) => {
  return {
    email: (state.users.userClaims as UserClaims).email,
    map: (state.map.map as any) as mapboxgl.Map,
    center: state.map.center,
    zoom: state.map.zoom,
    pitch: state.map.pitch,
    bearing: state.map.bearing,
    selectedProject: state.projects.selectedProject,
    currentProjectDefaultZoning: state.projects.currentProjectDefaultZoning,
    projectOpeningOperation: state.operations[operations.projectOpening],
    buildingIsLoading:
      state.buildings.flags.buildingIsLoading ||
      state.operations[operations.projectBuildingsFetch].isLoading,
    hasMultipleAvailableRegions: state.users.availableRegions.length >= 2,
    region: state.regions.selectedRegion as Region
  };
};

const connector = connect(mapStateToProps, actions);

type PropsFromRedux = ConnectedProps<typeof connector>;
type HeaderProps = {} & PropsFromRedux;

class HeaderComponent extends React.Component<HeaderProps> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {};
  }

  handleLogOutClick() {
    localStorage.removeItem("token");
    localStorage.removeItem("uc_token");
    this.props.logoutUser();
    this.props.resetApp();
    history.push("/");
  }

  handleLogoClick() {
    this.props.returnToInitialView(this.props.map);
  }

  handleRegionClick() {
    if (!this.props.hasMultipleAvailableRegions) {
      return;
    }
    this.props.openRegionSelectorModal();
  }

  render() {
    const { selectedProject, currentProjectDefaultZoning } = this.props;
    return (
      <div>
        <Menu style={{ height: mainMenuHeight }}>
          <Menu.Item
            name="headerMenuLog"
            onClick={() => {
              if (!this.props.projectOpeningOperation.isLoading && !this.props.buildingIsLoading) {
                this.handleLogoClick();
              }
            }}
            fitted="vertically"
          >
            <Header as="h5" textAlign="center">
              <Image src={logo} size="small" style={{ marginTop: 0 }} /> Penciler
            </Header>
          </Menu.Item>

          <Menu.Menu position="right">
            {env.errorReportShouldBeShown ? (
              <Menu.Item className="borderless">
                <ErrorModal />
              </Menu.Item>
            ) : null}
            {!isProject(selectedProject) ? null : (
              <Dropdown item simple text="Project Information" icon="caret down">
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <List>
                      <List.Item>
                        <List.Header>Project Name</List.Header>
                        {selectedProject.name}
                      </List.Item>
                      {containsAnyKeyword(
                        selectedProject.address,
                        keywords.projects.avoidableAddress
                      ) ? null : (
                        <List.Item>
                          <List.Header>Address</List.Header>
                          {selectedProject.address}
                        </List.Item>
                      )}
                      <List.Item>
                        <List.Header>Author</List.Header>
                        {selectedProject.author}
                      </List.Item>
                      <List.Item>
                        <List.Header>Parcel Zoning</List.Header>
                        {selectedProject.parcel_data.zoning}
                      </List.Item>
                      <List.Item>
                        <List.Header>Lot Size</List.Header>
                        {`${formatCommas(
                          selectedProject.parcel_size.area
                        )} ${getShortAreaLabel()} (${formatDecimal(
                          amountOfAcres(selectedProject.parcel_size.area)
                        )} ${getAcreLabel()})`}
                      </List.Item>
                      <List.Item>
                        <List.Header>Height Limit</List.Header>
                        {exists(selectedProject.parcel_data.height)
                          ? `${selectedProject.parcel_data.height} ${getDistanceLabel()}`
                          : unrestrictedLabel}
                      </List.Item>
                      <List.Item>
                        <List.Header>Density Limit</List.Header>
                        {exists(selectedProject.density_limit)
                          ? getDensityLimitLabel(selectedProject.density_limit)
                          : calculateAndGetDensityLimitLabel(
                              selectedProject.parcel_size.area,
                              currentProjectDefaultZoning
                            )}
                      </List.Item>
                    </List>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}

            <Menu.Item className="borderless">
              <StatusSummary />
            </Menu.Item>

            <Menu.Item name={this.props.region.name} onClick={() => this.handleRegionClick()} />

            <Dropdown fluid item text={`${this.props.email}`}>
              <Dropdown.Menu>
                <Dropdown.Item
                  as="a"
                  href={externalLinks.accountManagement}
                  target="_blank"
                  text="Manage Account"
                />
                <Dropdown.Item
                  as="a"
                  href={externalLinks.documentation}
                  target="_blank"
                  text="User Guide"
                />
                <Dropdown.Item
                  text="About"
                  onClick={() => {
                    this.props.toggleDisplayAbout(true);
                  }}
                />
                <Dropdown.Item
                  as="a"
                  href={externalLinks.support}
                  target="_blank"
                  text="Report a problem"
                />
                <Dropdown.Item
                  text="Logout"
                  onClick={() => {
                    this.handleLogOutClick();
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}

export default connector(HeaderComponent);
