import { autocompleteAddress } from "../../helpers/geocoding";
import { ParcelCandidate } from "../../lib/ParcelCandidate";
import { AppDispatch } from "../../store";
import { safeOperation, mandatoryOperation, operations } from "../../store/operations";
import { updateParcelSearchCandidates } from "../../store/ui";

export const byAddress = (address: string, sessionToken: string): any =>
  mandatoryOperation(operations.parcelSearchByAddress, async (dispatch: AppDispatch) =>
    dispatch(
      updateParcelSearchCandidates(
        (await autocompleteAddress(address, sessionToken)).map(
          (autocompleteResult: { address: string }) =>
            new ParcelCandidate(null, autocompleteResult.address)
        )
      )
    )
  );

export const byID = (parcelId: number) =>
  safeOperation(operations.parcelSearchById, async (dispatch: AppDispatch) =>
    dispatch(updateParcelSearchCandidates([new ParcelCandidate(parcelId, null)]))
  );

export default { byAddress, byID };
