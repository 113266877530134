import { isEmpty } from "lodash";
import { Map } from "mapbox-gl";
import React from "react";
import { Popup as ReactMapboxPopup } from "react-mapbox-gl";
import { connect, ConnectedProps } from "react-redux";
import { Icon } from "semantic-ui-react";
import { buildingLayersUnderPoint, projectBoundaryLayer } from "../../../config/map";

import { markFocusedFeature, unmarkPreviousFocusedFeature } from "../../../controllers/ui";
import { selectClickedParcel } from "../../../controllers/ui/projectCreation";
import { coordinates, mapbox } from "../../../converters";
import { mapboxFillLayerID } from "../../../queriers/region";
import { RootState } from "../../../store";
import { toggleMapPopup } from "../../../store/map";
import { operations } from "../../../store/operations";
import { cleanClickedParcel } from "../../../store/projects";
import {
  anchorDataLayersPopup,
  cleanDataLayersPopupCoordinates,
  unanchorDataLayersPopup,
  updateDataLayersPopupCoordinates
} from "../../../store/ui/dataLayers";
import { ParcelInformation } from "../../../types/ui";
import { withMap } from "../../common/map/ReactMapboxContext";
import ParcelInformationPopup from "../../ParcelInformationPopup";
import ParcelsSection from "./ParcelsSection";
import ZoningsSection from "./ZoningsSection";

type Display = {
  zoningsSummary: boolean;
  parcelsSummary: boolean;
  projectCreation: boolean;
};
const willAtLeastOnePopupGetRendered = (display: Display) =>
  display.zoningsSummary || display.parcelsSummary || display.projectCreation;

const connector = connect(
  (state: RootState) => ({
    clickedParcelSelectionOperation: state.operations[operations.clickedParcelSelection],
    clickedParcel: state.projects.clickedParcel,
    selectedParcel: state.projects.selectedParcel,
    popupCoordinates: state.ui.dataLayers.popupCoordinates,
    popupIsAnchored: state.ui.dataLayers.popupIsAnchored,
    parcelsFocusedFeature: state.ui.dataLayers.focusedFeatures["parcels"],
    zoningsFocusedFeature: state.ui.dataLayers.focusedFeatures["zonings"],
    parcelsDataLayer: state.regions.dataLayers["parcels"],
    zoningsDataLayer: state.regions.dataLayers["zonings"]
  }),
  {
    anchorDataLayersPopup,
    cleanClickedParcel,
    cleanDataLayersPopupCoordinates,
    unanchorDataLayersPopup,
    unmarkPreviousFocusedFeature,
    markFocusedFeature,
    updateDataLayersPopupCoordinates,
    selectClickedParcel,
    toggleMapPopup
  }
);
type PropsFromRedux = ConnectedProps<typeof connector>;

class PurePopup extends React.Component<PropsFromRedux & { map: Map }> {
  componentDidMount() {
    this.props.map.on("click", async ({ point, lngLat }) => {
      const clickedBuilding = buildingLayersUnderPoint(this.props.map, point);
      if (clickedBuilding) {
        return;
      }
      const segmentSelectionFeaturesClicked = this.props.map.queryRenderedFeatures(point, {
        layers: [projectBoundaryLayer]
      });

      const parcelFeaturesClicked = this.props.map.queryRenderedFeatures(point, {
        layers: [mapboxFillLayerID(this.props.parcelsDataLayer)]
      });

      if (parcelFeaturesClicked.length > 0 && segmentSelectionFeaturesClicked.length === 0) {
        if (
          this.doesHoveredCoordinateFallsUnderSelectedParcel ||
          this.props.clickedParcelSelectionOperation.isLoading
        ) {
          return;
        }
        this.props.updateDataLayersPopupCoordinates(mapbox.mouseEventLngLat(lngLat).to.coordinates);
        this.props.anchorDataLayersPopup();
        this.props.markFocusedFeature(
          this.props.map,
          this.props.parcelsDataLayer,
          parcelFeaturesClicked
        );
        this.props.toggleMapPopup(false);
        await this.props.selectClickedParcel(parcelFeaturesClicked[0]);
      } else {
        if (!this.props.popupIsAnchored) {
          return;
        }
        this.closePopup();
      }
    });

    this.props.map.on("click", mapboxFillLayerID(this.props.zoningsDataLayer), ({ features }) =>
      this.props.markFocusedFeature(this.props.map, this.props.zoningsDataLayer, features ?? [])
    );
  }

  get doesHoveredCoordinateFallsUnderSelectedParcel() {
    const { selectedParcel, parcelsFocusedFeature } = this.props;
    if (
      !selectedParcel ||
      !(selectedParcel as ParcelInformation).composingParcelsIds ||
      !parcelsFocusedFeature
    ) {
      return false;
    }

    return (selectedParcel as ParcelInformation).composingParcelsIds.includes(
      parseInt((parcelsFocusedFeature.id ?? "") as string)
    );
  }

  chooseDisplayedPopups(): Display {
    const display: Display = {
      zoningsSummary:
        !this.doesHoveredCoordinateFallsUnderSelectedParcel && !!this.props.zoningsFocusedFeature,
      // https://github.com/urbansim/penciler-planning/issues/359
      parcelsSummary: false,
      projectCreation: false
    };
    if (!this.props.clickedParcelSelectionOperation.isReady || !isEmpty(this.props.clickedParcel)) {
      Object.assign(display, {
        zoningsSummary: false,
        parcelsSummary: false,
        projectCreation: true
      });
    }
    return display;
  }

  closePopup() {
    if (!this.props.clickedParcelSelectionOperation.isReady) {
      return;
    }
    this.props.unanchorDataLayersPopup();
    this.props.cleanDataLayersPopupCoordinates();
    this.props.unmarkPreviousFocusedFeature(this.props.map, this.props.parcelsDataLayer);
    this.props.cleanClickedParcel();
  }

  render() {
    const {
      popupIsAnchored,
      popupCoordinates,
      parcelsFocusedFeature,
      zoningsFocusedFeature
    } = this.props;
    const display = this.chooseDisplayedPopups();
    if (popupCoordinates === null || !willAtLeastOnePopupGetRendered(display)) {
      return null;
    }

    return (
      <ReactMapboxPopup coordinates={coordinates(popupCoordinates).to.mapboxPointLike} offset={15}>
        {popupIsAnchored && this.props.clickedParcelSelectionOperation.isReady ? (
          <div style={{ position: "relative", display: "flex", justifyContent: "flex-end" }}>
            <Icon
              name="close"
              size="small"
              onClick={this.closePopup.bind(this)}
              style={{ cursor: "pointer" }}
            />
          </div>
        ) : null}
        {display.zoningsSummary ? <ZoningsSection focusedFeature={zoningsFocusedFeature} /> : null}
        {display.parcelsSummary ? <ParcelsSection focusedFeature={parcelsFocusedFeature} /> : null}
        {display.projectCreation ? <ParcelInformationPopup /> : null}
      </ReactMapboxPopup>
    );
  }
}

export default connector(withMap(PurePopup));
