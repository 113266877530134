/* eslint react/display-name: 0 */
import React from "react";
import { Popup } from "semantic-ui-react";

import { Operation } from "../store/operations";
import { InformativeButton } from "./common";

// See https://github.com/Semantic-Org/Semantic-UI-React/issues/2804 regarding that extra span
export default ({
  trigger,
  onClick,
  zoningRefreshOperation
}: {
  trigger: React.ReactNode;
  onClick: any;
  zoningRefreshOperation: Operation;
}) => (
  <Popup hoverable trigger={<span> {trigger} </span>}>
    <h5> This project&apos;s default zoning is outdated </h5>
    But we can refresh it! You will then be able to create new building options as usual.
    <br />
    Existing building options will not get affected by this change: when trying to edit them you
    will be shown the original input with which they were generated.
    <InformativeButton
      content={"Refresh default zoning"}
      operation={zoningRefreshOperation}
      style={{ marginTop: "1em", float: "right" }}
      onClick={onClick}
    />
  </Popup>
);
