/* eslint react/display-name: 0 */
import React from "react";
import { Layer } from "react-mapbox-gl";
import { connect } from "react-redux";

import { projectParcelMaximumArea } from "../../config/config";
import { parcelVisualizationsSeparatorLayerId } from "../../config/map";
import { is } from "../../lib";
import { dataLayersQuerier, mapboxSourceID, mapboxOutlineLayerID } from "../../queriers/region";
import { RootState } from "../../store";
import { DataLayer } from "../../types/region";
import { ParcelInformation } from "../../types/ui";
import TilesLayer from "../common/map/TilesLayer";
import NeighborsVisualization from "./NeighborsVisualization";

const parcelsBaseColor = "#29587F";
const neighboringParcelsColor = "purple";
const featureColor = [
  "case",
  ["boolean", ["feature-state", "isNeighbor"], false],
  neighboringParcelsColor,
  parcelsBaseColor
];

export default connect((state: RootState) => ({
  parcelsDataLayer: dataLayersQuerier(state.regions.dataLayers).byName("parcels"),
  selectedParcel: state.projects.selectedParcel
}))(
  class extends React.Component<{
    parcelsDataLayer: DataLayer;
    selectedParcel: ParcelInformation | null;
    minzoom?: number;
  }> {
    get selectedParcelNeighborsIds(): Set<number> {
      const { selectedParcel } = this.props;
      if (selectedParcel === null || !is.array(selectedParcel.neighbors)) {
        return new Set();
      }
      return new Set(
        selectedParcel.neighbors
          .filter(neighbor => selectedParcel.area + neighbor.area <= projectParcelMaximumArea)
          .map(n => n.parcelId)
      );
    }
    render() {
      if (!is.id(this.props.parcelsDataLayer.remoteId)) {
        return null;
      }
      return (
        <>
          <TilesLayer
            dataLayer={this.props.parcelsDataLayer}
            minzoom={this.props.minzoom || 15}
            paint={{
              "fill-outline-color": parcelsBaseColor,
              "fill-opacity": ["case", ["boolean", ["feature-state", "isNeighbor"], false], 0.3, 0],
              "fill-color": featureColor
            }}
            before={parcelVisualizationsSeparatorLayerId}
          />
          <Layer
            type="line"
            id={mapboxOutlineLayerID(this.props.parcelsDataLayer)}
            sourceId={mapboxSourceID(this.props.parcelsDataLayer)}
            sourceLayer="features"
            paint={{
              "line-color": featureColor,
              "line-width": [
                "interpolate",
                ["exponential", 2],
                ["zoom"],
                13,
                ["*", ["get", "line_width"], ["^", 2, -10]],
                24,
                ["*", ["get", "line_width"], ["^", 2, 10]]
              ]
            }}
            layout={{
              "line-cap": "round",
              "line-join": "round",
              visibility: this.props.parcelsDataLayer.isVisible ? "visible" : "none"
            }}
            before={parcelVisualizationsSeparatorLayerId}
          />
          <NeighborsVisualization
            parcelsDataLayer={this.props.parcelsDataLayer}
            selectedParcelNeighborsIds={this.selectedParcelNeighborsIds}
            before={parcelVisualizationsSeparatorLayerId}
          />
        </>
      );
    }
  }
);
