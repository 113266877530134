/* eslint react/display-name: 0 */
import React from "react";
import { Button } from "semantic-ui-react";

import { Operation } from "../../store/operations";

export const InformativeButton = ({
  operation,
  disabled = false,
  ...props
}: {
  content?: React.ReactNode;
  operation: Operation;
  disabled?: boolean;
  style?: React.CSSProperties;
  onClick?: any;
}) => (
  <Button
    loading={operation.isLoading}
    color={operation.justSucceeded ? "green" : operation.justFailed ? "red" : undefined}
    disabled={disabled || !operation.isReady || operation.justSucceeded}
    {...props}
  />
);
