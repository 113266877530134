import axios from "axios";
import { ucUrl, baseUrl } from "../../config/urls";
import { isNullOrUndefined, formatParcelData } from "../../config/utils";

const availableParcelRetrievalMethods = {
  id: {
    requiredParameters: ["parcelId", "layerId"],
    url: query => `${ucUrl}/layers/${query.layerId}/${query.parcelId}`,
    options: () => ({
      params: {
        with_metadata: "true",
        as_geojson: "true",
        retrieve_neighbors: "true"
      }
    })
  },
  geocoding: {
    requiredParameters: ["address", "layerId"],
    url: query => `${ucUrl}/layers/${query.layerId}/${query.address}`,
    options: () => ({
      params: {
        with_metadata: "true",
        as_geojson: "true",
        retrieve_neighbors: "true"
      }
    })
  }
};

export const asyncHeightRetrieval = parcelData =>
  new Promise(resolve => {
    const buildResult = receivedHeight =>
      isNullOrUndefined(receivedHeight) ? {} : { height: parseInt(receivedHeight) };

    if (!isNullOrUndefined(parcelData.height_id)) {
      const url = `${ucUrl}/height/${parcelData.height_id}`;
      axios
        .get(url)
        .then(response => resolve(buildResult(response.data.height)))
        .catch(error => {
          console.error(
            `asyncHeightRetrieval -> Failed to retrieve height information with ${error}`
          );
          resolve(buildResult(null));
        });
    } else {
      resolve(buildResult(null));
    }
  });

export const asyncUpdateMainBuilding = (siteId, buildingId) =>
  new Promise((resolve, reject) => {
    const url = `${baseUrl}/sites/${siteId}`;
    axios
      .put(url, { main_building_id: buildingId })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncZoningRetrieval = (heightId, cityId, zoningId) => {
  if (isNaN(cityId)) {
    throw new Error(`asyncZoningRetrieval -> cityId is not valid (${cityId}).`);
  }

  if (isNaN(zoningId)) {
    throw new Error(`asyncZoningRetrieval -> zoningId is not valid (${zoningId}).`);
  }

  return new Promise(resolve => {
    const buildResult = (receivedZoningCode, receivedHeight) => {
      if (isNullOrUndefined(receivedZoningCode) && isNullOrUndefined(receivedHeight)) return {};
      let result = {};
      if (!isNullOrUndefined(receivedZoningCode)) result.zoningCode = receivedZoningCode;

      if (isNullOrUndefined(heightId) && !isNullOrUndefined(receivedHeight))
        result.height = receivedHeight;

      return result;
    };
    const url = `${ucUrl}/zoning/${cityId}/${zoningId}`;
    axios
      .get(url)
      .then(response => resolve(buildResult(response.data.zoning_code, response.data.max_height)))
      .catch(error => {
        console.error(
          `asyncZoningRetrieval -> Failed to retrieve zoning information with ${error}`
        );
        resolve(buildResult(null, null));
      });
  });
};

/**
 * Format result of parcel depending on the parcel retrieval method
 * Request any extra data if needed
 *
 * @argument {Object} query             - Query used to request the data
 * @argument {Object} parcelData        - Data obtained after query was succesfully run
 *
 * @return {Promise<ParcelInformation>} - Rejects if formatting or extra data retrieval fails,
 *                                        resolves to a ParcelInformation object.
 */
export const asyncFormatAndCompleteParcelData = (query, parcelData) =>
  new Promise((resolve, reject) => {
    const { height_id, city_id, zoning_id } = parcelData;
    const mainDataPromises = [
      asyncHeightRetrieval(parcelData),
      asyncZoningRetrieval(height_id, city_id, zoning_id)
    ];

    const neighborsDataPromises = isNullOrUndefined(parcelData.neighbors)
      ? []
      : parcelData.neighbors.map(neighborData =>
          asyncFormatAndCompleteParcelData(query, neighborData)
        );

    Promise.all([Promise.all(mainDataPromises), Promise.all(neighborsDataPromises)])
      .then(result => {
        const mainDataResult = result[0];
        const neighborsDataResult = result[1];

        let parcelInformation = formatParcelData(query, parcelData);
        mainDataResult.forEach(chunk => Object.assign(parcelInformation, chunk));
        parcelInformation.neighbors = neighborsDataResult;

        resolve(parcelInformation);
      })
      .catch(reject);
  });

export const asyncParcelRetrieval = query =>
  new Promise((resolve, reject) => {
    if (isNullOrUndefined(query.method))
      return reject(`asyncParcelRetrieval -> Missing parcel retrieval method.`);

    if (isNullOrUndefined(availableParcelRetrievalMethods[query.method]))
      return reject(
        `asyncParcelRetrieval -> Unknown parcel retrieval method (${query.method}).
        Known methods are ${Object.keys(availableParcelRetrievalMethods)}.`
      );

    const requiredParameters = availableParcelRetrievalMethods[query.method].requiredParameters;
    for (const parameter of requiredParameters) {
      if (isNullOrUndefined(query[parameter]))
        return reject(
          `asyncParcelRetrieval -> Not enough parameters for retrieval by ${query.method}.
          ${requiredParameters} are needed.`
        );
    }

    const chosenMethod = availableParcelRetrievalMethods[query.method];
    axios
      .get(chosenMethod.url(query), chosenMethod.options(query))
      .then(response => asyncFormatAndCompleteParcelData(query, response.data))
      .then(resolve)
      .catch(reject);
  });

/**
 * Post new parcel to UC
 *
 * @param {Object} parcelInformation
 * @param {GeoJSON} parcelInformation.theGeomGeoJSON      GeomGeoJSON of the Parcel being posted
 * @param {[Number, Number]} parcelInformation.centroid   Centroid as [latitude, longitude]
 *
 * @typedef {Object} PostParcelResponse
 * @property {number} id
 *
 * @return {Promise<PostParcelResponse>}
 */
export const postParcel = parcelInformation => {
  const uc_token = localStorage.getItem("uc_token");
  return new Promise((resolve, reject) => {
    axios
      .post(`${ucUrl}/parcels/`, parcelInformation, { params: { token: uc_token } })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

/**
 * Request list of Projects from penciler-server
 *
 * @return {Promise<(Project & { hidden: boolean })[]>}
 */
export const asyncProjectList = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/projects`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

/**
 * Set a project as hidden/shown
 *
 * @param {number} projectId        - ID of project being shown/hidden
 * @param {boolean} hide            - Whether to hide or unhide it
 * @return {Promise<number[]>}
 */
export const asyncSetHiddenProject = (projectId, hide) =>
  new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}/projects/show_hide/${projectId}`, { hide })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

/**
 * Request project by id
 *
 * @param {number} projectId           - id of project to retrieve
 * @return {Promise<Project>}
 */

export const asyncProjectById = projectId =>
  new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/projects/${projectId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

/**
 * Request list of buildings for a given project
 *
 * @param {number} projectId  - Id of project to get buildings
 * @return {Promise<{[siteId:number]: Building[]}>}
 *
 *  */

export const asyncProjectBuildings = projectId =>
  new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/buildings/${projectId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

/**
 * Post a new project
 *
 * @typedef {Object} NewProject
 * @property {number} id       - id of new project added
 * @param {Object} projectObj  - project object to be added to database
 * @return {Promise<NewProject>}
 */

export const asyncNewProject = projectObj =>
  new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/projects`, projectObj)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

/**
 * Delete a project
 *
 * @param {number} projectId  - id of project to delete
 * @return {Promise}
 *
 */

export const asyncDeleteProject = projectId =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}/projects/${projectId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

/**
 * Edit a project
 *
 * @param {number} projectId        - id of project to be updated
 * @param {Partial<Project>} data   - data to be updated
 * @return {Promise}
 */

export const asyncEditProject = (projectId, data) =>
  axios.put(`${baseUrl}/projects/${projectId}`, data);

/**
 * Delete building option from project
 * @param {number} buildingId  - Id of building to remove
 * @return {Promise}
 */

export const asyncDeleteBuilding = buildingId =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}/buildings/id/${buildingId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

/**
 * Delete building option from project
 * @param {number[]} buildingIdList   - List of ids of buildings to remove
 * @param {number} siteId          - Id of development site of buildings to remove
 * @return {Promise}
 */

export const asyncDeleteBuildingList = (buildingIdList, siteId) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/buildings/delete_multiple/${siteId}`, { building_ids: buildingIdList })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
