/**
 * Created by jmartinez on 9/25/18.
 */

import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Field, formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { Segment, Form, Button, Grid } from "semantic-ui-react";
import {
  validateInt,
  programSteps,
  initStairwells,
  getInitCourtyardWidth,
  initElevators,
  initHallwayWidth,
  initAccessibleRoof
} from "../../config/config";
import { getDistanceLabel } from "../../config/utils";
import { validateStairsDeadEnd, validateStairsTravelDistance } from "../../config/validators";
import { actions, ownerships, resources } from "../../constants/permissions";
import { submitCirculation } from "../../controllers/programs";
import { userIsAllowedToAny } from "../../helpers/permissions";
import { RootState } from "../../store";
import { setActiveModalForm } from "../../store/projects";
import "../../stylesheets/CirculationStep.css";
import { CheckboxInput, CheckboxInputField } from "../forms/CheckboxInput";
import Steps from "../Steps";
import WizardStep from "../WizardStep";
import TextInput from "./../forms/TextInput";
import WizardTextInput from "./../forms/WizardTextInput";

const footerMapStateToProps = () => {
  return {};
};

const footerConnector = connect(footerMapStateToProps, { setActiveModalForm, submitCirculation });
type FooterProps = ConnectedProps<typeof footerConnector>;

const footerButtons: React.FC<FooterProps> = props => {
  const canCreateOrUpdate = userIsAllowedToAny(
    [actions.update, actions.create],
    [ownerships.all, ownerships.own, ownerships.organization],
    resources.customProgram
  );

  return (
    <div>
      <Button
        onClick={() => {
          props.setActiveModalForm(programSteps["program_step_2"]);
        }}
      >
        Back
      </Button>

      {canCreateOrUpdate ? <Button onClick={() => props.submitCirculation()}>Next</Button> : null}
    </div>
  );
};

export const CirculationStepFooter = footerConnector(footerButtons);

const mapStateToProps = (state: RootState) => {
  const { programValues } = state.programs;
  const { metric } = state.users;
  const initialValues = {
    typology_all: true,
    number_stairwells: initStairwells,
    number_elevators: initElevators,
    hallway_width: initHallwayWidth,
    minimum_courtyard_width: getInitCourtyardWidth(),
    multiple_buildings: false,
    accessible_roof: initAccessibleRoof,
    typology_i: false,
    typology_c: false,
    typology_h: false,
    typology_l: false,
    typology_t: false,
    typology_e: false,
    stairs_max_dead_end: metric ? 6.09 : 20,
    stairs_max_travel_distance: metric ? 76.2 : 250,
    specified_typologies: false,
    ...programValues
  };

  initialValues["specified_typologies"] = !initialValues["typology_all"];
  return {
    initialValues,
    editProgram: state.programs.editProgram.multi_family,
    allTypologies: selector(state, "typology_all"),
    specifiedTypologies: selector(state, "specified_typologies")
  };
};

const connector = connect(mapStateToProps, { setActiveModalForm });

type CirculationStepProps = ConnectedProps<typeof connector>;
type CirculationStepFormProps = CirculationStepProps & InjectedFormProps<{}, CirculationStepProps>;

class CirculationStep extends WizardStep<CirculationStepFormProps> {
  handleBackClick(e: any) {
    e.preventDefault();
    this.props.setActiveModalForm("main");
  }

  handleAllTypologyToggle() {
    this.props.change("specified_typologies", this.props.allTypologies);
  }

  handleSelectedTypologyToggle() {
    this.props.change("typology_all", this.props.specifiedTypologies);
  }

  render() {
    const { editProgram, handleSubmit, initialValues } = this.props;
    const stepData = [
      { title: "Unit Mix", active: false, disabled: false },
      { title: "Non-Residential", active: false, disabled: false },
      { title: "Circulation", active: true, disabled: false }
    ];
    const originalData: { originalData: typeof initialValues } | {} =
      editProgram && !this.readOnlyMode ? { originalData: initialValues } : {};
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Steps stepData={stepData} />
        <Form onSubmit={handleSubmit}>
          <Segment>
            <Field
              name="minimum_courtyard_width"
              component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
              label="Minimum Courtyard Width:"
              unitLabel={getDistanceLabel()}
              labelPosition="right"
              validate={[validateInt]}
              readOnly={this.readOnlyMode}
              {...originalData}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Field
                name="accessible_roof"
                component="input"
                type="checkbox"
                style={{ marginRight: "8px" }}
                readOnly={this.readOnlyMode}
                {...originalData}
              />
              <br />
              <label>Has accessible roof</label>
            </div>
          </Segment>
          <h5>Interior circulation is automatically calculated.</h5>

          <Segment>
            <Grid className={"field-group"} columns={3}>
              <Grid.Row>
                <div>
                  <b>{"Corridor Typology:"}</b>
                </div>
              </Grid.Row>
              <Grid.Row>
                <CheckboxInputField
                  name="typology_all"
                  label="All Typologies"
                  component={CheckboxInput}
                  toggle
                  onChange={() => this.handleAllTypologyToggle()}
                  readOnly={this.readOnlyMode}
                />
              </Grid.Row>
              <Grid.Row>
                <CheckboxInputField
                  name="specified_typologies"
                  label="Specified Typologies"
                  component={CheckboxInput}
                  toggle
                  onChange={() => this.handleSelectedTypologyToggle()}
                  readOnly={this.readOnlyMode}
                />
              </Grid.Row>
              {this.props.specifiedTypologies ? (
                <>
                  <Grid.Row>
                    {["I", "C", "H"].map(typology => (
                      <Grid.Column key={`typology-column-${typology}`}>
                        <CheckboxInputField
                          name={`typology_${typology.toLowerCase()}`}
                          label={`${typology} Type`}
                          component={CheckboxInput}
                          readOnly={this.readOnlyMode}
                        />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                  <Grid.Row>
                    {["L", "T", "E"].map(typology => (
                      <Grid.Column key={`typology-column-${typology}`}>
                        <CheckboxInputField
                          name={`typology_${typology.toLowerCase()}`}
                          label={`${typology} Type`}
                          component={CheckboxInput}
                          readOnly={this.readOnlyMode}
                        />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                  {this.props.error ? (
                    <label style={{ color: "red" }}>{this.props.error}</label>
                  ) : null}
                </>
              ) : null}
            </Grid>
          </Segment>
          <Segment>
            <Grid className={"field-group"} columns={2}>
              <Grid.Row>
                <div>
                  <b>{"Vertical Circulation:"}</b>
                </div>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Field
                    name="stairs_max_dead_end"
                    component={TextInput}
                    label="Stairs Max Dead End:"
                    unitLabel={getDistanceLabel()}
                    validate={[validateStairsDeadEnd]}
                    labelPosition="right"
                    fluid
                    readOnly={this.readOnlyMode}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Field
                    name="stairs_max_travel_distance"
                    component={TextInput}
                    label="Stairs Max Travel Distance:"
                    unitLabel={getDistanceLabel()}
                    validate={[validateStairsTravelDistance]}
                    labelPosition="right"
                    fluid
                    readOnly={this.readOnlyMode}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Form>
      </div>
    );
  }
}

const selector = formValueSelector("CirculationForm");

const CirculationForm = reduxForm<{}, CirculationStepProps>({
  form: "CirculationForm",
  destroyOnUnmount: false
})(CirculationStep);

export default connector(CirculationForm);
