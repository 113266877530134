/**
 * Created by jmartinez on 8/30/18.
 */

import { difference, isNull, groupBy, keys } from "lodash";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { reset } from "redux-form";
import {
  Accordion,
  Confirm,
  Dimmer,
  Header,
  Icon,
  Input,
  Loader,
  Menu,
  Table
} from "semantic-ui-react";
import { getCitiesPlaceholder, mainMenuHeight, projectListHeaderHeight } from "../config/config";
import { actions, ownerships, resources } from "../constants/permissions";
import { apiDeleteProject, apiGetProjectList } from "../controllers/projects";
import { renameProject, updateProjectsNameFilter } from "../controllers/ui";
import { userIsAllowedToAny } from "../helpers/permissions";
import { RootState } from "../store";
import { getCitiesList } from "../store/cities";
import {
  checkEditBox,
  cleanPreviousParcelSelection,
  setActiveAccordionIndex,
  setSideBarComponent,
  toggleEditProjectList
} from "../store/projects";
import { closeProjectRenameModal, openProjectRenameModal } from "../store/ui/index";
import { Project } from "../types/projects";
import { SidebarComponent } from "../types/ui";
import ProjectListItem from "./ProjectListItem";
import ProjectListItemEdit from "./ProjectListItemEdit";
import RenameModal from "./RenameModal";
import "../stylesheets/ProjectList.css";

const projectCtrl = { apiDeleteProject, apiGetProjectList };
const projectActions = {
  checkEditBox,
  cleanPreviousParcelSelection,
  setActiveAccordionIndex,
  setSideBarComponent,
  toggleEditProjectList
};
const citiesActions = { getCitiesList };
const uiCtrl = { renameProject, updateProjectsNameFilter };
const uiActions = { closeProjectRenameModal, openProjectRenameModal };

const actionObj = {
  ...citiesActions,
  ...projectActions,
  ...projectCtrl,
  ...uiActions,
  ...uiCtrl
};

const mapStateToProps = (state: RootState) => {
  return {
    ui: state.ui.index,
    citiesLoaded: state.cities.citiesList.length > 0,
    projectList: state.projects.projectList,
    toggleEdit: state.projects.toggleEdit,
    editProjectList: state.projects.editProjectList,
    activeAccordionIndex: state.projects.activeAccordionIndex,
    displayProjectRenameModal: state.ui.index.displayProjectRenameModal,
    projectRenameOperation: state.operations.projectRename,
    projectListFetchOperation: state.operations.projectListFetch
  };
};

const connector = connect(mapStateToProps, actionObj);

type PropsFromRedux = ConnectedProps<typeof connector>;
type ProjectListProps = {} & PropsFromRedux;

type ProjectListState = {
  listHeight: number;
  confirmOpen: boolean;
  activeIndex: number;
};

class ProjectList extends React.Component<ProjectListProps, ProjectListState> {
  constructor(props: ProjectListProps) {
    super(props);

    this.state = {
      listHeight: window.innerHeight - mainMenuHeight,
      confirmOpen: false,
      activeIndex: 0
    };
  }
  componentDidMount() {
    if (this.props.projectList.ids.length === 0) {
      this.props.apiGetProjectList();
    }
    if (!this.props.citiesLoaded) {
      this.props.getCitiesList();
    }
    window.addEventListener("resize", this.setListHeight.bind(this));
  }

  componentWillUnmount() {
    this.props.updateProjectsNameFilter("");
  }

  handleToggleEdit() {
    this.props.toggleEditProjectList();
  }

  handleClickSelectAll() {
    this.props.checkEditBox(this.props.projectList.ids);
  }

  handleClickSelectNone() {
    this.props.checkEditBox([]);
  }

  handleClickInvert() {
    this.props.checkEditBox(difference(this.props.projectList.ids, this.props.editProjectList));
  }

  handleDeleteProjects() {
    const { editProjectList } = this.props;
    editProjectList.forEach(id => this.props.apiDeleteProject(id));
    this.props.checkEditBox([]);
  }

  cancelRenameProject(event: any) {
    event.preventDefault();
    this.props.closeProjectRenameModal();
    reset("RenameModalForm");
  }

  saveRenameProject() {
    this.props.renameProject();
  }

  setListHeight() {
    this.setState({
      listHeight: window.innerHeight - mainMenuHeight
    });
  }

  handleAccordionClick(cityId: number) {
    const newIndex = this.props.activeAccordionIndex === cityId ? -1 : cityId;
    this.props.setActiveAccordionIndex(newIndex);
  }

  editControlsHeight = 34.3;

  render() {
    const { projectList, activeAccordionIndex, editProjectList } = this.props;

    const createProjectList = (data: Project[]) => {
      if (!Array.isArray(data))
        throw new Error("ProjectList::render::createProjectList -> `data` should be an array.");

      return data.map(project => {
        return this.props.toggleEdit ? (
          <ProjectListItemEdit key={project.id} project={project} />
        ) : (
          <ProjectListItem key={project.id} project={project} />
        );
      });
    };

    const displayedProjects = projectList.ids
      .filter(id => this.props.ui.displayedProjectsIDs.includes(id))
      .filter(id => !isNull(projectList.byId[id].parcel_data.city_id))
      .map(id => projectList.byId[id]);
    const groupedProjectList = groupBy(displayedProjects, project => project.parcel_data.city_id);

    let listHeight = this.state.listHeight - projectListHeaderHeight;
    if (this.props.toggleEdit) {
      listHeight -= this.editControlsHeight;
    }

    return (
      <>
        <Menu className="projectListHeader" size="tiny" style={{ height: projectListHeaderHeight }}>
          <Input
            className="projectSearch"
            style={{ width: "170px" }}
            icon="search"
            placeholder="Search"
            onChange={event =>
              this.props.updateProjectsNameFilter((event.target as HTMLInputElement).value)
            }
          />
          <Menu.Item
            className="addProject"
            onClick={() => {
              this.props.setSideBarComponent(SidebarComponent.addNewProject);
              this.props.cleanPreviousParcelSelection();
            }}
          >
            {userIsAllowedToAny(actions.create, ownerships.all, resources.project)
              ? "Add Project"
              : "Search Parcel"}
          </Menu.Item>
          {userIsAllowedToAny(
            actions.update,
            [ownerships.own, ownerships.organization],
            resources.project
          ) ? (
            <Menu.Item
              style={{
                width: "84px",
                background: this.props.toggleEdit ? "#366CC5" : "",
                color: this.props.toggleEdit ? "white" : ""
              }}
              onClick={() => {
                this.handleToggleEdit();
              }}
            >
              <div style={{ margin: "0 auto" }}>Edit</div>
            </Menu.Item>
          ) : null}
        </Menu>
        {this.props.toggleEdit ? (
          <Menu className="editProjectsControls" secondary size="mini" fluid>
            <Menu.Item>
              <Header as="h5">Select: </Header>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.handleClickSelectAll();
              }}
            >
              <p>All</p>
            </Menu.Item>
            <Menu.Item
              disabled={this.props.editProjectList.length === 0}
              onClick={() => {
                this.handleClickSelectNone();
              }}
            >
              <p>None</p>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.handleClickInvert();
              }}
            >
              <p>Invert</p>
            </Menu.Item>
            <Menu.Item
              disabled={this.props.editProjectList.length === 0}
              onClick={() => {
                this.setState({ confirmOpen: true });
              }}
            >
              Delete
            </Menu.Item>
            <Menu.Item
              disabled={this.props.editProjectList.length !== 1}
              onClick={() => {
                this.props.openProjectRenameModal();
              }}
            >
              Rename
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.handleToggleEdit();
              }}
            >
              Done
            </Menu.Item>
          </Menu>
        ) : (
          <div />
        )}
        <div style={{ height: listHeight, background: "white", overflowX: "hidden" }}>
          <Confirm
            content="Are you sure you want to delete these projects?"
            open={this.state.confirmOpen}
            onCancel={() => {
              this.setState({ confirmOpen: false });
            }}
            onConfirm={() => {
              this.handleDeleteProjects();
              this.setState({ confirmOpen: false });
            }}
            size="mini"
          />
          <Dimmer inverted blurring="true" active={this.props.projectListFetchOperation.isLoading}>
            <Loader size="big" />
          </Dimmer>
          <div>
            {projectList.ids.length > 0 ? (
              <Accordion>
                {keys(groupedProjectList).map(cityIdString => {
                  const cityId = parseInt(cityIdString, 10);
                  return (
                    <React.Fragment key={cityId}>
                      <Accordion.Title
                        index={cityId}
                        active={activeAccordionIndex === cityId}
                        onClick={() => this.handleAccordionClick(cityId)}
                        style={{
                          borderBottom: "1px solid rgba(34,36,38,.15)",
                          backgroundColor: "#FCFCFC"
                        }}
                      >
                        <Icon name="dropdown" />
                        {getCitiesPlaceholder()[cityId]}
                      </Accordion.Title>
                      <Accordion.Content
                        active={activeAccordionIndex === cityId}
                        style={{ padding: 0, border: 0 }}
                      >
                        <Table fixed>
                          <Table.Body>{createProjectList(groupedProjectList[cityId])}</Table.Body>
                        </Table>
                      </Accordion.Content>
                    </React.Fragment>
                  );
                })}
              </Accordion>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px"
                }}
              >
                <Header as="h3">
                  No projects to display
                  <Header.Subheader>{`Click 'Add Project' to get started.`}</Header.Subheader>
                </Header>
              </div>
            )}
          </div>
        </div>
        <RenameModal
          what="Project"
          open={this.props.displayProjectRenameModal}
          cancelAction={event => this.cancelRenameProject(event)}
          saveAction={() => this.saveRenameProject()}
          initialValues={{ name: projectList.byId[editProjectList[0]]?.name ?? "" }}
          operation={this.props.projectRenameOperation}
        />
      </>
    );
  }
}

export default connector(ProjectList);
