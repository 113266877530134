/**
 * Created by jmartinez on 9/25/18.
 */

import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Grid, Segment, Form, Button } from "semantic-ui-react";
import {
  validateInt,
  validatePercentage,
  validateUnitSqft,
  validateGroundFloorHeight,
  unitLabels
} from "../../config/config";
import { getAreaLabel, getDistanceLabel } from "../../config/utils";
import * as programCtrl from "../../controllers/programs";
import * as projectCtrl from "../../controllers/projects";
import Steps from "../Steps";

import WizardStep from "../WizardStep";
import TextInput from "./../forms/TextInput";
import WizardTextInput from "./../forms/WizardTextInput";

const actionsObj = Object.assign({}, programCtrl, projectCtrl);

const footerButtons = props => {
  return (
    <div>
      <Button
        onClick={() => {
          props.submitUnitMix();
        }}
      >
        Next
      </Button>
    </div>
  );
};

export const UnitMixStepFooter = connect(() => {
  return {};
}, actionsObj)(footerButtons);

class UnitMixStep extends WizardStep {
  componentDidMount() {
    this.props.apiGetProgramsList();
  }

  render() {
    const { handleSubmit, editProgram, initialValues } = this.props;
    const stepData = [
      { title: "Unit Mix", active: true, disabled: false },
      { title: "Non-Residential", active: false, disabled: true },
      { title: "Circulation", active: false, disabled: true }
    ];
    const originalData = editProgram && !this.readOnlyMode ? { originalData: initialValues } : {};
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Steps stepData={stepData} />
        <Form onSubmit={handleSubmit}>
          <Grid>
            {!editProgram ? (
              <Grid.Row>
                <Grid.Column width={16}>
                  <Segment>
                    <Field
                      name="name"
                      component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                      label="New Building Program Name"
                      required
                      placeholder="Program Name"
                      readOnly={this.readOnlyMode}
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            ) : null}
            <Grid.Row>
              <Grid.Column width={16}>
                <Segment>
                  <label>Unit Mix</label>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                      alignItems: "stretch",
                      alignContent: "stretch"
                    }}
                  >
                    <div style={{ marginRight: "10px" }}>
                      <Field
                        name="area_studio"
                        component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label={`${unitLabels.apartments.full.singular[0]}:`}
                        unitLabel={getAreaLabel()}
                        labelPosition="right"
                        validate={[validateUnitSqft]}
                        readOnly={this.readOnlyMode}
                        {...originalData}
                      />
                      <Field
                        name="area_1"
                        component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label={`${unitLabels.apartments.full.singular[1]}:`}
                        unitLabel={getAreaLabel()}
                        labelPosition="right"
                        validate={[validateUnitSqft]}
                        readOnly={this.readOnlyMode}
                        {...originalData}
                      />
                      <Field
                        name="area_2"
                        component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label={`${unitLabels.apartments.full.singular[2]}:`}
                        unitLabel={getAreaLabel()}
                        labelPosition="right"
                        validate={[validateUnitSqft]}
                        readOnly={this.readOnlyMode}
                        {...originalData}
                      />
                      <Field
                        name="area_3"
                        component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label={`${unitLabels.apartments.full.singular[3]}:`}
                        unitLabel={getAreaLabel()}
                        labelPosition="right"
                        validate={[validateUnitSqft]}
                        readOnly={this.readOnlyMode}
                        {...originalData}
                      />
                      <Field
                        name="area_4"
                        component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label={`${unitLabels.apartments.full.singular[4]}:`}
                        unitLabel={getAreaLabel()}
                        labelPosition="right"
                        validate={[validateUnitSqft]}
                        readOnly={this.readOnlyMode}
                        {...originalData}
                      />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <Field
                        name="prop_studio"
                        component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label={`${unitLabels.apartments.full.singular[0]}:`}
                        unitLabel="%"
                        labelPosition="right"
                        validate={[validatePercentage, validateInt]}
                        readOnly={this.readOnlyMode}
                        {...originalData}
                      />
                      <Field
                        name="prop_1"
                        component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label={`${unitLabels.apartments.full.singular[1]}:`}
                        unitLabel="%"
                        labelPosition="right"
                        validate={[validatePercentage, validateInt]}
                        readOnly={this.readOnlyMode}
                        {...originalData}
                      />
                      <Field
                        name="prop_2"
                        component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label={`${unitLabels.apartments.full.singular[2]}:`}
                        unitLabel="%"
                        labelPosition="right"
                        validate={[validatePercentage, validateInt]}
                        readOnly={this.readOnlyMode}
                        {...originalData}
                      />
                      <Field
                        name="prop_3"
                        component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label={`${unitLabels.apartments.full.singular[3]}:`}
                        unitLabel="%"
                        labelPosition="right"
                        validate={[validatePercentage, validateInt]}
                        readOnly={this.readOnlyMode}
                        {...originalData}
                      />
                      <Field
                        name="prop_4"
                        component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                        label={`${unitLabels.apartments.full.singular[4]}:`}
                        unitLabel="%"
                        labelPosition="right"
                        validate={[validatePercentage, validateInt]}
                        readOnly={this.readOnlyMode}
                        {...originalData}
                      />
                    </div>
                  </div>
                  <br />
                  <Field
                    id="ceiling_height"
                    name="ceiling_height"
                    component={editProgram && !this.readOnlyMode ? WizardTextInput : TextInput}
                    label="Residential Floor Height"
                    unitLabel={getDistanceLabel()}
                    labelPosition="right"
                    validate={[validateGroundFloorHeight]}
                    readOnly={this.readOnlyMode}
                    {...originalData}
                  />
                  {this.props.error ? (
                    <label style={{ color: "red" }}>{this.props.error}</label>
                  ) : (
                    ""
                  )}
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { programInitialValues } = state.programs;
  const initValues = Object.assign({}, programInitialValues);
  initValues["prop_studio"] = programInitialValues.hasOwnProperty("prop_studio")
    ? Math.round(programInitialValues["prop_studio"] * 100).toString()
    : null;
  initValues["prop_1"] = programInitialValues.hasOwnProperty("prop_1")
    ? Math.round(programInitialValues["prop_1"] * 100).toString()
    : null;
  initValues["prop_2"] = programInitialValues.hasOwnProperty("prop_2")
    ? Math.round(programInitialValues["prop_2"] * 100).toString()
    : null;
  initValues["prop_3"] = programInitialValues.hasOwnProperty("prop_3")
    ? Math.round(programInitialValues["prop_3"] * 100).toString()
    : null;
  initValues["prop_4"] = programInitialValues.hasOwnProperty("prop_4")
    ? Math.round(programInitialValues["prop_4"] * 100).toString()
    : null;
  return {
    initialValues: initValues,
    editProgram: state.programs.editProgram.multi_family
  };
};

const UnitMixForm = reduxForm({
  form: "UnitMixForm",
  destroyOnUnmount: false,
  onSubmitFail: () => {
    document.getElementById("ceiling_height").scrollIntoView({ behavior: "smooth" });
  }
})(UnitMixStep);

export default connect(mapStateToProps, actionsObj)(UnitMixForm);
