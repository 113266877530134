import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  header: {
    backgroundColor: "#22556A",
    color: "#FFFFFF",
    width: "100%",
    height: "10mm",
    justifyContent: "center",
    textTransform: "uppercase",
    top: 0
  },
  headerText: {
    fontWeight: 700,
    fontSize: 13,
    marginLeft: "5mm"
  }
});

const Header: React.FunctionComponent<{}> = () => {
  return (
    <View style={styles.header}>
      <Text style={styles.headerText}>Penciler Project Summary</Text>
    </View>
  );
};

export default Header;
