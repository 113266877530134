import { Marker } from "mapbox-gl";
import React from "react";

import markerImageHover from "../../data/images/icons8-marker-40-hover.png";
import markerImage from "../../data/images/icons8-marker-40.png";

export default class ProjectMarker extends React.Component {
  constructor(props) {
    super(props);
    const { coordinate, projectId } = props;
    const element = document.createElement("div");
    element.className = "marker";
    element.style.backgroundImage = `url(${markerImage})`;
    element.style.width = "40px";
    element.style.height = "40px";
    element.style.marginBottom = "20px";
    element.id = `project-marker-${projectId}`;
    element.addEventListener("click", event => {
      event.stopPropagation();
      props.openProject(projectId);
    });
    element.addEventListener("mouseover", () => props.focusProject(projectId));
    element.addEventListener("mouseout", props.unfocusProject);
    this.marker = new Marker({ element, anchor: "bottom" }).setLngLat(coordinate.asArray);
    this.marker.addTo(this.props.map);
  }
  componentWillUnmount() {
    this.marker.remove();
  }
  render() {
    const { isFocused } = this.props;
    this.marker.getElement().style.backgroundImage = isFocused
      ? `url(${markerImageHover})`
      : `url(${markerImage})`;
    return null;
  }
}
