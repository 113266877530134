/**
 * Read Penciler api token from local storage getter
 *
 * @return {string}                         - Retrieved token
 */
export const getPencilerToken = (): string | null => localStorage.getItem("token");

/**
 * Read UC api token from local storage getter
 *
 * @return {string}                         - Retrieved token
 */
export const getUCToken = (): string | null => localStorage.getItem("uc_token");
