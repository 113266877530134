import area from "@turf/area";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { Coord, Feature, lineString, Point, point, Properties } from "@turf/helpers";
import {
  bearing,
  destination,
  distance,
  lineIntersect,
  pointOnFeature,
  pointOnLine,
  pointToLineDistance,
  polygonize,
  polygonToLine,
  union
} from "@turf/turf";
import { sqMetersToSqFeet } from "../../../../config/config";
import {
  formatCommas,
  formatDistanceLabel,
  getShortAreaLabel,
  latitudeIndex,
  longitudeIndex
} from "../../../../config/utils";
import store from "../../../../store";
import { exists } from "../../../../utils";
import { DrawFeature, DrawMode, DrawMouseEvent } from "../../commonTypes";
import {
  boundarySnappingDistance,
  endpointDistanceExtension,
  Modes,
  multiLineId,
  projectBoundaryId,
  vertexSnappingDistance
} from "../../constants";
import {
  createVertex,
  getClosestPoint,
  isFeatureIntersectingItself,
  snapAtAngle
} from "../../utils";

type DrawLineModeState = {
  line: DrawFeature;
  multiLine: DrawFeature;
  projectBoundary: DrawFeature;
  currentVertexPosition: number;
  vertexOnBoundary: boolean;
  isMetric: boolean;
  amountOfSubsitesShown: number;
  lastMousePos: [number, number];
};

const DrawLineMode: DrawMode = {
  onSetup: function(): DrawLineModeState {
    let multiLine;
    const projectBoundary = this.getFeature(projectBoundaryId);
    multiLine = this.getFeature(multiLineId);
    const line = this.newFeature({
      type: "Feature",
      properties: {
        meta: "feature",
        intersects: "false",
        canBeCompleted: "false"
      },
      geometry: {
        type: "LineString",
        coordinates: []
      }
    });
    const currentVertexPosition = 0;
    if (multiLine === undefined) {
      multiLine = this.newFeature({
        type: "Feature",
        id: multiLineId,
        properties: {
          meta: "feature",
          intersects: "false",
          canBeCompleted: "false"
        },
        geometry: {
          type: "MultiLineString",
          coordinates: []
        }
      });
      this.addFeature(multiLine);
    }

    line.addCoordinate(currentVertexPosition, 0, 0);
    this.addFeature(line);

    const isMetric = store.getState().users.metric;

    return {
      line,
      multiLine,
      projectBoundary,
      currentVertexPosition,
      vertexOnBoundary: false,
      isMetric,
      amountOfSubsitesShown: 0,
      lastMousePos: [0, 0]
    };
  },

  pushLine: function(state: DrawLineModeState) {
    const coords = state.multiLine.getCoordinates();
    coords.push(state.line.coordinates);
    state.multiLine.setCoordinates(coords);
    return coords.length - 1;
  },

  isIntersecting: function(line: DrawFeature) {
    return isFeatureIntersectingItself(line);
  },

  removeLastVertex(state: DrawLineModeState) {
    if (state.currentVertexPosition > 0) {
      // Remove last placed vertex of the current line
      state.line.removeCoordinate(state.currentVertexPosition);
      state.currentVertexPosition -= 1;
      this.updateVertexPosition(state, state.lastMousePos);
    }
  },

  onMouseMove: function(state: DrawLineModeState, e: DrawMouseEvent) {
    this.updateUIClasses({ mouse: "add" });
    state.lastMousePos = [e.lngLat.lng, e.lngLat.lat];
    // We only care for angle snapping if we have at least two vertices
    if (e.originalEvent.shiftKey && state.currentVertexPosition > 0) {
      const coords = state.line.getCoordinates();

      let A;
      const O = coords[state.currentVertexPosition - 1];
      if (state.currentVertexPosition >= 2) {
        // If we have at least three vertices then use the previous of the previous
        A = coords[state.currentVertexPosition - 2];
      } else {
        // If not we need to look for the boundary vertex
        // of the line where the initial line vertex is attached to
        const parcelWithLines = this.getParcelWithLines(state);
        let parcelCoords;
        if (parcelWithLines.geometry.type === "LineString") {
          parcelCoords = parcelWithLines.geometry.coordinates;
        } else if (parcelWithLines.geometry.type === "MultiLineString") {
          parcelCoords = parcelWithLines.geometry.coordinates.flat();
        }
        parcelCoords.push(parcelCoords[0]);

        for (let i = 1; i < parcelCoords.length && A === undefined; i += 1) {
          const p1 = parcelCoords[i - 1];
          const p2 = parcelCoords[i];

          const line = lineString([p1, p2]);
          const dist = pointToLineDistance(O, line, {
            units: "meters"
          });
          if (dist <= vertexSnappingDistance) {
            A = p1;
          }
        }

        if (A === undefined) {
          return;
        }
      }
      const B: [number, number] = [e.lngLat.lng, e.lngLat.lat];

      const nearestCoord = snapAtAngle(A, O, B) || B;
      this.updateVertexPosition(state, nearestCoord, true);
    } else {
      this.updateVertexPosition(state, state.lastMousePos);
    }

    state.line.properties.canBeCompleted = String(
      state.vertexOnBoundary && state.currentVertexPosition > 0
    );
  },

  onLeftClick: function(state: DrawLineModeState) {
    // Check for intersections to avoid holes
    const intersects = this.isIntersecting(state.line);

    if ((state.currentVertexPosition === 0 || !state.vertexOnBoundary) && !intersects) {
      // Place new vertex and allow to add a new one
      state.currentVertexPosition++;
    } else if (state.vertexOnBoundary && !intersects) {
      // Place last vertex and allow to add a new line
      state.line.properties.canBeCompleted = "false";
      this.pushLine(state);
      this.deleteFeature([state.line.id], { silent: true });
      this.changeMode(Modes.confirm_subdivision, { editLastLine: true });
    }
  },

  onRightClick: function(state: DrawLineModeState, e: DrawMouseEvent) {
    this.removeLastVertex(state);
  },

  onClick: function(state: DrawLineModeState, e: DrawMouseEvent) {
    const button = e.originalEvent.button;
    switch (button) {
      // Left Click
      case 0:
        this.onLeftClick(state);
        break;
      // Right Click
      case 2:
        this.onRightClick(state, e);
        break;
    }
  },

  onKeyUp: function(state: DrawLineModeState, e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.onExit(state);
    } else if ((e.ctrlKey || e.metaKey) && e.key === "z") {
      this.removeLastVertex(state);
    }
  },

  // Join parcel's outline with the multiline
  getParcelWithLines: function(state: DrawLineModeState) {
    const coords = state.multiLine.getCoordinates();
    const multiLine: any = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "MultiLineString",
        coordinates: coords
      }
    };
    return union(polygonToLine(state.projectBoundary) as any, multiLine);
  },

  updateVertexPosition: function(
    state: DrawLineModeState,
    newPosition: [number, number],
    maintainAngle = false
  ) {
    let newPoint = point(newPosition);
    const parcelWithLines = this.getParcelWithLines(state);

    const pointOnBoundary = pointOnLine(parcelWithLines, newPoint) as Feature<Point, Properties>;
    if (
      state.currentVertexPosition === 0 ||
      !booleanPointInPolygon(newPoint, state.projectBoundary) ||
      distance(newPoint, pointOnBoundary, { units: "meters" }) <= boundarySnappingDistance
    ) {
      // Snap point to boundary
      newPoint = pointOnBoundary;
      state.vertexOnBoundary = true;
    } else {
      state.vertexOnBoundary = false;
    }

    if (maintainAngle && state.vertexOnBoundary && state.currentVertexPosition >= 1) {
      newPoint = point(newPosition);
      const coords = state.line.getCoordinates();
      const lastPoint = coords[state.currentVertexPosition - 1];

      const extended = destination(
        newPoint,
        boundarySnappingDistance * 2,
        bearing(lastPoint, newPoint),
        {
          units: "meters"
        }
      );

      const intersections = lineIntersect(
        lineString([lastPoint, extended.geometry?.coordinates]),
        polygonToLine(state.projectBoundary)
      );
      if (intersections.features.length) {
        newPoint = intersections.features[0] as Feature<Point, Properties>;
      } else {
        newPoint = pointOnBoundary;
      }
    } else {
      const [closestPoint, dist] = getClosestPoint(parcelWithLines, newPoint, {
        units: "meters"
      });
      // Snap point to vertex
      if (dist <= vertexSnappingDistance) newPoint = closestPoint as Feature<Point, Properties>;
    }

    // If line.coordinates.length === vertexIdx
    // line.updateCoordinate() will create and add the new vertex
    state.line.updateCoordinate(
      state.currentVertexPosition,
      newPoint.geometry?.coordinates[0],
      newPoint.geometry?.coordinates[1]
    );

    // Check for intersections
    state.line.properties.intersects = String(this.isIntersecting(state.line));
  },

  divideParcel: function(multiLine: DrawFeature, projectBoundary: DrawFeature) {
    const polyAsLine: any = polygonToLine(projectBoundary);

    const lineCoordinates = multiLine.getCoordinates();
    // For each line we need to extend a bit their endpoints so polygonize works as expected
    const resultingCoords = lineCoordinates
      .map((lineCoords: [number, number][]) => {
        try {
          const resultingCoords = [];

          const extendPoint = (p: [number, number], q: [number, number]) => {
            return destination(point(q), endpointDistanceExtension, bearing(point(p), point(q)), {
              units: "meters"
            }).geometry?.coordinates;
          };

          let extendedPoint = extendPoint(lineCoords[1], lineCoords[0]);
          if (extendedPoint !== undefined) {
            resultingCoords.push(extendedPoint);
          }

          resultingCoords.push(...lineCoords);

          // In order to make the subdivision work the edges of the
          // line have to be outside of the polygon
          extendedPoint = extendPoint(
            lineCoords[lineCoords.length - 2],
            lineCoords[lineCoords.length - 1]
          );
          if (extendedPoint !== undefined) {
            resultingCoords.push(extendedPoint);
          }

          return resultingCoords;
        } catch (err) {
          console.error(err);
          return [];
        }
      })
      .filter((coords: [number, number][]) => coords !== []);

    // We need to convert the multiLine to a proper GeoJson format for union to work
    const multiLineGeoJSON: any = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "MultiLineString",
        coordinates: resultingCoords
      }
    };

    try {
      const unionedLines: any = union(multiLineGeoJSON, polyAsLine);
      return polygonize(unionedLines).features.filter(feature => {
        const coord = pointOnFeature(feature).geometry?.coordinates as Coord;
        // Filter out polygons outside of the original
        return booleanPointInPolygon(coord, projectBoundary);
      });
    } catch (e) {
      return [];
    }
  },

  displayProjectBoundary: function(
    state: DrawLineModeState,
    geojson: DrawFeature,
    display: Function
  ) {
    if (state.amountOfSubsitesShown < 2) {
      geojson.properties.meta = "feature";
      geojson.properties.active = "true";
      display(geojson);
      this.displayMeasurements(geojson, display);
    }
  },

  displayMeasurements: function(site: DrawFeature, display: Function) {
    site.geometry.coordinates[0].forEach((endCoord: [number, number], coordinateId: number) => {
      if (coordinateId === 0) return;
      const startCoord = site.geometry.coordinates[0][coordinateId - 1];
      const geometry = {
        type: "LineString",
        coordinates: [
          [startCoord[longitudeIndex], startCoord[latitudeIndex]],
          [endCoord[longitudeIndex], endCoord[latitudeIndex]]
        ]
      };
      display({
        type: "Feature",
        properties: {
          active: "true",
          parent: multiLineId,
          user_length: formatDistanceLabel(geometry)
        },
        geometry: geometry
      });
    });
  },

  displaySubSites: function(state: DrawLineModeState, geojson: DrawFeature, display: Function) {
    const subsites =
      state.multiLine.properties.intersects === "true"
        ? []
        : this.divideParcel(state.multiLine, state.projectBoundary);
    if (subsites.length > 1) {
      subsites.forEach((site: DrawFeature) => {
        // Display Subsite
        display({
          type: "Feature",
          properties: {
            meta: "feature",
            active: "true",
            parent: multiLineId,
            user_label: `${formatCommas(
              Math.round(area(site) * (state.isMetric ? 1 : sqMetersToSqFeet))
            )} ${getShortAreaLabel()}`
          },
          geometry: site.geometry
        });

        // Display Measurements
        this.displayMeasurements(site, display);
      });
    }

    if (
      state.amountOfSubsitesShown !== subsites.length &&
      (subsites.length <= 1 || state.amountOfSubsitesShown <= 1)
    ) {
      this.map.fire("draw.canSubdivide", { canSubdivide: subsites.length > 1 });
      state.amountOfSubsitesShown = subsites.length;
      this.doRender(projectBoundaryId);
    } else {
      state.amountOfSubsitesShown = subsites.length;
    }
  },

  displayMultiLine: function(state: DrawLineModeState, geojson: DrawFeature, display: Function) {
    geojson.properties.meta = "feature";
    geojson.properties.active = "true";
    display(geojson);
  },

  displayLine: function(state: DrawLineModeState, geojson: DrawFeature, display: Function) {
    display(
      createVertex(
        state.line.id,
        geojson.geometry.coordinates[state.currentVertexPosition],
        String(state.currentVertexPosition),
        true
      )
    );
    geojson.properties.meta = "feature";
    geojson.properties.active = "true";
    geojson.properties.user_length = formatDistanceLabel(geojson.geometry);
    display(geojson);
  },

  toDisplayFeatures: function(state: DrawLineModeState, geojson: DrawFeature, display: Function) {
    const geojsonId = geojson?.properties?.id;
    if (geojsonId === projectBoundaryId) {
      this.displayProjectBoundary(state, geojson, display);
    } else if (geojsonId === multiLineId) {
      this.displayMultiLine(state, geojson, display);
      this.displaySubSites(state, geojson, display);
    } else if (geojsonId === state.line.id) {
      this.displayLine(state, geojson, display);
    }
  },

  onExit: function(state: DrawLineModeState) {
    if (exists(state.line)) this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(Modes.site_selected);
  }
};

export default DrawLineMode;
