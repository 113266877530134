import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { List, Icon, Header, Segment } from "semantic-ui-react";
import { palette } from "../constants/palette";
import { RootState } from "../store";

const mapStateToProps = (state: RootState) => {
  return {
    sideSegments: state.projects.sideSegments,
    frontSegments: state.projects.frontSegments,
    showSegmentError: state.projects.showSegmentError
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type SegmentRequirementsProps = {} & PropsFromRedux;

class SegmentRequirements extends React.Component<SegmentRequirementsProps> {
  render() {
    const { frontSegments, sideSegments } = this.props;
    return (
      <React.Fragment>
        <Header size="small">Segment Requirements</Header>
        <Segment.Group>
          <Segment>
            <p>Click parcel edges to set frontage and rear yard</p>
          </Segment>
          <Segment>
            <List>
              <List.Item>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    <svg height="10" width="10">
                      <rect height="10" width="10" style={{ fill: palette.front.primary }} />
                    </svg>
                  </div>
                  <div style={{ marginRight: "10px" }}>
                    <p>Frontage</p>
                  </div>
                  {frontSegments.length > 0 ? (
                    <div style={{ marginRight: "10px" }}>
                      <Icon name="check" color="green" />
                    </div>
                  ) : (
                    <div style={{ marginRight: "10px" }}>
                      <Icon name="cancel" color="red" />
                    </div>
                  )}
                  <div style={{ marginRight: "20px" }}>
                    <span style={{ color: "#D3D3D3" }}>At least 1 required</span>
                  </div>
                </div>
              </List.Item>
              <List.Item>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    <svg height="10" width="10">
                      <rect height="10" width="10" style={{ fill: palette.rear.primary }} />
                    </svg>
                  </div>
                  <div style={{ marginRight: "6px" }}>
                    <p>Rear Yard</p>
                  </div>
                  <div style={{ marginRight: "10px" }}>
                    <Icon name="check" color="green" />
                  </div>
                </div>
              </List.Item>
              <List.Item>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    <svg height="10" width="10">
                      <rect height="10" width="10" style={{ fill: palette.sides.primary }} />
                    </svg>
                  </div>
                  <div style={{ marginRight: "10px" }}>
                    <p>Side Yard</p>
                  </div>
                  {}
                  {sideSegments.length > 0 ? (
                    <div style={{ marginRight: "10px" }}>
                      <Icon name="check" color="green" />
                    </div>
                  ) : (
                    <div style={{ marginRight: "10px" }}>
                      <Icon name="cancel" color="red" />
                    </div>
                  )}
                </div>
              </List.Item>
              {this.props.showSegmentError ? (
                <List.Item>
                  <label style={{ color: "red" }}>
                    Segment error. Make sure parcel has at least 1 frontage.
                  </label>
                </List.Item>
              ) : null}
            </List>
          </Segment>
        </Segment.Group>
      </React.Fragment>
    );
  }
}

export default connector(SegmentRequirements);
