import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DimmedLoaderSlice } from "../slices/ui";

const initialState: DimmedLoaderSlice = {
  active: false,
  message: "Loading...",
  style: {}
};

const slice = createSlice({
  name: "dimmedLoader",
  initialState,
  reducers: {
    showDimmedLoader(state) {
      state.active = true;
    },
    hideDimmedLoader(state) {
      state.active = false;
      state.message = initialState.message;
      state.style = initialState.style;
    },
    setLoadingMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
    setLoaderStyle(state, action: PayloadAction<React.CSSProperties>) {
      state.style = action.payload;
    }
  }
});

export const {
  showDimmedLoader,
  hideDimmedLoader,
  setLoadingMessage,
  setLoaderStyle
} = slice.actions;

export default slice.reducer;
