import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "./index";
import { getCities, getRents } from "../controllers/cities";
import { is } from "../lib";
import { CitiesSlice, City, CityRents } from "./types";

const initialState: CitiesSlice = {
  citiesList: [],
  currentCityRents: []
};

const citiesSlice = createSlice({
  name: "cities",
  initialState,
  reducers: {
    getCitiesListSuccess(state, action: PayloadAction<City[]>) {
      state.citiesList = action.payload;
    },
    getCitiesListFail(state) {
      state.citiesList = [];
    },
    getCurrentCityRentsSuccess(state, action: PayloadAction<CityRents[]>) {
      if (!is.array(action.payload)) {
        throw new TypeError(
          `'action.payload' should be an array. Got ${JSON.stringify(action.payload)} instead.`
        );
      }
      state.currentCityRents = action.payload;
    },
    getCurrentCityRentsFail(state) {
      state.currentCityRents = [];
    }
  }
});

export const getCitiesList = () => async (dispatch: AppDispatch) => {
  try {
    const cities = await getCities();
    dispatch(citiesSlice.actions.getCitiesListSuccess(cities));
  } catch (error) {
    dispatch(citiesSlice.actions.getCitiesListFail());
  }
};

export const getCityRents = (cityId: number) => async (dispatch: AppDispatch) => {
  try {
    const rents = await getRents(cityId);
    rents.sort((a: CityRents, b: CityRents) =>
      (a.rent ?? Infinity) > (b.rent ?? Infinity) ? 1 : -1
    );
    dispatch(citiesSlice.actions.getCurrentCityRentsSuccess(rents));
  } catch (error) {
    dispatch(citiesSlice.actions.getCurrentCityRentsFail());
  }
};

export default citiesSlice.reducer;
