import * as d3 from "d3";
import { geoPath, geoMercator } from "d3-geo";
import { geoScaleBar, geoScaleFeet, geoScaleMeters, geoScaleTop } from "d3-geo-scale-bar";
import { tile } from "d3-tile";
import { isNull } from "lodash";
import React from "react";

import {
  mapBoxStyle,
  tau,
  floorplanVisualization,
  unitLabels,
  floorPlanScaleBarLeft,
  floorPlanScaleBarTop,
  floorPlanMargin
} from "../../config/config";
import { mapbox } from "../../config/map";
import { isNullOrUndefined, getDistanceLabel } from "../../config/utils";
import { buildingFeatureColor } from "../../constants/palette";
import arrow from "../../data/images/compass-arrow.svg";
import { BuildingTypeCode } from "../../types/buildings";
import { exists } from "../../utils";
import { UnitSummaryPopup } from "./UnitSummaryPopup";

class FloorPlanSvg extends React.Component {
  componentDidMount() {
    const { height: floorPlanSvgHeight, width: floorPlanSvgWidth, isMetric } = this.props;

    const scaleBar = geoScaleBar()
      .projection(this.projection())
      .size([floorPlanSvgWidth, floorPlanSvgHeight])
      .orient(geoScaleTop)
      .units(isMetric ? geoScaleMeters : geoScaleFeet)
      .left(floorPlanScaleBarLeft)
      .top(floorPlanScaleBarTop)
      .label(null)
      .tickSize(0)
      .tickFormat(d => `${d} ${getDistanceLabel()}`);

    const styleBar = bar => {
      bar.selectAll("rect").remove();

      const text = bar.select(".tick:last-of-type").text();

      const ticks = bar.selectAll(".tick");

      ticks
        .select("line")
        .attr("y2", -10)
        .style("display", (d, i, e) => (i === e.length - 1 || i === 0 ? "block" : "none"));

      ticks
        .select("text")
        .attr("dy", 0)
        .attr("dx", -20)
        .style("display", (d, i) => (i === 0 ? "block" : "none"));

      bar.select(".tick text").text(text);
    };
    d3.select(document.getElementById("floorplanSVG"))
      .append("g")
      .call(scaleBar)
      .call(styleBar);
  }

  projection() {
    const { selectedBuilding, height: floorPlanSvgHeight, width: floorPlanSvgWidth } = this.props;
    if (!selectedBuilding.hasOwnProperty("geometry"))
      throw new Error(
        "FloorPlanSvg::projection -> `selectedBuilding` should have `geometry` property."
      );

    return geoMercator()
      .scale(1 / tau)
      .fitSize(
        [floorPlanSvgWidth - floorPlanMargin, floorPlanSvgHeight - floorPlanMargin],
        selectedBuilding.geometry.reduced_parcel
      );
  }

  render() {
    const { selectedBuilding, informationIdx, information, height, width } = this.props;
    const buildingTypeCode = selectedBuilding.building_type.code;
    const floorPlanSvgHeight = height;
    const floorPlanSvgWidth = width;
    if (!selectedBuilding.hasOwnProperty("geometry"))
      throw new Error(
        "FloorPlanSvg::render -> `selectedBuilding` should have `geometry` property."
      );

    const projection = this.projection();
    const coordinatesConverter = geoPath(projection);

    const floorLayoutSvg = selectedBuilding.geometry.sub_buildings.map((sub_building, subIdx) => {
      const emptySvg = <g> </g>;
      if (information.length === 0) return emptySvg;

      const blockIdx = information[informationIdx].blockIndexes[subIdx];

      if (isNullOrUndefined(blockIdx))
        throw new Error("FloorPlanSvg::render -> `blockIdx` should be correctly defined.");

      const buildingFeaturesSVGs = sub_building[blockIdx].geojson.features.map((feature, i) => {
        const featureSVGPath = (
          <path
            key={i}
            d={coordinatesConverter(feature)}
            fillOpacity="1"
            fill={buildingFeatureColor(
              buildingTypeCode,
              feature.properties.type,
              feature.properties.id
            )}
            stroke={floorplanVisualization.featuresStrokeColor}
            strokeWidth={feature.properties.type === "building_footprint" ? 2 : 1}
          />
        );

        const isUnit = !isNull(feature.properties.type.match(/^unit_/));
        if (!isUnit) {
          return featureSVGPath;
        }

        return (
          <UnitSummaryPopup
            key={i}
            unitLabel={
              selectedBuilding.building_type.code === BuildingTypeCode.apartment
                ? unitLabels.apartments.full.singular[Number(feature.properties.type.match(/\d+/))]
                : unitLabels.nplex.unit
            }
            area={
              exists(feature.properties.gross_area)
                ? feature.properties.gross_area.toFixed(0)
                : undefined
            }
            trigger={featureSVGPath}
          />
        );
      });
      return <g key={subIdx}>{buildingFeaturesSVGs}</g>;
    });
    const tiles = tile()
      .size([floorPlanSvgWidth, floorPlanSvgHeight])
      .scale(projection.scale() * 2 * Math.PI)
      .translate(projection([0, 0]))();

    const tileImages = tiles.map((tileData, i) => {
      return (
        <image
          key={i}
          // eslint-disable-next-line max-len
          href={`${mapBoxStyle.api}/${tileData.z}/${tileData.x}/${tileData.y}?access_token=${mapbox.accessToken}`}
          x={(tileData.x + tiles.translate[0]) * tiles.scale}
          y={(tileData.y + tiles.translate[1]) * tiles.scale}
          width={tiles.scale}
          height={tiles.scale}
        />
      );
    });

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="floorplanSVG"
        width={floorPlanSvgWidth}
        height={floorPlanSvgHeight}
      >
        {this.props.showTiles ? tileImages : null}
        {floorLayoutSvg}
        {this.props.showNorthArrow ? (
          <image
            x={floorPlanSvgWidth - 40}
            y={0}
            preserveAspectRatio="xMidYMid meet"
            width={40}
            xlinkHref={arrow}
          />
        ) : null}
      </svg>
    );
  }
}

export default FloorPlanSvg;
