import { Page, View, Document, StyleSheet, BlobProvider, Font } from "@react-pdf/renderer";
import React, { useState } from "react";
import FinancialPerformance from "./FinancialPerformance";
import Footer from "./Footer";
import Header from "./Header";
import { addImages } from "./images";
import OperatingPeriodAssumptions from "./OperatingPeriodAssumptions";
import ProposedDevelopment from "./ProposedDevelopment";
import SiteInformation from "./SiteInformation";
import Title from "./Title";
import { connector, ReportProps } from "./types";

Font.register({
  family: "Roboto",
  fontStyle: "normal",
  fonts: [
    {
      fontWeight: 100,
      src: "https://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1MmgVxIIzc.ttf"
    },
    {
      fontWeight: 300,
      src: "https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc9.ttf"
    },
    {
      fontWeight: 400,
      src: "https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf"
    },
    {
      fontWeight: 700,
      src: "https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc9.ttf"
    },
    {
      fontWeight: 900,
      src: "https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmYUtfBBc9.ttf"
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    fontFamily: "Roboto"
  },
  columns: {
    display: "flex",
    flexDirection: "row",
    marginTop: "3mm",
    marginBottom: "3mm",
    flexGrow: 1,
    flexShrink: 1
  },
  imagesColumn: { flex: 44 },
  dataColumn: { flex: 56 },
  data: { marginLeft: "8mm", marginRight: "8mm" },
  images: { marginBottom: "5mm" }
});

const Report = (props: ReportProps) => {
  const { project, defaultZoning, building, cities, isCanada } = props;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={styles.columns}>
          <View style={styles.imagesColumn} />
          <View style={styles.dataColumn}>
            <Title project={project} cities={cities} />
            <View style={styles.data}>
              <SiteInformation project={project} defaultZoning={defaultZoning} />
              <ProposedDevelopment building={building} project={project} />
              <OperatingPeriodAssumptions building={building} isCanada={isCanada} />
              <FinancialPerformance building={building} />
            </View>
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  );
};

const generatePdfUrl = async (props: ReportProps, reactPDFBlob: Blob) => {
  // In order to add the images we need to use pdf-lib.
  let pdfLibDoc = await (await import("pdf-lib")).PDFDocument.load(
    await reactPDFBlob.arrayBuffer()
  );
  props.setLoadingMessage("Adding images...");
  pdfLibDoc = await addImages(props, pdfLibDoc);
  props.setLoadingMessage("Downloading file...");
  const pdfFile = await pdfLibDoc.save();
  const URL = window.URL || window.webkitURL || window;
  return URL.createObjectURL(new Blob([pdfFile], { type: "application/pdf" }));
};

const PDFProvider: React.FunctionComponent<ReportProps> = props => {
  // clicked state is needed to avoid pre-generating the report
  const [clicked, setClicked] = useState(false);

  return clicked ? (
    <BlobProvider document={Report(props)}>
      {({ blob, loading, error }) => {
        if (error) {
          props.pushNewError({
            name: "PDF Summary Failed",
            description: `PDFReport::PDFProvider::render -> ${error}`
          });
          props.toggleErrorModal(true);
          setClicked(false);
        }
        if (!loading) {
          generatePdfUrl(props, blob as Blob)
            .then(pdfUrl => {
              const link = document.createElement("a");
              link.href = pdfUrl;
              link.download = `${props.project.name} - ${props.building.name}`;
              link.target = "_blank";
              link.rel = "noopener noreferrer";
              link.click();
              setClicked(false);
              props.hideDimmedLoader();
            })
            .catch(err => {
              props.pushNewError({
                name: "PDF Summary Failed",
                description: `PDFReport::PDFProvider::render -> ${err}`
              });
              props.toggleErrorModal(true);
              setClicked(false);
              props.hideDimmedLoader();
            });
        }
        return <div>PDF Summary</div>;
      }}
    </BlobProvider>
  ) : (
    <div
      onClick={() => {
        setClicked(true);
        props.showDimmedLoader();
        props.setLoadingMessage("Generating PDF report...");
      }}
    >
      PDF Summary
    </div>
  );
};

export default connector(PDFProvider);
