import React, { MouseEvent } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Modal, Segment, Button, Popup } from "semantic-ui-react";
import { externalLinks } from "../config/config";
import { RootState } from "../store";
import * as errorActions from "../store/errors";

const mapStateToProps = (state: RootState) => {
  const activeErrors = state.errors.activeErrors;
  const open = state.errors.open;
  return { activeErrors, open };
};

const connector = connect(mapStateToProps, errorActions);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {} & PropsFromRedux;

class ErrorModal extends React.Component<Props> {
  show = (): void => {
    this.props.toggleErrorModal(true);
  };
  close = (): void => {
    this.props.toggleErrorModal(false);
  };

  private messagesEnd: HTMLDivElement | null = null;
  scrollToBottom = (): void => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ behavior: "auto" });
    }
  };

  componentDidMount(): void {
    this.scrollToBottom();
  }

  componentDidUpdate(): void {
    // Note: If we add ability to dismiss errors, doing so will trigger this scrolling.
    this.scrollToBottom();
  }

  handleToggleButton(event: MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
    const { open } = this.props;
    this.props.toggleErrorModal(!open);
  }

  copyToClipboard(text: string): void {
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  linesLimit = 4;

  formatErrorDescription(errorDescription: string): JSX.Element {
    return (
      <Segment style={{ overflow: "hidden", whiteSpace: "pre-wrap", fontFamily: "monospace" }}>
        {errorDescription.split("\n", this.linesLimit).join("\n")}
        {errorDescription.split("\n", this.linesLimit + 1).length > this.linesLimit ? (
          <div>...</div>
        ) : (
          undefined
        )}
      </Segment>
    );
  }

  copyToClipboardButton(copyableContent: string): JSX.Element {
    return (
      <Segment padded={false} style={{ display: "table", border: "none" }}>
        <Popup
          content="Copied!"
          on="click"
          trigger={
            <Button
              icon="copy"
              labelPosition="left"
              content="Copy"
              onClick={() => this.copyToClipboard(copyableContent)}
            />
          }
        />
      </Segment>
    );
  }

  render(): JSX.Element {
    const { activeErrors, open } = this.props;
    const showButton = activeErrors.length > 0;

    return (
      <div>
        {showButton ? (
          <Button onClick={e => this.handleToggleButton(e)}>Show Errors</Button>
        ) : (
          undefined
        )}
        <Modal
          open={open}
          closeIcon
          closeOnDimmerClick={false}
          closeOnEscape={true}
          onClose={() => this.props.toggleErrorModal(false)}
        >
          <Modal.Header>Oops!</Modal.Header>
          <Modal.Content scrolling>
            {activeErrors.map((error, index) => (
              <Segment.Group key={index}>
                <Segment tertiary>{error.timestamp}</Segment>
                <Segment
                  secondary={index < activeErrors.length - 1}
                  inverted={index === activeErrors.length - 1}
                >
                  {error.name}
                </Segment>
                <Segment.Group horizontal>
                  {this.formatErrorDescription(error.description)}
                  {this.copyToClipboardButton(error.description)}
                </Segment.Group>
              </Segment.Group>
            ))}
            <div
              className="dummyElement"
              style={{ float: "left", clear: "both" }}
              ref={el => {
                this.messagesEnd = el;
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Report a problem"
              as="a"
              href={externalLinks.support}
              target="_blank"
            />
            <Button icon="close" labelPosition="right" content="Close" onClick={this.close} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default connector(ErrorModal);
