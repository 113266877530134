import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Region } from "../types/region";
import { UsersSlice, UserClaims, UserPermissions } from "./types";

const initialState: UsersSlice = {
  authenticated: false,
  loginFail: false,
  loginFailMsg: "",
  userClaims: {},
  isAuthenticating: false,
  metric: false,
  epsgCode: null,
  proj4EPSGDefinition: null,
  permissions: {},
  availableRegions: []
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserClaims(state, action: PayloadAction<UserClaims>) {
      state.userClaims = action.payload;
    },
    updateLoginFail(state, action: PayloadAction<string>) {
      state.loginFail = true;
      state.loginFailMsg = action.payload;
    },
    updateLoginSuccess(state) {
      state.loginFail = false;
    },
    logoutUser(state) {
      state.userClaims = {};
      state.authenticated = false;
    },
    startAuthenticating(state) {
      state.isAuthenticating = true;
    },
    authenticatedSuccess(state) {
      state.isAuthenticating = false;
      state.authenticated = true;
    },
    authenticatedFail(state) {
      state.isAuthenticating = false;
      state.authenticated = false;
    },
    setMetric(state, action: PayloadAction<boolean>) {
      state.metric = action.payload;
    },
    setEPSG(state, action: PayloadAction<number>) {
      state.epsgCode = action.payload;
    },
    setProj4EPSGDefinition(state, action: PayloadAction<string>) {
      state.proj4EPSGDefinition = action.payload;
    },
    setPermissions(state, action: PayloadAction<UserPermissions>) {
      state.permissions = action.payload;
    },
    setAvailableRegions(state, action: PayloadAction<Region[]>) {
      state.availableRegions = action.payload;
    },
    resetApp() {
      /**/
    }
  }
});

export const {
  setUserClaims,
  updateLoginFail,
  updateLoginSuccess,
  logoutUser,
  startAuthenticating,
  authenticatedSuccess,
  authenticatedFail,
  setMetric,
  setEPSG,
  setProj4EPSGDefinition,
  setPermissions,
  setAvailableRegions,
  resetApp
} = usersSlice.actions;

export default usersSlice.reducer;
