import React from "react";
import { connect } from "react-redux";
import { dataLayersQuerier } from "../../queriers/region";
import { RootState } from "../../store";
import { DataLayer } from "../../types/region";
import TilesLayer from "../common/map/TilesLayer";
import Legend from "./zoningsLayerLegend";

const ZoningsLayer = (props: {
  zoningsDataLayer: DataLayer;
  zoningsLayerLegendIsVisible: boolean;
}) => (
  <>
    <TilesLayer
      dataLayer={props.zoningsDataLayer}
      paint={{
        "fill-outline-color": "grey",
        "fill-color": ["get", "hex"],
        "fill-opacity": 0.7
      }}
    />
    {props.zoningsDataLayer.isVisible && props.zoningsLayerLegendIsVisible ? <Legend /> : null}
  </>
);

export default connect((state: RootState) => ({
  zoningsDataLayer: dataLayersQuerier(state.regions.dataLayers).byName("zonings"),
  zoningsLayerLegendIsVisible: state.map.legendIsVisible
}))(ZoningsLayer);
