import React from "react";
import { Button, Modal, ModalActions } from "semantic-ui-react";

export default class UICrashModal extends React.PureComponent<{}> {
  render() {
    return (
      <Modal open>
        <Modal.Header>Oops! Something went wrong on our end</Modal.Header>
        <Modal.Content>
          We&apos;ll try to fix this. In the meantime, try reloading the webpage.
        </Modal.Content>
        <ModalActions>
          <Button
            color="teal"
            icon="redo"
            content="Refresh"
            onClick={() => {
              window.location.reload();
              return false;
            }}
          />
        </ModalActions>
      </Modal>
    );
  }
}
