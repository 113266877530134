/**
 * Created by jmartinez on 9/19/18.
 */
import { isNull, omit } from "lodash";
import { destroy, submit } from "redux-form";
import { isToggleChecked } from "../components/forms/CheckboxInput";
import { podiumMaxHeightStartingOffset, zoningSteps } from "../config/config";
import {
  getBulkRequirementsDefaultValues,
  getDensityBonusNeutralValues,
  heightLimitsDefaultValues
} from "../config/defaultValues";
import {
  formatZoningObject,
  isNullOrUndefined,
  formatDecimal,
  calculateDensityLimit
} from "../config/utils";
import * as zoningPromises from "../promises/zoning/zoning";
import { setVirtualZoning, clearVirtualZoning } from "../store/buildings";
import { pushNewError } from "../store/errors";
import { safeOperation, mandatoryOperation, operations } from "../store/operations";
import { setActiveModalForm, toggleWizardModal } from "../store/projects";
import { getSelectedBuilding } from "../store/selectors/projects";
import * as zoningActions from "../store/zoning";
import { allExist, exists } from "../utils";
import { updateProgramToggles } from "./ui/projectCreation";

export const apiGetZoningList = () => {
  return async (dispatch, getState) => {
    const editBuildingMode = getState().buildings.editBuildingMode;
    const selectedProject = getState().projects.selectedProject;
    dispatch(zoningActions.updateLoadingStatus(true));
    try {
      if (!editBuildingMode) {
        dispatch(apiGetZoningInfo(selectedProject.parcel_data.zoning_id));
      } else {
        const selectedBuilding = getSelectedBuilding(getState());
        dispatch(zoningActions.setUserZoningIsSelected(true));
        dispatch(zoningActions.setSelectedZoning(selectedBuilding.zoning));
        dispatch(zoningActions.setSelectedZoningName(selectedBuilding.zoning.name));
        dispatch(setVirtualZoning(selectedBuilding.zoning));
      }

      const regionalZoning = await zoningPromises.asyncGetBaseZoningList(selectedProject);
      const userZoning = await zoningPromises.asyncGetUserZoningList();
      dispatch(
        zoningActions.setZoningList({
          regional: regionalZoning,
          userDefined: userZoning
        })
      );
      dispatch(zoningActions.updateLoadingStatus(false));
    } catch (error) {
      dispatch(
        pushNewError({
          name: "Couldn't retrieve zoning.",
          description: `actions::zoning::apiGetZoningList -> Zoning retrieval failed with: ${error}`
        })
      );
    }
  };
};

export const apiGetZoningInfo = zoningId =>
  safeOperation(operations.getZoningInfo, async (dispatch, getState) => {
    dispatch(zoningActions.setUserZoningIsSelected(false));
    const cityId = getState().projects.selectedProject.parcel_data.city_id;
    const zoningData = await zoningPromises.asyncGetZoningInfo(zoningId, cityId);
    const selectedProject = getState().projects.selectedProject;

    dispatch(zoningActions.setSelectedZoning(zoningData));
    dispatch(updateProgramToggles(zoningData));

    const zoningName = isNull(zoningData.name)
      ? "Custom"
      : selectedProject.parcel_data.zoning === zoningData.zoning_code
      ? `${zoningData.zoning_code} (Default)`
      : zoningData.zoning_code;

    dispatch(zoningActions.setSelectedZoningName(zoningName));
    dispatch(clearVirtualZoning());
  });

export const apiGetUserZoningInfo = zoningId => {
  return async dispatch => {
    dispatch(zoningActions.setUserZoningIsSelected(true));

    try {
      const zoningData = await zoningPromises.asyncGetUserZoningInfo(zoningId);

      dispatch(zoningActions.setSelectedZoning(zoningData));
      dispatch(updateProgramToggles(zoningData));
      let zoningName = isNull(zoningData.name) ? "Custom" : zoningData.name;

      if (!isNullOrUndefined(zoningData.derived_zoning_id)) {
        zoningName = isNull(zoningData.name)
          ? `Custom (Derived from: ${zoningData.zoning_code})`
          : zoningData.name;
      }

      dispatch(zoningActions.setSelectedZoningName(zoningName));
      dispatch(clearVirtualZoning());
    } catch (error) {
      console.error(
        "actions::zoning::apiGetUserZoningInfo -> Zoning retrieval failed with:",
        error
      );
    }
  };
};

export const handleZoningStepSubmit = values => {
  return (dispatch, getState) => {
    const { zoningValues, editZoning } = getState().zoning;
    const newValues = !editZoning ? Object.assign({}, zoningValues, values) : values;
    dispatch(zoningActions.setZoningValues(newValues));
    dispatch(setActiveModalForm(zoningSteps["zoning_step_2"]));
  };
};

export const handleHDSubmit = values => {
  return (dispatch, getState) => {
    [
      "density_bonus",
      "floor_area_ratio_analysis_enabled",
      "bulk_requirements_enabled",
      "lot_coverage_enabled",
      "density_limit_enabled"
    ].forEach(key => {
      values[key] = isToggleChecked(values[key]);
    });
    if (parseFloat(values["maximum_floor_area_ratio"]) === 0) {
      values["floor_area_ratio_analysis_enabled"] = false;
    }

    const { zoningValues, editZoning } = getState().zoning;
    const newValues = !editZoning ? Object.assign({}, zoningValues, values) : values;
    dispatch(
      zoningActions.setZoningValues(
        omit(newValues, ["density_bonus_ground_floor_height", "bulk_requirements"])
      )
    );

    dispatch(setActiveModalForm(zoningSteps["zoning_step_3"]));
  };
};

export const handleSetbacksSubmit = values => {
  return (dispatch, getState) => {
    const { zoningValues } = getState().zoning;
    const newValues = Object.assign({}, zoningValues, values);
    dispatch(zoningActions.setZoningValues(newValues));

    dispatch(setActiveModalForm(zoningSteps["zoning_step_4"]));
  };
};

export const handleOpenSpaceSubmit = values => {
  return (dispatch, getState) => {
    const { zoningValues } = getState().zoning;
    const newValues = Object.assign({}, zoningValues, values);
    dispatch(zoningActions.setZoningValues(newValues));

    dispatch(setActiveModalForm(zoningSteps["zoning_step_5"]));
  };
};

export const handleParkingInputsSubmit = values => {
  return async (dispatch, getState) => {
    // Flags arrive as object representing the toggle, so we need to correct those properties
    // before sending them to the appserver.
    values.podium_parking_enabled =
      typeof values.podium_parking_enabled === "object"
        ? values.podium_parking_enabled.checked
        : values.podium_parking_enabled;
    values.underground_parking_enabled =
      typeof values.underground_parking_enabled === "object"
        ? values.underground_parking_enabled.checked
        : values.underground_parking_enabled;
    const { zoningValues } = getState().zoning;
    const newValues = Object.assign({}, zoningValues, values);
    const finalValues = newValues.hasOwnProperty("zoning_name")
      ? Object.assign({}, omit(newValues, "zoning_name"), {
          name: newValues["zoning_name"]
        })
      : newValues;

    dispatch(
      zoningSubmission(finalValues, {
        percentagesAreEncodedBetweenZeroAndOne: false
      })
    );
  };
};

export const zoningSubmission = (zoning, { percentagesAreEncodedBetweenZeroAndOne }) =>
  mandatoryOperation(operations.zoningSubmission, async (dispatch, getState) => {
    const {
      confirmZoningFavorites,
      derivedZoningId,
      selectedZoning,
      editZoning
    } = getState().zoning;
    const isUserDefined = isNaN(parseInt(derivedZoningId));
    const saveToFavorites = !editZoning || confirmZoningFavorites;
    const isVirtualZoning = !zoning.hasOwnProperty("name");
    const dataToSend = formatZoningObject(zoning, { percentagesAreEncodedBetweenZeroAndOne });
    dataToSend["derived_zoning_id"] = derivedZoningId ? parseInt(derivedZoningId) : null;
    if (!dataToSend.hasOwnProperty("density_bonus_percentage")) {
      dataToSend["density_bonus_percentage"] = 0;
    }
    dataToSend["city_id"] = getState().projects.selectedProject.parcel_data.city_id;
    if (isVirtualZoning) {
      dataToSend.name = selectedZoning.name;
      dispatch(setVirtualZoning(dataToSend));
      dispatch(zoningActions.setSelectedZoning(dataToSend));
    } else {
      const newZoningResponse = isUserDefined
        ? await zoningPromises.asyncPostUserDefinedZoning(dataToSend)
        : await zoningPromises.asyncPostZoning(dataToSend);

      if (saveToFavorites) {
        const newZoning = { id: newZoningResponse.id, name: zoning.name };
        dispatch(zoningActions.addUserDefinedZoning(newZoning));
      }
      const newZoningId = newZoningResponse.id;

      dispatch(apiGetUserZoningInfo(newZoningId));
    }
    dispatch(zoningActions.setZoningComplete(true));
    dispatch(zoningActions.toggleConfirmZoningFavorites(false));
    dispatch(toggleWizardModal(false));
    dispatch(destroyZoningForms());
    dispatch(zoningActions.cleanDerivedZoning());
    dispatch(zoningActions.cleanZoningValues());
  });

export const zoningSubmissionStep = () => {
  return dispatch => {
    dispatch(submit("ZoningStepForm"));
  };
};

export const submitHD = () => {
  return dispatch => {
    dispatch(submit("HDStepForm"));
  };
};

export const submitSetbacks = usePercent => {
  return dispatch => {
    if (usePercent) {
      dispatch(submit("SetbacksPercentForm"));
    } else {
      dispatch(submit("SetbacksFtForm"));
    }
  };
};

export const submitOpenSpace = () => {
  return dispatch => {
    dispatch(submit("OpenSpaceStepForm"));
  };
};

export const submitParkingInputs = () => {
  return dispatch => {
    dispatch(submit("ParkingInputsStepForm"));
  };
};

export const destroyZoningForms = () => {
  return dispatch => {
    dispatch(destroy("ZoningStepForm"));
    dispatch(destroy("HDStepForm"));
    dispatch(destroy("SetbacksFtForm"));
    dispatch(destroy("SetbacksPercentForm"));
    dispatch(destroy("OpenSpaceStepForm"));
    dispatch(destroy("ParkingInputsStepForm"));
  };
};

export const apiGetDerivedZoning = zoningId => {
  return async (dispatch, getState) => {
    const cityId = getState().projects.selectedProject.parcel_data.city_id;
    try {
      const baseZoning = await zoningPromises.asyncGetZoningInfo(zoningId, cityId);
      dispatch(zoningActions.setDerivedZoning({ zoningId: baseZoning.id, zoning: baseZoning }));
    } catch (error) {
      console.error(
        `actions::zoning::handleParkingInputsSubmit -> Zoning retrieval failed with:${error}`
      );
    }
  };
};

export const getDefaultZoningWizardValues = (zoning, parcelDataHeight, parcelSizeArea) => {
  const { zoningValues, selectedZoning, derivedZoningData } = zoning;
  const { maxHeight, groundFloorHeight } = heightLimitsDefaultValues();
  const densityBonusNeutralValues = getDensityBonusNeutralValues();
  const bulkRequirementsDefaultValues = getBulkRequirementsDefaultValues();
  const defaultMaxHeight = exists(parcelDataHeight) ? parcelDataHeight : maxHeight;
  const startingMaxHeight = parseFloat(
    exists(zoningValues.max_height) ? zoningValues.max_height : defaultMaxHeight
  );

  let startingDerivedMaxHeight = startingMaxHeight;
  if (exists(derivedZoningData)) {
    startingDerivedMaxHeight = parseFloat(
      exists(derivedZoningData.max_height) ? derivedZoningData.max_height : defaultMaxHeight
    );
  }

  const startingEffectiveHeight = exists(selectedZoning.effective_height)
    ? selectedZoning.effective_height
    : startingMaxHeight + densityBonusNeutralValues.bonusMaxHeight;

  const startingGroundFloorHeight = parseFloat(
    exists(zoningValues.ground_floor_ceiling_height)
      ? zoningValues.ground_floor_ceiling_height
      : groundFloorHeight
  );

  const startingEffectiveGroundFloorHeight = exists(selectedZoning.effective_ground_floor_height)
    ? selectedZoning.effective_ground_floor_height
    : startingGroundFloorHeight + densityBonusNeutralValues.bonusGroundFloorHeight;

  let startingDerivedGroundFloorHeight = startingGroundFloorHeight;
  let startingDerivedEffectiveGroundFloorHeight = startingEffectiveGroundFloorHeight;
  if (exists(derivedZoningData)) {
    startingDerivedGroundFloorHeight = parseFloat(
      exists(derivedZoningData.ground_floor_ceiling_height)
        ? derivedZoningData.ground_floor_ceiling_height
        : groundFloorHeight
    );
    startingDerivedEffectiveGroundFloorHeight = exists(
      derivedZoningData.effective_ground_floor_height
    )
      ? derivedZoningData.effective_ground_floor_height
      : startingDerivedGroundFloorHeight + densityBonusNeutralValues.bonusGroundFloorHeight;
  }

  const startingDensityBonusGroundFloorHeight = allExist(
    selectedZoning.effective_ground_floor_height,
    selectedZoning.ground_floor_ceiling_height
  )
    ? parseFloat(selectedZoning.effective_ground_floor_height) -
      parseFloat(selectedZoning.ground_floor_ceiling_height)
    : densityBonusNeutralValues.bonusGroundFloorHeight;

  const densityLimit = calculateDensityLimit(parcelSizeArea, selectedZoning);
  const res = {
    initialValues: {
      // Heights limits
      max_height: startingMaxHeight,
      ground_floor_ceiling_height: startingGroundFloorHeight,
      effective_height: startingEffectiveHeight,
      effective_ground_floor_height: startingEffectiveGroundFloorHeight,

      // Density bonus
      density_bonus: exists(selectedZoning.density_bonus)
        ? selectedZoning.density_bonus
        : densityBonusNeutralValues.enabled,
      density_bonus_height: exists(selectedZoning.density_bonus_height)
        ? selectedZoning.density_bonus_height
        : densityBonusNeutralValues.bonusMaxHeight,
      density_bonus_ground_floor_height: startingDensityBonusGroundFloorHeight,
      density_bonus_percentage: exists(selectedZoning.density_bonus_percentage)
        ? parseFloat(selectedZoning.density_bonus_percentage) * 100
        : densityBonusNeutralValues.bonusPercentage,

      dwelling_units_per_acre: selectedZoning.dwelling_units_per_acre,
      area_of_lot_per_unit: selectedZoning.area_of_lot_per_unit,
      units_permitted: selectedZoning.units_permitted,
      density_limit: densityLimit,
      density_limit_enabled: exists(selectedZoning.density_limit_enabled)
        ? selectedZoning.density_limit_enabled
        : densityLimit >= 1,

      // Bulk requirements
      bulk_requirements_enabled: selectedZoning.bulk_requirements_enabled
        ? selectedZoning.bulk_requirements_enabled
        : false,
      podium_max_height: zoningValues.podium_max_height
        ? zoningValues.podium_max_height
        : startingEffectiveGroundFloorHeight + podiumMaxHeightStartingOffset(),
      lower_tower_shrink_value: zoningValues.lower_tower_shrink_value
        ? zoningValues.lower_tower_shrink_value
        : bulkRequirementsDefaultValues.lowerTowerShrinkValue,
      upper_tower_shrink_value: zoningValues.upper_tower_shrink_value
        ? zoningValues.upper_tower_shrink_value
        : bulkRequirementsDefaultValues.upperTowerShrinkValue,

      // Floor area ratio analysis
      floor_area_ratio_analysis_enabled: zoningValues.floor_area_ratio_analysis_enabled,
      maximum_floor_area_ratio: zoningValues.maximum_floor_area_ratio,

      // Lot coverage
      lot_coverage_enabled: exists(zoningValues.lot_coverage),
      lot_coverage: exists(zoningValues.lot_coverage)
        ? formatDecimal(parseFloat(zoningValues.lot_coverage) * 100)
        : 100
    },
    derivedZoningData: null
  };

  if (exists(derivedZoningData)) {
    res.derivedZoningData = Object.assign({}, derivedZoningData, {
      max_height: startingDerivedMaxHeight,
      effective_height: exists(derivedZoningData.effective_height)
        ? derivedZoningData.effective_height
        : startingDerivedMaxHeight + densityBonusNeutralValues.bonusMaxHeight,
      ground_floor_ceiling_height: startingDerivedGroundFloorHeight,
      effective_ground_floor_height: startingDerivedEffectiveGroundFloorHeight,
      podium_max_height: exists(derivedZoningData.podium_max_height)
        ? derivedZoningData.podium_max_height
        : // TODO: bulkRequirementsDefaultValues.podium_max_height does not exists
          bulkRequirementsDefaultValues.podium_max_height,
      lower_tower_shrink_value: exists(derivedZoningData.lower_tower_shrink_value)
        ? derivedZoningData.lower_tower_shrink_value
        : bulkRequirementsDefaultValues.lower_tower_shrink_value,
      upper_tower_shrink_value: exists(derivedZoningData.upper_tower_shrink_value)
        ? derivedZoningData.upper_tower_shrink_value
        : bulkRequirementsDefaultValues.upper_tower_shrink_value,
      area_of_lot_per_unit: exists(derivedZoningData.area_of_lot_per_unit)
        ? derivedZoningData.area_of_lot_per_unit
        : "",
      // Lot coverage
      lot_coverage_enabled: exists(derivedZoningData.lot_coverage),
      lot_coverage: exists(derivedZoningData.lot_coverage)
        ? formatDecimal(parseFloat(derivedZoningData.lot_coverage) * 100)
        : 100
    });
  }
  return res;
};

export const getCandidateZoningIdentifier = projectBoundary =>
  mandatoryOperation(operations.getCandidateZoningIdentifier, async () => {
    const zoningIds = await zoningPromises.asyncPostGeoJSONZoning(projectBoundary);

    if (zoningIds.length === 0) {
      throw new Error("The given geometry doesn't have any zoning associated");
    }
    // Note (@nicoluce): We return the first zoning id from the list since
    //                   they are already ordered by area
    return {
      id: zoningIds[0].id,
      name: zoningIds[0].code,
      cityId: zoningIds[0].cityId
    };
  });
