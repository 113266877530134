/**
 * Created by jmartinez on 8/27/18.
 */

import React from "react";
import { connect } from "react-redux";
import * as userCtrl from "../controllers/users";
import LoginView from "../views/LoginView";

class Authenticated extends React.Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");
    if (token) {
      this.props.authenticate(token);
    }
  }

  render() {
    if (this.props.isAuth) {
      return React.createElement(this.props.component, { ...this.props });
    } else {
      return <LoginView />;
    }
  }
}

const mapStateToProps = state => {
  return {
    isAuth: state.users.authenticated
  };
};

export default connect(mapStateToProps, userCtrl)(Authenticated);
