import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { unitLabels } from "../../config/config";
import {
  formatCommas,
  formatDecimal,
  getDistanceLabel,
  getGrossAreaLabel,
  getResAreaLabel,
  getAreaPerUnitLabel,
  getShortAreaLabel
} from "../../config/utils";
import { amountOfAcres } from "../../helpers/unitsConversion";
import { buildingsQuerier } from "../../queriers/users/building";
import { Building } from "../../types/buildings";
import { Project } from "../../types/projects";
import { exists } from "../../utils";
import Row from "./Row";

const styles = StyleSheet.create({
  title: {
    fontWeight: 400,
    fontSize: 11,
    textTransform: "uppercase",
    color: "#54B2AB",
    marginBottom: "4mm"
  }
});

type ProposedDevelopmentProps = {
  building: Building;
  project: Project;
};

const ProposedDevelopment: React.FunctionComponent<ProposedDevelopmentProps> = props => {
  const { building, project } = props;
  const querier = buildingsQuerier(building);

  return (
    <View style={{ marginTop: "6mm" }}>
      <Text style={styles.title}>Proposed Development</Text>
      <View>
        <Row name="Stories" value={building.stories} />
        <Row name="Height" value={`${formatDecimal(querier.height)} ${getDistanceLabel()}`} />
        <Row name="Total Units" value={querier.totalUnits} />
        <Row
          name="Unit Mix"
          value={
            querier.isNplex
              ? `${querier.totalUnits} ${unitLabels.nplex.unit}`
              : unitLabels.apartments.layout
                  .filter(unit => (building as any)[unit] > 0)
                  .map(unit => {
                    const unitLabelIdx = unitLabels.apartments.layout.indexOf(unit);
                    const unitLabel = unitLabels.apartments.full.singular[unitLabelIdx];
                    return `${(building as any)[unit] as number} ${unitLabel}`;
                  })
                  .join(", ")
          }
        />
        <Row
          name="Lot Coverage"
          value={
            exists(building.lot_coverage)
              ? `${Math.floor((building.lot_coverage / project.parcel_size.area) * 100)}%`
              : "-"
          }
        />
        <Row name={getAreaPerUnitLabel()} value={formatCommas(building.gsf / querier.totalUnits)} />
        <Row name={getGrossAreaLabel()} value={formatCommas(building.gsf)} />
        <Row name={getResAreaLabel()} value={formatCommas(building.rsf)} />
        <Row
          name="FAR"
          value={formatDecimal(
            building.far !== null
              ? building.far / project.parcel_size.area
              : building.gsf / project.parcel_size.area - building.total_underground_parking_levels
          )}
        />
        <Row
          name="DUA"
          value={formatDecimal(querier.totalUnits / amountOfAcres(project.parcel_size.area))}
        />
        <Row
          name="Parking Stalls"
          value={
            querier.isNplex
              ? "-"
              : `${building.total_parking_slots} (ratio ${formatDecimal(
                  building.total_parking_slots / querier.totalUnits
                )})`
          }
        />
        <Row
          name="Commercial Retail"
          value={
            querier.isNplex
              ? "-"
              : `${formatCommas(building.actual_commercial_retail_gsf)} ${getShortAreaLabel()}`
          }
        />
      </View>
    </View>
  );
};

export default ProposedDevelopment;
