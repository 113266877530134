import { is } from "../lib";
import {
  asyncUpdateParcelUsage,
  asyncUpdateSegments
} from "../promises/developmentSites/developmentSites";
import { AppDispatch, RootState } from "../store";
import { pushNewError } from "../store/errors";
import { operations, safeOperation } from "../store/operations";
import * as projectActions from "../store/projects";
import { selectBuildingById, deselectBuilding } from "./buildings";
import { hideEditFrontageControls } from "./projects";

export const changeSelectedSite = (siteId: number) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  dispatch(projectActions.setSelectedProjectSelectedSite(siteId));
  const siteBuildings = getState().projects.projectBuildingsBySite[siteId];
  if (siteBuildings && is.id(siteBuildings.selectedId)) {
    dispatch(selectBuildingById(siteBuildings.selectedId));
  } else {
    dispatch(deselectBuilding);
  }
};

export const apiUpdateSiteSegments = (
  siteId: number | null,
  segments: { front: number[]; rear: number[]; sides: number[] }
) => {
  return async (dispatch: AppDispatch) => {
    if (is.null(siteId)) {
      dispatch(
        pushNewError({
          name: "Segment edition failed.",
          description:
            "controllers::developmentSites::apiUpdateSiteSegments -> No site was selected."
        })
      );
      return;
    }
    try {
      const updatedSite = await asyncUpdateSegments(siteId, segments);
      dispatch(projectActions.updateSelectedProjectSite(updatedSite["updated_site"]));
      dispatch(hideEditFrontageControls());
      dispatch(projectActions.toggleEditFrontage(false));
      dispatch(projectActions.showSegmentError(false));
    } catch (error) {
      console.error(`controllers::developmentSites::asyncUpdateSegments -> Failed with ${error}`);
      dispatch(projectActions.showSegmentError(true));
    }
  };
};

export const changeSelectedSiteUsage = (siteId: number, usageId: number) =>
  safeOperation(
    operations.siteUsageChanging,
    async (dispatch: AppDispatch) => {
      await asyncUpdateParcelUsage(siteId, usageId);
      dispatch(projectActions.setProjectSiteUsage({ siteId, usageId }));
    },
    async (error, dispatch) =>
      dispatch(
        pushNewError({
          name: "Edition of parcel usage failed",
          description: `controllers::developmentSites::changeSelectedSiteUsage -> ${error}`
        })
      )
  );
