/**
 * Created by jmartinez on 8/24/18.
 */

import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button, Form, Grid, Header, Image, Segment, Message } from "semantic-ui-react";

import { externalLinks, reCaptchaSiteKey } from "../config/config";
import * as userCtrl from "../controllers/users";
import logo from "../data/images/logo.png";
import PasswordInput from "./forms/PasswordInput";
import TextInput from "./forms/TextInput";
import { Loader } from "./Loaders";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpModalIsOpen: false
    };

    this.handleSubmitSignUp = this.handleSubmitSignUp.bind(this);
  }

  handleSubmitSignUp(values) {
    this.props.validateSignUpForm(values);
  }

  handleRecaptchaSuccess(field, value) {
    field.input.onChange(value);
  }

  handleRecaptchaFailure(field) {
    field.input.onChange();
  }

  renderRecaptchaField = field => {
    return (
      <ReCAPTCHA
        style={{ marginBottom: "20px" }}
        name="recaptcha"
        align="center"
        sitekey={reCaptchaSiteKey}
        onChange={value => this.handleRecaptchaSuccess(field, value)}
        onErrored={() => this.handleRecaptchaFailure(field)}
        onExpired={() => this.handleRecaptchaFailure(field)}
      />
    );
  };

  parseUsernameInput = value => value.replace(/[^a-zA-Z0-9_@.+-]/g, "").toLowerCase();

  render() {
    const { handleSubmit, isAuthenticating } = this.props;

    if (isAuthenticating) {
      return <Loader />;
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100vh"
          }}
        >
          <Grid textAlign="center" verticalAlign="middle">
            <Grid.Column style={{ maxWidth: 450 }}>
              <Header as="h2" textAlign="center" style={{ marginTop: "-60px" }}>
                <Image src={logo} /> Penciler
              </Header>
              <Header as="h3" textAlign="center">
                Log in to your account
              </Header>
              <Form size="large" onSubmit={handleSubmit}>
                <Segment>
                  <Field
                    name="username"
                    component={TextInput}
                    fluid
                    icon="user"
                    iconPosition="left"
                    placeholder="Enter your e-mail address"
                    parse={this.parseUsernameInput}
                    required
                  />
                  <Field
                    name="password"
                    component={PasswordInput}
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="Password"
                    type="password"
                    required
                  />
                  <div className="field">
                    <a
                      href={externalLinks.blankHref}
                      onClick={event => {
                        event.preventDefault();
                        window.location.href = externalLinks.amForgotPassword;
                      }}
                    >
                      Forgot password?
                    </a>
                  </div>
                  <Field name="captchaToken" component={this.renderRecaptchaField} required />
                  <Button color="green" fluid size="large">
                    Login
                  </Button>
                  <br />
                  <Button
                    color="grey"
                    fluid
                    size="large"
                    onClick={event => {
                      event.preventDefault();
                      window.location.href = externalLinks.amSignUp;
                    }}
                  >
                    Create an Account
                  </Button>
                </Segment>
              </Form>
              {this.props.loginFail ? (
                <Message negative>{this.props.loginFailMsg}</Message>
              ) : (
                <div />
              )}
            </Grid.Column>
          </Grid>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    loginFail: state.users.loginFail,
    loginFailMsg: state.users.loginFailMsg,
    isAuthenticating: state.users.isAuthenticating
  };
};

const LoginForm = reduxForm({
  form: "login"
})(Login);

export default connect(mapStateToProps, userCtrl)(LoginForm);
