/* eslint react/display-name: 0 */
import React from "react";
import { Icon, List, Popup } from "semantic-ui-react";

import { IntegrationError } from "../../lib/errors";
import Report from "./Report";

export default (props: { integrationErrors: IntegrationError[] }) =>
  props.integrationErrors.length === 0 ? null : (
    <Popup trigger={<Icon name="exclamation triangle" color="red" />}>
      <Popup.Header>
        {props.integrationErrors.length === 1 ? "An integration error" : "Integration errors"}{" "}
        occurred!
      </Popup.Header>
      <List>
        {" "}
        {props.integrationErrors.map((error: IntegrationError) => (
          <List.Item key={error.id}>
            {" "}
            <Report integrationError={error} />{" "}
          </List.Item>
        ))}{" "}
      </List>
    </Popup>
  );
