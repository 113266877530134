import { Map } from "mapbox-gl";
import React from "react";
import { Accordion, Form, Icon, Menu, Segment } from "semantic-ui-react";

import { getCitiesPlaceholder, zoningCategories } from "../../../config/config";
import { mapboxFillLayerID, mapboxSourceID } from "../../../queriers/region";
import { DataLayer } from "../../../types/region";
import { withMap } from "../../common/map/ReactMapboxContext";
import LayerToggler from "./LayerToggler";

type ZoningsLayerSegmentProps = {
  map: Map;
  isVisible: boolean;
  toggleLayerVisibility: any;
  toggleLegendVisibility: any;
  dataLayer: DataLayer;
};

type ZoningsLayerSegmentState = {
  cityFilter: number[];
  zoningCategoryFilter: any;
  filtersAreOpen: boolean;
  citiesFilterIsActive: boolean;
  categoriesFilterIsActive: boolean;
};

class ZoningsLayerSegment extends React.Component<
  ZoningsLayerSegmentProps,
  ZoningsLayerSegmentState
> {
  constructor(props: ZoningsLayerSegmentProps) {
    super(props);
    this.state = {
      cityFilter: Object.keys(getCitiesPlaceholder()).map(key => parseInt(key)),
      zoningCategoryFilter: Object.values(zoningCategories),
      filtersAreOpen: false,
      citiesFilterIsActive: false,
      categoriesFilterIsActive: false
    };
  }

  _setZoningsFilter(zoningCategories: string[], cityIDs: number[]) {
    const clean = (l: string[]) => l.map((x: string) => x.trim().toLowerCase());
    const cleanedZoningCategories = clean(zoningCategories);
    this.props.map.setFilter(mapboxFillLayerID(this.props.dataLayer), [
      "all",
      ["in", "city_id", ...cityIDs],
      [
        "in",
        "_uc_id",
        ...this.props.map
          .querySourceFeatures(mapboxSourceID(this.props.dataLayer), { sourceLayer: "features" })
          .filter(
            (f: any) =>
              !!f &&
              clean(f.properties.category.split(",")).some(c => cleanedZoningCategories.includes(c))
          )
          .map((f: any) => f.properties._uc_id)
      ]
    ]);
  }

  handleCityFilter(clickedCheckbox: any) {
    const { cityFilter } = this.state;
    const cityId = clickedCheckbox.value;
    const newFilter = cityFilter.includes(cityId)
      ? cityFilter.filter((val: any) => val !== cityId)
      : [...cityFilter, cityId];
    this.setState({ cityFilter: newFilter });
    this._setZoningsFilter(this.state.zoningCategoryFilter, newFilter);
  }

  handleZoningFilter(clickedCheckbox: any) {
    const { zoningCategoryFilter } = this.state;
    const id = clickedCheckbox.value;
    const newFilter = zoningCategoryFilter.includes(id)
      ? zoningCategoryFilter.filter((val: any) => val !== id)
      : [...zoningCategoryFilter, id];
    this.setState({ zoningCategoryFilter: newFilter });
    this._setZoningsFilter(newFilter, this.state.cityFilter);
  }

  render() {
    const cityOptions = Object.keys(getCitiesPlaceholder()).map(cityId => {
      return {
        key: cityId,
        // @ts-ignore
        text: getCitiesPlaceholder()[cityId],
        value: parseInt(cityId, 10)
      };
    });

    const zoningCategoryOptions = Object.keys(zoningCategories).map(id => {
      return {
        key: id,
        // @ts-ignore
        text: zoningCategories[id],
        // @ts-ignore
        value: zoningCategories[id]
      };
    });

    return (
      <Segment>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div>
            <LayerToggler
              isVisible={this.props.isVisible}
              label="Zonings"
              toggleVisibility={this.props.toggleLayerVisibility}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon
              name="map outline"
              style={{ marginLeft: "8px", cursor: "pointer" }}
              onClick={this.props.toggleLegendVisibility}
            />
            <Icon
              name="edit"
              style={{ marginLeft: "8px", cursor: "pointer" }}
              onClick={() =>
                this.setState({
                  filtersAreOpen: !this.state.filtersAreOpen,
                  citiesFilterIsActive: false,
                  categoriesFilterIsActive: false
                })
              }
            />
          </div>
        </div>
        {this.state.filtersAreOpen ? (
          <Accordion as={Menu} vertical fluid>
            <Menu.Item>
              <Accordion.Title
                content="Filter by City"
                active={this.state.citiesFilterIsActive}
                onClick={() =>
                  this.setState({
                    filtersAreOpen: true,
                    citiesFilterIsActive: !this.state.citiesFilterIsActive,
                    categoriesFilterIsActive: false
                  })
                }
              />
              <Accordion.Content active={this.state.citiesFilterIsActive}>
                <Form>
                  <Form.Group grouped>
                    {cityOptions.map((option, idx) => {
                      return (
                        <Form.Checkbox
                          key={idx}
                          label={option.text}
                          name="city"
                          value={option.value}
                          checked={this.state.cityFilter.includes(option.value)}
                          onClick={(e, data) => this.handleCityFilter(data)}
                        />
                      );
                    })}
                  </Form.Group>
                </Form>
              </Accordion.Content>
            </Menu.Item>
            <Menu.Item>
              <Accordion.Title
                content="Filter by Zoning Category"
                active={this.state.categoriesFilterIsActive}
                onClick={() =>
                  this.setState({
                    filtersAreOpen: true,
                    citiesFilterIsActive: false,
                    categoriesFilterIsActive: !this.state.categoriesFilterIsActive
                  })
                }
              />
              <Accordion.Content active={this.state.categoriesFilterIsActive}>
                <Form>
                  <Form.Group grouped>
                    {zoningCategoryOptions.map((option, idx) => {
                      return (
                        <Form.Checkbox
                          key={idx}
                          label={option.text}
                          name="city"
                          value={option.value}
                          checked={this.state.zoningCategoryFilter.includes(option.value)}
                          onClick={(e, data) => this.handleZoningFilter(data)}
                        />
                      );
                    })}
                  </Form.Group>
                </Form>
              </Accordion.Content>
            </Menu.Item>
          </Accordion>
        ) : null}
      </Segment>
    );
  }
}

export default withMap(ZoningsLayerSegment);
