import { Polygon } from "@turf/helpers";
import { isEmpty } from "lodash";
import { Building } from "./buildings";

interface ProjectParcelData {
  city_id: number;
  geometry: Polygon;
  height: string | number;
  height_id: number | null;
  id: number;
  zoning: string;
  zoning_id: number;
}

interface SegmentsIndexes {
  front: number[];
  rear: number[];
  sides: number[];
}

export type DevelopmentSite = {
  city_id: number;
  geometry: Polygon;
  height: number | null;
  height_id: number | null;
  id: number;
  latitude: string;
  logntigude: string;
  main_building_id: number | null;
  project_id: number;
  segments_indexes: SegmentsIndexes;
  size: { area: number };
  parcel_usage_id: number;
};

export interface Project {
  address: string;
  author: string;
  date_created: string;
  date_modified: string;
  density_limit?: number;
  development_sites: DevelopmentSite[];
  id: number;
  latitude: string;
  longitude: string;
  // TODO(https://github.com/urbansim/penciler-planning/issues/709): Remove main_building_id
  main_building_id: number | null;
  mainBuildings?: Record<number, Building>;
  name: string;
  parcel_data: ProjectParcelData;
  parcel_size: { area: number };
  segment_indexes: SegmentsIndexes;
  uc_organization_id: number;
  user_id: number;
}

export const isProject = (value: Project | {}): value is Project => !isEmpty(value);
