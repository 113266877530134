export enum Modes {
  site_selected = "site_selected",
  direct_select = "direct_select",
  draw_line_string = "draw_line_string",
  confirm_subdivision = "confirm_subdivision",
  edit_line = "edit_line",
  remove_selected = "remove_selected",
  simple_select = "simple_select",
  draw_polygon = "draw_polygon",
  edit_polygon = "edit_polygon"
}

export const MaximumAmountOfSubSites = 10;
export const AngleSnappingValue = 45; // in degrees

export const multiLineId = "parcel-division-multiline";
export const projectBoundaryId = "project-boundary";
export const boundarySnappingDistance = 1; // in meters
export const vertexSnappingDistance = 1; // in meters
export const endpointDistanceExtension = 1; // in meters
