const colors = {
  activePolygonStroke: "#363636",
  activePolygonFill: "#DCE7D2",
  disabledPolygonStroke: "#a3a3a3",
  disabledPolygonFill: "#a3a3a3",
  vertex: "#363636",
  vertexHalo: "#FFF",
  midpoint: "#363636",
  midpointHalo: "#FFF",
  erroneousLine: "#d91414",
  succesfullLine: "#20690c"
};

const lineWidth = {
  activePolygonStroke: 3,
  disabledPolygonStroke: 3
};

const circleRadius = {
  vertex: 6,
  vertexHalo: 8,
  midpoint: 4,
  midpointHalo: 6
};

export default [
  // ACTIVE (being drawn)
  // line stroke
  {
    id: "gl-draw-line",
    type: "line",
    filter: [
      "all",
      ["==", "active", "true"],
      ["==", "$type", "LineString"],
      ["!=", "mode", "static"]
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    paint: {
      "line-color": [
        "case",
        ["==", ["get", "user_intersects"], "true"],
        colors.erroneousLine,
        ["==", ["get", "user_canBeCompleted"], "true"],
        colors.succesfullLine,
        colors.activePolygonStroke
      ],
      "line-width": lineWidth.activePolygonStroke
    }
  },
  {
    id: "gl-draw-label",
    type: "symbol",
    filter: ["all", ["has", "user_label"]],
    layout: {
      "text-field": ["get", "user_label"],
      "text-variable-anchor": ["center", "top", "bottom", "left", "right"],
      "text-radial-offset": 0.5,
      "text-justify": "center",
      "text-allow-overlap": true
    }
  },
  // segment length
  {
    id: "gl-draw-line-length-label",
    type: "symbol",
    filter: ["all", ["has", "user_length"]],
    layout: {
      "text-field": ["get", "user_length"],
      "text-anchor": "bottom",
      "text-justify": "center",
      "symbol-placement": "line-center",
      "text-allow-overlap": true
    }
  },
  // polygon fill
  {
    id: "gl-draw-polygon-fill-active",
    type: "fill",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    paint: {
      "fill-color": colors.activePolygonFill,
      "fill-opacity": ["case", ["==", ["get", "user_intersects"], "true"], 0.0, 0.4]
    }
  },
  // polygon hovered fill
  {
    id: "gl-draw-polygon-fill-hovered",
    type: "fill",
    filter: [
      "all",
      ["==", "user_hover", "true"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"]
    ],
    paint: {
      "fill-color": "#000000",
      "fill-outline-color": colors.activePolygonStroke,
      "fill-opacity": 0.4
    }
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
      "line-sort-key": 9
    },
    paint: {
      "line-color": [
        "case",
        ["==", ["get", "user_intersects"], "true"],
        colors.erroneousLine,
        colors.activePolygonStroke
      ],
      "line-width": 3
    }
  },
  // vertex point halos
  {
    id: "gl-draw-polygon-and-line-vertex-halo-active",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "true"],
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"]
    ],
    paint: {
      "circle-radius": circleRadius.vertexHalo,
      "circle-color": colors.vertexHalo
    }
  },
  // vertex points
  {
    id: "gl-draw-polygon-and-line-vertex-active",
    type: "circle",
    filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    paint: {
      "circle-radius": circleRadius.vertex,
      "circle-color": colors.vertex
    }
  },
  // midpoint halos
  {
    id: "gl-draw-polygon-midpoint-halo-active",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"], ["!=", "mode", "static"]],
    paint: {
      "circle-radius": circleRadius.midpointHalo,
      "circle-color": colors.midpointHalo
    }
  },
  // midpoints
  {
    id: "gl-draw-polygon-midpoint-active",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": circleRadius.midpoint,
      "circle-color": colors.midpoint
    }
  },

  // INACTIVE (static, already drawn)
  // polygon fill
  {
    id: "gl-draw-polygon-fill-inactive",
    type: "fill",
    filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": colors.disabledPolygonFill,
      "fill-outline-color": colors.disabledPolygonStroke,
      "fill-opacity": 0.25
    }
  },
  {
    id: "gl-draw-polygon-stroke-inactive",
    type: "line",
    filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
      "line-sort-key": 0
    },
    paint: {
      "line-color": colors.disabledPolygonStroke,
      "line-width": lineWidth.disabledPolygonStroke
    }
  }
];
