import { isNull, isUndefined } from "lodash";

/**
 * Assign objects in sources to target in that order, but not replacing a value once it is not null
 * or undefined anymore
 * @param  {Object}   target  - Object from which to start assigning
 * @param  [{Object}] sources - List of object from which to takes values when needed
 *
 * @return {Object}
 */
export const assignIfYetNotPresent = (target, ...sources) => {
  for (const source of sources) {
    for (const key of Object.keys(source)) {
      const currentValue = target[key];
      const candidateValue = source[key];
      if (
        !isUndefined(candidateValue) &&
        !isNull(candidateValue) &&
        (isUndefined(currentValue) || isNull(currentValue))
      ) {
        target[key] = candidateValue;
      }
    }
  }
  return target;
};

export const parsePercentage = value => {
  return parseFloat(value) / 100;
};

export const emptyHref = "#";

/**
 * Checks if param is undefined, empty or null
 *
 * @param {any} param  - Param to check
 * @return {boolean}   - True if param fails any of this check. False otherwise
 */
export const doesNotExist = param => {
  if (typeof param === "undefined" || param === "" || param === null) return true;
  if (Array.isArray(param) && param.length === 0) return true;
  if (typeof param === "object" && Object.prototype.toString.call(param) !== "[object Date]") {
    return Object.keys(param).length === 0;
  }
  return false;
};

export const exists = param => !doesNotExist(param);

/**
 * Check if a given string contains any keyword
 *
 * @param {String} string               - String to check against
 * @param {Array<String>} keywords      - List of string to iterate
 * @returns {Boolean}                      - True if string contains any keyword, false otherwise
 */
export const containsAnyKeyword = (string, keywords) =>
  keywords.some(k => string.toLowerCase().includes(k));

/**
 * Checks if any param is undefined
 *
 * @param {Array} args      - Arguments
 * @returns {boolean}       - True if at least one parameter does not exist
 */
export const anyDoesNotExist = (...args) => args.some(a => doesNotExist(a));

export const allExist = (...args) => !anyDoesNotExist(...args);

/**
 * Unzips an array of arrays.
 * The following snippet:
 *   input = [
 *     [1, 100, 'a'],
 *     [2, 100, 'b'],
 *     [3, 100, 'c'],
 *     [4, 100, 'd']
 *   ]
 *   output = unzip(input)
 *   console.log(output)
 * will output:
 *   [ [ 1, 2, 3, 4 ],
 *     [ 100, 100, 100, 100 ],
 *     [ 'a', 'b', 'c', 'd' ] ]
 *
 * @param {Array<Array>} inputArray
 * @returns {Array<Array>}
 */
export const unzip = inputArray => {
  if (inputArray.length === 0) return [];

  const outputLength = inputArray[0].length;
  inputArray.forEach(nestedArray => {
    if (nestedArray.length !== outputLength)
      throw new Error("unzip -> Nested arrays should have same length.");
  });

  return inputArray.reduce(
    (acc, cur) => {
      acc.forEach((ithAcc, idx) => ithAcc.push(cur[idx]));
      return acc;
    },
    Array(outputLength)
      .fill()
      .map(() => [])
  );
};

/**
 * Converts object to camelCase notation
 * @param {Object} obj      - Object to be converted
 * @returns {Object}        - Converted object
 */
export const toCamelCase = obj => {
  if (obj === null || obj === undefined || typeof obj !== "object") return obj;
  if (obj instanceof Array) return obj.map(toCamelCase);
  let rtn = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = key.replace(/(_\w)/g, k => k[1].toUpperCase());
      rtn[newKey] = toCamelCase(obj[key]);
    }
  }
  return rtn;
};
