import { Map } from "mapbox-gl";
import { legendArray } from "../../../config/map";

export default class LayerLegend {
  _map?: Map;
  _container?: any;

  onAdd(map: Map) {
    this._container = document.createElement("div");
    this._map = map;
    this._container.id = "layer-legend";
    this._container.className = "ui segment";
    this._container.style.left = "10px";
    this._container.style.top = "5px";

    const list = document.createElement("div");
    list.className = "ui list";
    legendArray.forEach(category => {
      const item = document.createElement("div");
      item.className = "item";
      const flexBoxContainer = document.createElement("div");
      flexBoxContainer.className = "flex-box-container";

      const svg = document.createElement("div");
      svg.style.height = "40px";
      svg.style.width = "40px";
      svg.className = "color-container";

      category.colors.forEach(color => {
        const rect = document.createElement("div");
        rect.style.height = "40px";
        rect.style.width = "10px";
        rect.style.backgroundColor = color;
        svg.appendChild(rect);
      });

      flexBoxContainer.appendChild(svg);

      const textContainer = document.createElement("div");

      const h3 = document.createElement("h3");
      h3.innerText = category.name;
      textContainer.appendChild(h3);

      flexBoxContainer.appendChild(textContainer);

      item.appendChild(flexBoxContainer);

      list.appendChild(item);
    });

    this._container.appendChild(list);

    return this._container;
  }
  onRemove() {
    this._container.parentNode?.removeChild(this._container);
  }
}
