/**
 * Created by jmartinez on 8/30/18.
 */

import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Icon, Table } from "semantic-ui-react";
import { openProject, setHiddenProject } from "../controllers/ui";
import { RootState } from "../store";
import { toggleDisableProjectId, focusProject, unfocusProject } from "../store/ui";
import { Project } from "../types/projects";

const uiActions = { toggleDisableProjectId, focusProject, unfocusProject };
const uiCtrl = { openProject, setHiddenProject };

const actionObj = { ...uiActions, ...uiCtrl };

type CustomProps = {
  project: Project;
};
const mapStateToProps = (state: RootState, ownProps: CustomProps) => {
  return {
    isFocused: state.ui.index.focusedProjectId === ownProps.project.id,
    selectedProject: state.projects.selectedProject,
    map: state.map.map,
    sidebarVisible: state.projects.sidebarVisible,
    bottomBarVisible: state.projects.bottomBarVisible,
    disabled: state.ui.index.disabledProjectsIDs.includes(ownProps.project.id)
  };
};

const connector = connect(mapStateToProps, actionObj);

type PropsFromRedux = ConnectedProps<typeof connector>;
type ProjectListItemProps = CustomProps & PropsFromRedux;

class ProjectListItem extends React.Component<ProjectListItemProps> {
  eyeClickHandler(e: Event) {
    e.stopPropagation();
    this.props.setHiddenProject(this.props.project.id, !this.props.disabled);
  }

  render() {
    const { disabled, isFocused, project } = this.props;
    return (
      <Table.Row
        active={isFocused && !disabled}
        onMouseEnter={() => this.props.focusProject(project.id)}
        onMouseLeave={() => this.props.unfocusProject()}
      >
        <Table.Cell
          disabled={disabled}
          onClick={() =>
            this.props.sidebarVisible ? this.props.openProject(project.id) : undefined
          }
        >
          <p>{project.name !== "" ? project.name : project.address}</p>
          <span style={{ color: "#B2B2B2" }}>Created: </span>
          <p style={{ color: "#B2B2B2" }}>
            {moment(project.date_created).format("M/D/YYYY")},{" "}
            {moment(project.date_created).format("h:mm A")}
          </p>
        </Table.Cell>
        <Table.Cell style={{ width: "15%" }} onClick={this.eyeClickHandler.bind(this)}>
          {disabled ? (
            <Icon name="eye slash" color="grey" />
          ) : isFocused ? (
            <Icon name="eye" />
          ) : null}
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default connector(ProjectListItem);
