import React from "react";
import { Step } from "semantic-ui-react";

const Steps = props => {
  return (
    <Step.Group>
      {props.stepData.map((step, index) => {
        return (
          <Step key={index} active={step.active} disabled={step.disabled}>
            <Step.Content>
              <Step.Title>{step.title}</Step.Title>
            </Step.Content>
          </Step>
        );
      })}
    </Step.Group>
  );
};

export default Steps;
