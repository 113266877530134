import area from "@turf/area";
import bbox from "@turf/bbox";
import { Feature, Polygon } from "@turf/helpers";
import { isNull } from "lodash";
import { reset, formValueSelector } from "redux-form";
import { flyToPitch, flyToZoom, initMap, mapOffset, sqMetersToSqFeet } from "../config/config";
import { allBuildingsExtrusionLayer } from "../config/map";
import { buildingURLs } from "../config/urls";
import * as buildingPromises from "../promises/buildings/buildings";
import store, { AppDispatch, RootState } from "../store";
import * as buildingsActions from "../store/buildings";
import { pushNewError } from "../store/errors";
import { operations, safeOperation } from "../store/operations";
import { setRentProfiles } from "../store/proforma";
import { renameSiteBuilding, setSelectedBuildingId } from "../store/projects";
import { getSelectedBuilding, selectedSiteIdSelector } from "../store/selectors/projects";
import { FloorplanVisualizationObject, UnitProperties } from "../store/types";
import { Building, SubBuilding, UnitFeature } from "../types/buildings";
import { Project } from "../types/projects";
import { doesNotExist, exists } from "../utils.js";

export const setFloorplanVisualizationInformation = (subBuildings: SubBuilding[]) => {
  /**
   * This function uses the current building's sub-buildings to compute an array that will have
   * information about which floor must be used on the 2d-visualization.
   *
   * `subBuildings` is an array of arrays containing objects like the following one:
   *   {
   *     geojson: <GeoJSON with the floor layout>,
   *     stories: <amount of times this floor must be used>
   *   }
   *
   * The resulting array will be composed of elements with the following structure:
   *  {
   *    startingFloor: <integer>,
   *    endingFloor: <integer>,
   *    blockIndexes: <array of integers>
   *    unitProperties: <dictionary of <unit_id(string), {area, id, total_sf}> >
   *  }
   * `blockIndexes` will be an array as long as amount of sub-buildings, indicating each time which
   * block's floor (podium, inner or upper block) must be used for the visualization.
   *
   * For example, if we have the following building with two sub-buildings:
   *   Floor   First sub   Second sub
   *   v       v           v
   *                ___
   *    14          | |
   *    13         _| |
   *    12         |  |
   *    11         |  |      ____
   *    10         |  |      |  |
   *               |  |      |  |
   *    08         |  |    __|  |__
   *    07         |  |    |      |
   *               |  |    |      |
   *               |  |    |      |
   *    04     ____|  |    |      |
   *    03     |      |    |      |
   *           |      |    |      |
   *    01     |______|    |______|
   *
   * then
   * resutArray = [
   *    { startingFloor: 1, endingFloor: 3, blockIndexes: [0, 0],
   *        { 10: { area: 105, id: 101, total_sf: 105 }, ... } },
   *    { startingFloor: 4, endingFloor: 7, blockIndexes: [1, 0], <unitProperties> },
   *    { startingFloor: 8, endingFloor: 10, blockIndexes: [1, 1], <unitProperties> },
   *    { startingFloor: 11, endingFloor: 12, blockIndexes: [1, null], <unitProperties> },
   *    { startingFloor: 13, endingFloor: 14, blockIndexes: [2, null], <unitProperties> },
   * ]
   *
   * Floors numbers are relative to the the first non residential levels. For example, if there are
   * 3 podium parking levels, `startingFloor` equal to 1 means that block is starting from the 4-th
   * level of the building.
   * If no podium parking is present, then there is 1 non residential level (which is the ground
   * floor)
   *
   * This allows to choose which floors should be shown in the vis, as well as keeping the legend
   * updated with the correct amount of units.
   * */
  const maximumStories = subBuildings.reduce((maximum, subBuilding) => {
    return Math.max(
      maximum,
      subBuilding.reduce((amount, floorObject) => amount + floorObject.stories, 0)
    );
  }, 0);

  let currentFloor = 0;
  const resultArray: FloorplanVisualizationObject[] = [];
  const unitTotalArea: Record<string, number> = {};
  const unitIds = {};
  const currentBlockIndexes: (number | null)[] = subBuildings.map(() => -1);
  // Indicates remaining floors until the current block ends
  const remainingFloors: (number | null)[] = subBuildings.map(() => 0);

  const forEachUnit = (
    unitCountPerFloor: number,
    unitProperties: UnitProperties,
    unitIds: Record<string, number>,
    unitTotalArea: Record<string, number>
  ) => {
    return (unit: UnitFeature) => {
      if (doesNotExist(unit.properties.id)) return;

      unitCountPerFloor++;
      const unitId = unit.properties.id;
      const isMetric = store.getState().users.metric;
      const unitArea = exists(unit.properties.gross_area)
        ? Number(unit.properties.gross_area.toFixed(0))
        : Math.floor(isMetric ? area(unit.geometry) : area(unit.geometry) * sqMetersToSqFeet);
      if (!(unitId in unitIds)) unitIds[unitId] = 100 * currentFloor + unitCountPerFloor;
      if (!(unitId in unitTotalArea)) unitTotalArea[unitId] = 0;

      unitProperties[unitId] = {
        area: unitArea,
        id: unitIds[unitId]
      };

      unitTotalArea[unitId] += unitArea;
    };
  };

  while (currentFloor < maximumStories) {
    const sectionEnded = remainingFloors.reduce(
      (changed, amount) => changed || amount === 0,
      false
    );
    currentFloor++;
    if (sectionEnded) {
      // If the previous section ended, we need to add a new one.
      // We first store the correct value in `currentBlockIndexes` and `remainingFloors`
      const unitProperties: UnitProperties = {};
      const unitCountPerFloor = 0;
      subBuildings.forEach((subBuilding, subIdx) => {
        if (remainingFloors[subIdx] === 0) {
          //@ts-ignore
          const newIdx = currentBlockIndexes[subIdx] + 1;
          if (newIdx < subBuilding.length) {
            remainingFloors[subIdx] = subBuilding[newIdx].stories - 1;
            currentBlockIndexes[subIdx] = newIdx;
            subBuilding[newIdx].geojson.features.forEach(
              forEachUnit(unitCountPerFloor, unitProperties, unitIds, unitTotalArea)
            );
          } else {
            remainingFloors[subIdx] = null;
            currentBlockIndexes[subIdx] = null;
          }
        } else {
          //@ts-ignore
          remainingFloors[subIdx]--;
        }
      });
      // Then we add the new element to our result array
      resultArray.push({
        startingFloor: currentFloor,
        endingFloor: currentFloor,
        blockIndexes: currentBlockIndexes.slice(),
        unitProperties: unitProperties
      });
    } else {
      // Otherwise we modify the result array's last element's `endingFloor` attribute
      resultArray[resultArray.length - 1].endingFloor += 1;
      subBuildings.forEach((_, subIdx) => {
        //@ts-ignore
        remainingFloors[subIdx]--;
      });
    }

    resultArray.forEach(floorInfo => {
      Object.keys(floorInfo.unitProperties).forEach(unitId => {
        floorInfo.unitProperties[unitId]["total_sf"] = unitTotalArea[unitId];
      });
    });
  }

  return buildingsActions.setFloorplanVisualizationInformation({
    floorplanVisualizationInformation: resultArray
  });
};

export const decreaseVisualizationFloor = () => {
  /**
   * Decrease the value which indicates the section that needs to be shown in the 2d-vis.
   * The mentioned value is the index in the array computed in the method
   * setFloorplanVisualizationInformation.
   * The index must be between 0 and the amount of sections - 1
   */
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const currentVisualizationFloorNumber = getState().buildings.visualizationFloorIdx;
    dispatch(
      buildingsActions.setVisualizationFloorIdx(
        currentVisualizationFloorNumber <= 0 ? 0 : currentVisualizationFloorNumber - 1
      )
    );
  };
};

export const increaseVisualizationFloor = () => {
  /**
   * Increase the value which indicates the section that needs to be shown in the 2d-vis.
   * The mentioned value is the index in the array computed in the method
   * setFloorplanVisualizationInformation.
   * The index must be between 0 and the amount of sections - 1
   */
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const currentVisualizationFloorNumber = getState().buildings.visualizationFloorIdx;
    const information = getState().buildings.floorplanVisualizationInformation;
    dispatch(
      buildingsActions.setVisualizationFloorIdx(
        currentVisualizationFloorNumber < information.length - 1
          ? currentVisualizationFloorNumber + 1
          : information.length - 1
      )
    );
  };
};

// TODO(gushuro, https://github.com/urbansim/penciler-planning/issues/711):
// removed all references to this function. Decide whether to keep it
export const apiGetBuildingInfo = (buildingId: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(buildingsActions.startedRetrievingBuildingData());
    try {
      const buildingResponse: Building = await buildingPromises.asyncGetBuildingInfo(buildingId);

      // NOTE: If we're gonna keep this function, we need a new action that stores
      // this with the rest of the buildings.
      // Also, selectBuildingById below should only be called if project was already fetched.
      dispatch(selectBuildingById(buildingResponse.id));
      dispatch(buildingsActions.retrievedBuildingData());
      dispatch(buildingsActions.finishedLoadingBuilding());
    } catch (error) {
      dispatch(
        pushNewError({
          name: "Error retrieving building info from building with id " + buildingId,
          description: `actions::buildings::apiGetBuildingInfo -> ${error}`
        })
      );
      dispatch(buildingsActions.buildingDataRetrievalFailed());
    }
  };
};

export const selectBuildingById = (buildingId: number) => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const siteId = selectedSiteIdSelector(getState());
  if (!siteId) {
    dispatch(
      pushNewError({
        name: `Error selecting building (ID: ${buildingId}). No site was selected.`,
        description: `actions::buildings::selectBuildingById -> No Site Selected`
      })
    );
    return;
  }
  const siteBuildings = getState().projects.projectBuildingsBySite[siteId];
  if (!siteBuildings.ids.includes(buildingId)) {
    dispatch(
      pushNewError({
        name: `Error selecting building (ID: ${buildingId}). Not found on site (ID: ${siteId}).`,
        description: `actions::buildings::selectBuildingById -> Building ID not in site's buildings`
      })
    );
    return;
  }
  const building = siteBuildings.byId[buildingId];
  dispatch(setSelectedBuildingId(buildingId));
  dispatch(setRentProfiles(isNull(building.rents) ? [] : building.rents.inputs.rent_profiles));
  dispatch(setFloorplanVisualizationInformation(building.geometry.sub_buildings));
};

export const deselectBuilding = () => (dispatch: AppDispatch) => {
  dispatch(setSelectedBuildingId(null));
  dispatch(setRentProfiles([]));
  dispatch(
    buildingsActions.setFloorplanVisualizationInformation({ floorplanVisualizationInformation: [] })
  );
};

/**
 * @deprecated
 */
export const getAndRenderBuilding = (buildingId: number) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const map = (getState().map.map as unknown) as mapboxgl.Map;
  const { selectedProject } = getState().projects;

  dispatch(buildingsActions.startedLoadingBuilding);
  await dispatch(apiGetBuildingInfo(buildingId));

  const center: [number, number] = [
    parseFloat((selectedProject as Project).longitude),
    parseFloat((selectedProject as Project).latitude) - mapOffset
  ];

  map?.flyTo({
    center: center,
    zoom: flyToZoom,
    pitch: flyToPitch,
    bearing: initMap.bearing
  });
  dispatch(buildingsActions.finishedLoadingBuilding());
};

export const hideNearbyBuildings = (parcel: Feature<Polygon>, map: mapboxgl.Map) => {
  return () => {
    const feature = {
      type: "Feature",
      geometry: {
        type: parcel.geometry.type,
        coordinates: parcel.geometry.coordinates
      }
    };
    const bboxArray = bbox(feature);
    const projectedMin = map.project([bboxArray[0], bboxArray[1]]);
    const projectedMax = map.project([bboxArray[2], bboxArray[3]]);

    const queriedFeature = map.queryRenderedFeatures(
      [
        [projectedMin.x, projectedMin.y],
        [projectedMax.x, projectedMax.y]
      ],
      {
        layers: [allBuildingsExtrusionLayer]
      }
    );

    if (queriedFeature.length === 0) return;

    const getFilteredBuildingsIds = (map: mapboxgl.Map, layer: string) => {
      const idsFilters = (map.getFilter(layer) || []).slice(1);
      const idOffsetInFilter = 2;
      return idsFilters.map((filter: any[]) => filter[idOffsetInFilter]);
    };

    const ids = getFilteredBuildingsIds(map, allBuildingsExtrusionLayer);
    queriedFeature.forEach(item => {
      map.setFeatureState(
        {
          source: "composite",
          id: item.id,
          sourceLayer: "building"
        },
        { hidden: true }
      );
      ids.push(item.id);
    });
    const filter: any[] = ["all"];
    for (const id of [...new Set(ids)]) {
      filter.push(["!=", ["id"], id]);
    }
    map.setFilter(allBuildingsExtrusionLayer, filter);
  };
};

export const openRenameBuildingModal = () => (dispatch: AppDispatch) =>
  dispatch(buildingsActions.toggleRenameBuildingModal(true));

export const closeRenameBuildingModal = () => (dispatch: AppDispatch) => {
  dispatch(buildingsActions.toggleRenameBuildingModal(false));
  dispatch(reset("RenameModalForm"));
};

const selector = formValueSelector("RenameModalForm");
export const renameBuilding = () =>
  safeOperation(
    operations.buildingRename,
    async (dispatch: AppDispatch, getState: () => RootState) => {
      const selectedBuilding = getSelectedBuilding(getState()) as Building;
      const buildingId = selectedBuilding.id;
      const name = selector(getState(), "name");
      if (doesNotExist(name)) {
        return;
      }

      const targetURL = buildingURLs.edit(buildingId);
      const dataToSend = {
        name: name,
        regenerate_building: false
      };

      await buildingPromises.asyncPutBuilding(targetURL, dataToSend);

      dispatch(renameSiteBuilding({ buildingId, name }));
      dispatch(closeRenameBuildingModal());
    },
    async (error, dispatch) =>
      dispatch(
        pushNewError({
          name: "Edition of building name failed",
          description: `actions::buildings::renameBuilding -> ${error}`
        })
      )
  );
