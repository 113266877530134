import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { formatDollars, formatPercentage } from "../../config/utils";
import { buildingsQuerier } from "../../queriers/users/building";
import { Building } from "../../types/buildings";
import { exists } from "../../utils";
import Row from "./Row";

const styles = StyleSheet.create({
  title: {
    fontWeight: 400,
    fontSize: 11,
    textTransform: "uppercase",
    color: "#54B2AB",
    marginBottom: "4mm"
  }
});

type FinancialPerformanceProps = {
  building: Building;
};

const FinancialPerformance: React.FunctionComponent<FinancialPerformanceProps> = props => {
  const { building } = props;
  const querier = buildingsQuerier(building);
  return (
    <View style={{ marginTop: "6mm" }}>
      <Text style={styles.title}>Financial Performance</Text>
      <View>
        <Row
          name="Total Development Cost per Unit"
          value={formatDollars(querier.totalDevelopmentCostPerUnit)}
        />
        <Row
          name="Year 1 NOI"
          value={
            exists(building.rents) ? formatDollars(building.rents.outputs.cashflow[0].NOI) : "-"
          }
        />
        <Row
          name="Capitalized Value"
          value={
            exists(building.finance)
              ? formatDollars(building.finance.outputs.capitalized_value)
              : "-"
          }
        />
        <Row
          name="Gap in Funding"
          value={exists(building.finance) ? formatDollars(building.finance.outputs.gap) : "-"}
        />
        <Row
          name="Project Profit"
          value={
            exists(building.finance) ? formatDollars(building.finance.outputs.project_profit) : "-"
          }
        />
        <Row
          name="Return on Cost"
          value={
            exists(building.finance)
              ? formatPercentage(building.finance.outputs.return_on_cost)
              : "-"
          }
        />
      </View>
    </View>
  );
};

export default FinancialPerformance;
