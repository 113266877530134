import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dimmer, Loader as SemanticLoader } from "semantic-ui-react";
import { RootState } from "../store";

export const Loader = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100vh",
      alignItems: "center"
    }}
  >
    <div className="penciler-loader" />
    <h1>Loading...</h1>
  </div>
);

export const TableLoader = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: "1em"
    }}
  >
    <div className="table-loader" />
    <h2>Loading...</h2>
  </div>
);

const mapStateToProps = (state: RootState) => ({
  active: state.ui.dimmedLoader.active,
  message: state.ui.dimmedLoader.message,
  style: state.ui.dimmedLoader.style
});

export const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const DimmedLoaderComponent: React.FunctionComponent<PropsFromRedux> = props => {
  const { active, message, style } = props;
  return (
    <Dimmer active={active} style={{ ...style, zIndex: 9999 }}>
      <SemanticLoader size="massive">{message}</SemanticLoader>
    </Dimmer>
  );
};

export const DimmedLoader = connector(DimmedLoaderComponent);
