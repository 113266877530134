import React from "react";
import { Popup } from "react-mapbox-gl";
import { Icon } from "semantic-ui-react";

const ClosablePopup = props => {
  return (
    <Popup coordinates={props.coordinates}>
      <div style={{ position: "relative", display: "flex", justifyContent: "flex-end" }}>
        <Icon name="close" size="small" onClick={props.onClose} style={{ cursor: "pointer" }} />
      </div>
      {props.children}
    </Popup>
  );
};

export default ClosablePopup;
