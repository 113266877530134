import { Polygon, Point, Feature } from "@turf/helpers";

export enum ActiveTable {
  summary = "summary",
  buildings = "buildings",
  proforma = "proforma"
}

export type ActiveModalForm = string;

export interface SiteInformation {
  theGeomGeoJSON: Polygon;
  area: number;
  centroid: Feature<Point>;
  latitude: number;
  longitude: number;
  usage?: number;
}

export interface ParcelInformation extends SiteInformation {
  address: string;
  height: number;
  zoningCode: string;
  parcelId: number;
  zoningId: number;
  heightId: number | string;
  cityId: number;
  neighbors: ParcelInformation[];
  composingParcelsIds: number[];
}

export enum SidebarComponent {
  addNewProject = "AddNewProject",
  projectList = "ProjectList",
  segmentRequirements = "SegmentRequirements"
}
