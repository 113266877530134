import store from "../store";
import { getConvertedDistanceValue } from "./utils";

// Bulk requirements (values in feet)
export const getBulkRequirementsDefaultValues = () => ({
  lowerTowerShrinkValue: Math.ceil(getConvertedDistanceValue(5)),
  upperTowerShrinkValue: Math.ceil(getConvertedDistanceValue(5))
});

// Density bonus default values
export const getDensityBonusNeutralValues = () => ({
  enabled: false,
  bonusMaxHeight: 0,
  bonusGroundFloorHeight: 0,
  bonusPercentage: 0
});
export const getDensityBonusDefaultValues = () => ({
  enabled: false,
  bonusMaxHeight: getConvertedDistanceValue(35),
  bonusGroundFloorHeight: getConvertedDistanceValue(5),
  bonusPercentage: 0
});

// Heights limits
export const heightLimitsDefaultValues = () => {
  const isMetric = store.getState().users.metric;
  return isMetric
    ? { maxHeight: 24, groundFloorHeight: 3 }
    : {
        maxHeight: 80,
        groundFloorHeight: 10
      };
};

// Proforma default values

// Note(gushuro): DevCosts default values by region ID from following issue
// https://github.com/urbansim/penciler-planning/issues/458
// Should match values in penciler-server repository
export const developmentCostsDefaults = {
  Vancouver: {
    unitCostPerHeight: [
      { start: 0, end: 7, unitCost: 2400 },
      { start: 7, end: 13, unitCost: 2500 },
      { start: 13, end: 40, unitCost: 2600 },
      { start: 40, end: 60, unitCost: 2700 },
      { start: 60, end: Infinity, unitCost: 3000 }
    ],
    rest: {
      commercial_construction_cost_per_sf: 1600,
      podium_parking_space_construction_cost: 1150,
      underground_parking_space_construction_cost: 1500
    }
  },
  Toronto: {
    unitCostPerHeight: [
      { start: 0, end: 7, unitCost: 2300 },
      { start: 7, end: 13, unitCost: 2350 },
      { start: 13, end: 40, unitCost: 2400 },
      { start: 40, end: 60, unitCost: 2500 },
      { start: 60, end: Infinity, unitCost: 2700 }
    ],
    rest: {
      commercial_construction_cost_per_sf: 1600,
      podium_parking_space_construction_cost: 1130,
      underground_parking_space_construction_cost: 1480
    }
  }
};

export const sourceOfFundsDefaults = {
  int_rate: 6.0,
  years: 30,
  dcr: 1.25,
  origination_fee: 0,
  other_debt: 0,
  tax_credit: "four",
  tax_credit_pct: 3.3,
  tax_credit_pricing: 0.9,
  percent_affordable: 100,
  basis_boost: 100,
  basis_boost_checked: 130,
  dda_or_qct_checked: 115,
  compliance_period: 10,
  other_equity: 0,
  residential_cap_rate: 5,
  has_LIHTC: false,
  has_mortgage: true
};
