import React from "react";
import UICrashModal from "./UICrashModal";

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }
  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return <UICrashModal />;
    }
    return this.props.children;
  }
}
