import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { env } from "../config/env";
import { IntegrationError } from "../lib/errors";
import { ErrorSlice } from "./types";

const initialState: ErrorSlice = {
  integrationErrors: [],
  activeErrors: [],
  open: false
};
const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    pushIntegrationError(state, action: PayloadAction<IntegrationError>) {
      if (state.integrationErrors.find(x => x.id === action.payload.id)) {
        // If there is already an error with this same id then we won't be pushing the new error.
        // This is to prevent conditions in which the same errors occurs multiple times (for
        // example the failures of the MapBox tiles' fetchs)
        return;
      }
      state.integrationErrors.push(action.payload);
    },
    pushNewError(state, action: PayloadAction<{ name: string; description?: string }>) {
      let { description } = action.payload;
      const { name } = action.payload;
      const noDescription = "No description provided";

      if (typeof description != "string") {
        description = JSON.stringify(description, undefined, 2) || noDescription;
      } else if (!description.trim()) {
        description = noDescription;
      }

      if (description !== noDescription && env.errorReportShouldBeShown) {
        console.error(description);
      }

      const timestamp = Date();

      state.activeErrors = state.activeErrors.concat({ name, description, timestamp });
      state.open = true;
    },
    toggleErrorModal(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
    }
  }
});

export const { pushNewError, pushIntegrationError, toggleErrorModal } = errorsSlice.actions;

export default errorsSlice.reducer;
