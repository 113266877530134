import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";
import ReactDom from "react-dom";
import { connect, ConnectedProps } from "react-redux";
import { Button, Dropdown, Popup } from "semantic-ui-react";
import { BuildingTableRow, projectViewFilteredColumns } from "../config/buildingOptionTableData";
import { cashflowMeasures } from "../config/config";
import { actions, ownerships, resources } from "../constants/permissions";
import { buildingDataStatus } from "../constants/status";
import * as buildingsCtrl from "../controllers/buildings";
import { downloadDXF } from "../controllers/dxfExport";
import * as programCtrl from "../controllers/programs";
import * as projectCtrl from "../controllers/projects";
import { duplicateBuilding } from "../controllers/user/building";
import { userIsAllowedToAny } from "../helpers/permissions";
import { RootState } from "../store";
import * as buildingsActions from "../store/buildings";
import * as proformaActions from "../store/proforma";
import * as programActions from "../store/programs";
import * as projectActions from "../store/projects";
import { getSiteBuildingById, selectedSiteIdSelector } from "../store/selectors/projects";
import { Building, isBuilding } from "../types/buildings";
import { Project } from "../types/projects";
import { ActiveTable } from "../types/ui";
import PDFProvider from "./PDFReport";

type BuildingMenuOwnProps = {
  buildingOption: BuildingTableRow;
  isSiteView: boolean;
  onClick?: Function;
};

const mapStateToProps = (state: RootState, ownProps: BuildingMenuOwnProps) => ({
  currentBuildingDataStatus: state.buildings.currentBuildingDataStatus,
  selectedSiteId: selectedSiteIdSelector(state),
  building: getSiteBuildingById(state, ownProps.buildingOption[0].value as number),
  activeTable: state.projects.activeTable,
  selectedProject: state.projects.selectedProject as Project,
  flags: state.buildings.flags,
  userEPSGCode: state.users.epsgCode,
  userProj4EPSGDefinition: state.users.proj4EPSGDefinition,
  userIsMetric: state.users.metric,
  map: (state.map.map as unknown) as mapboxgl.Map
});

const actionObj = {
  duplicateBuilding,
  ...buildingsActions,
  ...buildingsCtrl,
  ...proformaActions,
  ...programActions,
  ...programCtrl,
  ...projectActions,
  ...projectCtrl
};

const connector = connect(mapStateToProps, actionObj);

type PropsFromRedux = ConnectedProps<typeof connector>;
type BuildingMenuProps = BuildingMenuOwnProps & PropsFromRedux;
class BuildingOptionMenu extends React.Component<BuildingMenuProps> {
  handleDeleteClick(buildingId: number) {
    this.props.apiDeleteBuildingOptions([buildingId], this.props.selectedSiteId);
  }

  handleProformaClick(buildingId: number) {
    this.props.setProformaBuildingId(buildingId);
    this.props.setActiveModalForm("DevCostsStep");
    this.props.toggleWizardModal(true);
  }

  handleDuplicateClick() {
    const { building } = this.props;
    if (!isBuilding(building)) return;
    const name = `${building.name} (Copy)`;
    this.props.duplicateBuilding(
      [{ id: building.building_program_id, is_building_program: false }],
      building.zoning_id,
      name
    );
  }

  handleEditBuildingOptionClick() {
    const { building } = this.props;
    if (!isBuilding(building)) return;
    this.props.cleanSelectedPrograms();
    this.props.setSelectedProgram({
      ...building.building_program,
      building_type: building.building_type,
      isBuildingProgram: true
    });
    this.props.changeEditBuildingMode(true);
    this.props.toggleAddBuildingModal(true);
  }

  handleRenameBuildingClick() {
    this.props.openRenameBuildingModal();
  }

  downloadCSV(building: Building) {
    const cashflowData = building?.rents?.outputs.cashflow ?? [];

    const cashflowDownloadData = cashflowMeasures.map(row => {
      const rowObj: any = { measure: row };
      cashflowData.forEach((record, index) => {
        //@ts-ignore
        rowObj[`Period ${index + 1}`] = record[row];
      });
      return rowObj;
    });

    ReactDom.render(
      <CSVLink
        id="csvLink"
        data={cashflowDownloadData}
        target="_blank"
        filename={`${building.name}_${moment().format("MM-DD-YYYY")}_cashflows.csv`}
      />,
      document.getElementById("dummy-element")
    );
    setTimeout(() => {
      const element = document.getElementById("csvLink");
      if (!element) return;
      element.click();
      ReactDom.unmountComponentAtNode(element);
    }, 1000);
  }

  render() {
    const {
      buildingOption,
      currentBuildingDataStatus,
      flags,
      activeTable,
      isSiteView,
      selectedProject,
      building
    } = this.props;
    let buildingOptionSlice = buildingOption.slice(2);
    if (!isSiteView) {
      buildingOptionSlice = buildingOptionSlice.filter(
        obj => !projectViewFilteredColumns.includes(obj.name)
      );
    }
    const buildingId = buildingOption[0].value as number;
    const isMissingFinance =
      activeTable === ActiveTable.proforma &&
      buildingOptionSlice.some(option => option.value === "");
    const isDemoBuilding = typeof buildingId === "string";

    const buildingActionsEnabled =
      currentBuildingDataStatus !== buildingDataStatus.beingRetrieved && !flags.buildingIsLoading;

    return (
      <Dropdown
        button
        color="teal"
        floating
        className={"icon building-table-action" + (isMissingFinance ? " blinking" : "")}
        icon="ellipsis horizontal"
        onClick={(...args) => this.props.onClick && this.props.onClick(...args)}
        upward
      >
        <Dropdown.Menu>
          <Button.Group icon basic size="small">
            <Popup
              content={`${
                userIsAllowedToAny(
                  actions.update,
                  [ownerships.own, ownerships.organization],
                  resources.buildingOption
                )
                  ? "Edit"
                  : "View"
              } selected building`}
              trigger={
                <Button
                  icon="edit"
                  color="teal"
                  disabled={!buildingActionsEnabled}
                  onClick={() => {
                    this.handleEditBuildingOptionClick();
                  }}
                />
              }
            />
            {userIsAllowedToAny(
              [actions.update, actions.create],
              [ownerships.all, ownerships.own, ownerships.organization],
              resources.financialAnalysis
            ) ? (
              <Popup
                content="Add financial assumptions"
                trigger={
                  <Button
                    icon="dollar sign"
                    color="olive"
                    onClick={() => {
                      this.handleProformaClick(buildingId);
                      this.handleProformaClick(buildingOption[0].value as number);
                    }}
                    disabled={!buildingActionsEnabled}
                  />
                }
              />
            ) : null}
            <Popup
              content="Download building data"
              trigger={
                <Dropdown button icon="download" simple disabled={!buildingActionsEnabled}>
                  <Dropdown.Menu>
                    {isBuilding(building) && building.rents ? (
                      <Dropdown.Item
                        onClick={() => {
                          this.downloadCSV(building);
                        }}
                      >
                        <div id="csv-link" />
                        Cashflows
                      </Dropdown.Item>
                    ) : null}

                    <Dropdown.Item>
                      <PDFProvider
                        // @ts-ignore
                        building={building}
                        project={selectedProject}
                        map={this.props.map}
                      />
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => {
                        downloadDXF(
                          building,
                          selectedProject,
                          this.props.userEPSGCode,
                          this.props.userProj4EPSGDefinition,
                          this.props.userIsMetric
                        );
                      }}
                    >
                      DXF Export
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              }
            />

            {!isDemoBuilding &&
            userIsAllowedToAny(
              actions.duplicate,
              [ownerships.own, ownerships.organization],
              resources.buildingOption
            ) ? (
              <Popup
                content="Duplicate selected building"
                trigger={
                  <Button
                    icon="copy"
                    color="grey"
                    onClick={() => {
                      this.handleDuplicateClick();
                    }}
                    disabled={!buildingActionsEnabled}
                  />
                }
              />
            ) : null}

            <Popup
              content="Display building floorplan"
              trigger={
                <Button
                  icon="building"
                  color="grey"
                  onClick={() => {
                    this.props.toggleFloorLayout(true);
                  }}
                  disabled={!buildingActionsEnabled}
                />
              }
            />
            <Popup
              content="Rename Building"
              trigger={
                <Button
                  icon="i cursor"
                  color="teal"
                  onClick={() => {
                    this.handleRenameBuildingClick();
                  }}
                  disabled={!buildingActionsEnabled}
                />
              }
            />
            {!isDemoBuilding &&
            userIsAllowedToAny(
              actions.delete,
              [ownerships.own, ownerships.organization],
              resources.buildingOption
            ) ? (
              <Popup
                content="Delete selected building"
                trigger={
                  <Button
                    icon="trash"
                    color="red"
                    onClick={() => {
                      this.handleDeleteClick(buildingId);
                    }}
                    disabled={!buildingActionsEnabled}
                  />
                }
              />
            ) : null}
          </Button.Group>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default connector(BuildingOptionMenu);
