import { BoundingBox } from "../../lib";
import { RootState, AppDispatch } from "../../store";
import { mandatoryOperation, safeOperation, operations } from "../../store/operations";
import { mainSelectorCreator } from "../../store/selectors";
import { updateMapViewport } from "../../store/ui";

const _updateAction = (boundingBox: BoundingBox) => (
  dispatch: AppDispatch,
  getState: () => RootState
) =>
  dispatch(
    updateMapViewport({
      mapViewport: boundingBox,
      projects: mainSelectorCreator(getState()).getAllProjects()
    })
  );

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const update = (boundingBox: BoundingBox) =>
  safeOperation(operations.updateMapViewport, _updateAction(boundingBox));

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const forceUpdate = (boundingBox: BoundingBox) =>
  mandatoryOperation(operations.updateMapViewport, _updateAction(boundingBox));

export default { update, forceUpdate };
