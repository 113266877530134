/**
 * Created by jmartinez on 9/19/18.
 */

import { isEmpty, isNull } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { isZeroOrPositiveNumeric, zoningSteps } from "../../config/config";
import { getAreaLabel } from "../../config/utils";
import * as projectCtrl from "../../controllers/projects";
import * as zoningCtrl from "../../controllers/zoning";
import * as projectActions from "../../store/projects";
import * as zoningActions from "../../store/zoning";
import Steps from "../Steps";
import WizardStep from "../WizardStep";
import { ZoningSaveModal } from "../ZoningSave";
import TextInput from "./../forms/TextInput";
import WizardTextInput from "./../forms/WizardTextInput";

const actionsObj = Object.assign({}, projectActions, projectCtrl, zoningActions, zoningCtrl);

const footerButtons = props => {
  return (
    <div>
      <Button
        onClick={() => {
          props.setActiveModalForm(zoningSteps["zoning_step_3"]);
        }}
      >
        Back
      </Button>

      <Button
        onClick={() => {
          props.submitOpenSpace();
        }}
      >
        Next
      </Button>
    </div>
  );
};

export const OpenspaceStepFooter = connect(state => {
  return {
    editZoning: state.zoning.editZoning
  };
}, actionsObj)(footerButtons);

class OpenSpaceStep extends WizardStep {
  handleBackClick(e) {
    e.preventDefault();
    this.props.setActiveModalForm("zoningPt3");
  }

  render() {
    const { editMode, derivedZoningData } = this.props;
    const stepData = [
      { title: "Zoning", active: false, disabled: false },
      { title: "Height & Density", active: false, disabled: false },
      { title: "Setbacks", active: false, disabled: false },
      { title: "Open Space", active: true, disabled: false },
      { title: "Parking", active: false, disabled: true }
    ];
    const originalData = editMode ? { originalData: derivedZoningData } : {};

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Steps stepData={stepData} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form onSubmit={this.props.handleSubmit}>
                <Segment>
                  <Field
                    name="open_sp_per_unit"
                    component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
                    label="Usable Common Open Space/Unit"
                    unitLabel={getAreaLabel()}
                    labelPosition="right"
                    validate={[isZeroOrPositiveNumeric]}
                    {...originalData}
                    readOnly={this.readOnlyMode}
                  />
                  <Field
                    name="open_sp_notes"
                    component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
                    label="Open Space Notes"
                    {...originalData}
                    readOnly={this.readOnlyMode}
                  />
                </Segment>

                <ZoningSaveModal
                  open={this.props.confirmZoningFavorites}
                  cancelAction={() => {
                    this.props.toggleConfirmZoningFavorites(false);
                  }}
                  saveAction={() => {
                    this.props.submitOpenSpace();
                  }}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    openSpaceNotes: isNull(state.zoning.zoningValues)
      ? state.zoning.zoningValues.open_sp_notes
      : "",
    initialValues: state.zoning.zoningValues,
    editMode: !isEmpty(state.zoning.zoningInitialValues),
    derivedZoningData: state.zoning.derivedZoningData,
    confirmZoningFavorites: state.zoning.confirmZoningFavorites
  };
};

const OpenSpaceStepForm = reduxForm({
  form: "OpenSpaceStepForm",
  destroyOnUnmount: false
})(OpenSpaceStep);

export default connect(mapStateToProps, actionsObj)(OpenSpaceStepForm);
