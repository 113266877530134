import { UnitType } from "../store/types";

export interface RentProfile {
  allowance: number;
  ami: string;
  name: UnitType;
  rent: number;
  unit_count: number;
}

export interface SimplifiedDevCostsInputs {
  development_cost_per_unit: number;
  development_cost_type: "use_simplified_development_costs";
}

export interface CompleteDevCostsInputs {
  acquistion_cost: number;
  commercial_construction_cost_per_sf: number;
  developer_fee: number;
  development_cost_type: "use_complete_development_costs";
  impact_fees_per_unit: number;
  podium_parking_space_construction_cost: number;
  residential_construction_cost_per_sf: number;
  soft_cost_percentage: number;
  underground_parking_space_construction_cost: number;
}
export interface DevcostsData {
  inputs: SimplifiedDevCostsInputs | CompleteDevCostsInputs;
  outputs: { preliminary_total_development_costs: number; total_parking_costs?: number };
}

export function hasCompleteInputs(
  inputs: DevcostsData["inputs"]
): inputs is CompleteDevCostsInputs {
  return inputs.development_cost_type === "use_complete_development_costs";
}

export interface OperatingInput {
  commercial_rent_inflator: number;
  commercial_rent_per_sf_per_annum: number;
  commercial_vacancy: number;
  expense_inflator: number;
  laundry_income_per_unit_per_month: number;
  manager_units: number;
  operating_expenses_per_unit_per_annum: number;
  rent_profiles: RentProfile[];
  reserves_per_unit_per_annum: number;
  residential_rent_inflator: number;
  residential_vacancy: number;
}

export interface OperatingOutput {
  annual_commercial_rent: number;
  annual_laundry_income: number;
  annual_residential_rent: number;
  cashflow: {
    NOI: number;
    commercial_income: number;
    estimated_gross_income: number;
    operating_expenses: number;
    other_income: number;
    reserves: number;
    residential_income: number;
    vacancy: number;
  }[];
  monthly_residential_rent: number;
}

export interface SourceOfFundsInput {
  interest_rate?: number;
  armortization_period?: number;
  debt_service_coverage_ratio?: number;
  origination_fee?: number;
  other_debt?: number;

  has_LIHTC?: boolean;
  has_mortgage?: boolean;
  eligible_basis?: number;
  tax_credit_type?: "four" | "nine";
  tax_credit_percentage: number;
  tax_credit_pricing: number;
  percent_affordable: number;
  basis_boost: number;
  compliance_period: number;

  other_equity: number;
  residential_cap_rate: number;
  actual_commercial_retail_gsf?: number;
}
export type SourceOfFundsOutput = {
  final_total_development_costs: number;
  total_mortgage: number;
  mortgage_payment: number;
  origination_fee: number;
  total_debt: number;
  LIHTC_equity: number;
  total_equity: number;
  gap: number;
  total_sources: number;
  gap_per_unit: number;
  capitalized_value: number;
  project_profit: number;
  return_on_cost: number;
  yield_on_cost: number;
};
