import { DrawFeature, DrawMode, DrawMouseEvent } from "../../commonTypes";
import { Modes, projectBoundaryId } from "../../constants";
import { getFirstUsedSiteId, parcelFeatureIdPrefix } from "./utils";

type RemoveSelectedModeState = {
  selectedSiteId: string;
  hoveredId: string;
};

const RemoveSelectedMode: DrawMode = {
  onSetup: function() {
    const selectedSite =
      this.getSelected().find((feature: DrawFeature) =>
        feature.id.includes(parcelFeatureIdPrefix)
      ) ?? getFirstUsedSiteId(this)[1];
    return {
      selectedSiteId: selectedSite.id,
      hoveredId: null
    };
  },

  onClick: function(state: RemoveSelectedModeState, e: DrawMouseEvent) {
    const subparcel: DrawFeature = e.featureTarget;
    if (!subparcel?.properties?.id?.includes(parcelFeatureIdPrefix)) return;
    if (subparcel !== undefined) {
      const idToBeDeleted = subparcel.properties.id;
      this.deleteFeature(idToBeDeleted);
      this.map.fire("draw.siteDeleted", { siteIdRemoved: subparcel.properties.user_subparcelId });

      const idToBeSelected =
        idToBeDeleted === state.selectedSiteId
          ? (getFirstUsedSiteId(this)[1] as string)
          : state.selectedSiteId;

      this.onExit(idToBeSelected);
    }
  },

  onMouseMove(state: RemoveSelectedModeState, e: DrawMouseEvent) {
    const features = this.featuresAt(e).filter(
      (feature: DrawFeature) => feature.properties.id !== projectBoundaryId
    );

    if (state.hoveredId !== null) {
      this.doRender(state.hoveredId);
    }

    state.hoveredId = features[0]?.properties?.id ?? null;

    if (state.hoveredId !== null) {
      this.doRender(state.hoveredId);
    }
  },

  onKeyUp: function(state: RemoveSelectedModeState, e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.onExit(state.selectedSiteId);
    }
  },

  toDisplayFeatures: function(
    state: RemoveSelectedModeState,
    geojson: DrawFeature,
    display: Function
  ) {
    if (state.hoveredId && state.hoveredId === geojson.properties.id) {
      geojson.properties.user_hover = "true";
    } else {
      geojson.properties.user_hover = "false";
    }
    display(geojson);
  },
  onExit: function(siteToBeSelected: string) {
    this.changeMode(Modes.site_selected, { selectedId: siteToBeSelected });
  }
};

export default RemoveSelectedMode;
