import axios from "axios";
import { baseUrl } from "../../config/urls";

export const asyncPostBuilding = (url, dataToSend) =>
  new Promise((resolve, reject) => {
    axios
      .post(url, dataToSend)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncPutBuilding = (url, dataToSend) =>
  new Promise((resolve, reject) => {
    axios
      .put(url, dataToSend)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const asyncGetBuildingInfo = buildingId =>
  new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/buildings/id/${buildingId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
