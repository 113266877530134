import { Polygon } from "@turf/helpers";
import { Feature, Point, LineString } from "@turf/helpers";
import { area, centroid } from "@turf/turf";
import { Map } from "mapbox-gl";
import React, { useEffect, useState, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { sqMetersToSqFeet } from "../../../config/config";
import { RootState } from "../../../store";
import {
  setSelectedCandidateSiteId,
  addCandidateSite,
  removeCandidateSite,
  setParcelIsValid,
  setSiteSubdivision
} from "../../../store/projects";
import { selectedCandidateSiteIdSelector } from "../../../store/selectors/projects";
import { setParcelEditionMode } from "../../../store/ui";
import { setBoundaryVisualization } from "../../../store/ui/boundaryVisualization";
import { showInfoMessage, hideInfoMessage } from "../../../store/ui/infoMessage";
import styles from "../../../stylesheets/DrawingControls";
import { DrawFeature, MapBoxDrawControl } from "../commonTypes";
import { Modes as ModesConstants } from "../constants";
import DrawControl from "../index";
import modes from "./Modes";
import { getParcelFeatureId, parcelFeatureIdPrefix } from "./Modes/utils";

const mapStateToProps = (state: RootState) => ({
  selectedParcel: state.projects.selectedParcel,
  selectedCandidateSiteId: selectedCandidateSiteIdSelector(state),
  currentParcelEditionMode: state.ui.index.currentParcelEditionMode,
  isMetric: state.users.metric
});

const connector = connect(mapStateToProps, {
  setParcelEditionMode,
  setSelectedCandidateSiteId,
  addCandidateSite,
  removeCandidateSite,
  showInfoMessage,
  hideInfoMessage,
  setBoundaryVisualization,
  setParcelIsValid,
  setSiteSubdivision
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {} & PropsFromRedux;

const ParcelSubdivionControls: React.FunctionComponent<Props> = props => {
  const [drawObj, setDrawObj] = useState<MapBoxDrawControl | null>(null);
  const [map, setMap] = useState<Map | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);

  const {
    addCandidateSite,
    removeCandidateSite,
    showInfoMessage,
    hideInfoMessage,
    selectedParcel,
    selectedCandidateSiteId,
    currentParcelEditionMode,
    setParcelEditionMode,
    isMetric,
    setSiteSubdivision
  } = props;

  const onSiteCreated = useCallback(
    ({ site, line }: { site: DrawFeature; line?: Feature<LineString> }) => {
      const polygon: Polygon = site.geometry;
      const siteCentroid = centroid(polygon) as Feature<Point>;
      addCandidateSite({
        id: site.properties.subparcelId,
        site: {
          theGeomGeoJSON: polygon,
          area: isMetric ? area(polygon) : area(polygon) * sqMetersToSqFeet,
          centroid: siteCentroid,
          longitude: siteCentroid.geometry.coordinates[0],
          latitude: siteCentroid.geometry.coordinates[1]
        }
      });

      setSiteSubdivision(line?.geometry ?? null);
    },
    [addCandidateSite, isMetric, setSiteSubdivision]
  );

  const onSiteDeleted = useCallback(
    (data: { siteIdRemoved: number }) => {
      removeCandidateSite(data.siteIdRemoved);
    },
    [removeCandidateSite]
  );

  const onDrawModeChange = useCallback(
    (mode: ModesConstants) => {
      switch (mode) {
        case ModesConstants.draw_line_string:
          showInfoMessage({
            header: "Parcel Subdivision",
            content:
              "Press left click to place a new anchor point and right click to remove the last one placed.\nTo cancel the operation press Esc."
          });
          setParcelEditionMode(mode);
          break;
        case ModesConstants.confirm_subdivision:
          showInfoMessage({
            header: "Parcel Subdivision",
            content:
              "To confirm the operation press Enter or click Confirm.\nTo cancel the operation press Esc."
          });
          setParcelEditionMode(mode);
          break;
        case ModesConstants.site_selected:
          hideInfoMessage();
          setParcelEditionMode(mode);
          break;
        case ModesConstants.remove_selected:
          showInfoMessage({
            header: "Remove Parcel",
            content: "Click a site to remove it."
          });
          setParcelEditionMode(mode);
          break;
        case ModesConstants.draw_polygon:
        case ModesConstants.edit_polygon:
          showInfoMessage({
            header: "Freehand Drawing",
            content:
              "Press Control + Z (Command + Z on Mac) to undo the last set anchor.\nTo Cancel Freehand Drawing (remove all the anchors/lines) press Esc Key."
          });
          setParcelEditionMode(mode);
          break;
        default:
          hideInfoMessage();
          break;
      }
    },
    [showInfoMessage, hideInfoMessage, setParcelEditionMode]
  );

  useEffect(() => {
    // Component updated
    if (drawObj !== null && map !== null && selectedParcel !== null) {
      drawObj.changeMode(ModesConstants.site_selected, {
        sites: [selectedParcel.theGeomGeoJSON],
        projectBoundary: selectedParcel.theGeomGeoJSON
      });
      onDrawModeChange(ModesConstants.site_selected);

      map.on("draw.siteCreated", onSiteCreated);
      map.on("draw.siteDeleted", onSiteDeleted);
    }
  }, [drawObj, map, selectedParcel, onDrawModeChange, onSiteCreated, onSiteDeleted]);

  useEffect(() => {
    // Component updated
    if (drawObj !== null) {
      const selectedIds = drawObj.getSelectedIds();
      if (
        selectedCandidateSiteId !== null &&
        !selectedIds.includes(getParcelFeatureId(selectedCandidateSiteId))
      ) {
        drawObj.changeMode(ModesConstants.site_selected, {
          selectedId: getParcelFeatureId(selectedCandidateSiteId)
        });
        onDrawModeChange(ModesConstants.site_selected);
      }
    }
  }, [drawObj, selectedCandidateSiteId, onDrawModeChange]);

  useEffect(() => {
    // Component updated
    if (
      drawObj !== null &&
      currentParcelEditionMode !== null &&
      drawObj.getMode() !== currentParcelEditionMode
    ) {
      drawObj.changeMode(currentParcelEditionMode);
      onDrawModeChange(currentParcelEditionMode);
    }
  }, [drawObj, currentParcelEditionMode, onDrawModeChange]);

  useEffect(
    () => () => {
      // Component will unmount
      if (map !== null) {
        map.off("draw.siteCreated", onSiteCreated);
        map.off("draw.siteDeleted", onSiteDeleted);
      }
      props.hideInfoMessage();
      props.setParcelEditionMode(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [map]
  );

  return (
    <DrawControl
      ref={(drawControls: DrawControl) => {
        if (
          !initialized &&
          drawControls?.draw !== undefined &&
          drawControls?.context !== undefined
        ) {
          setDrawObj(drawControls.draw);
          setMap(drawControls.context);
          setInitialized(true);
        }
      }}
      userProperties={true}
      displayControlsDefault={false}
      styles={styles}
      position="top-right"
      modes={modes}
      onDrawModeChange={({ mode }) => {
        onDrawModeChange(mode);
      }}
      onDrawSelectionChange={({ features }) => {
        const selectedSite = features.find((feature: DrawFeature) =>
          feature.id.includes(parcelFeatureIdPrefix)
        );
        if (selectedSite !== undefined) {
          props.setSelectedCandidateSiteId(selectedSite.properties.subparcelId);
        }
      }}
    />
  );
};

export default connector(ParcelSubdivionControls);
