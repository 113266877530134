import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { mapbox } from "../config/map";
import { setSelectedProjectCountryCode } from "../store/projects";

/**
 * Note (@nicoluce): This has been copied from '../config/config.js'.
 *                   When importing it, it has no value (undefined)
 *                   because of a circular dependency.
 *
 * TODO (@nicoluce): Refactor '../config/config.js' file, import initMap
 *                   from a proper config file and remove this.
 * https://github.com/urbansim/penciler-planning/issues/131
 */
const initMap = {
  center: [-122.4876913, 37.757815],
  zoom: 11,
  pitch: 0,
  bearing: 0
};

const mapSlice = createSlice({
  name: "map",
  initialState: {
    map: undefined,
    center: initMap.center,
    pitch: initMap.pitch,
    zoom: initMap.zoom,
    bearing: initMap.bearing,
    showPopup: false,
    popupCoordinates: [],
    showLayerControls: false,
    showBuildingsLayer: true,
    legendIsVisible: false
  },
  reducers: {
    setMapViewer(state, action) {
      state.map = action.payload;
    },
    setMapCenter(state, action) {
      state.center = action.payload;
    },
    toggleMapPopup(state, action) {
      state.showPopup = action.payload;
    },
    setPopupCoordinates(state, action) {
      state.popupCoordinates = action.payload;
    },
    toggleLayersControls(state) {
      state.showLayerControls = !state.showLayerControls;
    },
    toggleBuildingsLayerVisibility(state) {
      state.showBuildingsLayer = !state.showBuildingsLayer;
    },
    toggleLegendVisibility(state) {
      state.legendIsVisible = !state.legendIsVisible;
    }
  }
});

export const getCountryCodeByProject = project => async dispatch => {
  try {
    const mapboxRes = await axios.get(mapbox.getAddressUrl(project.address), {
      params: {
        access_token: mapbox.accessToken,
        limit: 1,
        proximity: `${project.longitude},${project.latitude}`
      }
    });

    if (mapboxRes.data.features.length === 0)
      throw new Error(`Address '${project.address}' not found`);

    const context = mapboxRes.data.features[0].context;
    const countryCode = context.find(c => c.id.startsWith("country"))["short_code"];
    dispatch(setSelectedProjectCountryCode(countryCode));
  } catch (error) {
    console.error(`actions::map::getCountryCodeByProject -> Error: ${error}`);
  }
};

export const {
  setMapViewer,
  setMapCenter,
  toggleMapPopup,
  setPopupCoordinates,
  toggleLayersControls,
  toggleBuildingsLayerVisibility,
  toggleLegendVisibility
} = mapSlice.actions;

export default mapSlice.reducer;
