import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Field, FieldArrayFieldsProps } from "redux-form";
import { Button, Icon, Popup, Segment } from "semantic-ui-react";
import { required, validateInt, validatePercentage } from "../../config/config";
import { formatFormInteger, parseFormInteger } from "../../config/utils";
import { changeRentProfileFormField } from "../../controllers/proforma";
import { RentProfile } from "../../types/proforma";
import DropdownInput from "../forms/DropdownInput";
import TextInput from "../forms/TextInput";

const proformaCtrl = { changeRentProfileFormField };

const actionObj = { ...proformaCtrl };

const connector = connect(null, actionObj);
type PropsFromRedux = ConnectedProps<typeof connector>;

type RentField = {
  allowance: number;
  unit_count: React.ReactText;
  unit_percentage: React.ReactText;
  ami: string | undefined;
  rent: React.ReactText;
};

type CustomProps = {
  /** Rents for a roomType options **/
  columnRelevantCityRents: any[];
  /** Selected profiles for rent roomType: RentProfile/Profile differs from a Rent since it was
   *  already preselected and processed by redux form. It's state is not instantly refreshed **/
  columnRelevantOldProfiles: any[];
  /** Enable percentage AMI dropdown **/
  enablePercentageAmi: boolean;
  columnTotalUnits: number;
  fields: FieldArrayFieldsProps<RentField>;
  isApartment: boolean;
  isCanada: boolean;
  showUnitCounts: boolean;
};
type RentProfilesColumnProps = CustomProps & PropsFromRedux;

class RentProfilesColumn extends React.Component<RentProfilesColumnProps> {
  static defaultProps = {
    selectedProfiles: [],
    enablePercentageAmi: true
  };
  _mapRentToProfile = (rent: Partial<RentProfile>, units = 0, allowance = 0) => ({
    allowance: allowance,
    unit_count: units,
    unit_percentage: Math.round((units / this.props.columnTotalUnits) * 100),
    ami: rent.ami,
    rent: rent.rent ?? ""
  });

  _getDefaultRent(units?: number) {
    const { columnTotalUnits } = this.props;
    return this._mapRentToProfile({}, units ?? columnTotalUnits);
  }

  handleUnitCountChange(newUnitCount: number, profile: string, index: number) {
    const { columnTotalUnits } = this.props;
    if (isNaN(newUnitCount) || newUnitCount < 0) return;
    const newPercentage = Math.round((newUnitCount / columnTotalUnits) * 100);
    this.props.changeRentProfileFormField(`${profile}.unit_percentage`, newPercentage);
    this.complementProfile(profile, index, columnTotalUnits - newUnitCount, 100 - newPercentage);
  }

  handleUnitPercentageChange(newPercentage: number, profile: string, index: number) {
    const { columnTotalUnits, showUnitCounts } = this.props;
    if (isNaN(newPercentage) || newPercentage < 0 || newPercentage > 100) return;
    const newUnitCount = Math.round((columnTotalUnits * newPercentage) / 100);
    if (showUnitCounts) {
      this.props.changeRentProfileFormField(`${profile}.unit_count`, newUnitCount);
    }
    this.complementProfile(profile, index, columnTotalUnits - newUnitCount, 100 - newPercentage);
  }

  complementProfile(profileName: string, index: number, units: number, percentage: number) {
    if (units < 0 || units > this.props.columnTotalUnits) return;
    if (percentage < 0 || percentage > 100) return;
    if (this.props.fields.length === 2) {
      const otherField = profileName.replace(`[${index}]`, `[${1 - index}]`);
      if (this.props.showUnitCounts) {
        this.props.changeRentProfileFormField(`${otherField}.unit_count`, units);
      }
      this.props.changeRentProfileFormField(`${otherField}.unit_percentage`, percentage);
    }
  }

  componentDidMount() {
    const { fields, columnRelevantOldProfiles, columnTotalUnits } = this.props;

    // Load previous data if exists
    if (columnRelevantOldProfiles.length > 0) {
      columnRelevantOldProfiles.forEach(r => {
        const formattedProfile = {
          allowance: r.allowance,
          ami: r.ami,
          rent: r.rent,
          unit_count: r.unit_count,
          unit_percentage: Math.round((r.unit_count / columnTotalUnits) * 100)
        };
        fields.push(formattedProfile);
      });
    } else {
      // Or insert defaults
      fields.push(this._getDefaultRent());
    }
  }
  render() {
    const {
      columnRelevantCityRents,
      enablePercentageAmi,
      fields,
      isApartment,
      columnTotalUnits,
      isCanada,
      showUnitCounts
    } = this.props;
    const unitsPlaced = (fields?.getAll() ?? []).reduce(
      (acc, field) => (acc += Number(field.unit_count)),
      0
    );
    const percentagePlaced = (fields?.getAll() ?? []).reduce(
      (acc, field) => (acc += Number(field.unit_percentage)),
      0
    );
    const unitsRemaining = showUnitCounts ? columnTotalUnits - unitsPlaced : 100 - percentagePlaced;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "250px",
          margin: "8px"
        }}
      >
        {isApartment ? (
          <div>
            <Button
              type="button"
              onClick={e => {
                e.preventDefault();
                fields.push(
                  this._getDefaultRent(fields.length === 0 ? columnTotalUnits : unitsRemaining)
                );
              }}
              disabled={fields.length >= 10}
            >
              {`Add ${fields.name}`}
            </Button>
            {showUnitCounts ? <span>Total Units {columnTotalUnits} </span> : null}
            {unitsRemaining === 0 ? (
              <Icon name="check" color="green" />
            ) : (
              <Popup
                content={
                  unitsRemaining > 0
                    ? showUnitCounts
                      ? `There are ${unitsRemaining} units left to assign.`
                      : `There is ${unitsRemaining}% left to assign.`
                    : showUnitCounts
                    ? `There are ${-unitsRemaining} extra units assigned.`
                    : `There is an extra ${-unitsRemaining}% assigned.`
                }
                trigger={<Icon name="cancel" color="red" />}
              />
            )}
          </div>
        ) : (
          <div>
            <span> {`${fields.name}`} </span>
          </div>
        )}
        {fields.map((profile, index) => {
          return (
            <Segment key={`${profile}_${index}`}>
              {isApartment ? (
                <div>
                  <Button
                    type="button"
                    style={{ float: "right" }}
                    circular
                    icon="cancel"
                    size="mini"
                    onClick={() => fields.remove(index)}
                  />
                  <br />
                  <div>
                    <label>{isCanada ? "Affordability Target" : "Area Median Income"}</label>
                    <Field<any>
                      name={`${profile}.ami`}
                      component={DropdownInput}
                      placeholder={`Select ${isCanada ? "AMR" : "AMI"}`}
                      selection
                      options={columnRelevantCityRents.map(item => {
                        return { text: item.percentageAmi, value: item.percentageAmi };
                      })}
                      onChange={(e: any, data: any) => {
                        this.props.changeRentProfileFormField(
                          `${profile}.rent`,
                          columnRelevantCityRents.find(item => item.percentageAmi === data.value)
                            .rent
                        );
                      }}
                      disabled={!enablePercentageAmi}
                      validate={enablePercentageAmi ? [required] : []}
                    />
                  </div>
                  {showUnitCounts ? (
                    <Field<any>
                      name={`${profile}.unit_count`}
                      component={TextInput}
                      fluid
                      unitLabel="units"
                      labelPosition="right"
                      onChange={(e: any, data: any) =>
                        this.handleUnitCountChange(Number(data), profile, index)
                      }
                      validate={[required, validateInt]}
                    />
                  ) : null}
                  <Field<any>
                    name={`${profile}.unit_percentage`}
                    component={TextInput}
                    fluid
                    unitLabel="% of units"
                    labelPosition="right"
                    onChange={(e: any, data: any) =>
                      this.handleUnitPercentageChange(Number(data), profile, index)
                    }
                    validate={[required, validatePercentage]}
                  />
                </div>
              ) : null}
              <Field
                label="Rent"
                name={`${profile}.rent`}
                component={TextInput}
                fluid
                format={formatFormInteger}
                parse={parseFormInteger}
                unitLabel="$"
                labelPosition="left"
                validate={[validateInt, required]}
              />
              <Field
                name={`${profile}.allowance`}
                label="Utility Allowance"
                component={TextInput}
                fluid
                format={formatFormInteger}
                parse={parseFormInteger}
                unitLabel="$"
                labelPosition="left"
                validate={[validateInt]}
              />
            </Segment>
          );
        })}
      </div>
    );
  }
}
export default connector(RentProfilesColumn);
