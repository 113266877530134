import { isEmpty, pick } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Form, Segment } from "semantic-ui-react";
import { validateInt, validatePercentage } from "../config/config";
import { isNullOrUndefined } from "../config/utils";
import TextInput from "./forms/TextInput";
import WizardTextInput from "./forms/WizardTextInput";
import WizardStep from "./WizardStep";

class SetbacksPercent extends WizardStep {
  render() {
    const { editMode, derivedZoningData } = this.props;
    const originalData = editMode ? { originalData: derivedZoningData } : {};
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <Segment>
          <Field
            name="front_setback_percent"
            component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
            label="Front Setback:"
            unitLabel="% of lot"
            labelPosition="right"
            validate={[validateInt, validatePercentage]}
            {...originalData}
            readOnly={this.readOnlyMode}
          />
          <Field
            name="side_setback_percent"
            component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
            label="Side Setback:"
            unitLabel="% of lot"
            labelPosition="right"
            validate={[validateInt, validatePercentage]}
            {...originalData}
            readOnly={this.readOnlyMode}
          />
          <Field
            name="rear_yard_setback_percent"
            component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
            label="Rear Yard Setback:"
            unitLabel="% of lot"
            labelPosition="right"
            validate={[validateInt, validatePercentage]}
            {...originalData}
            readOnly={this.readOnlyMode}
          />
          <label className="textarea-label">Setback Notes:</label>
          <Field
            name="setback_notes"
            component="textarea"
            label="Setback Notes"
            rows={3}
            {...originalData}
            readOnly={this.readOnlyMode}
          />
        </Segment>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  const { zoningInitialValues } = state.zoning;
  let derivedZoningData = state.zoning.derivedZoningData;
  const zoningExists = !isEmpty(zoningInitialValues);
  const initVal = Object.assign({}, zoningInitialValues);

  const storePercentageValue = (target, field, source) => {
    target[field] = !isNaN(source) ? (source * 100).toFixed() : "";
  };

  if (zoningExists) {
    const frontSetbackPct = parseFloat(initVal["front_setback_percent"]);
    const rearYardSetbackPct = parseFloat(initVal["rear_yard_setback_percent"]);
    const sideSetbackPct = parseFloat(initVal["side_setback_percent"]);

    const setbacks = [
      { target: "front_setback_percent", source: frontSetbackPct },
      { target: "rear_yard_setback_percent", source: rearYardSetbackPct },
      { target: "side_setback_percent", source: sideSetbackPct }
    ];
    setbacks.forEach(fieldObj => {
      storePercentageValue(initVal, fieldObj.target, fieldObj.source);
    });

    if (!isNullOrUndefined(derivedZoningData)) {
      const derivedSetbacks = [
        { target: "front_setback_percent", source: derivedZoningData.front_setback_percent },
        {
          target: "rear_yard_setback_percent",
          source: derivedZoningData.rear_yard_setback_percent
        },
        { target: "side_setback_percent", source: derivedZoningData.side_setback_percent }
      ];

      derivedZoningData = Object.assign({}, derivedZoningData);

      derivedSetbacks.forEach(fieldObj => {
        storePercentageValue(derivedZoningData, fieldObj.target, fieldObj.source);
      });
    }
  }

  return {
    editMode: zoningExists,
    initialValues: pick(initVal, [
      "front_setback_percent",
      "rear_yard_setback_percent",
      "side_setback_percent",
      "setback_notes"
    ]),
    derivedZoningData: derivedZoningData
  };
};

const SetbacksPercentForm = reduxForm({
  form: "SetbacksPercentForm",
  destroyOnUnmount: false
})(SetbacksPercent);

export default connect(mapStateToProps)(SetbacksPercentForm);
