import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Tab,
  Header,
  List,
  Segment,
  Accordion,
  Icon,
  Dropdown,
  DropdownProps
} from "semantic-ui-react";
import { defaultParcelUsageId, unrestrictedLabel } from "../config/config";
import {
  formatCommas,
  getDistanceLabel,
  calculateAndGetDensityLimitLabel,
  getShortAreaLabel,
  formatDecimal,
  getAcreLabel
} from "../config/utils";
import { getCandidateZoningIdentifier } from "../controllers/zoning";
import { amountOfAcres } from "../helpers/unitsConversion";
import { RootState } from "../store";
import { candidateSitesSelector } from "../store/selectors/projects";
import { ZoningIdentifier } from "../store/types";
import { ParcelInformation as ParcelInformationType } from "../types/ui";
import { exists } from "../utils";

const mapStateToProps = (state: RootState) => ({
  sites: candidateSitesSelector(state),
  parcel: state.projects.selectedParcel as ParcelInformationType,
  defaultZoning: state.projects.currentProjectDefaultZoning,
  parcelUsages: state.ui.parcelUsages.parcelUsages
});
const connector = connect(mapStateToProps, { getCandidateZoningIdentifier });

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {
  siteId: number;
  parcelUsageChangeHandler: (usageId: number) => any;
} & PropsFromRedux;

const ParcelInformation: React.FunctionComponent<Props> = props => {
  const { getCandidateZoningIdentifier } = props;
  const site = props.sites[props.siteId];
  const siteGeom = site.theGeomGeoJSON;
  const [zoningIdentifier, setZoningIdentifier] = useState<ZoningIdentifier>({
    id: props.parcel.zoningId,
    name: props.parcel.zoningCode,
    cityId: props.parcel.cityId
  });

  useEffect(() => {
    getCandidateZoningIdentifier({ geojson: siteGeom }).then(({ succeeded, output }) => {
      if (succeeded) {
        setZoningIdentifier(output);
      }
    });
  }, [getCandidateZoningIdentifier, setZoningIdentifier, siteGeom]);

  const [overlayActive, setOverlayActive] = useState<boolean>(false);
  return (
    <Tab.Pane>
      <Header size="small">Parcel Information</Header>
      <Segment.Group>
        <Segment>{props.parcel.address}</Segment>
        <Segment.Group horizontal>
          <Segment color="red">
            <List>
              <List.Item>
                <List.Header>Parcel ID:</List.Header>
                {props.parcel.parcelId}
              </List.Item>
              <List.Item>
                <List.Header>Site Area:</List.Header>
                <List.Item>{`${formatCommas(site.area)} (${getShortAreaLabel()})`}</List.Item>
                <List.Item>{`${formatDecimal(
                  amountOfAcres(site.area)
                )} (${getAcreLabel()})`}</List.Item>
              </List.Item>
            </List>
          </Segment>
          <Segment color="red">
            <List>
              <List.Item>
                <List.Header>Height Limit:</List.Header>
                {exists(props.parcel.height)
                  ? `${props.parcel.height} ${getDistanceLabel()}`
                  : unrestrictedLabel}
              </List.Item>
              <List.Item>
                <List.Header>Density Limit:</List.Header>
                {calculateAndGetDensityLimitLabel(site.area, props.defaultZoning)}
              </List.Item>
            </List>
          </Segment>
        </Segment.Group>
      </Segment.Group>
      <Segment.Group>
        <Segment>
          <h5 className="ui header">Zoning: {zoningIdentifier.name}</h5>
        </Segment>
        {props.defaultZoning.overlays_applied && props.defaultZoning.overlays_applied.length > 0 ? (
          <Segment style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
            <Accordion>
              <Accordion.Title
                active={overlayActive}
                onClick={() => {
                  setOverlayActive(!overlayActive);
                }}
              >
                <Icon name="dropdown" />
                Overlays
              </Accordion.Title>
              <Accordion.Content active={overlayActive}>
                <List>
                  {props.defaultZoning.overlays_applied.map((overlay: string) => (
                    <List.Item key={`overlay-item-${overlay}`}>{overlay}</List.Item>
                  ))}
                </List>
              </Accordion.Content>
            </Accordion>
          </Segment>
        ) : null}
      </Segment.Group>
      <Segment.Group horizontal>
        <Segment className="half-width">
          <h5 className="ui header">Parcel Usage</h5>
        </Segment>
        <Segment className="half-width">
          <Dropdown
            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) =>
              props.parcelUsageChangeHandler(data.value as number)
            }
            options={Object.entries(props.parcelUsages).map(([id, usage]) => ({
              key: Number(id),
              text: usage,
              value: Number(id)
            }))}
            value={site.usage ?? defaultParcelUsageId}
          />
        </Segment>
      </Segment.Group>
    </Tab.Pane>
  );
};

export default connector(ParcelInformation);
