/**
 * Created by jmartinez on 9/19/18.
 */

import { isUndefined } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { Button, Divider, Form, Grid, Popup, Segment } from "semantic-ui-react";

import {
  densityPercentageDisclaimer,
  required,
  validateDensityBonusPercentage,
  validateGroundFloorHeight,
  validateMaxPodiumHeight,
  validateLotCoverage,
  zoningSteps,
  isZeroOrPositiveNumeric,
  unrestrictedLabel,
  unavailableLabel,
  validatePositiveInteger
} from "../../config/config";
import {
  getDensityBonusDefaultValues,
  getDensityBonusNeutralValues
} from "../../config/defaultValues";
import {
  formatDecimal,
  calculateDensityLimit,
  getDistanceLabel,
  getGrossAreaLabel,
  isNullOrUndefined
} from "../../config/utils";
import * as zoningCtrl from "../../controllers/zoning";
import { getDefaultZoningWizardValues } from "../../controllers/zoning";
import * as projectActions from "../../store/projects";
import * as zoningActions from "../../store/zoning";
import { exists } from "../../utils";
import { CheckboxInput, isToggleChecked } from "../forms/CheckboxInput";
import TextInput from "../forms/TextInput";
import WizardTextInput from "../forms/WizardTextInput";
import Steps from "../Steps";
import WizardStep from "../WizardStep";

const actionsObj = Object.assign({}, zoningActions, projectActions, zoningCtrl);

const footerButtons = props => {
  return (
    <div>
      <Button
        onClick={() => {
          props.setActiveModalForm(zoningSteps["zoning_step_1"]);
        }}
      >
        Back
      </Button>
      <Button
        onClick={() => {
          props.submitHD();
        }}
      >
        Next
      </Button>
    </div>
  );
};

export const HDStepFooter = connect(() => {
  return {};
}, actionsObj)(footerButtons);

class HDStep extends WizardStep {
  handleBackClick(e) {
    e.preventDefault();
    this.props.setActiveModalForm("zoningPt1");
  }

  computeDensityLimit(changedValues) {
    const readCorrectValue = (targetAttribute, currentValueAttribute) => {
      const updatedValue = isUndefined(changedValues[targetAttribute])
        ? this.props[currentValueAttribute]
        : changedValues[targetAttribute];
      const parsedValue = parseFloat(updatedValue, 10);
      return isNaN(parsedValue) ? null : parsedValue;
    };
    const info = {
      units_permitted: readCorrectValue("units_permitted", "unitsPermitted"),
      dwelling_units_per_acre: readCorrectValue("dwelling_units_per_acre", "dwellingUnitsPerAcre"),
      area_of_lot_per_unit: readCorrectValue("area_of_lot_per_unit", "areaOfLotPerUnit")
    };
    let densityLimit;
    const densityLimitValue = calculateDensityLimit(this.props.lot_area, info);
    if (densityLimitValue >= 1) {
      densityLimit = densityLimitValue;
    } else if (densityLimitValue === 0) {
      densityLimit = unavailableLabel;
    } else {
      densityLimit = unrestrictedLabel;
    }
    this.props.change("density_limit", densityLimit);
  }

  handleCheckDensityBonus(toggle) {
    // Choose correct density bonus values depending on whether the density bonus is enabled
    let densityValues = getDensityBonusNeutralValues();
    let sqftPerUnit = this.props.initialValues.area_of_lot_per_unit;
    if (toggle.checked) {
      densityValues = getDensityBonusDefaultValues();
      sqftPerUnit = 0;
    }

    // Update inputs
    this.props.change("density_bonus_height", densityValues.bonusMaxHeight);
    this.props.change("density_bonus_ground_floor_height", densityValues.bonusGroundFloorHeight);
    this.props.change("density_bonus_percentage", densityValues.bonusPercentage);
    this.props.change("area_of_lot_per_unit", sqftPerUnit);

    // Re-compute effective heights
    this.computeEffectiveHeights({
      bonusMaxHeight: densityValues.bonusMaxHeight,
      bonusGroundFloorHeight: densityValues.bonusGroundFloorHeight
    });
  }

  computeEffectiveHeights(changedValues) {
    const readCorrectValue = (targetAttribute, currentValueAttribute) => {
      const updatedValue = isUndefined(changedValues[targetAttribute])
        ? this.props[currentValueAttribute]
        : changedValues[targetAttribute];
      const parsedValue = parseFloat(updatedValue, 10);
      return isNaN(parsedValue) ? 0 : parsedValue;
    };

    const inputMaxHeight = readCorrectValue("inputMaxHeight", "maxHeight");
    const bonusMaxHeight = readCorrectValue("bonusMaxHeight", "bonusMaxHeight");
    const inputGroundFloorHeight = readCorrectValue("inputGroundFloorHeight", "groundFloorHeight");
    const bonusGroundFloorHeight = readCorrectValue(
      "bonusGroundFloorHeight",
      "bonusGroundFloorHeight"
    );

    this.props.change("effective_height", inputMaxHeight + bonusMaxHeight);
    this.props.change(
      "effective_ground_floor_height",
      inputGroundFloorHeight + bonusGroundFloorHeight
    );
  }

  neutralizeDensityHeightBonus() {
    this.props.change("density_bonus_height", getDensityBonusNeutralValues().bonusMaxHeight);
    this.props.change(
      "density_bonus_ground_floor_height",
      getDensityBonusNeutralValues().bonusGroundFloorHeight
    );
    this.props.change("area_of_lot_per_unit", this.props.initialValues.area_of_lot_per_unit);
  }

  neutralizeDensityPercentageBonus() {
    this.props.change("density_bonus_percentage", getDensityBonusNeutralValues().bonusPercentage);
    this.props.change("area_of_lot_per_unit", 0);
  }

  render() {
    const { editMode, derivedZoningData, isSelectedProjectInsideUSA } = this.props;

    const stepData = [
      { title: "Zoning", active: false, disabled: false },
      { title: "Height & Density", active: true, disabled: false },
      { title: "Setbacks", active: false, disabled: true },
      { title: "Open Space", active: false, disabled: true },
      { title: "Parking", active: false, disabled: true }
    ];
    const originalData = editMode ? { originalData: derivedZoningData } : {};

    const densityBonusFields = (
      <div>
        <Segment>
          <Popup
            content="Add density by increasing number of units allowed"
            trigger={
              <Field
                name="density_bonus_percentage"
                component={TextInput}
                label="Additional Density:"
                unitLabel="%"
                labelPosition="right"
                footnoteText={isSelectedProjectInsideUSA ? densityPercentageDisclaimer : null}
                validate={[validateDensityBonusPercentage, required]}
                {...originalData}
                onChange={() => {
                  this.neutralizeDensityHeightBonus();
                  this.computeEffectiveHeights({});
                }}
                readOnly={this.readOnlyMode}
              />
            }
          />

          <Divider horizontal>OR</Divider>

          <Popup
            content="Add density by increasing number of floors allowed"
            trigger={
              <Field
                name="density_bonus_height"
                component={TextInput}
                label="Additional Height:"
                unitLabel={getDistanceLabel()}
                labelPosition="right"
                validate={[isZeroOrPositiveNumeric, required]}
                {...originalData}
                onChange={e => {
                  this.neutralizeDensityPercentageBonus();
                  this.computeEffectiveHeights({ bonusMaxHeight: e.target.value });
                }}
                readOnly={this.readOnlyMode}
              />
            }
          />
          <Field
            name="density_bonus_ground_floor_height"
            component={TextInput}
            label="Amount Allocated to Ground Floor:"
            unitLabel={getDistanceLabel()}
            labelPosition="right"
            validate={[isZeroOrPositiveNumeric, required]}
            {...originalData}
            onChange={e => {
              this.neutralizeDensityPercentageBonus();
              this.computeEffectiveHeights({ bonusGroundFloorHeight: e.target.value });
            }}
            readOnly={this.readOnlyMode}
          />
        </Segment>
        <br />
      </div>
    );

    const densityLimitFields = (
      <div>
        <Segment>
          <Field
            name="dwelling_units_per_acre"
            component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
            label={"Dweling Units per Acre"}
            unitLabel={"DUA"}
            labelPosition="right"
            validate={[isZeroOrPositiveNumeric]}
            readOnly={this.readOnlyMode}
            {...originalData}
            onChange={(e, value) => {
              if (isZeroOrPositiveNumeric(value) === undefined) {
                this.computeDensityLimit({ dwelling_units_per_acre: e.target.value });
              }
            }}
          />
          <Field
            name="area_of_lot_per_unit"
            component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
            label={`${getGrossAreaLabel()} of Lot Required per Unit`}
            unitLabel={getGrossAreaLabel()}
            labelPosition="right"
            validate={[isZeroOrPositiveNumeric]}
            {...originalData}
            readOnly={this.readOnlyMode}
            onChange={(e, value) => {
              if (isZeroOrPositiveNumeric(value) === undefined) {
                this.computeDensityLimit({ area_of_lot_per_unit: value });
              }
            }}
          />
          <Field
            name="units_permitted"
            component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
            label={"Units Permitted"}
            unitLabel={"Units"}
            labelPosition="right"
            validate={[validatePositiveInteger]}
            {...originalData}
            readOnly={this.readOnlyMode}
            onChange={(e, value) => {
              if (validatePositiveInteger(value) === undefined) {
                this.computeDensityLimit({ units_permitted: value });
              }
            }}
          />
        </Segment>
        <Segment>
          <Field
            name="density_limit"
            component={TextInput}
            label={"Resulting Density Limit"}
            unitLabel={"Units"}
            labelPosition="right"
            validate={[isZeroOrPositiveNumeric]}
            disabled
            readOnly
          />
        </Segment>
        <br />
      </div>
    );

    const bulkRequirementsFields = (
      <div>
        <Segment>
          <Field
            name="podium_max_height"
            normalize={value => +value}
            component={TextInput}
            label="Podium maximum height:"
            unitLabel={getDistanceLabel()}
            labelPosition="right"
            validate={[isZeroOrPositiveNumeric, required, validateMaxPodiumHeight]}
            {...originalData}
            readOnly={this.readOnlyMode}
          />
          <Field
            name="lower_tower_shrink_value"
            component={TextInput}
            label="Lower tower stepback:"
            unitLabel={getDistanceLabel()}
            labelPosition="right"
            validate={[isZeroOrPositiveNumeric, required]}
            {...originalData}
            readOnly={this.readOnlyMode}
          />
          <Field
            name="upper_tower_shrink_value"
            component={TextInput}
            label="Upper tower stepback:"
            unitLabel={getDistanceLabel()}
            labelPosition="right"
            validate={[isZeroOrPositiveNumeric, required]}
            {...originalData}
            readOnly={this.readOnlyMode}
          />
        </Segment>
        <br />
      </div>
    );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Steps stepData={stepData} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form onSubmit={this.props.handleSubmit}>
                <Segment>
                  <Field
                    name="max_height"
                    normalize={value => +value}
                    label="Maximum Allowable Building Height:"
                    unitLabel={getDistanceLabel()}
                    labelPosition="right"
                    component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
                    {...originalData}
                    onChange={e => {
                      this.computeEffectiveHeights({ inputMaxHeight: e.target.value });
                    }}
                    validate={[isZeroOrPositiveNumeric]}
                    readOnly={this.readOnlyMode}
                  />
                  <Field
                    name="effective_height"
                    component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
                    label="Effective Maximum Building Height:"
                    unitLabel={getDistanceLabel()}
                    labelPosition="right"
                    validate={[isZeroOrPositiveNumeric]}
                    {...originalData}
                    disabled={true}
                  />
                  <Field
                    name="ground_floor_ceiling_height"
                    component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
                    label="Ground Floor Height"
                    unitLabel={getDistanceLabel()}
                    labelPosition="right"
                    validate={[validateGroundFloorHeight]}
                    {...originalData}
                    onChange={e => {
                      this.computeEffectiveHeights({ inputGroundFloorHeight: e.target.value });
                    }}
                    readOnly={this.readOnlyMode}
                  />
                  <Field
                    name="effective_ground_floor_height"
                    component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
                    label="Effective Ground Floor Height:"
                    unitLabel={getDistanceLabel()}
                    labelPosition="right"
                    validate={[isZeroOrPositiveNumeric]}
                    {...originalData}
                    disabled={true}
                    readOnly={this.readOnlyMode}
                  />

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <Field
                      name="density_limit_enabled"
                      label="Add Density Limit"
                      component={CheckboxInput}
                      onChange={() => {
                        this.computeDensityLimit({});
                      }}
                      toggle
                      style={{ marginRight: "8px" }}
                      readOnly={this.readOnlyMode}
                    />
                  </div>

                  {isToggleChecked(this.props.densityLimitToggle) ? densityLimitFields : ""}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <Field
                      name="density_bonus"
                      label="Add Density Bonus"
                      component={CheckboxInput}
                      onChange={toggle => {
                        this.handleCheckDensityBonus(toggle);
                      }}
                      toggle
                      style={{ marginRight: "8px" }}
                      readOnly={this.readOnlyMode}
                    />
                  </div>

                  {isToggleChecked(this.props.densityBonusToggle) ? densityBonusFields : ""}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <Field
                      name="bulk_requirements_enabled"
                      label="Add Bulk Requirements"
                      component={CheckboxInput}
                      toggle
                      style={{ marginRight: "8px" }}
                      readOnly={this.readOnlyMode}
                    />
                    <label />
                  </div>

                  {isToggleChecked(this.props.bulkRequirementsToggle) ? bulkRequirementsFields : ""}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <Field
                      name="floor_area_ratio_analysis_enabled"
                      label="Add Floor Area Ratio Analysis"
                      component={CheckboxInput}
                      toggle
                      style={{ marginRight: "8px" }}
                      readOnly={this.readOnlyMode}
                    />
                  </div>

                  {isToggleChecked(this.props.floorAreaRatioAnalysisToggle) ? (
                    <div>
                      <Segment>
                        <Field
                          name="maximum_floor_area_ratio"
                          component={TextInput}
                          label="Maximum Floor Area Ratio"
                          validate={[required, isZeroOrPositiveNumeric]}
                        />
                      </Segment>
                      <br />
                    </div>
                  ) : (
                    ""
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <Field
                      name="lot_coverage_enabled"
                      label="Add Lot Coverage"
                      component={CheckboxInput}
                      toggle
                      style={{ marginRight: "8px" }}
                      readOnly={this.readOnlyMode}
                    />
                  </div>

                  {isToggleChecked(this.props.lotCoverageToggle) ? (
                    <div>
                      <Segment>
                        <Field
                          name="lot_coverage"
                          label="Maximum Lot Coverage"
                          component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
                          unitLabel="%"
                          labelPosition="right"
                          {...originalData}
                          validate={[required, validateLotCoverage]}
                        />
                      </Segment>
                    </div>
                  ) : (
                    ""
                  )}
                </Segment>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const selector = formValueSelector("HDStepForm");

const mapStateToProps = state => {
  const { zoningInitialValues } = state.zoning;
  const { isSelectedProjectInsideUSA, selectedProject } = state.projects;

  const zoningDefaults = getDefaultZoningWizardValues(
    state.zoning,
    selectedProject.parcel_data.height,
    selectedProject.parcel_size.area
  );

  const initialValues = { ...zoningInitialValues };

  ["lot_coverage", "density_bonus_percentage"].forEach(field => {
    if (exists(initialValues[field])) {
      initialValues[field] = formatDecimal(parseFloat(initialValues[field]) * 100);
    }
  });

  Object.keys(zoningDefaults.initialValues).forEach(key => {
    if (isNullOrUndefined(initialValues[key])) {
      initialValues[key] = zoningDefaults.initialValues[key];
    }
  });

  return {
    initialValues,
    unitsPermitted: selector(state, "units_permitted"),
    dwellingUnitsPerAcre: selector(state, "dwelling_units_per_acre"),
    areaOfLotPerUnit: selector(state, "area_of_lot_per_unit"),
    densityBonusToggle: selector(state, "density_bonus"),
    densityLimitToggle: selector(state, "density_limit_enabled"),
    bonusMaxHeight: selector(state, "density_bonus_height"),
    bonusGroundFloorHeight: selector(state, "density_bonus_ground_floor_height"),
    maxHeight: selector(state, "max_height"),
    groundFloorHeight: selector(state, "ground_floor_ceiling_height"),
    bulkRequirementsToggle: selector(state, "bulk_requirements_enabled"),
    floorAreaRatioAnalysisToggle: selector(state, "floor_area_ratio_analysis_enabled"),
    lotCoverageToggle: selector(state, "lot_coverage_enabled"),
    editMode: exists(zoningInitialValues),
    lot_area: selectedProject.parcel_size.area,
    derivedZoningData: zoningDefaults.derivedZoningData,
    isSelectedProjectInsideUSA: isSelectedProjectInsideUSA
  };
};

const HDStepForm = reduxForm({
  form: "HDStepForm",
  destroyOnUnmount: false
})(HDStep);

export default connect(mapStateToProps, actionsObj)(HDStepForm);
