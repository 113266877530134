import React from "react";
import { Coordinate } from "../../lib";
import ProjectMarker from "./ProjectMarker";

export default props => {
  const { projects, focusedProjectId, ...childProps } = props;
  return projects.map(project => (
    <ProjectMarker
      key={project.id}
      projectId={project.id}
      coordinate={new Coordinate(project.longitude, project.latitude)}
      isFocused={project.id === focusedProjectId}
      {...childProps}
    />
  ));
};
