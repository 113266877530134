import { feature } from "@turf/helpers";
import lineSegment from "@turf/line-segment";
import { area, centroid, union } from "@turf/turf";
import { isEmpty } from "lodash";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { Button, Header, Table, Input, Tab } from "semantic-ui-react";
import { maxArea, sqMetersToSqFeet } from "../config/config";
import {
  formatCommas,
  getGrossAreaLabel,
  calculateDensityLimit,
  latitudeIndex,
  longitudeIndex
} from "../config/utils";
import { actions, ownerships, resources } from "../constants/permissions";
import { apiAddNewProject } from "../controllers/projects";
import { openProject } from "../controllers/ui";
import { getCandidateZoningIdentifier } from "../controllers/zoning";
import { userIsAllowedToAny } from "../helpers/permissions";
import { RootState } from "../store";
import { setPopupCoordinates, toggleMapPopup } from "../store/map";
import { operations } from "../store/operations";
import {
  cleanPreviousParcelSelection,
  parcelSearchFail,
  setCandidateSiteUsage,
  setSelectedCandidateSiteId,
  setSideBarComponent
} from "../store/projects";
import {
  getSelectedCandidateSite,
  candidateSitesIdsSelector,
  candidateSitesSelector,
  areAllSitesValid,
  selectedCandidateSiteIdSelector
} from "../store/selectors/projects";
import { ZoningIdentifier } from "../store/types";
import { cleanBoundaryVisualization } from "../store/ui/boundaryVisualization";
import { ParcelInformation, SidebarComponent } from "../types/ui";
import { doesNotExist } from "../utils";
import { InformativeButton } from "./common";
import { Modes } from "./DrawControls/constants";
import { getParcelUserId } from "./DrawControls/ParcelEditionControls/Modes/utils";
import EditProjectSite from "./EditProjectSite";
import ParcelInformationComponent from "./ParcelInformation";
import ParcelSearch from "./ParcelSearch";
import "../stylesheets/AddNewProject.css";

const mapActions = { setPopupCoordinates, toggleMapPopup };

const projectActions = {
  cleanPreviousParcelSelection,
  parcelSearchFail,
  setCandidateSiteUsage,
  setSelectedCandidateSiteId,
  setSideBarComponent
};

const projectCtrl = { apiAddNewProject };
const zoningCtrl = { getCandidateZoningIdentifier };
const uiActions = { cleanBoundaryVisualization };
const uiCtrl = { openProject };
const actionObj = {
  ...mapActions,
  ...projectCtrl,
  ...zoningCtrl,
  ...projectActions,
  ...uiActions,
  ...uiCtrl
};

const mapStateToProps = (state: RootState) => {
  return {
    isMetric: state.users.metric,
    selectedParcel: state.projects.selectedParcel as ParcelInformation,
    candidateSites: candidateSitesSelector(state),
    siteSubdivisionLine: state.projects.siteSubdivisionLine,
    selectedCandidateSiteId: selectedCandidateSiteIdSelector(state),
    selectedCandidateSite: getSelectedCandidateSite(state),
    candidateSiteIds: candidateSitesIdsSelector(state),
    parcelUsages: state.ui.parcelUsages.parcelUsages,
    defaultZoning: state.projects.currentProjectDefaultZoning,
    parcelSearchError: state.projects.parcelSearchError,
    sideSegments: state.projects.sideSegments,
    rearSegments: state.projects.rearSegments,
    frontSegments: state.projects.frontSegments,
    map: (state.map.map as any) as mapboxgl.Map,
    center: state.map.center as [number, number],
    zoom: state.map.zoom,
    pitch: state.map.pitch,
    areAllSitesValid: areAllSitesValid(state),
    projectCreationOperation: state.operations[operations.projectCreation],
    isParcelBeingEdited:
      state.ui.index.currentParcelEditionMode !== null &&
      state.ui.index.currentParcelEditionMode !== Modes.site_selected
  };
};

const connector = connect(mapStateToProps, actionObj);

type AddNewProjectProps = ConnectedProps<typeof connector>;
type AddNewProjectFormProps = AddNewProjectProps & InjectedFormProps<{}, AddNewProjectProps>;
type AddNewProjectState = {
  newProjectName: string;
  projectNameError: boolean;
  projectNameErrorMsg: string;
  siteError: boolean;
  siteErrorMsg: string;
};

class AddNewProject extends React.Component<AddNewProjectFormProps, AddNewProjectState> {
  constructor(props: AddNewProjectFormProps) {
    super(props);
    this.state = {
      newProjectName: "",
      projectNameError: false,
      projectNameErrorMsg: "",
      siteError: false,
      siteErrorMsg: ""
    };
  }

  handleModalClose() {
    this.props.setSideBarComponent(SidebarComponent.projectList);
    this.props.cleanPreviousParcelSelection();
    this.props.cleanBoundaryVisualization();
    this.props.map.flyTo({
      center: this.props.center,
      zoom: this.props.zoom,
      pitch: this.props.pitch
    });
    this.props.parcelSearchFail(false);
    this.props.toggleMapPopup(false);
    this.props.setPopupCoordinates([]);
  }

  handleUsagesDropdownChange = (value: number) => {
    this.props.setCandidateSiteUsage(value);
  };

  async handleSubmitNewProject() {
    const {
      selectedParcel,
      siteSubdivisionLine,
      defaultZoning,
      frontSegments,
      rearSegments,
      candidateSites,
      candidateSiteIds,
      sideSegments,
      isMetric
    } = this.props;

    if (this.state.newProjectName === "") {
      this.setState({
        projectNameError: true,
        projectNameErrorMsg: "Project name is required."
      });
      return;
    }

    const resultingProjectBoundary =
      candidateSiteIds.length > 1
        ? union(...candidateSiteIds.map(id => feature(candidateSites[id].theGeomGeoJSON)))
        : feature(candidateSites[candidateSiteIds[0]].theGeomGeoJSON);
    const resultingProjectArea = isMetric
      ? area(resultingProjectBoundary)
      : area(resultingProjectBoundary) * sqMetersToSqFeet;
    const resultingProjectCentroid = centroid(resultingProjectBoundary).geometry?.coordinates || [
      selectedParcel.longitude,
      selectedParcel.latitude
    ];

    let zoningIdentifier: ZoningIdentifier;
    {
      const { succeeded, output } = await this.props.getCandidateZoningIdentifier({
        geojson: resultingProjectBoundary.geometry
      });
      zoningIdentifier = succeeded
        ? output
        : {
            id: selectedParcel.zoningId,
            name: selectedParcel.zoningCode,
            cityId: selectedParcel.cityId
          };
    }

    // Convert stored data to the format required by Penciler's appserver
    const projectObj = {
      // TODO(gushuro, https://github.com/urbansim/penciler-planning/issues/709):
      // remove now unnecessary payload in request
      name: this.state.newProjectName === "" ? selectedParcel.address : this.state.newProjectName,
      address: selectedParcel.address,
      latitude: resultingProjectCentroid[latitudeIndex],
      longitude: resultingProjectCentroid[longitudeIndex],
      parcel_data: {
        geometry: resultingProjectBoundary.geometry,
        id: selectedParcel.parcelId,
        height: selectedParcel.height,
        height_id: selectedParcel.heightId,
        // TODO(@nicoluce): Request the server for the corresponding city of the resulting boundary
        // https://github.com/urbansim/penciler-planning/issues/720
        zoning: zoningIdentifier.name,
        zoning_id: zoningIdentifier.id,
        city_id: zoningIdentifier.cityId
      },
      parcel_size: { area: resultingProjectArea },
      density_limit: calculateDensityLimit(resultingProjectArea, defaultZoning),
      sites: Object.entries(candidateSites).map(([, site]) => {
        const sideSegments = lineSegment(site.theGeomGeoJSON).features.map(feature => feature.id);
        return {
          geometry: site.theGeomGeoJSON,
          size: { area: site.area },
          latitude: site.latitude,
          longitude: site.longitude,
          segments_indexes: {
            sides: sideSegments,
            front: [],
            rear: []
          },
          usage_id: site.usage
        };
      }),
      subdivision_input: siteSubdivisionLine,
      // To be removed
      segments_indexes: {
        sides: sideSegments,
        front: frontSegments,
        rear: rearSegments
      }
    };
    {
      const { succeeded, output: projectId } = await this.props.apiAddNewProject(projectObj);

      if (succeeded) {
        this.props.openProject(projectId);
      }
    }
  }

  render() {
    const {
      selectedParcel,
      candidateSites,
      candidateSiteIds,
      selectedCandidateSiteId,
      defaultZoning,
      parcelSearchError,
      areAllSitesValid
    } = this.props;

    if (!areAllSitesValid && !this.state.siteError) {
      for (const siteId of candidateSiteIds) {
        if (candidateSites[siteId].area > maxArea()) {
          const unit = getGrossAreaLabel();
          this.setState({
            siteError: true,
            siteErrorMsg: `Parcel size of ${formatCommas(
              candidateSites[siteId].area
            )} ${unit} is bigger than ${formatCommas(maxArea())} ${unit}`
          });
          this.props.setSelectedCandidateSiteId(siteId);
        }
      }
    } else if (areAllSitesValid && this.state.siteError) {
      this.setState({
        siteError: false,
        siteErrorMsg: ""
      });
    }

    const allowedToCreateProjects = userIsAllowedToAny(
      actions.create,
      ownerships.all,
      resources.project
    );
    return (
      <div>
        <Table id="add-new-project">
          <Table.Body>
            <Table.Row className="header-search">
              <Table.Cell
                style={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <Button
                  circular
                  icon="chevron left"
                  onClick={() => {
                    this.handleModalClose();
                  }}
                />
                <ParcelSearch />
              </Table.Cell>
            </Table.Row>
            {parcelSearchError ? (
              <Table.Row>
                <Table.Cell>
                  <Header size="small" color="red">
                    Could not locate parcel
                  </Header>
                </Table.Cell>
              </Table.Row>
            ) : null}

            {doesNotExist(selectedParcel) ? (
              <Table.Row>
                <Table.Cell id="parcel-search-description">
                  Parcels can also be selected by clicking directly on the map
                </Table.Cell>
              </Table.Row>
            ) : (
              <>
                {!allowedToCreateProjects ? null : (
                  <>
                    <Table.Row>
                      <Table.Cell>
                        <Header size="small">Project Name:</Header>
                        <Input
                          name="projectName"
                          fluid
                          error={this.state.projectNameError}
                          onChange={(e, data) => {
                            const name = data.value.trim();
                            this.setState({
                              newProjectName: name,
                              projectNameError: name === ""
                            });
                          }}
                        />
                        {this.state.projectNameError ? (
                          <Header size="small" color="red">
                            {this.state.projectNameErrorMsg}
                          </Header>
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <EditProjectSite map={this.props.map} />
                      </Table.Cell>
                    </Table.Row>
                  </>
                )}
                {isEmpty(defaultZoning) || selectedCandidateSiteId === null ? null : (
                  <Table.Row>
                    <Table.Cell error={this.state.siteError}>
                      {candidateSiteIds.length > 1 ? (
                        <Tab
                          id="site-tabs"
                          menu={{ attached: false }}
                          activeIndex={candidateSiteIds.indexOf(selectedCandidateSiteId)}
                          onTabChange={(e, { activeIndex }) => {
                            this.props.setSelectedCandidateSiteId(
                              candidateSiteIds[Number(activeIndex)]
                            );
                          }}
                          panes={candidateSiteIds.map(id => ({
                            menuItem: getParcelUserId(Number(id)),
                            render: () => (
                              <ParcelInformationComponent
                                siteId={id}
                                parcelUsageChangeHandler={(value: number) =>
                                  this.handleUsagesDropdownChange(value)
                                }
                              />
                            )
                          }))}
                        />
                      ) : candidateSites[selectedCandidateSiteId] ? (
                        <ParcelInformationComponent
                          siteId={selectedCandidateSiteId}
                          parcelUsageChangeHandler={(value: number) =>
                            this.handleUsagesDropdownChange(value)
                          }
                        />
                      ) : null}
                      {this.state.siteError ? (
                        <Header size="small" color="red">
                          {this.state.siteErrorMsg}
                        </Header>
                      ) : null}
                    </Table.Cell>
                  </Table.Row>
                )}
                {allowedToCreateProjects ? (
                  <>
                    <Table.Row textAlign="center" verticalAlign="middle">
                      <Table.Cell>
                        <InformativeButton
                          content="Create Project"
                          operation={this.props.projectCreationOperation}
                          disabled={
                            this.state.siteError ||
                            this.state.projectNameError ||
                            this.props.isParcelBeingEdited
                          }
                          onClick={this.handleSubmitNewProject.bind(this)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </>
                ) : null}
              </>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

const AddNewProjectForm = reduxForm<{}, AddNewProjectProps>({
  form: "AddNewProjectForm"
})(AddNewProject);

export default connector(AddNewProjectForm);
