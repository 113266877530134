/**
 * Created by jmartinez on 8/27/18.
 */

import React from "react";
import { Form, Input, Popup, Icon } from "semantic-ui-react";
import { Footnote } from "./Footnote.js";

const TextInput = ({
  label,
  defaultValue,
  inline,
  unitLabel,
  footnoteText,
  hasInfo,
  infoText,
  meta: { error, touched },
  ...rest
}) => (
  <Form.Field inline={inline} defaultValue={defaultValue} error={error && touched}>
    <label>
      {label}{" "}
      {hasInfo ? (
        <Popup trigger={<Icon name="info circle" style={{ opacity: 0.4 }} />} content={infoText} />
      ) : null}
    </label>
    <Input label={unitLabel} error={!!error} {...rest} />
    {error && touched ? <span style={{ color: "red" }}>*{error}</span> : ""}
    <Footnote footnoteText={footnoteText} />
  </Form.Field>
);

export default TextInput;
