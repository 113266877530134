import { DrawFeature, DrawMode, DrawMouseEvent } from "../../commonTypes";
import { MaximumAmountOfSubSites, Modes, projectBoundaryId } from "../../constants";
import { getFirstUsedSiteId, getParcelFeatureId, getParcelUserId } from "./utils";

const SiteSelected: DrawMode = {
  onSetup: function(opts: {
    sites?: GeoJSON.Polygon[];
    selectedId?: string;
    projectBoundary?: GeoJSON.Polygon;
  }) {
    if (opts.projectBoundary !== undefined) {
      const geojson = {
        type: "Feature",
        id: projectBoundaryId,
        properties: {},
        geometry: opts.projectBoundary
      };
      const siteFeature = this.newFeature(geojson);
      // newFeature() removes the last coordinate, we need it for draw line
      siteFeature.coordinates[0].push(siteFeature.coordinates[0][0]);
      this.addFeature(siteFeature);
    }

    this.clearSelectedFeatures();
    if (opts.sites !== undefined && opts.sites.length > 0) {
      const areMultipleSites = opts.sites.length > 1;
      const createdSiteIds: string[] = [];
      opts.sites.forEach((sitePolygon, i) => {
        if (i >= MaximumAmountOfSubSites) return;
        const siteId = getParcelFeatureId(i);
        if (siteId === null) {
          return;
        }
        const geojson = {
          type: "Feature",
          id: siteId,
          properties: {
            subparcelId: i
          },
          geometry: sitePolygon
        };
        const siteFeature = this.newFeature(geojson);
        if (areMultipleSites) {
          siteFeature.properties.label = getParcelUserId(i);
        }
        // newFeature() removes the last coordinate for some reason
        // which is the same than the first one
        siteFeature.coordinates[0].push(siteFeature.coordinates[0][0]);
        this.addFeature(siteFeature);
        this.map.fire("draw.siteCreated", { site: geojson });
        createdSiteIds.push(siteId);
      });
      this.select(createdSiteIds[0]);
      return { id: createdSiteIds[0] };
    } else if (opts.selectedId !== undefined) {
      this.select(opts.selectedId);
      return { id: opts.selectedId };
    } else {
      return { id: getFirstUsedSiteId(this)[1] };
    }
  },

  onClick(state: { id: string }, e: DrawMouseEvent) {
    const id: string | undefined = e?.featureTarget?.properties?.id;
    if (this.featuresAt(e).length < 2 && id !== undefined && id !== state.id) {
      this.changeMode(Modes.site_selected, { selectedId: id });
    }
  },

  toDisplayFeatures: function(state: { id: string }, geojson: DrawFeature, display: Function) {
    if (geojson.properties.id === projectBoundaryId) return;
    if (geojson.properties.id === state.id) {
      geojson.properties.active = "true";
    }
    display(geojson);
  }
};

export default SiteSelected;
