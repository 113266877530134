import React from "react";
import { Field, GenericField, WrappedFieldProps } from "redux-form";
import { Checkbox, Popup } from "semantic-ui-react";
import { isNullOrUndefined } from "../../config/utils";
import { is } from "../../lib";
import { exists } from "../../utils";

type CheckboxInputOwnProps = {
  label: string;
  toggle?: boolean;
  readOnly?: boolean;
  tooltip?: string;
};
type CheckboxInputProps = WrappedFieldProps & CheckboxInputOwnProps;

// Note(gushuro): see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18279
export const CheckboxInputField = Field as new () => GenericField<CheckboxInputOwnProps>;

export const CheckboxInput: React.FC<CheckboxInputProps> = ({ label, tooltip, input, ...rest }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox
        checked={exists(input.value) && input.value}
        onChange={(_e, data) => input.onChange(data.checked)}
        name={input.name}
        {...rest}
      />
      <div style={{ marginLeft: "10px" }}>
        {tooltip ? (
          <Popup content={tooltip} trigger={<label>{label}</label>} />
        ) : (
          <label>{label}</label>
        )}
      </div>
    </div>
  );
};

export const isToggleChecked = (toggle: any) => {
  return isNullOrUndefined(toggle) ? false : is.object(toggle) ? toggle.checked : toggle;
};
