import React from "react";
import { Layer, Source } from "react-mapbox-gl";

import { ucUrl } from "../../../config/urls";
import { is } from "../../../lib";
import { mapboxFillLayerID, mapboxSourceID } from "../../../queriers/region";
import { DataLayer } from "../../../types/region";

type TilesLayerProps = {
  dataLayer: DataLayer;
  paint: any;
  minzoom?: number;
  before?: string;
};

export default class TilesLayer extends React.Component<TilesLayerProps> {
  shouldComponentUpdate(nextProps: TilesLayerProps) {
    if (this.props.dataLayer.isVisible !== nextProps.dataLayer.isVisible) {
      return true;
    }

    if (this.props.dataLayer.remoteId !== nextProps.dataLayer.remoteId) {
      return true;
    }

    return false;
  }
  render() {
    const { dataLayer, minzoom, paint } = this.props;
    if (!is.id(dataLayer.remoteId)) {
      return null;
    }
    const tilesURL = `${ucUrl}/layers/${
      dataLayer.remoteId
    }/{z}/{x}/{y}?token=${localStorage.getItem("uc_token")}`;

    const sourceId = mapboxSourceID(dataLayer);
    const layerId = mapboxFillLayerID(dataLayer);
    paint["fill-opacity"] = [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      0.6,
      paint["fill-opacity"] || 0.3
    ];
    return (
      <>
        <Source
          id={sourceId}
          tileJsonSource={{
            type: "vector",
            tiles: [tilesURL],
            minzoom: minzoom || 0,
            promoteId: { features: "_uc_id" }
          }}
        />
        <Layer
          type="fill"
          id={layerId}
          sourceId={sourceId}
          sourceLayer="features"
          paint={paint}
          layout={{ visibility: dataLayer.isVisible ? "visible" : "none" }}
          before={this.props.before}
        />
      </>
    );
  }
}
