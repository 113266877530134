import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Input, Search } from "semantic-ui-react";

import { parcelSearchMinimumInputLength } from "../config/config";
import parcelSearch from "../controllers/ui/parcelSearch";
import projectCreation from "../controllers/ui/projectCreation";
import { generateUUIDV4, is } from "../lib";
import { ParcelCandidate } from "../lib/ParcelCandidate";
import { deepBindActionCreators } from "../lib/redux";
import { AppDispatch, RootState } from "../store";
import { operations } from "../store/operations";
import { updateParcelSearchCandidates } from "../store/ui";
import { doesNotExist } from "../utils";
import "../stylesheets/ParcelSearch.css";

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    ...deepBindActionCreators(
      {
        parcelSearch: {
          ...parcelSearch,
          updateParcelSearchCandidates
        },
        projectCreation
      },
      dispatch
    )
  };
}

const connector = connect(
  (state: RootState) => ({
    parcelSearchCandidates: state.ui.index.parcelSearchCandidates,
    parcelSelectionOperation: state.operations[operations.parcelSelection],
    parcelSearchByAddressOperation: state.operations[operations.parcelSearchByAddress],
    parcelSearchByIdOperation: state.operations[operations.parcelSearchById]
  }),
  mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {} & PropsFromRedux;
type State = {
  sessionToken: string | null;
};

class ParcelSearch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sessionToken: null
    };
  }

  updateToken() {
    let sessionToken = this.state.sessionToken;
    if (doesNotExist(sessionToken)) {
      sessionToken = generateUUIDV4();
      this.setState({ sessionToken: sessionToken });
    }
    return sessionToken;
  }

  async handleSelect(candidate: ParcelCandidate): Promise<void> {
    await this.props.projectCreation.selectSearchParcel(candidate);
    this.setState({ sessionToken: null });
    this.props.parcelSearch.updateParcelSearchCandidates([]);
  }

  render(): JSX.Element {
    return (
      <Search
        name="parcelSearch"
        minCharacters={parcelSearchMinimumInputLength}
        loading={
          this.props.parcelSelectionOperation.isLoading ||
          this.props.parcelSearchByAddressOperation.isLoading ||
          this.props.parcelSearchByIdOperation.isLoading
        }
        input={<Input placeholder="Address or parcel ID" />}
        // TODO(gushuro): Once we type the store.ui, cast should not be needed anymore
        results={(this.props.parcelSearchCandidates as ParcelCandidate[]).map(candidate => ({
          key: `${candidate.parcelId}-${candidate.address}`,
          title: candidate.isParcelId ? `Parcel #${candidate.parcelId}` : candidate.address,
          description: candidate.isParcelId ? "You can try searching by parcel id instead." : null,
          onClick: () => this.handleSelect(candidate)
        }))}
        onBlur={() => this.setState({ sessionToken: null })}
        // This enables selecting by hitting enter
        onResultSelect={(e, { result }) => result.onClick()}
        onSearchChange={(_, data) => {
          const content = (data.value || "").trim();
          const isNumeric = new RegExp(/^\d+$/, "i").test(content);
          if (isNumeric) {
            this.updateToken();
            this.props.parcelSearch.byID(parseInt(content));
          } else if (is.nonEmptyString(content)) {
            this.props.parcelSearch.byAddress(content, this.updateToken());
          }
        }}
      />
    );
  }
}

export default connector(ParcelSearch);
