export enum buildingVisualizationStatus {
  nonExistant = "nonExistant",
  underCleaning = "underCleaning",
  inConstruction = "inConstruction",
  ready = "ready"
}

export enum buildingDataStatus {
  nonExistant = "nonExistant",
  beingRetrieved = "beingRetrieved",
  ready = "ready"
}
