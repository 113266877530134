import { Map } from "mapbox-gl";
import React, { useEffect, useState, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Accordion, ButtonGroup, Icon, Popup } from "semantic-ui-react";
import { Button } from "semantic-ui-react";
import { createBoundaryVisualization } from "../controllers/projects";
import { RootState } from "../store";
import { candidateSitesIdsSelector, getSelectedCandidateSite } from "../store/selectors/projects";
import { setParcelEditionMode } from "../store/ui";
import { SiteInformation } from "../types/ui";
import { Modes } from "./DrawControls/constants";
import "../stylesheets/EditProjectSite.css";

const mapStateToProps = (state: RootState) => ({
  selectedSite: getSelectedCandidateSite(state) as SiteInformation,
  currentParcelEditionMode: state.ui.index.currentParcelEditionMode,
  amountOfSites: candidateSitesIdsSelector(state).length
});

const connector = connect(mapStateToProps, {
  setParcelEditionMode,
  createBoundaryVisualization
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = { map: Map } & PropsFromRedux;

const EditProjectSite: React.FunctionComponent<Props> = props => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [canSubdivide, setCanSubdivide] = useState(false);
  const { map } = props;

  const onSubsitesShown = useCallback(
    ({ canSubdivide }: { canSubdivide: boolean }) => {
      setCanSubdivide(canSubdivide);
    },
    [setCanSubdivide]
  );

  useEffect(() => {
    map.on("draw.canSubdivide", onSubsitesShown);
  }, [map, onSubsitesShown]);

  let confirmPopupText = null;
  switch (props.currentParcelEditionMode) {
    case Modes.edit_line:
    case Modes.confirm_subdivision:
      confirmPopupText = "Confirm Parcel Subdivision";
      break;
    case Modes.edit_polygon:
      confirmPopupText = "Confirm Parcel Edition";
      break;
  }

  const confirmButton = (
    <Button
      floated="right"
      positive
      style={{
        backgroundColor: "#54B2AB"
      }}
      disabled={
        (props.currentParcelEditionMode !== Modes.confirm_subdivision &&
          props.currentParcelEditionMode !== Modes.edit_line &&
          props.currentParcelEditionMode !== Modes.edit_polygon) ||
        !canSubdivide
      }
      onClick={() => {
        props.map.fire("draw.confirm");
      }}
    >
      Confirm
    </Button>
  );

  return (
    <Accordion>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={() => {
          setActiveIndex(activeIndex === 0 ? -1 : 0);
        }}
      >
        <Icon name="dropdown" />
        Edit Project Site
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0}>
        <ButtonGroup size="tiny" basic>
          <Popup
            content="Draw Parcel"
            trigger={
              <Button
                className="edit-button"
                id="draw-parcel-button"
                active={props.currentParcelEditionMode === Modes.draw_polygon}
                onClick={() => {
                  if (props.currentParcelEditionMode !== Modes.draw_polygon) {
                    props.setParcelEditionMode(Modes.draw_polygon);
                  } else {
                    props.setParcelEditionMode(Modes.site_selected);
                  }
                  props.map.getCanvas().focus();
                }}
              >
                <Icon
                  name="object ungroup outline"
                  style={{
                    marginRight: 0
                  }}
                />
              </Button>
            }
          />
          <Popup
            content="Edit Parcel"
            trigger={
              <Button
                className="edit-button"
                id="edit-parcel-button"
                active={props.currentParcelEditionMode === Modes.edit_polygon}
                onClick={() => {
                  if (props.currentParcelEditionMode !== Modes.edit_polygon) {
                    props.setParcelEditionMode(Modes.edit_polygon);
                  } else {
                    props.setParcelEditionMode(Modes.site_selected);
                  }
                  props.map.getCanvas().focus();
                }}
              >
                <Icon
                  name="object ungroup"
                  style={{
                    marginRight: 0
                  }}
                />
              </Button>
            }
          />
        </ButtonGroup>
        <ButtonGroup size="tiny" basic>
          <Popup
            content="Parcel Subdivision"
            trigger={
              <Button
                className="edit-button"
                id="cut-button"
                active={
                  props.currentParcelEditionMode === Modes.draw_line_string ||
                  props.currentParcelEditionMode === Modes.confirm_subdivision
                }
                onClick={() => {
                  if (
                    props.currentParcelEditionMode !== Modes.draw_line_string &&
                    props.currentParcelEditionMode !== Modes.confirm_subdivision
                  ) {
                    props.setParcelEditionMode(Modes.draw_line_string);
                  } else {
                    props.setParcelEditionMode(Modes.site_selected);
                  }
                  props.map.getCanvas().focus();
                }}
              >
                <Icon
                  name="cut"
                  style={{
                    marginRight: 0
                  }}
                />
              </Button>
            }
          />
          <Popup
            content="Edit Subdivision"
            trigger={
              <Button
                className="edit-button"
                id="edition-button"
                active={props.currentParcelEditionMode === Modes.edit_line}
                onClick={() => {
                  if (props.currentParcelEditionMode !== Modes.edit_line) {
                    props.setParcelEditionMode(Modes.edit_line);
                  } else {
                    props.setParcelEditionMode(Modes.site_selected);
                  }
                  props.map.getCanvas().focus();
                }}
              >
                <Icon
                  name="pencil alternate"
                  style={{
                    marginRight: 0
                  }}
                />
              </Button>
            }
          />
          <Popup
            content="Remove Parcel"
            trigger={
              <Button
                className="edit-button"
                id="delete-button"
                active={props.currentParcelEditionMode === Modes.remove_selected}
                disabled={props.amountOfSites <= 1}
                onClick={() => {
                  if (props.currentParcelEditionMode !== Modes.remove_selected) {
                    props.setParcelEditionMode(Modes.remove_selected);
                  } else {
                    props.setParcelEditionMode(Modes.site_selected);
                  }
                  props.map.getCanvas().focus();
                }}
              >
                <Icon
                  name="erase"
                  style={{
                    marginRight: 0
                  }}
                />
              </Button>
            }
          />
        </ButtonGroup>
        {confirmPopupText ? (
          <Popup content={confirmPopupText} trigger={confirmButton} />
        ) : (
          confirmButton
        )}
      </Accordion.Content>
    </Accordion>
  );
};

export default connector(EditProjectSite);
