import { Map } from "mapbox-gl";
import React from "react";

import { withMap } from "../../common/map/ReactMapboxContext";
import LegendIControl from "./LegendIControl";

export default withMap(
  class ZoningsLayerLegend extends React.Component<{ map: Map }> {
    control: LegendIControl;

    constructor(props: { map: Map }) {
      super(props);
      this.control = new LegendIControl();
    }

    componentDidMount() {
      this.props.map.addControl(this.control, "top-left");
    }

    componentWillUnmount() {
      this.props.map.removeControl(this.control);
    }

    render() {
      return null;
    }
  }
);
