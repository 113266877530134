import { bindActionCreators, Dispatch } from "redux";
import { is } from ".";

/*
 * Applies `bindActionCreators` recursively in the source object.
 * To illustrate, if
 * ```
 * const source = {
 *   foo1,
 *   foo2,
 *   bar: {
 *     foo3,
 *     foo4
 *   }
 * }
 * ```
 * with `foon` being a function, then
 * ```
 * deepBindActionCreators(source, dispatch) ~= {
 *   foo1: (...args) => dispatch(source.foo1(...args)),
 *   foo2: (...args) => dispatch(source.foo2(...args)),
 *   bar: {
 *     foo3: (...args) => dispatch(source.foo3(...args)),
 *     foo4: (...args) => dispatch(source.foo4(...args)),
 *   }
 * }
 * ```
 *
 * More info:
 *   - https://redux.js.org/api/bindactioncreators#bindactioncreatorsactioncreators-dispatch
 *   - https://github.com/reduxjs/react-redux/issues/132
 * */
export const deepBindActionCreators = (source: { [key: string]: any }, dispatch: Dispatch): any => {
  const boundActionCreators: { [action: string]: any } = {};
  for (const k of Object.keys(source)) {
    boundActionCreators[k] = is.function(source[k])
      ? bindActionCreators(source[k], dispatch)
      : deepBindActionCreators(source[k], dispatch);
  }
  return boundActionCreators;
};

export default { deepBindActionCreators };
