import { Map } from "mapbox-gl";
import React, { useCallback, useEffect, useState } from "react";
import { Layer, MapContext, Source } from "react-mapbox-gl";
import { connect, ConnectedProps } from "react-redux";
import { projectBoundarySource, projectBoundaryLayer, thinBoundaryLineWidth } from "../config/map";
import { palette } from "../constants/palette";
import { RootState } from "../store";

const mapStateToProps = (state: RootState) => ({
  boundaryVisualizationGeoJSON: state.ui.boundaryVisualization.geoJSON,
  sideSegments: state.projects.sideSegments,
  frontSegments: state.projects.frontSegments,
  rearSegments: state.projects.rearSegments
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const BoundaryVisualization: React.FunctionComponent<PropsFromRedux> = props => {
  const { sideSegments, frontSegments, rearSegments } = props;
  const [map, setMap] = useState<Map | null>(null);

  const updateSegments = useCallback(() => {
    sideSegments.forEach(segment => {
      map?.setFeatureState(
        { source: projectBoundarySource, id: segment + 1 },
        { segmentType: "side" }
      );
    });
    frontSegments.forEach(segment => {
      map?.setFeatureState(
        { source: projectBoundarySource, id: segment + 1 },
        { segmentType: "front" }
      );
    });
    rearSegments.forEach(segment => {
      map?.setFeatureState(
        { source: projectBoundarySource, id: segment + 1 },
        { segmentType: "rear" }
      );
    });
  }, [frontSegments, rearSegments, sideSegments, map]);

  useEffect(() => {
    updateSegments();
  }, [updateSegments]);
  return (
    <MapContext.Consumer>
      {map => (
        <>
          <Source
            id={projectBoundarySource}
            geoJsonSource={{
              type: "geojson",
              data: props.boundaryVisualizationGeoJSON
            }}
            onSourceLoaded={() => {
              if (map !== undefined) {
                setMap(map);
              }
              updateSegments();
            }}
          />
          <Layer
            id={projectBoundaryLayer}
            type="line"
            sourceId={projectBoundarySource}
            layout={{ "line-cap": "round" }}
            paint={{
              "line-width": thinBoundaryLineWidth,
              "line-color": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                [
                  "case",
                  ["==", ["feature-state", "segmentType"], "front"],
                  palette.front.hover,
                  ["==", ["feature-state", "segmentType"], "rear"],
                  palette.rear.hover,
                  palette.sides.hover
                ],
                [
                  "case",
                  ["==", ["feature-state", "segmentType"], "front"],
                  palette.front.primary,
                  ["==", ["feature-state", "segmentType"], "rear"],
                  palette.rear.primary,
                  palette.sides.primary
                ]
              ]
            }}
          />
        </>
      )}
    </MapContext.Consumer>
  );
};

export default connector(BoundaryVisualization);
