import React from "react";
import { Popup, List } from "semantic-ui-react";

import { floorplanVisualization } from "../../config/config";
import { getShortAreaLabel } from "../../config/utils";
import { exists } from "../../utils";

export const UnitSummaryPopup = props => {
  return (
    <Popup
      trigger={props.trigger}
      position="bottom center"
      mouseEnterDelay={floorplanVisualization.unitsPopupDelay}
    >
      <Popup.Header> Unit summary </Popup.Header>
      <Popup.Content>
        <List bulleted>
          <List.Item>{props.unitLabel}</List.Item>
          {exists(props.area) ? (
            <List.Item>
              {props.area} {getShortAreaLabel()}
            </List.Item>
          ) : null}
        </List>
      </Popup.Content>
    </Popup>
  );
};
