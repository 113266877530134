import { Map } from "mapbox-gl";
import React from "react";
import { Dropdown, Segment } from "semantic-ui-react";

import { mapboxStyles } from "../../../config/config";
import { withMap } from "../../common/map/ReactMapboxContext";

class BaseMapSwitcher extends React.Component<{
  map: Map;
}> {
  render() {
    return (
      <Segment>
        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
          <div>Basemap</div>
          <Dropdown
            options={mapboxStyles}
            selection
            defaultValue="light-v9"
            onChange={(e, { value }) => this.props.map.setStyle(`mapbox://styles/mapbox/${value}`)}
          />
        </div>
      </Segment>
    );
  }
}

export default withMap(BaseMapSwitcher);
