import { find, without, isUndefined, difference } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Table, Checkbox, Menu, Grid } from "semantic-ui-react";
import * as programActions from "../store/programs";

class DeletePrograms extends React.Component {
  handleCheckboxChange(programOption) {
    const programId = programOption.id;
    const selectedZoning = find(this.props.deleteProgramList, {
      id: programId
    });
    if (!isUndefined(selectedZoning)) {
      this.props.checkDeleteProgram(without(this.props.deleteProgramList, selectedZoning));
    } else {
      this.props.checkDeleteProgram([...this.props.deleteProgramList, programOption]);
    }
  }

  handleClickSelectAll() {
    this.props.checkDeleteProgram(this.props.programList);
  }

  handleClickSelectNone() {
    this.props.checkDeleteProgram([]);
  }

  handleClickInvert() {
    this.props.checkDeleteProgram(difference(this.props.programList, this.props.deleteProgramList));
  }

  render() {
    const { programList, deleteProgramList } = this.props;
    return (
      <div>
        <Menu secondary size="mini" fluid style={{ margin: "0px", backgroundColor: "white" }}>
          <Menu.Item
            onClick={() => {
              this.handleClickSelectAll();
            }}
          >
            <p>All</p>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              this.handleClickSelectNone();
            }}
          >
            <p>None</p>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              this.handleClickInvert();
            }}
          >
            <p>Invert</p>
          </Menu.Item>
          <Menu.Item onClick={() => {}}>Delete</Menu.Item>
        </Menu>
        <Table selectable={true} fixed>
          <Table.Body>
            {programList.map((item, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={2}>
                          <Checkbox
                            value={item.id}
                            checked={deleteProgramList.includes(item)}
                            onClick={() => {
                              this.handleCheckboxChange(item);
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column width={14}>
                          <p>{item.name}</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    deleteProgramList: state.programs.deleteProgramList,
    programList: state.programs.programList
  };
};

export default connect(mapStateToProps, programActions)(DeletePrograms);
