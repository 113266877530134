import csg from "@jscad/csg";
import dxfSerializer from "@jscad/dxf-serializer";
import proj4 from "proj4";
import { doesNotExist } from "../utils.js";

export const downloadDXF = (
  selectedBuilding,
  selectedProject,
  regionDestination,
  proj4EPSGDefinition,
  isMetric
) => {
  const regionOrigin = "EPSG:4326";
  regionDestination = "EPSG:" + regionDestination;
  if (doesNotExist(proj4EPSGDefinition)) {
    console.error("EPSG Definition not found, DXF Export aborted");
    return;
  }
  proj4.defs(regionDestination, proj4EPSGDefinition);

  const allPaths = [];
  const fullParcel = selectedBuilding.geometry.full_parcel.geometry.coordinates[0];
  const fullParcelProjected = projectCoordinates(fullParcel, regionOrigin, regionDestination);
  const differenceToOrigin = fullParcelProjected[0];
  const fullParcelShape = coordinatesToShape(fullParcelProjected, differenceToOrigin, isMetric);
  allPaths.push(fullParcelShape);

  const reducedParcel = selectedBuilding.geometry.reduced_parcel.geometry.coordinates[0];
  const reducedParcelProjected = projectCoordinates(reducedParcel, regionOrigin, regionDestination);
  const reducedParcelShape = coordinatesToShape(
    reducedParcelProjected,
    differenceToOrigin,
    isMetric
  );
  allPaths.push(reducedParcelShape);

  const subbuildings = selectedBuilding.geometry.sub_buildings[0][0].geojson.features;
  subbuildings.forEach(feature => {
    const featureParcel = feature.geometry.coordinates[0];
    const featureParcelProjected = projectCoordinates(
      featureParcel,
      regionOrigin,
      regionDestination
    );
    const featureParcelShape = coordinatesToShape(
      featureParcelProjected,
      differenceToOrigin,
      isMetric
    );
    allPaths.push(featureParcelShape);
  });

  const allDXF = dxfSerializer.serialize(allPaths);
  download(`${selectedProject.name}-${selectedBuilding.name}-floorplan.dxf`, allDXF);
};

const projectCoordinates = (coordinates, projectionOrigin, projectionDestination) =>
  coordinates.map(coordinate => proj4(projectionOrigin, projectionDestination, coordinate));

const coordinatesToShape = (coordinates, differenceToOrigin, isMetric) => {
  let path = new csg.CSG.Path2D(coordinates, /*closed=*/ true);
  path = path.translate([-differenceToOrigin[0], -differenceToOrigin[1], 0]);
  if (!isMetric) {
    const meterToFeet = 3.28084;
    path = path.scale(meterToFeet);
  }
  return path;
};

function download(filename, text) {
  let element = document.createElement("a");
  element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
