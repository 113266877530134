/*
 * A plain copy of `withMap` from `react-mapbox-gl` which is not exported from the library.
 *      source: https://github.com/alex3165/react-mapbox-gl/blob/master/src/context.tsx
 *      library's entry point: https://github.com/alex3165/react-mapbox-gl/blob/master/src/index.ts
 * */
import * as React from "react";
import { MapContext } from "react-mapbox-gl";

export function withMap(Component: React.ComponentClass<any>) {
  return function MappedComponent<T>(props: T) {
    return <MapContext.Consumer>{map => <Component map={map} {...props} />}</MapContext.Consumer>;
  };
}
