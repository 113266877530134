/**
 * Created by jmartinez on 8/31/18.
 */

import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Table, Checkbox, Grid } from "semantic-ui-react";
import { RootState } from "../store";
import * as projectActions from "../store/projects";
import { Project } from "../types/projects";

const mapStateToProps = (state: RootState) => {
  return {
    editProjectList: state.projects.editProjectList
  };
};

const connector = connect(mapStateToProps, projectActions);

type PropsFromRedux = ConnectedProps<typeof connector>;
type ProjectListItemEditProps = { project: Project } & PropsFromRedux;

class ProjectListItemEdit extends React.Component<ProjectListItemEditProps> {
  handleChange(projectId: number) {
    const { editProjectList } = this.props;
    const isChecked = editProjectList.includes(projectId);
    if (isChecked) {
      this.props.checkEditBox(editProjectList.filter(id => id !== projectId));
    } else {
      this.props.checkEditBox([...editProjectList, projectId]);
    }
  }

  render() {
    const { project } = this.props;
    return (
      <Table.Row
        onClick={() => {
          this.handleChange(project.id);
        }}
      >
        <Table.Cell>
          <Grid>
            <Grid.Row>
              <Grid.Column width={2}>
                <Checkbox
                  value={project.id}
                  checked={this.props.editProjectList.includes(project.id)}
                />
              </Grid.Column>
              <Grid.Column width={14}>
                <p>{project.name !== "" ? project.name : project.address}</p>
                <p style={{ color: "#B2B2B2" }}>
                  {moment(project.date_created).format("M/D/YYYY")},{" "}
                  {moment(project.date_created).format("h:mm A")}
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default connector(ProjectListItemEdit);
