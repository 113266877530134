import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IncompatibleProformaSlice } from "../slices/ui";
import { IncompatibleRentProfilesData } from "../types";

const initialState: IncompatibleProformaSlice = {
  detected: false,
  data: { newUnitTypes: [] }
};

const slice = createSlice({
  name: "incompatibleProforma",
  initialState,
  reducers: {
    setIncompatibleProforma(state, action: PayloadAction<IncompatibleRentProfilesData>) {
      state.detected = true;
      state.data = action.payload;
    },
    closeIncompatibleProformaModal(state) {
      state.detected = false;
      state.data = { newUnitTypes: [] };
    }
  }
});

export const { closeIncompatibleProformaModal, setIncompatibleProforma } = slice.actions;

export default slice.reducer;
