import { Feature, FeatureCollection, Polygon, Properties } from "@turf/helpers";
import lineSegment from "@turf/line-segment";
import { Point } from "mapbox-gl";

export const parcelVisualizationsSeparatorLayerId = "parcelVisualizationsSeparatorLayer";
export const allBuildingsExtrusionLayer = "3d-buildings-layer";
export const projectBoundaryLayer = "project-boundary-layer";
export const projectBoundarySource = "project-boundary-source";

export const mapbox = {
  apiUrl: "https://api.mapbox.com/geocoding/v5",
  getAddressUrl: (address: string) =>
    `${mapbox.apiUrl}/mapbox.places/${encodeURIComponent(address.trim())}.json`,
  accessToken:
    "pk.eyJ1IjoidmFyZ2F2YSIsImEiOiJjaXFvdDQ0dXcwMXVmZ2lqZmJ1NTNxd2Q1In0.V9E78uvfaP6OCrXnAx_Osg"
};

export const lineSegmentsFromPolygon = (
  geoJSON: Feature<Polygon, Properties> | Polygon
): FeatureCollection => {
  const segments = lineSegment(geoJSON);
  /**
   * code below modifies feature ids generated by turf.js because
   * mapbox-gl's setFeatureState functions do work with
   * feature ids == 0 */
  const newFeatureArray = segments.features.map(feature => {
    const newObj = feature;
    newObj["id"] = (feature.id as number) + 1;
    return newObj;
  });
  segments["features"] = newFeatureArray;
  return segments;
};

export const legendArray = [
  {
    name: "Residential",
    colors: ["#fdf5c8", "#f1e9b9", "#fad19c", "#fab993"]
  },
  {
    name: "Commercial",
    colors: ["#eedbf9", "#dba1eb", "#d87cdf", "#bc3ea0"]
  },
  {
    name: "Mixed Use",
    colors: ["#c2ce81", "#f8d2ac", "#e6c4b3", "#ff807f"]
  },
  {
    name: "Misc",
    colors: ["#dff7c7", "#dff7c7", "#dff7c7", "#dff7c7"]
  },
  {
    name: "Public",
    colors: ["#bdbdb2", "#bdbdb2", "#bdbdb2", "#bdbdb2"]
  },
  {
    name: "Industrial",
    colors: ["#D0d1d6", "#c8cad6", "#aab0d0", "#a8afd0"]
  }
];

export const thinBoundaryLineWidth = 8;
export const thickBoundaryLineWidth = 15;

export const buildingVisualizationZoomLevelThreshold = 15.0;

export const buildingLayersUnderPoint = (map: mapboxgl.Map, point: Point) => {
  return map
    .queryRenderedFeatures(point)
    .find(feature => feature.layer.id.startsWith("layer-building"));
};
