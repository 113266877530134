/**
 * Created by jmartinez on 9/19/18.
 */

import React from "react";
import { Dropdown } from "semantic-ui-react";
import { exists } from "../../utils";

const DropdownInput = ({
  selection,
  placeholder,
  fluid,
  options,
  defaultValue = 0,
  disabled,
  meta: { error, touched },
  ...props
}) => {
  const getDefaultValue = () => {
    if (
      exists(props.input) &&
      exists(props.input.value) &&
      options.map(o => o.value).includes(props.input.value)
    ) {
      return props.input.value;
    }
    return defaultValue;
  };
  return (
    <div>
      <Dropdown
        selection={selection}
        placeholder={placeholder}
        fluid={fluid}
        options={options}
        defaultValue={getDefaultValue()}
        disabled={disabled}
        onChange={(e, data) => props.input.onChange(data)}
      />
      {error && touched ? <span style={{ color: "red" }}>*{error}</span> : ""}
    </div>
  );
};

export default DropdownInput;
