import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { unitLabels } from "../../config/config";
import { formatDollars, isNullOrUndefined } from "../../config/utils";
import { Building, BuildingTypeCode } from "../../types/buildings";
import Row from "./Row";

const styles = StyleSheet.create({
  title: {
    fontWeight: 400,
    fontSize: 11,
    textTransform: "uppercase",
    color: "#54B2AB",
    marginBottom: "4mm"
  },
  rentProfilesTitle: { marginBottom: "2mm", fontSize: 7.5, fontWeight: "bold" }
});

type OpPeriodAssumptionsProps = {
  building: Building;
  isCanada: boolean;
};

const OperatingPeriodAssumptions: React.FunctionComponent<OpPeriodAssumptionsProps> = props => {
  const { building, isCanada } = props;
  const isNplex = building.building_type.code === BuildingTypeCode.nplex;
  return (
    <View style={{ marginTop: "6mm" }}>
      <Text style={styles.title}>Operating Period Assumptions</Text>
      <View>
        <View style={{ marginBottom: "3mm" }}>
          <Row
            name="Operating Expenses per Unit per Annum"
            value={
              isNullOrUndefined(building.rents)
                ? "-"
                : formatDollars(building.rents.inputs.operating_expenses_per_unit_per_annum)
            }
            drawLine={false}
          />
          <Row
            name="Commercial Rent per SF per Annum"
            value={
              isNullOrUndefined(building.rents)
                ? "-"
                : formatDollars(building.rents.inputs.commercial_rent_per_sf_per_annum)
            }
            drawLine={false}
          />
        </View>
        <View>
          <Text style={styles.rentProfilesTitle}>Residential Rent Profiles</Text>
          {isNplex ||
          isNullOrUndefined(building.rents) ||
          building.rents.inputs.rent_profiles.length === 0 ? (
            <Row name={""} value={""}></Row>
          ) : (
            building.rents.inputs.rent_profiles.map((rent, i) => {
              const unitLabelIdx = unitLabels.apartments.abbreviated.indexOf(rent.name);
              const unitLabel = unitLabels.apartments.full.singular[unitLabelIdx];
              return isCanada ? (
                <View key={i}>
                  <Row
                    name={unitLabel}
                    value={`${rent.unit_count} units ${
                      rent.ami.includes("%") ? `at ${rent.ami}` : `Market Rental`
                    }`}
                  />
                </View>
              ) : (
                <View key={i}>
                  <Row name={unitLabel} value={`${rent.unit_count} units at ${rent.ami} AMI`} />
                </View>
              );
            })
          )}
        </View>
      </View>
    </View>
  );
};

export default OperatingPeriodAssumptions;
