import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { Button, Table } from "semantic-ui-react";

import { unavailableLabel, unrestrictedLabel } from "../config/config";
import {
  getShortAreaLabel,
  getDistanceLabel,
  calculateDensityLimit,
  formatCommas,
  formatDecimal,
  getAcreLabel
} from "../config/utils";
import { calculateAndGetDensityLimitLabel } from "../config/utils";
import { actions, ownerships, resources } from "../constants/permissions";
import { combineParcel, selectPreviouslyClickedParcel } from "../controllers/projects";
import { userIsAllowedToAny } from "../helpers/permissions";
import { amountOfAcres } from "../helpers/unitsConversion";
import { RootState } from "../store";
import { operations } from "../store/operations";
import { setSideBarComponent } from "../store/projects";
import { getSelectedCandidateSite } from "../store/selectors/projects";
import { ParcelInformation, SidebarComponent } from "../types/ui";
import { doesNotExist, exists } from "../utils";

const projectCtrl = { combineParcel, selectPreviouslyClickedParcel };
const actionObj = { ...projectCtrl, setSideBarComponent };

const mapStateToProps = (state: RootState) => {
  const selectedCandidateSite = getSelectedCandidateSite(state);
  return {
    parcelCombinationOperation: state.operations[operations.parcelCombination],
    clickedParcelSelectionOperation: state.operations[operations.clickedParcelSelection],
    projectCreationInProgress:
      state.projects.sideBarComponent === "AddNewProject" && exists(state.projects.selectedParcel),
    clickedParcel: state.projects.clickedParcel as ParcelInformation,
    clickedParcelIsNeighbor: state.projects.clickedParcelIsNeighborOfSelectedParcel,
    selectedZoning: state.zoning.selectedZoning,
    parcelWasEdited:
      state.projects.selectedParcel !== null &&
      state.projects.selectedParcel.theGeomGeoJSON !== selectedCandidateSite?.theGeomGeoJSON
  };
};
const connector = connect(mapStateToProps, actionObj);

type PropsFromRedux = ConnectedProps<typeof connector>;
type ParcelInformationPopupProps = {} & PropsFromRedux;

class ParcelInformationPopup extends React.Component<ParcelInformationPopupProps> {
  render() {
    const { clickedParcelSelectionOperation, parcelCombinationOperation } = this.props;
    const CombineButton = (props: { useNeighborInformation: boolean; label: string }) => (
      <Button
        loading={parcelCombinationOperation.isLoading}
        negative={parcelCombinationOperation.justFailed}
        onClick={() =>
          this.props.combineParcel({
            useNeighborInformation: props.useNeighborInformation
          })
        }
        disabled={
          parcelCombinationOperation.isLoading ||
          clickedParcelSelectionOperation.isLoading ||
          parcelCombinationOperation.justFailed
        }
      >
        {props.label}
      </Button>
    );

    const InformationRow = (props: { name: string; contentCreator: () => number | string }) => {
      const content = clickedParcelSelectionOperation.isLoading ? "---" : props.contentCreator();

      return (
        <Table.Row>
          <Table.Cell>{props.name}</Table.Cell>
          <Table.Cell>{content}</Table.Cell>
        </Table.Row>
      );
    };

    const densityLimit = calculateDensityLimit(
      this.props.clickedParcel.area,
      this.props.selectedZoning
    );
    const isDensityLimitTooLow = 0 < densityLimit && densityLimit < 0.5;

    return (
      <>
        <h5>Parcel Information</h5>
        <Table selectable={true} celled={true}>
          <Table.Body>
            <InformationRow
              name={"Address"}
              contentCreator={() => this.props.clickedParcel.address}
            />
            <InformationRow
              name={"Parcel ID"}
              contentCreator={() => this.props.clickedParcel.parcelId}
            />
            <InformationRow
              name={"Zoning"}
              contentCreator={() => this.props.clickedParcel.zoningCode}
            />
            <InformationRow
              name={"Lot Size"}
              contentCreator={() =>
                `${formatCommas(
                  this.props.clickedParcel.area
                )} ${getShortAreaLabel()} (${formatDecimal(
                  amountOfAcres(this.props.clickedParcel.area)
                )} ${getAcreLabel()})`
              }
            />
            <InformationRow
              name={"Height Limit"}
              contentCreator={() =>
                exists(this.props.clickedParcel.height)
                  ? `${this.props.clickedParcel.height} ${getDistanceLabel()}`
                  : unrestrictedLabel
              }
            />
            <InformationRow
              name={"Density limit"}
              contentCreator={() => {
                if (doesNotExist(this.props.selectedZoning)) {
                  return unavailableLabel;
                }

                return calculateAndGetDensityLimitLabel(
                  this.props.clickedParcel.area,
                  this.props.selectedZoning
                );
              }}
            />
          </Table.Body>
        </Table>
        {isDensityLimitTooLow ? (
          <label style={{ color: "red", whiteSpace: "pre-wrap" }}>
            {`There is not enough space to build a unit according to the City's regulations.` +
              `\nTry joining parcels or editing the zoning constraints`}
          </label>
        ) : null}
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button
            loading={clickedParcelSelectionOperation.isLoading}
            negative={clickedParcelSelectionOperation.justFailed}
            onClick={() => {
              this.props.setSideBarComponent(SidebarComponent.addNewProject);
              this.props.selectPreviouslyClickedParcel();
            }}
            disabled={
              clickedParcelSelectionOperation.isLoading || parcelCombinationOperation.isLoading
            }
          >
            {this.props.projectCreationInProgress
              ? "Switch Parcel"
              : userIsAllowedToAny(actions.create, ownerships.all, resources.project)
              ? "Add Project"
              : "Select Parcel"}
          </Button>
        </div>

        {this.props.projectCreationInProgress &&
        this.props.clickedParcelIsNeighbor &&
        !this.props.parcelWasEdited ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "5px"
            }}
          >
            <CombineButton
              useNeighborInformation={true}
              label={"Combine using neighbor's zoning"}
            />
            <CombineButton useNeighborInformation={false} label={"Combine using original zoning"} />
          </div>
        ) : null}
      </>
    );
  }
}

export default connector(ParcelInformationPopup);
