import { find, isUndefined, some, without, sortBy } from "lodash";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Checkbox, Image, Popup, Table, Radio } from "semantic-ui-react";
import {
  BuildingTableRow,
  ColumnNames,
  projectViewFilteredColumns
} from "../config/buildingOptionTableData";
import * as buildingsCtrl from "../controllers/buildings";
import * as projectCtrl from "../controllers/projects";
import ellipsis from "../data/images/ellipsis.png";
import { is } from "../lib";
import { RootState } from "../store";
import * as projectActions from "../store/projects";
import {
  getProjectMainBuildingsIds,
  getSelectedBuilding,
  selectedSiteIdSelector
} from "../store/selectors/projects";
import { isBuilding } from "../types/buildings";
import { Project } from "../types/projects";
import { ActiveTable } from "../types/ui";
import BuildingOptionContextMenu from "./BuildingOptionContextMenu";
import RenameModal from "./RenameModal";
import "../stylesheets/BuildingOptionTable.css";

const mapStateToProps = (state: RootState) => {
  return {
    isSiteView: is.id(selectedSiteIdSelector(state)),
    toggleEdit: state.projects.toggleBuildingEdit,
    editBuildingList: state.projects.editBuildingList,
    selectedBuilding: getSelectedBuilding(state),
    selectedProject: state.projects.selectedProject as Project,
    selectedSiteId: selectedSiteIdSelector(state),
    activeTable: state.projects.activeTable,
    renameBuildingModalOpen: state.buildings.renameBuildingModalOpen,
    buildingRenameOperation: state.operations.buildingRename,
    flags: state.buildings.flags
  };
};

const actionObj = {
  ...buildingsCtrl,
  ...projectActions,
  ...projectCtrl
};

const connector = connect(mapStateToProps, actionObj);

type PropsFromRedux = ConnectedProps<typeof connector>;
type BuildingTableProps = {
  data: BuildingTableRow[];
  summaryRow: Record<string, any>;
} & PropsFromRedux;
type BuildingTableState = {
  sortedBy: string | null;
  sortDirection: "ascending" | "descending";
  dataIds: number[];
};

class BuildingOptionTable extends React.Component<BuildingTableProps, BuildingTableState> {
  constructor(props: BuildingTableProps) {
    super(props);
    this.state = {
      sortedBy: null,
      sortDirection: "ascending",
      dataIds: this.getIds(props.data)
    };
  }

  updateState(props: BuildingTableProps) {
    const newIds = this.getIds(props.data);

    if (this.state.dataIds.some(id => !newIds.includes(id))) {
      const prevSortedBy = this.state.sortedBy;
      const wasDescending = this.state.sortDirection === "descending";
      this.setState({ dataIds: newIds, sortedBy: null }, () => {
        if (prevSortedBy !== null) {
          this.sortByColumn(prevSortedBy, wasDescending);
        }
      });
    }
  }

  componentWillReceiveProps(nextProps: BuildingTableProps) {
    this.updateState(nextProps);
  }

  getIds(data: BuildingTableRow[]) {
    const fieldIdx = data[0].findIndex(field => field.name === ColumnNames.Id);
    return data.map(row => row[fieldIdx].value as number);
  }

  handleChange(building: { id: number }) {
    //Fixme (https://github.com/urbansim/penciler-planning/issues/712)
    const selectedBuilding = find(this.props.editBuildingList, {
      id: building.id
    });
    if (!isUndefined(selectedBuilding)) {
      this.props.checkBuildingEditBox(without(this.props.editBuildingList, selectedBuilding));
    } else {
      //@ts-ignore
      this.props.checkBuildingEditBox([...this.props.editBuildingList, building]);
    }
  }

  sortByColumn(columnName: string | null, forceDescending = false) {
    if (this.state.dataIds.length <= 1 || !this.props.isSiteView) {
      return;
    }
    if (columnName !== this.state.sortedBy || forceDescending) {
      const index = this.props.data[0].findIndex(row => row.name === columnName);
      if (index < 0) return;
      const newData = sortBy(this.props.data, row => {
        if (row[index].sortableValue) return row[index].sortableValue;
        const value = row[index].value;
        if (is.number(value)) return value;
        const unformattedValue = value.replace(/[$%,]/g, "");
        // @ts-ignore
        if (!isNaN(unformattedValue)) {
          return parseFloat(unformattedValue);
        }
        return value;
      });
      const newDataIds = this.getIds(newData);
      this.setState({
        sortedBy: columnName,
        sortDirection: forceDescending ? "descending" : "ascending",
        dataIds: forceDescending ? newDataIds.reverse() : newDataIds
      });
    } else {
      this.setState({
        dataIds: this.state.dataIds.reverse(),
        sortDirection: this.state.sortDirection === "ascending" ? "descending" : "ascending"
      });
    }
  }

  handleRowClick(buildingId: number) {
    const { selectedBuilding, selectedSiteId } = this.props;
    if (!is.id(selectedSiteId)) return;

    if (isBuilding(selectedBuilding) && selectedBuilding.id === buildingId) return;
    this.props.selectBuildingById(buildingId);
  }

  handleMainBuildingButtonClick(buildingId: number) {
    this.props.apiUpdateMainBuilding(buildingId);
  }

  handleRenameBuildingClick() {
    this.props.openRenameBuildingModal();
  }

  cancelRenameBuilding(event: React.MouseEvent<Element, MouseEvent>) {
    event.preventDefault();
    this.props.closeRenameBuildingModal();
  }

  saveRenameBuilding() {
    this.props.renameBuilding();
  }

  componentDidUpdate() {
    this.updateState(this.props);
  }

  render() {
    const {
      isSiteView,
      selectedBuilding,
      selectedProject,
      selectedSiteId,
      renameBuildingModalOpen
    } = this.props;

    let columnNames: string[] = [];
    if (this.props.data.length > 0) {
      columnNames = this.props.data[0].slice(1).map(row => row.name);
    }
    if (!isSiteView) {
      columnNames = columnNames.filter(name => !projectViewFilteredColumns.includes(name));
    }

    return (
      <div className="building-output-table">
        <Table
          sortable
          selectable
          celled
          singleLine
          compact={!this.props.toggleEdit && isSiteView}
          size={"small"}
        >
          <Table.Header>
            <Table.Row>
              {isSiteView ? <Table.HeaderCell /> : null}
              {this.props.toggleEdit || this.state.dataIds.length === 0 || !isSiteView ? null : (
                <Popup
                  key="mainBuilding"
                  content="Set building option as the default view on map"
                  trigger={
                    <Table.HeaderCell
                      className={"main-building-icon"}
                      icon={"eye"}
                      textAlign="center"
                    />
                  }
                />
              )}
              {this.props.data.length > 0
                ? this.props.data[0]
                    .slice(1)
                    .filter(col => isSiteView || !projectViewFilteredColumns.includes(col.name))
                    .map(row => {
                      const className = `${row.name} ${this.state.sortDirection}${
                        isSiteView ? " sorted" : ""
                      }${this.state.sortedBy === row.name ? " column" : ""}`;
                      return row.tooltip ? (
                        <Popup
                          key={row.name}
                          content={row.tooltip}
                          trigger={
                            <Table.HeaderCell
                              onClick={() => this.sortByColumn(row.name)}
                              className={className}
                            >
                              {row.name}
                            </Table.HeaderCell>
                          }
                        />
                      ) : (
                        <Table.HeaderCell
                          key={row.name}
                          onClick={() => this.sortByColumn(row.name)}
                          className={className}
                        >
                          {row.name}
                        </Table.HeaderCell>
                      );
                    })
                : null}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.dataIds.map(buildingId => {
              const buildingOption = this.props.data.find(
                rowData => rowData[0].value === buildingId
              ) as BuildingTableRow;
              let buildingOptionSlice = buildingOption.slice(2);
              if (!isSiteView) {
                buildingOptionSlice = buildingOptionSlice.filter(
                  obj => !projectViewFilteredColumns.includes(obj.name)
                );
              }
              const buildingOptionName = buildingOption[1].value as string;
              const isMissingFinance =
                this.props.activeTable === ActiveTable.proforma &&
                buildingOptionSlice.some(option => option.value === "");
              return (
                <Table.Row
                  key={buildingId}
                  // @ts-ignore
                  active={this.props.selectedBuilding.id === buildingId}
                  onClick={() => {
                    if (
                      // @ts-ignore
                      this.props.selectedBuilding.id === buildingId ||
                      this.props.flags.buildingIsLoading
                    ) {
                      return;
                    }

                    this.handleRowClick(buildingId);
                  }}
                >
                  {isSiteView ? (
                    <Table.Cell
                      className={
                        this.props.toggleEdit ? "check-button-cell" : "ellipsis-button-cell"
                      }
                    >
                      {this.props.toggleEdit ? (
                        <Checkbox
                          value={buildingId}
                          checked={some(this.props.editBuildingList, {
                            id: buildingId
                          })}
                          onClick={event => {
                            event.stopPropagation();
                            this.handleChange({ id: buildingId });
                          }}
                        />
                      ) : (
                        <BuildingOptionContextMenu
                          buildingOption={buildingOption}
                          isSiteView={isSiteView}
                          onClick={() => this.handleRowClick(buildingId)}
                        />
                      )}
                    </Table.Cell>
                  ) : null}
                  {this.props.toggleEdit || !isSiteView ? null : (
                    <Table.Cell textAlign="center">
                      <Radio
                        name="mainBuilding"
                        checked={
                          !is.null(selectedSiteId) &&
                          buildingId === getProjectMainBuildingsIds(selectedProject)[selectedSiteId]
                        }
                        onChange={() => this.handleMainBuildingButtonClick(buildingId)}
                      />
                    </Table.Cell>
                  )}
                  <Table.Cell
                    key="Building Option"
                    onDoubleClick={
                      isBuilding(this.props.selectedBuilding) &&
                      this.props.selectedBuilding.id === buildingId
                        ? () => {
                            this.handleRenameBuildingClick();
                          }
                        : undefined
                    }
                  >
                    {buildingOptionName}
                  </Table.Cell>
                  {isMissingFinance && isSiteView ? (
                    <React.Fragment>
                      <Table.Cell>{buildingOptionSlice[0].value}</Table.Cell>
                      <Table.Cell>{buildingOptionSlice[1].value}</Table.Cell>
                      <Table.Cell colSpan={buildingOptionSlice.length - 2}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <span>
                            For pro forma analysis click
                            <Image src={ellipsis} size="mini" spaced />
                            and choose &#34;Add financial assumptions&#34;
                          </span>
                        </div>
                      </Table.Cell>
                    </React.Fragment>
                  ) : (
                    buildingOptionSlice.map(col => {
                      return <Table.Cell key={col.name}>{col.value}</Table.Cell>;
                    })
                  )}
                </Table.Row>
              );
            })}
            {isSiteView ? null : (
              <Table.Row key={"totals"}>
                <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }} content="All" />
                {columnNames.slice(1).map((columnName, i) => {
                  return (
                    <Table.Cell key={i}>{this.props.summaryRow[columnName] ?? "-"}</Table.Cell>
                  );
                })}
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <RenameModal
          what="Building"
          open={renameBuildingModalOpen}
          cancelAction={event => this.cancelRenameBuilding(event)}
          saveAction={() => this.saveRenameBuilding()}
          // @ts-ignore
          initialValues={{ name: selectedBuilding.name }}
          operation={this.props.buildingRenameOperation}
        />

        <div id="pdf-report-floorplan-container" style={{ display: "none" }} />
        <div id="dummy-element"></div>
      </div>
    );
  }
}

export default connector(BuildingOptionTable);
