import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import centroid from "@turf/centroid";
import { Geometry } from "@turf/helpers";
import { isUndefined, omit } from "lodash";
import { NavigationControl, Popup as PopupGl } from "mapbox-gl";
import React from "react";
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { Table } from "semantic-ui-react";

import { initMap, mainMenuHeight, mapBoxStyle } from "../config/config";
import {
  allBuildingsExtrusionLayer,
  mapbox,
  projectBoundaryLayer,
  projectBoundarySource,
  buildingVisualizationZoomLevelThreshold,
  buildingLayersUnderPoint,
  parcelVisualizationsSeparatorLayerId
} from "../config/map";
import { palette } from "../constants/palette";
import * as projectCtrl from "../controllers/projects";
import ui from "../controllers/ui";
import { boundingBoxFromMapBoxBounds, is } from "../lib";
import { IntegrationError } from "../lib/errors";
import { dataLayersQuerier, mapboxFillLayerID } from "../queriers/region";
import { AppDispatch, RootState } from "../store";
import * as buildingsActions from "../store/buildings";
import { pushIntegrationError } from "../store/errors";
import * as mapActions from "../store/map";
import { operations } from "../store/operations";
import * as projectActions from "../store/projects";
import {
  getProjectMainBuildingsIds,
  getSelectedBuilding,
  getSelectedProjectMainBuildings,
  selectedSiteIdSelector,
  selectedSiteUsageIsBuildingSelector
} from "../store/selectors/projects";
import * as uiActions from "../store/ui";
import { Building, isBuilding } from "../types/buildings";
import { DevelopmentSite, isProject } from "../types/projects";
import { doesNotExist, exists } from "../utils";
import BoundaryVisualization from "./BoundaryVisualization";
import BuildingVisualization from "./BuildingVisualization";
import ClosablePopup from "./ClosablePopup";
import LayersIcon from "./dataLayers/LayersIcon";
import { Menu as DataLayersMenu } from "./dataLayers/menu";
import ParcelsLayer from "./dataLayers/ParcelsLayer";
import DataLayersPopup from "./dataLayers/popup";
import ZoningsLayer from "./dataLayers/ZoningsLayer";
import { Modes } from "./DrawControls/constants";
import ParcelControls from "./DrawControls/ParcelEditionControls";
import { visualizationsLayerIds } from "./mapVisualization";
import ParcelVisualization from "./ParcelVisualization";

const Map = ReactMapboxGl({
  accessToken: mapbox.accessToken,
  preserveDrawingBuffer: true,
  antialias: true
});

const mapStateToProps = (state: RootState) => {
  const selectedProjectSiteId = selectedSiteIdSelector(state);
  const isSiteView = is.id(selectedProjectSiteId);
  return {
    dataLayers: state.regions.dataLayers,
    dataLayersPopupIsAnchored: state.ui.dataLayers.popupIsAnchored,
    projects: state.projects.projectList,
    map: (state.map.map as any) as mapboxgl.Map | undefined,
    mapLoaded: state.ui.index.mapLoaded,
    clickedSegmentId: state.projects.clickedSegmentId,
    boundaryVisualizationIsActive: state.ui.boundaryVisualization.isActive,
    projectSegmentSelectionPopupIsOpen: state.map.showPopup,
    popupCoordinates: state.map.popupCoordinates,
    displayedProjectsIDs: state.ui.index.displayedProjectsIDs,
    disabledProjectsIDs: state.ui.index.disabledProjectsIDs,
    focusedProjectId: state.ui.index.focusedProjectId,
    selectedProject: state.projects.selectedProject,
    selectedProjectSite: isSiteView
      ? state.projects.selectedProjectSites.byId[selectedProjectSiteId as number]
      : null,
    selectedBuilding: getSelectedBuilding(state),
    isSiteView,
    selectedSiteUsageIsBuilding: selectedSiteUsageIsBuildingSelector(state),
    projectViewMainBuildings:
      isProject(state.projects.selectedProject) && !isSiteView
        ? getSelectedProjectMainBuildings(state)
        : [],
    parcelHoveringIsEnabled: !state.projects.bottomBarVisible,
    showBuildingsLayer: state.map.showBuildingsLayer,
    editFrontage: state.projects.editFrontage,
    center: state.map.center,
    projectCreationInProgress:
      state.projects.sideBarComponent === "AddNewProject" && exists(state.projects.selectedParcel),
    isCursorLoading: state.operations[operations.clickedParcelSelection].isLoading,
    isParcelBeingEdited:
      state.ui.index.currentParcelEditionMode !== null &&
      state.ui.index.currentParcelEditionMode !== Modes.site_selected
  };
};

const uiCtrl = omit(ui, ["viewport", "parcelSearch"]);

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    ui: {
      viewport: bindActionCreators(ui.viewport, dispatch),
      ...bindActionCreators(uiCtrl, dispatch)
    },
    errors: bindActionCreators({ pushIntegrationError }, dispatch),
    ...bindActionCreators(
      {
        ...uiActions,
        ...mapActions,
        ...buildingsActions,
        ...projectActions,
        ...projectCtrl
      },
      dispatch
    )
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type MainMapProps = {} & PropsFromRedux;
type MainMapState = {
  map: mapboxgl.Map | null;
  mapHeight: number;
  hoveredStateId: string | number | undefined;
  buildingsToVisualize: Building[];
};

class MainMap extends React.Component<MainMapProps, MainMapState> {
  constructor(props: MainMapProps) {
    super(props);
    this.state = {
      map: null,
      mapHeight: window.innerHeight - mainMenuHeight,
      hoveredStateId: undefined,
      buildingsToVisualize: []
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.setWindowHeight.bind(this));
  }

  componentDidUpdate(prevProps: MainMapProps) {
    if (
      this.props.selectedBuilding !== prevProps.selectedBuilding ||
      this.props.selectedProject !== prevProps.selectedProject ||
      this.props.editFrontage !== prevProps.editFrontage ||
      this.props.disabledProjectsIDs !== prevProps.disabledProjectsIDs
    ) {
      this.updateBuildingVisualizations();
    }
  }

  shouldComponentUpdate(nextProps: MainMapProps) {
    if (this.props.dataLayersPopupIsAnchored !== nextProps.dataLayersPopupIsAnchored) {
      return false;
    }

    return true;
  }

  handleLoad(map: mapboxgl.Map) {
    this.setState({ map: map });
    this.props.setMapViewer(map);
    map.addControl(new NavigationControl());

    const buildingTooltip = new PopupGl({
      closeButton: false,
      closeOnClick: false,
      anchor: "bottom-left"
    });
    const buildingTooltipHTML = "<span>View Floorplan</span>";

    // Click on one of parcel's sides
    map.on("click", projectBoundaryLayer, event => {
      if (this.props.isCursorLoading) return;

      const point: [number, number] = [event.point.x, event.point.y];
      const feature = map.queryRenderedFeatures(point, {
        layers: [projectBoundaryLayer]
      });

      const coordinates = [event.lngLat.lng, event.lngLat.lat];
      this.props.setClickedSegmentId(feature[0].id as number);
      this.props.setPopupCoordinates(coordinates);
      this.props.toggleMapPopup(true);
    });

    // Mouse over one of parcel's sides
    map.on("mousemove", projectBoundaryLayer, event => {
      const point: [number, number] = [event.point.x, event.point.y];
      const { hoveredStateId } = this.state;
      const features = map.queryRenderedFeatures(point, {
        layers: [projectBoundaryLayer]
      });
      if (features.length > 0) {
        if (hoveredStateId) {
          map.setFeatureState(
            { source: projectBoundarySource, id: hoveredStateId },
            { hover: false }
          );
        }
        this.setState({ hoveredStateId: features[0].id });
        map.setFeatureState({ source: projectBoundarySource, id: features[0].id }, { hover: true });
      } else if (!isUndefined(hoveredStateId)) {
        map.setFeatureState(
          { source: projectBoundarySource, id: hoveredStateId },
          { hover: false }
        );
      }
    });
    map.on("mouseleave", projectBoundaryLayer, () => {
      if (this.props.projectSegmentSelectionPopupIsOpen) {
        return;
      }
      map.setFeatureState(
        { source: projectBoundarySource, id: this.state.hoveredStateId },
        { hover: false }
      );
      this.setState({ hoveredStateId: undefined });
    });

    // Mouse over selected parcel
    map.on("mouseover", visualizationsLayerIds.fullParcel, event => {
      if (!isBuilding(this.props.selectedBuilding)) return;
      if (this.props.editFrontage) return;
      if (!event.features) return;
      const center = centroid(event.features[0].geometry as Geometry);
      if (!center.geometry?.coordinates) return;
      map.setPaintProperty(
        visualizationsLayerIds.fullParcel,
        "fill-color",
        palette.fullParcelHover
      );
      map.setPaintProperty(
        `${visualizationsLayerIds.setbackedParcel}-${this.props.selectedBuilding.id}`,
        "fill-color",
        palette.fullParcelHover
      );
      buildingTooltip
        .setLngLat(center.geometry.coordinates as [number, number])
        .setHTML(buildingTooltipHTML)
        .addTo(map);
    });

    // Mouse off selected parcel
    map.on("mouseout", visualizationsLayerIds.fullParcel, () => {
      if (!isBuilding(this.props.selectedBuilding)) return;

      map.setPaintProperty(visualizationsLayerIds.fullParcel, "fill-color", palette.fullParcel);
      map.setPaintProperty(
        `${visualizationsLayerIds.setbackedParcel}-${this.props.selectedBuilding.id}`,
        "fill-color",
        palette.reducedParcel
      );
      buildingTooltip.remove();
    });

    // Handle 3d extrusions hover
    map.on("mousemove", ({ point }) => {
      if (
        map.getZoom() < buildingVisualizationZoomLevelThreshold ||
        exists(this.props.selectedProject)
      ) {
        return;
      }

      const hoveredFloor = buildingLayersUnderPoint(map, point);
      if (hoveredFloor) {
        const projectId = hoveredFloor.properties?.projectId;
        if (projectId) {
          this.props.focusProject(projectId);
          return;
        }
      }
      this.props.unfocusProject();
    });

    dataLayersQuerier(this.props.dataLayers).all.forEach(dataLayer => {
      const hoverShouldGetUpdated = () => !this.props.dataLayersPopupIsAnchored;
      map.on("mousemove", mapboxFillLayerID(dataLayer), ({ features, point }) => {
        if (!hoverShouldGetUpdated()) {
          return;
        }

        const hoveredFloor = buildingLayersUnderPoint(map, point);
        if (hoveredFloor) {
          this.props.ui.unmarkPreviousFocusedFeature(map, dataLayer);
        } else {
          this.props.ui.markFocusedFeature(map, dataLayer, features ?? []);
        }
      });
      map.on("mouseleave", mapboxFillLayerID(dataLayer), () => {
        if (!hoverShouldGetUpdated()) {
          return;
        }
        this.props.ui.unmarkPreviousFocusedFeature(map, dataLayer);
      });
    });

    map.on("error", error => {
      try {
        const isTileFetchingError =
          "source" in error && "tiles" in error.source && Array.isArray(error.source.tiles);
        if (isTileFetchingError) {
          const tileFetchingURL = error.source.tiles[0];
          const fetchedLayerId = parseInt(tileFetchingURL.match(/layers\/(\d+)/)[1]);
          const dataLayer = dataLayersQuerier(this.props.dataLayers).byRemoteId(fetchedLayerId);
          this.props.errors.pushIntegrationError(
            new IntegrationError(dataLayer.name, dataLayer.errorMessage, { failingTileFetch: true })
          );
        }
      } catch {
        // MapBox seems to "console.error" errors by default. Keeping that same behavior in case we
        // don't identify the error that occured.
        console.error(error);
      }
    });

    map.resize();

    /* Map viewport update*/
    // Update viewport once now on the map load
    this.props.ui.viewport.forceUpdate(boundingBoxFromMapBoxBounds(map.getBounds()));
    // Then with a certain delay if the camera is being moved
    map.on("move", () => {
      this.props.ui.viewport.update(boundingBoxFromMapBoxBounds(map.getBounds()));
    });
    // But always when the camera stops
    map.on("moveend", () => {
      this.props.ui.viewport.forceUpdate(boundingBoxFromMapBoxBounds(map.getBounds()));
      this.updateBuildingVisualizations();
    });
    this.props.setMapLoaded();
  }

  handleMapClick(map: mapboxgl.Map, event: any) {
    const point: any = [event.point.x, event.point.y];

    if (!isProject(this.props.selectedProject)) {
      // Clicked on the map, no project selected.
      // If clicked a building extrusion, select such project.
      const foundFloor = buildingLayersUnderPoint(map, point);
      if (foundFloor && foundFloor.properties && is.number(foundFloor.properties.projectId)) {
        this.props.ui.openProject(foundFloor.properties.projectId);
      }
      return;
    }

    if (!map.getLayer(visualizationsLayerIds.fullParcel)) return;

    if (!isBuilding(this.props.selectedBuilding)) return;
    if (this.props.editFrontage) return;

    const features = map.queryRenderedFeatures(point, {
      layers: [visualizationsLayerIds.fullParcel]
    });

    if (features.length > 0) {
      this.props.toggleFloorLayout(true);
    }
  }

  handleSetSegment(type: "front" | "rear" | "sides") {
    const { clickedSegmentId } = this.props;
    this.props.updateBoundarySegments(type, clickedSegmentId);
  }

  setWindowHeight() {
    const height = window.innerHeight - mainMenuHeight;
    this.setState({
      mapHeight: height
    });
    if (this.state.map) {
      this.state.map.resize();
    }
  }

  updateBuildingVisualizations() {
    const { editFrontage, map, selectedProject, selectedBuilding } = this.props;
    let buildingsToVisualize: Building[] = [];
    if (map && map.getZoom() >= buildingVisualizationZoomLevelThreshold) {
      if (isBuilding(selectedBuilding) && !editFrontage) {
        if (this.props.selectedSiteUsageIsBuilding) {
          buildingsToVisualize.push(selectedBuilding);
        }
      } else if (isProject(selectedProject) && !this.props.isSiteView && !editFrontage) {
        buildingsToVisualize = this.props.projectViewMainBuildings;
      } else if (!this.props.projectCreationInProgress && !isProject(selectedProject)) {
        const displayedProjects = this.props.projects.ids
          .filter(id => this.props.displayedProjectsIDs.includes(id))
          .filter(id => !this.props.disabledProjectsIDs.includes(id))
          .map(id => this.props.projects.byId[id]);
        buildingsToVisualize = displayedProjects.flatMap(project => {
          const mainBuildingsIds = getProjectMainBuildingsIds(project);
          return Object.keys(mainBuildingsIds)
            .map(siteIdString => {
              const siteId = parseInt(siteIdString);
              const building = project.mainBuildings && project.mainBuildings[siteId];
              if (building !== undefined) {
                return building;
              } else if (mainBuildingsIds[siteId]) {
                this.props.updateMainBuilding(project.id, siteId);
              }
              return undefined;
            })
            .filter((value: Building | undefined): value is Building => value !== undefined);
        });
      }
    }
    this.setState({ buildingsToVisualize: buildingsToVisualize });
  }

  render() {
    const { selectedProject, selectedProjectSite, isSiteView } = this.props;
    const parcelLayers = () => {
      const coordinates = isProject(selectedProject)
        ? isSiteView
          ? (selectedProjectSite as DevelopmentSite).geometry.coordinates
          : selectedProject.parcel_data.geometry.coordinates
        : null;
      return exists(coordinates) && coordinates !== null ? (
        <>
          <Layer
            type="fill"
            id={visualizationsLayerIds.fullParcel}
            layout={{ visibility: "visible" }}
            paint={{ "fill-color": palette.fullParcel, "fill-opacity": 1 }}
            before={exists(selectedProject) ? allBuildingsExtrusionLayer : null}
          >
            <Feature coordinates={coordinates} />
          </Layer>
          <Layer
            type="line"
            id={visualizationsLayerIds.fullParcelOutline}
            layout={{ visibility: "visible" }}
            paint={{ "line-color": palette.border, "line-width": 1 }}
          >
            <Feature coordinates={coordinates} />
          </Layer>
        </>
      ) : null;
    };

    return (
      <div>
        {/* @ts-ignore */}
        <Map
          style={mapBoxStyle.style}
          containerStyle={{
            height: this.state.mapHeight,
            width: "100%"
          }}
          className={`${this.props.isCursorLoading ? "loading" : ""} mapboxgl-map`}
          // eslint-disable-next-line react/no-string-refs
          ref="map"
          center={this.props.center as [number, number]}
          zoom={initMap.zoom as [number]}
          pitch={initMap.pitch as [number]}
          onStyleLoad={map => {
            this.handleLoad(map);
          }}
          onClick={(map, e) => {
            this.handleMapClick(map, e);
          }}
        >
          <LayersIcon />
          <ZoningsLayer />
          <DataLayersMenu />
          <DataLayersPopup />
          {parcelLayers()}
          {/* Note(gushuro): added empty layer so all ParcelVisualizations are placed behind it */}
          <Layer id={parcelVisualizationsSeparatorLayerId} />
          {this.props.parcelHoveringIsEnabled && !this.props.isParcelBeingEdited ? (
            <ParcelsLayer />
          ) : null}
          {this.state.buildingsToVisualize.map(building =>
            doesNotExist(building.geometry.reduced_parcel) ? null : (
              <ParcelVisualization
                key={"setbackedParcel-" + building.id}
                buildingId={building.id}
                parcelCoordinates={building.geometry.reduced_parcel.geometry.coordinates}
              />
            )
          )}
          {this.state.buildingsToVisualize.map(building => (
            <BuildingVisualization
              key={building.id}
              building={building}
              focused={building.project_id === this.props.focusedProjectId}
            />
          ))}
          <Layer
            id={allBuildingsExtrusionLayer}
            type="fill-extrusion"
            sourceId="composite"
            sourceLayer="building"
            layout={{ visibility: this.props.showBuildingsLayer ? "visible" : "none" }}
            paint={{
              "fill-extrusion-color": "#aaa",
              "fill-extrusion-height": [
                "case",
                ["boolean", ["feature-state", "hidden"], false],
                0,
                ["get", "height"]
              ],
              "fill-extrusion-opacity": 0.6
            }}
          />
          {this.props.boundaryVisualizationIsActive && !this.props.isParcelBeingEdited ? (
            <BoundaryVisualization />
          ) : null}
          {this.props.projectSegmentSelectionPopupIsOpen ? (
            <ClosablePopup
              coordinates={this.props.popupCoordinates}
              onClose={() => {
                this.props.toggleMapPopup(false);
              }}
            >
              <Table selectable={true} celled={true}>
                <Table.Header>
                  <Table.Row>
                    <Table.Cell>
                      <h5>Choose Type</h5>
                    </Table.Cell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell
                      onClick={() => {
                        this.handleSetSegment("front");
                        this.props.toggleMapPopup(false);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          cursor: "pointer"
                        }}
                      >
                        <svg height="10" width="10">
                          <rect height="10" width="10" style={{ fill: palette.front.primary }} />
                        </svg>
                        <div>
                          <p>Frontage</p>
                        </div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell
                      onClick={() => {
                        this.handleSetSegment("rear");
                        this.props.toggleMapPopup(false);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          cursor: "pointer"
                        }}
                      >
                        <svg height="10" width="10">
                          <rect height="10" width="10" style={{ fill: palette.rear.primary }} />
                        </svg>
                        <div>
                          <p>Rear Yard</p>
                        </div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell
                      onClick={() => {
                        this.handleSetSegment("sides");
                        this.props.toggleMapPopup(false);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          cursor: "pointer"
                        }}
                      >
                        <svg height="10" width="10">
                          <rect height="10" width="10" style={{ fill: palette.sides.primary }} />
                        </svg>
                        <div>
                          <p>Side Yard</p>
                        </div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </ClosablePopup>
          ) : null}
          {this.props.mapLoaded && this.props.projectCreationInProgress ? <ParcelControls /> : null}
        </Map>
      </div>
    );
  }
}

export default connector(MainMap);
