import axios from "axios";
import { baseUrl, ucUrl } from "../../config/urls";
import { ParcelUsages } from "../../store/slices/ui";
import { MeasurementSystem, UserSettings } from "../../types/users";

export const asyncGetMeasurementSystems = async () => {
  try {
    return (await axios.get<MeasurementSystem[]>(`${ucUrl}/measurement_systems`)).data;
  } catch (error) {
    console.error(`promises::users::asyncGetMeasurementSystems -> ${error}`);
    throw error;
  }
};

export const asyncGetParcelUsages = async () => {
  try {
    return (await axios.get<ParcelUsages>(`${baseUrl}/parcel_usages`)).data;
  } catch (error) {
    console.error(`promises::users::asyncGetParcelUsages -> ${error}`);
    throw error;
  }
};

export const asyncGetRegion = async () => {
  try {
    return (await axios.get(`${baseUrl}/region`)).data;
  } catch (error) {
    console.error(`promises::users::asyncGetRegion -> ${error}`);
    throw error;
  }
};

export const asyncGetUrbanCanvasUser = async () => {
  try {
    return (await axios.get(`${ucUrl}/users/this`)).data;
  } catch (error) {
    console.error(`promises::users::asyncGetUrbanCanvasUser -> ${error}`);
    throw error;
  }
};

export const asyncGetUserSettings = async () => {
  try {
    return (await axios.get(`${ucUrl}/users/settings`)).data;
  } catch (error) {
    console.error(`promises::users::asyncGetUserSettings -> ${error}`);
    throw error;
  }
};

export const asyncPutUserSettings = async (data: UserSettings) => {
  try {
    await axios.put(`${ucUrl}/users/settings`, data);
  } catch (error) {
    console.error(`promises::users::asyncPutUserSettings -> ${error}`);
    throw error;
  }
};
