import React from "react";
import { actions, ownerships, resources } from "../constants/permissions";
import { userIsAllowedToAny } from "../helpers/permissions";

class WizardStep<P = {}, S = {}, SS = {}> extends React.Component<P, S, SS> {
  // TODO(ffigari): Change the way `this.readOnlyMode` is set. This is an incorrect usage of
  // `userIsAllowedToAny`: while it is meant to be used to check permissions, here it is being
  // used to decide how to decide the mode of a component. That decision should instead be made
  // depending on the action the user decided to carry on.
  // Currently the same wizards buttons are used for reading, editing and creating zoning,
  // programs and financial analysis. Creating distinct buttons might allow to set this variable
  // properly.
  readOnlyMode = !userIsAllowedToAny(actions.create, ownerships.all, resources.buildingOption);
}

export default WizardStep;
