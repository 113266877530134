import React from "react";
import { Button } from "semantic-ui-react";

class LayerToggler extends React.Component<{
  isVisible: boolean;
  label: string;
  toggleVisibility: any;
}> {
  render() {
    return (
      <>
        <Button
          size="mini"
          icon={this.props.isVisible ? "unhide" : "hide"}
          style={{ background: "white" }}
          onClick={this.props.toggleVisibility}
        />
        {`  `}
        <label>{this.props.label}</label>
      </>
    );
  }
}

export default LayerToggler;
