import { reset, formValueSelector } from "redux-form";

import { isToggleChecked } from "../../../components/forms/CheckboxInput";
import { buildingWizardFormNames } from "../../../config/config";
import { buildingURLs } from "../../../config/urls";
import * as statusCodes from "../../../constants/statusCodes";
import { is } from "../../../lib";
import * as buildingPromises from "../../../promises/buildings/buildings";
import { RootState, AppDispatch } from "../../../store";
import * as buildingsActions from "../../../store/buildings";
import { pushNewError } from "../../../store/errors";
import { safeOperation, operations } from "../../../store/operations";
import { toggleEditProgram, cleanSelectedPrograms } from "../../../store/programs";
import { toggleAddBuildingModal, setProjectSiteMainBuildingId } from "../../../store/projects";
import {
  getSelectedBuildingId,
  getSelectedProjectSelectedSite,
  selectedSiteIdSelector
} from "../../../store/selectors/projects";
import { MultifamilyProgram, SinglefamilyProgram } from "../../../store/types";
import { openProformaUpdatedModal } from "../../../store/ui";
import { setIncompatibleContraintsSuggestions } from "../../../store/ui/incompatibleConstraints";
import { setSelectedZoning, setSelectedZoningName, toggleEditZoning } from "../../../store/zoning";
import { Project } from "../../../types/projects";
import { exists } from "../../../utils";
import { selectBuildingById } from "../../buildings";
import { apiGetBuildingsFromProject } from "../../projects";

export const duplicateBuilding = (programs: any, zoningId: number, name: string) =>
  safeOperation(operations.buildingDuplication, async (dispatch: AppDispatch) => {
    await dispatch(_createBuildingWithHiglhyCoupledMethod(programs, zoningId, name, true));
  });

export const generateBuilding = (name: string) =>
  safeOperation(
    operations.buildingGeneration,
    async (dispatch: AppDispatch) => {
      await dispatch(_submitNewBuildingUsingDataAssociatedToTheBuildingAdditionModal(name));
    },
    (error: any, dispatch: AppDispatch, getState: () => RootState) => {
      if (
        error.response?.status === statusCodes.IncompatibleConstraints &&
        !getState().ui.incompatibleConstraints.detected
      ) {
        dispatch(setIncompatibleContraintsSuggestions(error.response.data["Error"]));
      } else {
        dispatch(
          pushNewError({
            name: "Creation of new building failed",
            description:
              `actions::buildings::createBuilding -> ${error}\n` +
              (error.response ? error.response.data["Error"] : "Internal Server Error")
          })
        );
      }
    }
  );

export const generateBuildingWithSuggestion = (name: string) =>
  safeOperation(operations.buildingGenerationWithSuggestion, async (dispatch: AppDispatch) => {
    await dispatch(_submitNewBuildingUsingDataAssociatedToTheBuildingAdditionModal(name));
  });

const _mainModalFormSelector = formValueSelector("MainModalForm");
const _submitNewBuildingUsingDataAssociatedToTheBuildingAdditionModal = (name: string) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const zoningId = getState().zoning.selectedZoning.id;

  const multifamilyToggle = _mainModalFormSelector(getState(), "multifamily_toggle");
  const singlefamilyToggle = _mainModalFormSelector(getState(), "singlefamily_toggle");

  const multi_family = getState().programs.selectedPrograms.multi_family as MultifamilyProgram;
  const single_family = getState().programs.selectedPrograms.single_family as SinglefamilyProgram;

  const programs = [];
  if (exists(multi_family) && isToggleChecked(multifamilyToggle)) {
    if (exists(multi_family.id)) {
      programs.push({
        id: multi_family.id,
        is_building_program: multi_family.isBuildingProgram || false
      });
    }
  }

  if (exists(single_family) && isToggleChecked(singlefamilyToggle)) {
    if (exists(single_family.id)) {
      programs.push({
        id: single_family.id,
        is_building_program: single_family.isBuildingProgram || false
      });
    }
  }

  await dispatch(_createBuildingWithHiglhyCoupledMethod(programs, zoningId, name, false));
};

const _createBuildingWithHiglhyCoupledMethod = (
  programs: any,
  zoningId: number,
  name: string,
  duplicate: boolean
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      id: projectId,
      parcel_data: { city_id: cityId, height_id: heightId }
    } = getState().projects.selectedProject as Project;
    const siteId = selectedSiteIdSelector(getState());
    const selectedSite = getSelectedProjectSelectedSite(getState());
    const { editBuildingMode, virtualPrograms, virtualZoning } = getState().buildings;
    const userZoningIsSelected = getState().zoning.userZoningIsSelected;
    const buildingId = getSelectedBuildingId(getState());

    const functionName = "user::buildings::_createBuildingWithHiglhyCoupledMethod";
    if (editBuildingMode && !buildingId) {
      dispatch(
        pushNewError({
          name: "Error editing building: no selected building.",
          description: `${functionName} --> No building selected`
        })
      );
    }
    if (duplicate && (!siteId || !buildingId)) {
      dispatch(
        pushNewError({
          name: `Error duplicating building: no selected ${siteId ? "buildingId" : "siteId"}.`,
          description: `${functionName} --> Received bad args (${siteId}, ${buildingId})`
        })
      );
    }
    if (!duplicate && !editBuildingMode && !siteId) {
      dispatch(
        pushNewError({
          name: "Error creating building: no selected site.",
          description: `${functionName} --> No site selected`
        })
      );
    }

    const currentMainBuildingId = is.null(selectedSite) ? null : selectedSite.main_building_id;

    const dataToSend = {
      name: name,
      city_id: cityId,
      user_zoning: userZoningIsSelected,
      height_id: parseInt(heightId as any, 10)
    } as any;

    if (exists(virtualZoning)) {
      dataToSend["explicit_zoning"] = virtualZoning;
    } else {
      dataToSend["zoning_id"] = zoningId;
    }

    if (exists(programs)) {
      dataToSend["programs"] = programs;
    }
    if (exists(virtualPrograms)) {
      dataToSend["explicit_programs"] = [];
      if (exists(virtualPrograms.multifamily)) {
        dataToSend["explicit_programs"].push(virtualPrograms.multifamily);
      }
      if (exists(virtualPrograms.singlefamily)) {
        dataToSend["explicit_programs"].push(virtualPrograms.singlefamily);
      }
    }
    if (editBuildingMode) {
      dataToSend["updated_proforma_inputs"] = {
        rent_profiles: getState().proforma.rentProfiles
      };
    }

    const targetURL = editBuildingMode
      ? buildingURLs.edit(buildingId)
      : duplicate
      ? buildingURLs.duplicate(siteId, buildingId)
      : buildingURLs.post(siteId);
    const creationMethod = editBuildingMode
      ? buildingPromises.asyncPutBuilding
      : buildingPromises.asyncPostBuilding;

    const buildingData = await creationMethod(targetURL, dataToSend);
    const newBuildingId = Number(buildingData.id);
    await dispatch(apiGetBuildingsFromProject(projectId, newBuildingId));
    if (!editBuildingMode) {
      if (is.id(buildingData["main_building_id"])) {
        dispatch(setProjectSiteMainBuildingId(buildingData["main_building_id"]));
      }
      dispatch(selectBuildingById(newBuildingId));

      buildingWizardFormNames.forEach(formName => {
        dispatch(reset(formName));
      });
    } else {
      if (buildingData.devcosts_updated) {
        dispatch(openProformaUpdatedModal());
      }
      if (is.id(currentMainBuildingId)) {
        dispatch(setProjectSiteMainBuildingId(currentMainBuildingId));
      }
    }
    dispatch(toggleEditProgram(false));
    dispatch(toggleEditZoning(false));
    dispatch(buildingsActions.changeEditBuildingMode(false));
    dispatch(buildingsActions.clearVirtualZoning());
    dispatch(buildingsActions.clearVirtualPrograms());
    dispatch(toggleAddBuildingModal(false));
    dispatch(setSelectedZoning({}));
    dispatch(setSelectedZoningName(""));
    dispatch(cleanSelectedPrograms());
  };
};
