/* eslint react/display-name: 0 */
import { startCase } from "lodash";
import React from "react";
import { Message } from "semantic-ui-react";
import { IntegrationError } from "../../lib/errors";

export default (props: { integrationError: IntegrationError }) => (
  <Message color="red">
    <Message.Header> {props.integrationError.name} </Message.Header>
    We could not retrieve our {startCase(props.integrationError.layerName)} layer.{" "}
    {props.integrationError.errorMessage}
  </Message>
);
