/**
 * Created by jmartinez on 9/18/18.
 */

import { omit, isEmpty, isNull, filter, orderBy } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, submit, formValueSelector } from "redux-form";
import { Button, Form, Grid, Dropdown, Segment, Popup, Icon, Modal } from "semantic-ui-react";
import {
  zoningSteps,
  programSteps,
  required,
  hasDuplicateName,
  zoningInfoMessage,
  scenarioInfoMessage,
  programInfoMessage,
  multifamilyInfoMessage,
  singlefamilyInfoMessage,
  typologyTogglesFields
} from "../config/config";
import { isNullOrUndefined } from "../config/utils";
import { actions, ownerships, resources } from "../constants/permissions";
import * as buildingCtrl from "../controllers/buildings";
import * as programCtrl from "../controllers/programs";
import * as projectCtrl from "../controllers/projects";
import * as zoningCtrl from "../controllers/zoning";
import { userIsAllowedToAny } from "../helpers/permissions";
import { operations } from "../store/operations";
import * as programActions from "../store/programs";
import * as projectActions from "../store/projects";
import { getSelectedBuilding, getSiteBuildings } from "../store/selectors/projects";
import * as zoningActions from "../store/zoning";
import { BuildingTypeCode, isBuilding } from "../types/buildings";
import { exists, doesNotExist } from "../utils";
import { InformativeButton } from "./common";
import DeletePrograms from "./DeletePrograms";
import { CheckboxInput, isToggleChecked } from "./forms/CheckboxInput";
import TextInput from "./forms/TextInput";

const actionsObj = Object.assign(
  {},
  zoningActions,
  zoningCtrl,
  projectActions,
  projectCtrl,
  programActions,
  programCtrl,
  buildingCtrl
);

const footerButtons = ({
  dispatch,
  buildingGenerationOperation,
  selectedPrograms,
  multifamily_toggle,
  singlefamily_toggle,
  isEditable
}) => {
  const canCreateOrUpdate = userIsAllowedToAny(
    [actions.update, actions.create],
    [ownerships.all, ownerships.own, ownerships.organization],
    resources.buildingOption
  );
  return canCreateOrUpdate ? (
    <div>
      <InformativeButton
        operation={buildingGenerationOperation}
        disabled={
          buildingGenerationOperation.isLoading ||
          (!isToggleChecked(multifamily_toggle) && !isToggleChecked(singlefamily_toggle)) ||
          (isToggleChecked(multifamily_toggle) && isEmpty(selectedPrograms.multi_family)) ||
          (isToggleChecked(singlefamily_toggle) && isEmpty(selectedPrograms.single_family)) ||
          isEditable
        }
        onClick={() => {
          dispatch(submit("MainModalForm"));
        }}
      >
        Submit
      </InformativeButton>
    </div>
  ) : null;
};

export const MainModalFooter = connect(state => {
  return {
    buildingGenerationOperation: state.operations[operations.buildingGeneration],
    selectedPrograms: state.programs.selectedPrograms,
    multifamily_toggle: selector(state, "multifamily_toggle"),
    singlefamily_toggle: selector(state, "singlefamily_toggle"),
    isEditable: !isBuilding(getSelectedBuilding(state)) && state.buildings.editBuildingMode
  };
})(footerButtons);

class MainModalForm extends React.Component {
  componentDidMount() {
    const {
      apiGetZoningList,
      apiGetProgramsList,
      editBuildingMode,
      setSelectedProgramName
    } = this.props;
    apiGetZoningList();
    apiGetProgramsList();
    if (!editBuildingMode) {
      setSelectedProgramName(null);
    }
  }

  handleZoningConstraints(e) {
    e.preventDefault();
    this.props.setZoningInitialValues({});
    this.props.toggleEditZoning(false);
    this.props.toggleEditProgram(false);
    this.props.toggleWizardModal(!this.props.wizardModalOpen);
    this.props.setActiveModalForm(zoningSteps["zoning_step_1"]);
    this.props.cleanDerivedZoning();
  }

  handleMultifamilyProgram(e) {
    e.preventDefault();
    this.props.setProgramInitialValues({});
    this.props.toggleEditZoning(false);
    this.props.toggleEditMultiProgram(false);
    this.props.toggleWizardModal(!this.props.wizardModalOpen);
    this.props.setActiveModalForm(programSteps["program_step_1"]);
  }

  handleSinglefamilyProgram(e) {
    e.preventDefault();
    this.props.setProgramInitialValues({});
    this.props.toggleEditZoning(false);
    this.props.toggleEditSingleProgram(false);
    this.props.toggleWizardModal(!this.props.wizardModalOpen);
    this.props.setActiveModalForm("SingleFamilyAttachedStep");
  }

  handleZoningOptionClick(id) {
    this.props.toggleEditZoning(false);
    this.props.setZoningComplete(false);
    this.props.apiGetZoningInfo(id);
  }

  handleUserZoningOptionClick(id) {
    this.props.toggleEditZoning(false);
    this.props.setZoningComplete(false);
    this.props.apiGetUserZoningInfo(id);
  }

  handleMultiProgramOptionClick(id) {
    this.props.toggleEditMultiProgram(false);
    this.props.setMultiProgramComplete(false);
    this.props.apiGetProgramInfo(id);
  }

  handleSingleProgramOptionClick(id) {
    this.props.toggleEditSingleProgram(false);
    this.props.setSingleProgramComplete(false);
    this.props.apiGetProgramInfo(id);
  }

  handleToggleEditZoning(event) {
    event.preventDefault();
    const { selectedZoning, userZoningIsSelected } = this.props;
    this.props.toggleEditZoning(true);
    this.props.toggleEditProgram(false);

    this.props.setActiveModalForm(zoningSteps["zoning_step_1"]);
    const parentZoningId = this.props.selectedZoning["id"];
    const initValues = omit(this.props.selectedZoning, ["id", "name"]);

    this.props.setZoningInitialValues(initValues);
    if (isNullOrUndefined(selectedZoning.derived_zoning_id)) {
      this.props.setDerivedZoning({
        zoningId: userZoningIsSelected ? null : parentZoningId,
        zoning: selectedZoning
      });
    } else {
      this.props.apiGetDerivedZoning(selectedZoning.derived_zoning_id);
    }
    this.props.toggleWizardModal(!this.props.wizardModalOpen);
  }

  handleToggleEditMultifamilyPrograms(event) {
    event.preventDefault();
    this.props.toggleEditMultiProgram(true);
    this.props.toggleWizardModal(!this.props.wizardModalOpen);
    this.props.setActiveModalForm(programSteps["program_step_1"]);
    const initValues = omit(this.props.selectedPrograms.multi_family, [
      "name",
      "id",
      "isBuildingProgram"
    ]);
    Object.keys(initValues).forEach(key => {
      if (
        !isNull(initValues[key]) &&
        ![
          "multiple_buildings",
          "accessible_roof",
          ...typologyTogglesFields,
          "building_type"
        ].includes(key)
      ) {
        initValues[key] = initValues[key].toString();
      }
    });
    this.props.setProgramInitialValues(initValues);
    if (exists(this.props.selectedPrograms.multi_family["name"])) {
      this.props.setSelectedProgramName(this.props.selectedPrograms.multi_family["name"]);
    }
  }

  handleToggleEditSinglefamilyPrograms(event) {
    event.preventDefault();
    this.props.toggleEditSingleProgram(true);
    this.props.toggleWizardModal(!this.props.wizardModalOpen);
    this.props.setActiveModalForm("SingleFamilyAttachedStep");
    const initValues = omit(this.props.selectedPrograms.single_family, [
      "name",
      "id",
      "isBuildingProgram"
    ]);
    Object.keys(initValues).forEach(key => {
      if (
        !isNull(initValues[key]) &&
        ![
          "multiple_buildings",
          "accessible_roof",
          ...typologyTogglesFields,
          "building_type"
        ].includes(key)
      ) {
        initValues[key] = initValues[key].toString();
      }
    });
    this.props.setProgramInitialValues(initValues);
    if (exists(this.props.selectedPrograms.single_family["name"])) {
      this.props.setSelectedProgramName(this.props.selectedPrograms.single_family["name"]);
    }
  }

  render() {
    const {
      zoningList,
      listIsLoading,
      programList,
      programListIsLoading,
      selectedZoning,
      selectedPrograms,
      selectedProgramName,
      selectedProject,
      userDefinedZonings,
      editZoning,
      zoningIsComplete,
      programsIsComplete,
      editProgram,
      deletePrograms,
      selectedZoningName,
      editBuildingMode
    } = this.props;
    const zoningOptions = orderBy(zoningList, ["zoning_code"]).map((item, i) => {
      return (
        <Dropdown.Item
          key={i}
          text={
            selectedProject.parcel_data.zoning === item.zoning_code
              ? `${item.zoning_code} (Default)`
              : item.zoning_code
          }
          value={item.id}
          selected={item.id === selectedZoning.id}
          active={item.id === selectedZoning.id}
          onClick={(e, data) => {
            this.handleZoningOptionClick(data.value);
          }}
        />
      );
    });

    const userZoningOptions = orderBy(userDefinedZonings, ["name"]).map((item, i) => {
      return (
        <Dropdown.Item
          key={i}
          text={item.name}
          value={item.id}
          selected={item.id === selectedZoning.id}
          active={item.id === selectedZoning.id}
          onClick={(e, data) => {
            this.handleUserZoningOptionClick(data.value);
          }}
        />
      );
    });

    const multiProgramOptions = filter(
      programList,
      program => exists(program.name) && program.building_type.code === BuildingTypeCode.apartment
    ).map((item, i) => {
      return (
        <Dropdown.Item
          key={i}
          text={item.name}
          value={item.id}
          selected={
            exists(selectedPrograms.multi_family) &&
            item.name === selectedPrograms.multi_family.name
          }
          active={
            exists(selectedPrograms.multi_family) &&
            item.name === selectedPrograms.multi_family.name
          }
          onClick={(e, data) => {
            this.handleMultiProgramOptionClick(data.value);
          }}
        />
      );
    });

    const singleProgramOptions = filter(
      programList,
      program => exists(program.name) && program.building_type.code === BuildingTypeCode.nplex
    ).map((item, i) => {
      return (
        <Dropdown.Item
          key={i}
          text={item.name}
          value={item.id}
          selected={
            exists(selectedPrograms.single_family) &&
            item.name === selectedPrograms.single_family.name
          }
          active={
            exists(selectedPrograms.single_family) &&
            item.name === selectedPrograms.single_family.name
          }
          onClick={(e, data) => {
            this.handleSingleProgramOptionClick(data.value);
          }}
        />
      );
    });

    const zoningAddButton = (
      <Button
        name="add_zoning"
        circular
        icon="add"
        onClick={e => {
          this.handleZoningConstraints(e);
        }}
      />
    );

    const zoningEditButton = (
      <Button
        name="edit_zoning"
        circular
        icon="edit"
        disabled={doesNotExist(selectedZoning)}
        loading={this.props.operations.zoningInfo.isLoading}
        onClick={e => {
          this.handleToggleEditZoning(e);
        }}
      />
    );

    const programEditButton = (isDisabled, handleOnClick) => (
      <Button
        circular
        icon="edit"
        disabled={isDisabled}
        loading={programListIsLoading}
        onClick={e => {
          handleOnClick.call(this, e);
        }}
      />
    );

    const programAddButton = (isDisabled, handleOnClick) => (
      <Button
        circular
        icon="add"
        onClick={e => {
          handleOnClick.call(this, e);
        }}
        disabled={isDisabled}
      />
    );

    const canCreateBuildingOption = userIsAllowedToAny(
      actions.create,
      ownerships.all,
      resources.buildingOption
    );

    const canReadZoning = userIsAllowedToAny(
      actions.read,
      [ownerships.own, ownerships.organization],
      resources.customZoning
    );
    const canCreateZoning = userIsAllowedToAny(
      actions.create,
      ownerships.all,
      resources.customZoning
    );

    const canReadProgram = userIsAllowedToAny(
      actions.read,
      [ownerships.own, ownerships.organization],
      resources.customProgram
    );
    const canCreateProgram = userIsAllowedToAny(
      actions.create,
      ownerships.all,
      resources.customProgram
    );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Form
          onSubmit={this.props.handleSubmit}
          loading={doesNotExist(selectedZoning) && this.props.operations.zoningInfo.isLoading}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Segment>
                  <Field
                    name="scenario-name"
                    component={TextInput}
                    placeholder="Scenario Name"
                    label="Scenario Name"
                    validate={editBuildingMode ? [required] : [required, hasDuplicateName]}
                    hasInfo={true}
                    transparent={!canCreateBuildingOption}
                    readOnly={!canCreateBuildingOption}
                    infoText={scenarioInfoMessage}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Segment>
                  <label className="form-label">
                    Zoning Options{" "}
                    {
                      <Popup
                        trigger={<Icon name="info circle" style={{ opacity: 0.4 }} />}
                        content={zoningInfoMessage}
                      />
                    }
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      justifyContent: "flex-start",
                      alignItems: "stretch",
                      alignContent: "stretch"
                    }}
                  >
                    <Dropdown
                      fluid
                      placeholder="Parcel Zoning"
                      loading={listIsLoading}
                      onChange={(e, data) => {
                        this.handleZoningOptionClick(data.value);
                      }}
                      value={selectedZoning.id || selectedZoningName}
                      text={selectedZoningName}
                      icon={
                        <Icon
                          name="caret down"
                          style={{
                            position: "absolute",
                            width: "auto",
                            height: "auto",
                            lineHeight: "1.21428571em",
                            top: ".78571429em",
                            right: "1em",
                            zIndex: "3",
                            margin: "-.78571429em",
                            padding: ".91666667em",
                            opacity: "0.8"
                          }}
                        />
                      }
                      style={{
                        width: "75%",
                        padding: ".78571429em 2.1em .78571429em 1em",
                        border: `1px solid ${
                          editZoning && zoningIsComplete ? "purple" : "rgba(34,36,38,.15)"
                        }`,
                        borderRadius: ".28571429rem"
                      }}
                    >
                      <Dropdown.Menu>
                        <Dropdown
                          scrolling
                          text="Region Specific"
                          pointing="left"
                          className="link item"
                        >
                          <Dropdown.Menu>{zoningOptions}</Dropdown.Menu>
                        </Dropdown>
                        <Dropdown
                          scrolling
                          text="User Defined"
                          pointing="left"
                          className="link item"
                          disabled={userDefinedZonings.length === 0}
                        >
                          <Dropdown.Menu>{userZoningOptions}</Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div style={{ marginLeft: "10px" }}>
                      {canCreateZoning || canReadZoning ? (
                        <Popup
                          trigger={zoningEditButton}
                          content={`${canCreateZoning ? "Edit" : "View"} zoning constraints`}
                        />
                      ) : null}
                      {canCreateZoning ? (
                        <Popup trigger={zoningAddButton} content="Add new zoning option" />
                      ) : null}
                    </div>
                  </div>
                  {editZoning && zoningIsComplete ? (
                    <span style={{ color: "purple" }}>Edited</span>
                  ) : null}
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <Segment>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px"
                    }}
                  >
                    <label className="form-label">
                      Program Options{" "}
                      {
                        <Popup
                          trigger={<Icon name="info circle" style={{ opacity: 0.4 }} />}
                          content={programInfoMessage}
                        />
                      }
                    </label>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <label>
                      Multifamily{" "}
                      {
                        <Popup
                          trigger={<Icon name="info circle" style={{ opacity: 0.4 }} />}
                          content={multifamilyInfoMessage}
                        />
                      }
                    </label>
                    <Field
                      name="multifamily_toggle"
                      component={CheckboxInput}
                      toggle
                      style={{ marginLeft: "8px" }}
                      readOnly={this.readOnlyMode}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      justifyContent: "flex-start",
                      alignItems: "stretch",
                      alignContent: "stretch",
                      marginBottom: "20px"
                    }}
                  >
                    <Dropdown
                      name="multifamily_dropdown"
                      fluid
                      selection
                      loading={programListIsLoading}
                      style={{
                        width: "75%",
                        border: `1px solid ${
                          editProgram.multi_family && programsIsComplete.multi_family
                            ? "purple"
                            : "rgba(34,36,38,.15)"
                        }`
                      }}
                      text={
                        isEmpty(selectedPrograms.multi_family) || programListIsLoading
                          ? "Choose a Building Program"
                          : exists(selectedPrograms.multi_family.name)
                          ? selectedPrograms.multi_family.name
                          : selectedProgramName
                      }
                      disabled={!isToggleChecked(this.props.multifamilyToggle)}
                    >
                      <Dropdown.Menu>
                        {multiProgramOptions}
                        <Dropdown.Divider />
                      </Dropdown.Menu>
                    </Dropdown>
                    <div style={{ marginLeft: "10px" }}>
                      {canCreateProgram || canReadProgram ? (
                        <Popup
                          trigger={programEditButton(
                            !isToggleChecked(this.props.multifamilyToggle) ||
                              isEmpty(selectedPrograms.multi_family),
                            this.handleToggleEditMultifamilyPrograms
                          )}
                          content={`${canCreateProgram ? "Edit" : "View"} building program`}
                        />
                      ) : null}
                      {canCreateProgram ? (
                        <Popup
                          trigger={programAddButton(
                            !isToggleChecked(this.props.multifamilyToggle),
                            this.handleMultifamilyProgram
                          )}
                          content="Add new building program"
                        />
                      ) : null}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <label>
                      Single Family Attached{" "}
                      {
                        <Popup
                          trigger={<Icon name="info circle" style={{ opacity: 0.4 }} />}
                          content={singlefamilyInfoMessage}
                        />
                      }
                    </label>
                    <Field
                      name="singlefamily_toggle"
                      component={CheckboxInput}
                      toggle
                      style={{ marginLeft: "8px" }}
                      readOnly={this.readOnlyMode}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      justifyContent: "flex-start",
                      alignItems: "stretch",
                      alignContent: "stretch",
                      marginBottom: "20px"
                    }}
                  >
                    <Dropdown
                      name="singlefamily_dropdown"
                      fluid
                      selection
                      loading={programListIsLoading}
                      style={{
                        width: "75%",
                        border: `1px solid ${
                          editProgram.single_family && programsIsComplete.single_family
                            ? "purple"
                            : "rgba(34,36,38,.15)"
                        }`
                      }}
                      text={
                        isEmpty(selectedPrograms.single_family) || programListIsLoading
                          ? "Choose a Building Program"
                          : exists(selectedPrograms.single_family.name)
                          ? selectedPrograms.single_family.name
                          : selectedProgramName
                      }
                      disabled={!isToggleChecked(this.props.singlefamilyToggle)}
                    >
                      <Dropdown.Menu>
                        {singleProgramOptions}
                        <Dropdown.Divider />
                      </Dropdown.Menu>
                    </Dropdown>
                    <div style={{ marginLeft: "10px" }}>
                      {canCreateProgram || canReadProgram ? (
                        <Popup
                          trigger={programEditButton(
                            !isToggleChecked(this.props.singlefamilyToggle) ||
                              isEmpty(selectedPrograms.single_family),
                            this.handleToggleEditSinglefamilyPrograms
                          )}
                          content={`${canCreateProgram ? "Edit" : "View"} building program`}
                        />
                      ) : null}
                      {canCreateProgram ? (
                        <Popup
                          trigger={programAddButton(
                            !isToggleChecked(this.props.singlefamilyToggle),
                            this.handleSinglefamilyProgram
                          )}
                          content="Add new building program"
                        />
                      ) : null}
                    </div>
                  </div>

                  {(editProgram.multi_family && programsIsComplete.multi_family) ||
                  (editProgram.single_family && programsIsComplete.single_family) ? (
                    <span style={{ color: "purple" }}>Edited</span>
                  ) : null}
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        <Modal size="mini" open={deletePrograms}>
          <Modal.Header>
            Delete Program Options
            <Button
              style={{ float: "right" }}
              circular
              icon="cancel"
              onClick={() => {
                this.props.toggleDeletePrograms(false);
              }}
            />
          </Modal.Header>
          <Modal.Content>
            <DeletePrograms />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const selector = formValueSelector("MainModalForm");

const MainModalFormForm = reduxForm({
  form: "MainModalForm"
})(MainModalForm);

const mapStateToProps = state => {
  const selectedBuilding = getSelectedBuilding(state);
  const buildingType = selectedBuilding.building_type;
  let initialMultifamilyToggle = true;
  if (state.buildings.editBuildingMode && exists(buildingType)) {
    initialMultifamilyToggle = buildingType.code === BuildingTypeCode.apartment;
  }

  return {
    operations: {
      zoningInfo: state.operations[operations.getZoningInfo]
    },
    zoningIsComplete: state.zoning.isComplete,
    programsIsComplete: state.programs.programsIsComplete,
    listIsLoading: state.zoning.listIsLoading,
    zoningList: state.zoning.parcelZoning,
    userDefinedZoning: state.zoning.userDefinedZoning,
    userDefinedZonings: state.zoning.userDefinedZonings,
    selectedZoning: state.zoning.selectedZoning,
    userZoningIsSelected: state.zoning.userZoningIsSelected,
    programListIsLoading: state.programs.programListIsLoading,
    programList: state.programs.programList,
    selectedPrograms: state.programs.selectedPrograms,
    selectedProgramName: state.programs.selectedProgramName,
    wizardModalOpen: state.projects.wizardModalOpen,
    selectedProject: state.projects.selectedProject,
    editZoning: state.zoning.editZoning,
    editProgram: state.programs.editProgram,
    deletePrograms: state.programs.deletePrograms,
    selectedZoningName: state.zoning.selectedZoningName,
    allBuildings: getSiteBuildings(state),
    editBuildingMode: state.buildings.editBuildingMode,
    selectedBuilding,
    multifamilyToggle: selector(state, "multifamily_toggle"),
    singlefamilyToggle: selector(state, "singlefamily_toggle"),
    // initialValues is used as the redux-form prop
    initialValues: {
      "scenario-name": state.buildings.editBuildingMode ? selectedBuilding.name : "",
      multifamily_toggle: initialMultifamilyToggle,
      singlefamily_toggle: !initialMultifamilyToggle
    }
  };
};

export default connect(mapStateToProps, actionsObj)(MainModalFormForm);
