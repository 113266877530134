import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { buildingDataStatus } from "../constants/status";
import {
  BuildingsSlice,
  FloorplanVisualizationObject,
  Zoning,
  MultifamilyProgram,
  SinglefamilyProgram
} from "./types";

const initialState: BuildingsSlice = {
  floorplanVisualizationIsOpen: false,
  floorplanVisualizationInformation: [],
  visualizationFloorIdx: 0,
  currentBuildingDataStatus: buildingDataStatus.nonExistant,
  editBuildingMode: false,
  renameBuildingModalOpen: false,
  virtualZoning: null,
  virtualPrograms: {
    multifamily: null,
    singlefamily: null
  },
  flags: {
    buildingIsLoading: false
  }
};

const buildingsSlice = createSlice({
  name: "buildings",
  initialState,
  reducers: {
    toggleFloorLayout(state, action: PayloadAction<boolean>) {
      state.floorplanVisualizationIsOpen = action.payload;
      state.visualizationFloorIdx = 0;
    },
    setVisualizationFloorIdx(state, action: PayloadAction<number>) {
      state.visualizationFloorIdx = action.payload;
    },
    setFloorplanVisualizationInformation(
      state,
      action: PayloadAction<{ floorplanVisualizationInformation: FloorplanVisualizationObject[] }>
    ) {
      state.floorplanVisualizationInformation = action.payload.floorplanVisualizationInformation;
    },
    changeEditBuildingMode(state, action: PayloadAction<boolean>) {
      state.editBuildingMode = action.payload;
    },
    startedRetrievingBuildingData(state) {
      state.currentBuildingDataStatus = buildingDataStatus.beingRetrieved;
    },
    buildingDataRetrievalFailed(state) {
      state.currentBuildingDataStatus = buildingDataStatus.nonExistant;
    },
    retrievedBuildingData(state) {
      state.currentBuildingDataStatus = buildingDataStatus.ready;
    },
    toggleRenameBuildingModal(state, action: PayloadAction<boolean>) {
      state.renameBuildingModalOpen = action.payload;
    },
    setVirtualZoning(state, action: PayloadAction<Zoning>) {
      state.virtualZoning = action.payload;
    },
    clearVirtualZoning(state) {
      state.virtualZoning = {};
    },
    setVirtualMultifamilyProgram(state, action: PayloadAction<MultifamilyProgram>) {
      state.virtualPrograms.multifamily = action.payload;
    },
    setVirtualSinglefamilyProgram(state, action: PayloadAction<SinglefamilyProgram>) {
      state.virtualPrograms.singlefamily = action.payload;
    },
    clearVirtualPrograms(state) {
      state.virtualPrograms.multifamily = {};
      state.virtualPrograms.singlefamily = {};
    },
    startedLoadingBuilding(state) {
      state.flags.buildingIsLoading = true;
    },
    finishedLoadingBuilding(state) {
      state.flags.buildingIsLoading = false;
    }
  }
});

export const {
  toggleFloorLayout,
  setVisualizationFloorIdx,
  setFloorplanVisualizationInformation,
  changeEditBuildingMode,
  startedRetrievingBuildingData,
  buildingDataRetrievalFailed,
  retrievedBuildingData,
  toggleRenameBuildingModal,
  setVirtualZoning,
  clearVirtualZoning,
  setVirtualMultifamilyProgram,
  setVirtualSinglefamilyProgram,
  clearVirtualPrograms,
  startedLoadingBuilding,
  finishedLoadingBuilding
} = buildingsSlice.actions;

export default buildingsSlice.reducer;
