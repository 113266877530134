import { Modes } from "../../constants";
import ConfirmSubdivisionMode from "./ConfirmSubdivisionMode";
import DrawLineMode from "./DrawLineMode";
import DrawPolygon from "./DrawPolygon";
import EditLineMode from "./EditLineMode";
import EditPolygon from "./EditPolygon";
import RemoveSelectedMode from "./RemoveSelectedMode";
import SiteSelected from "./SiteSelected";

export default {
  [Modes.site_selected]: SiteSelected,
  [Modes.draw_line_string]: DrawLineMode,
  [Modes.confirm_subdivision]: ConfirmSubdivisionMode,
  [Modes.remove_selected]: RemoveSelectedMode,
  [Modes.edit_line]: EditLineMode,
  [Modes.draw_polygon]: DrawPolygon,
  [Modes.edit_polygon]: EditPolygon
};
