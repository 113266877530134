import { Image, Link, Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import logoWhite from "../../data/images/logo-white.png";

const styles = StyleSheet.create({
  footer: {
    backgroundColor: "#22556A",
    color: "#E5E5E5",
    width: "100%",
    height: "8.5mm",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    bottom: 0
  },
  footerText: {
    fontWeight: 500,
    fontSize: 6,
    marginLeft: "2mm"
  },
  logo: { marginLeft: "3mm", width: "6mm", height: "6mm" }
});

const Footer: React.FunctionComponent<{}> = () => {
  return (
    <View style={styles.footer}>
      <Image style={styles.logo} src={logoWhite} />
      <Text style={styles.footerText}>
        {"Site Analysis provided by UrbanSim Penciler. Find out more at "}
        <Link src={"https://penciler.org"}>penciler.org</Link>
      </Text>
    </View>
  );
};

export default Footer;
