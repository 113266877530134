import { is } from "./index";

export class ParcelCandidate {
  constructor(parcelId, address) {
    if (!is.id(parcelId) && !is.nonEmptyString(address)) {
      throw new Error("Parcel candidates must have a valid address or a valid parcel ID.");
    }

    this.parcelId = parcelId;
    this.address = address;
  }

  get isParcelId() {
    return is.id(this.parcelId);
  }

  get isAddress() {
    return is.nonEmptyString(this.address);
  }
}
