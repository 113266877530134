import { Service } from "axios-middleware";
import axios from "axios/index";
import { baseUrl, ucUrl } from "./config/urls";
import { exists } from "./utils";

const service = new Service(axios);

service.register({
  onRequest(config) {
    if (config.url.includes(baseUrl)) {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    } else if (config.url.includes(ucUrl) && exists(localStorage.getItem("uc_token"))) {
      config.headers.urbancanvastk = localStorage.getItem("uc_token");
    }
    return config;
  }
});

export default service;
