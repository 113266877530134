import { is } from "./";

// Custom errors (https://stackoverflow.com/a/5251506/2923526)
export function NotFoundError(message) {
  this.name = "NotFoundError";
  this.message = message;
  this.stack = new Error().stack;
}
NotFoundError.prototype = new Error();

export function IntegrationError(layerName, errorMessage, reason = {}) {
  reason.missingLayer = reason.missingLayer || false;
  reason.failingTileFetch = reason.failingTileFetch || false;
  this.name = reason.missingLayer
    ? "Missing Layer"
    : reason.failingTileFetch
    ? "Failing Tile Fetch"
    : "Integration Error";
  this.id = `${
    reason.missingLayer
      ? "missing-layer"
      : reason.failingTileFetch
      ? "failing-tile-fetch"
      : "integration-error"
  }:${layerName}`;
  this.layerName = layerName;
  this.errorMessage = errorMessage;
  this.stack = new Error().stack;
}
IntegrationError.prototype = new Error();

export function InvariantError(message) {
  this.name = "InvariantError";
  this.message = message;
  this.stack = new Error().stack;
}
InvariantError.prototype = new Error();
// Object to group assertion including throwing an exception when assertions are not respected.
export const assert = {
  // Check if the input condition is respected.
  invariant(errorMessage, condition) {
    const result = condition();
    const invariantIsValid = is.boolean(result) && result;
    if (!invariantIsValid) {
      throw new InvariantError(errorMessage);
    }
  },
  // Check if the input item is an id
  id(errorMessage, item) {
    if (!is.id(item)) {
      throw new InvariantError(errorMessage);
    }
  },
  // Check if the input item is a string
  string(errorMessage, item) {
    if (!is.string(item)) {
      throw new InvariantError(errorMessage);
    }
  }
};
