import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IncompatibleConstraintsSlice } from "../slices/ui";
import { IncompatibleConstraintsData } from "../types";

const initialState: IncompatibleConstraintsSlice = {
  detected: false,
  data: { message: "", suggestions: [] }
};

const slice = createSlice({
  name: "incompatibleConstraints",
  initialState,
  reducers: {
    setIncompatibleContraintsSuggestions(
      state,
      action: PayloadAction<IncompatibleConstraintsData>
    ) {
      state.detected = true;
      state.data = action.payload;
    },
    closeSuggestionsModal(state) {
      state.detected = false;
      state.data = { message: "", suggestions: [] };
    }
  }
});

export const { closeSuggestionsModal, setIncompatibleContraintsSuggestions } = slice.actions;

export default slice.reducer;
