import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DevcostsData, RentProfile } from "../types/proforma";
import { ProformaSlice } from "./types";

const initialState: ProformaSlice = {
  proformaBuildingId: -1,
  rentProfileSpinner: false,
  devcostsData: {},
  devcostsMetric: undefined,
  toggleRentProfile: false,
  eligibleBasis: 0,
  rentProfiles: []
};

const proformaSlice = createSlice({
  name: "proforma",
  initialState,
  reducers: {
    setProformaBuildingId(state, action: PayloadAction<number>) {
      state.proformaBuildingId = action.payload;
    },
    toggleRentProfileModal(state, action: PayloadAction<boolean>) {
      state.toggleRentProfile = action.payload;
    },
    setRentProfileSpinner(state, action: PayloadAction<boolean>) {
      state.rentProfileSpinner = action.payload;
    },
    setDevcostsData(state, action: PayloadAction<DevcostsData>) {
      state.devcostsData = action.payload;
    },
    setDevcostsMetric(state, action: PayloadAction<boolean>) {
      state.devcostsMetric = action.payload;
    },
    setRentProfiles(state, action: PayloadAction<RentProfile[]>) {
      state.rentProfiles = action.payload;
    },
    setEligibleBasis(state, action: PayloadAction<number>) {
      state.eligibleBasis = action.payload;
    }
  }
});

export const {
  setProformaBuildingId,
  toggleRentProfileModal,
  setRentProfileSpinner,
  setDevcostsData,
  setDevcostsMetric,
  setRentProfiles,
  setEligibleBasis
} = proformaSlice.actions;

export default proformaSlice.reducer;
