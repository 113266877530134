import { isEmpty, pick } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Form, Segment } from "semantic-ui-react";
import { isZeroOrPositiveNumeric } from "../config/config";
import { getDistanceLabel } from "../config/utils";
import TextInput from "./forms/TextInput";
import WizardTextInput from "./forms/WizardTextInput";
import WizardStep from "./WizardStep";

class SetbacksFt extends WizardStep {
  render() {
    const { editMode, derivedZoningData } = this.props;
    const originalData = editMode ? { originalData: derivedZoningData } : {};
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <Segment>
          <Field
            name="front_setback"
            component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
            label="Front Setback:"
            unitLabel={getDistanceLabel()}
            labelPosition="right"
            validate={[isZeroOrPositiveNumeric]}
            {...originalData}
            readOnly={this.readOnlyMode}
          />
          <Field
            name="side_setback"
            component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
            label="Side Setback:"
            unitLabel={getDistanceLabel()}
            labelPosition="right"
            validate={[isZeroOrPositiveNumeric]}
            {...originalData}
            readOnly={this.readOnlyMode}
          />
          <Field
            name="rear_yard_setback"
            component={editMode && !this.readOnlyMode ? WizardTextInput : TextInput}
            label="Rear Yard Setback:"
            unitLabel={getDistanceLabel()}
            labelPosition="right"
            validate={[isZeroOrPositiveNumeric]}
            {...originalData}
            readOnly={this.readOnlyMode}
          />
          <label className="textarea-label">Setback Notes:</label>
          <Field
            name="setback_notes"
            component="textarea"
            label="Setback Notes"
            rows={3}
            {...originalData}
            readOnly={this.readOnlyMode}
          />
        </Segment>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  const { zoningInitialValues } = state.zoning;
  const zoningExists = !isEmpty(zoningInitialValues);
  const initVal = Object.assign({}, zoningInitialValues);

  return {
    editMode: zoningExists,
    initialValues: pick(initVal, [
      "front_setback",
      "side_setback",
      "rear_yard_setback",
      "setback_notes"
    ]),
    derivedZoningData: state.zoning.derivedZoningData
  };
};

const SetbacksFtForm = reduxForm({
  form: "SetbacksFtForm",
  destroyOnUnmount: false
})(SetbacksFt);

export default connect(mapStateToProps)(SetbacksFtForm);
